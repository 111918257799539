<div *ngIf="(userManagementService.getUserRole() | async ) === 'user' || (userManagementService.getUserRole() | async ) === 'admin'">

  
  <si-newton-header mobileTitle="LCS Calculation">

    <ul si-newton-header-menu>
      <li siNewtonHeaderMenuItem>
        <a class="nav-link" routerLink="/">Home</a>
      </li>
      <li siNewtonHeaderMenuItem title="Projects">
        <a class="nav-link" routerLink="projects">
          My Projects
        </a>
      </li>
      <li siNewtonHeaderMenuItem title="Calculation">
        <a class="nav-link" routerLink="general-information">
          Calculation
        </a>
      </li>
  
      <li siNewtonHeaderMenuItem title="Admin" *ngIf="(userManagementService.getUserRole() | async) === 'admin'">
        <a class="nav-link" routerLink="admin-view">
          Admin View
        </a>
      </li>

    </ul>
  
    <si-newton-header-sag-logo class="navbar-header"></si-newton-header-sag-logo>
  
    <ul siNewtonHeaderTopRightMenu>
      <li class="application-title">
        {{projectManagementService.projectName$ | async}}
      </li>
      <li siNewtonHeaderMenuItem>
        <a class="nav-link" href="">
          <span>Home</span>
        </a>
      </li>
    </ul>

  </si-newton-header>
  
  <main [@routerFadeAnimation]="getRouteAnimation(route)">
    <si-newton-breadcrumb></si-newton-breadcrumb>

    <router-outlet #route="outlet"></router-outlet>
  </main>
  
  <si-newton-footer>
    LCS Calculation <span style="float: right;">V2.4</span>
  </si-newton-footer>
  
</div>
<div *ngIf="(userManagementService.getUserRole() | async ) === 'noRole'"> 
  
  <si-newton-section>
    <b>You dont have rights to access this site. Please ask for access.</b>
  </si-newton-section>
</div>