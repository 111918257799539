<form [formGroup]="parentForm" style="margin: auto;">
  <div formGroupName="generalProjectParameter">
    <h5 class="subSectionHeader">General Project Parameter</h5>
    <div class="row">
      <div class="col-lg-4 col-md-5 col-sm-12 col-xs-12">
        <si-newton-form-group label="Customer name" [errorResolverMap]="{required: 'Value is required'}">
          <input formControlName="customerName" siNewton>
        </si-newton-form-group>
      </div>

      <div class="col-lg-4 col-md-5 col-sm-12 col-xs-12">
        <si-newton-form-group label="Project name" [errorResolverMap]="{required: 'Value is required'}">
          <input formControlName="projectName" siNewton>
        </si-newton-form-group>
      </div>
    </div>

    <div class="row">

      <div class="col-lg-4 col-md-5 col-sm-12 col-xs-12">
        <si-newton-form-group label="Country" [errorResolverMap]="{required: 'Value is required'}">
          <input formControlName="country" siNewton>
        </si-newton-form-group>
      </div>

      <div class="col-lg-4 col-md-5 col-sm-12 col-xs-12">
        <si-newton-form-group label="Location" [errorResolverMap]="{required: 'Value is required'}">
          <input formControlName="location" siNewton>
        </si-newton-form-group>
      </div>
    </div>

    <div class="row">

      <div class="col-lg-4 col-md-5 col-sm-12 col-xs-12">
        <si-newton-form-group label="Production of" [errorResolverMap]="{required: 'Value is required'}">
          <input formControlName="productionOf" siNewton>
        </si-newton-form-group>
      </div>

      <div class="col-lg-4 col-md-5 col-sm-12 col-xs-12">
        <si-newton-form-group label="Plant type" [errorResolverMap]="{required: 'Please select an option'}">
          <si-newton-dropdown
            [dropdownOptions]="getDropdownOptions('plantType')"
            [valueProvider]="getDropdownValue"
            formControlName="plantType"></si-newton-dropdown>
        </si-newton-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-4 col-md-5 col-sm-12 col-xs-12">
        <si-newton-form-group label="Project Type" [errorResolverMap]="{required: 'Please select an option'}">
          <si-newton-dropdown
            [dropdownOptions]="getDropdownOptions('projectType')"
            [valueProvider]="getDropdownValue"
            formControlName="projectType"></si-newton-dropdown>
        </si-newton-form-group>
      </div>

      <div class="col-lg-4 col-md-5 col-sm-12 col-xs-12">
        <si-newton-form-group label="Current PCS 7 Version" [errorResolverMap]="{required: 'Value is required'}">
          <input formControlName="currentPCS7version" siNewton>
        </si-newton-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-4 col-md-5 col-sm-12 col-xs-12">
        <si-newton-form-group label="LCS Offer Type" [errorResolverMap]="{required: 'Please select an option'}">
          <si-newton-dropdown
            [dropdownOptions]="getDropdownOptions('lcsOfferType')"
            [valueProvider]="getDropdownValue"
            formControlName="lcsOfferType"></si-newton-dropdown>
        </si-newton-form-group>

      </div>

      <div class="col-lg-4 col-md-5 col-sm-12 col-xs-12">
        <si-newton-form-group label="LoA Requirements" [errorResolverMap]="{required: 'Value is required'}">
          <input formControlName="loaRequirements" readOnly siNewton>
        </si-newton-form-group>
      </div>

    </div>

    <div class="row">
      <div class="col-lg-4 col-md-5 col-sm-12 col-xs-12">
        <si-newton-form-group label="SW Application Based on" [errorResolverMap]="{required: 'Please select an option'}">
          <si-newton-dropdown
            [dropdownOptions]="getDropdownOptions('swApplicationBasedOn')"
            [valueProvider]="getDropdownValue"
            formControlName="swApplicationBasedOn"></si-newton-dropdown>
        </si-newton-form-group>
      </div>

      <div class="col-lg-4 col-md-5 col-sm-12 col-xs-12">
        <si-newton-form-group label="Siemens BU Region" [errorResolverMap]="{required: 'Value is required'}">
          <input formControlName="siemensBURegion" siNewton>
        </si-newton-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-4 col-md-5 col-sm-12 col-xs-12">
        <si-newton-form-group label="Year of LCS Calculation" [errorResolverMap]="{required: 'Please select an option'}">
          <si-newton-dropdown
            [dropdownOptions]="getDropdownOptions('yearOfLCSCalculation')"
            [valueProvider]="getDropdownValue"
            formControlName="yearOfLCSCalculation"></si-newton-dropdown>
        </si-newton-form-group>
      </div>

      <div class="col-lg-4 col-md-5 col-sm-12 col-xs-12">
        <si-newton-form-group label="Year of Start" [errorResolverMap]="{required: 'Please select an option'}">
          <si-newton-dropdown
            [dropdownOptions]="getDropdownOptions('yearOfStart')"
            [valueProvider]="getDropdownValue"
            formControlName="yearOfStart"></si-newton-dropdown>
        </si-newton-form-group>
      </div>
    </div>
  </div>
</form>
