<form [formGroup]="parentForm">
  <div formGroupName="remoteServiceModules">

    <h5 class="subSectionHeader">Remote Service Modules
      <i style="float: right; cursor: pointer"
      [ngClass]="{'bi-arrow-up-circle-fill': isThisSectionVisible, 'bi-arrow-down-circle-fill': !isThisSectionVisible}"
      (click)="toggleVisibility()"></i>
    </h5>

    <div [ngStyle]="{'display': isThisSectionVisible ? 'block' : 'none'}">

      <div class="row">
        <div class="col-3">
          <si-newton-form-group label="Risk Addition" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers are allowed here'}">
            <div class="input-group-prepend">
              <span class="input-group-text">%</span>
            </div>
            <input formControlName="remoteServiceRiskAddition" siNewton type="number" step="any" [min]="0">
          </si-newton-form-group>
        </div>
        <div class="col-9">
          <p class="info">Risk Addition does impact for all services without mlfb</p>
        </div>
      </div>

      <div class="row" style="margin-bottom: 5px;">
        <div class="col">
          <b class="subSubSectionHeader">Add a new Remote Service</b>
        </div>

      </div>

      <div class="row">

        <div class="col">
          <si-newton-form-group label="MLFB" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="newRemoteServiceMlfb" siNewton>
          </si-newton-form-group>
        </div>

        <div class="col">
          <si-newton-form-group label="Name" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="newRemoteServiceName" siNewton>
          </si-newton-form-group>
        </div>

        <div class="col">
          <si-newton-form-group label="Description" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="newRemoteServiceDescription" siNewton>
          </si-newton-form-group>
        </div>

        <div class="col">
          <si-newton-form-group label="Period" [errorResolverMap]="{required: 'Please select an option'}">
            <si-newton-dropdown
              [dropdownOptions]="getDropdownOptions('remoteServicePeriod')"
              [valueProvider]="getDropdownValue"
              formControlName="newRemoteServicePeriod"></si-newton-dropdown>
          </si-newton-form-group>

        </div>


        <div class="col">
          <si-newton-form-group label="Quantity" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers are allowed here'}">
            <input formControlName="newRemoteServiceQuantity" siNewton type="number" [min]="0">
          </si-newton-form-group>
        </div>

      </div>

      <div class="row">
        <div class="col-md-9">

        </div>

        <div class="col-md-3">
          <button type="button" class="btn btn-success" style="float: right;" [disabled]="!isNewServiceValid()" (click)="updateRemoteServiceList()"
          >Add Service</button>
        </div>
      </div>

      <div class="row">
        <div *ngIf="areNullValuesInPricesPresent === true">
          <p class="warnInfo">
            There are Components with no price. This could mean that we could'nt get the prices. Please adapt manually. </p>
        </div>

      </div>

      <div  *ngFor="let _ of [].constructor(allRemoteServiceModules.length), let x = index">
        <div class="customCard">
          <div class="row">
            <div class="col-12">
              <p><b>{{allRemoteServiceModules[x].serviceName}}</b></p>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
              <p><b>MLFB</b></p>
              {{allRemoteServiceModules[x].mlfb}}
            </div>
  
            <div class="col-lg-2 col-md-3 col-sm-6 col-xs-12">
              <p><b>Period</b></p>
              {{allRemoteServiceModules[x].period}}
            </div>

            <div class="col-lg-2 col-md-3 col-sm-6 col-xs-12">
              <p><b>Quantity</b></p>
              {{allRemoteServiceModules[x].quantity}}
            </div>
            <div class="col-lg-2 col-md-3 col-sm-6 col-xs-12">
              <si-newton-form-group label="Service Cost">
                <div class="input-group-prepend">
                  <span class="input-group-text">€</span>
                </div>
                <input formControlName="remoteServiceCost{{(x+1)}}" type="number" step="any" [min]="0" siNewton>
              </si-newton-form-group>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-6 col-xs-12">
              <p><b>Selected?</b></p>
              <div class="form-check">
                <input class="form-check-input" formControlName="remoteServiceSelected{{(x+1)}}" type="checkbox">
              </div>
            </div>

            <div class="col-lg-2 col-md-3 col-sm-6 col-xs-12">
              <button title="Delete Service" class="btn-danger" (click)="deleteService(allRemoteServiceModules[x].serviceNumber)">
                <i class="bi bi-trash"></i>
              </button>
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <p><b>Description</b></p>
              
              {{allRemoteServiceModules[x].serviceDescription || 'No detailed description available'}}
            </div>


          </div>
        </div>
      </div>

<!-- 
      <si-newton-table [rows]="allRemoteServiceModules"
            [loading]="allRemoteServiceModules.length === 0" [rowsPerPage]="25">

            <siNewtonTableColumn key="serviceName" name="Name" [widthFactor]="1.2">
            </siNewtonTableColumn>

            <siNewtonTableColumn key="mlfb" name="MLFB" [widthFactor]="1.2">
              <ng-template siNewtonTableCell let-row="row">
                <si-newton-form-group>
                  <input formControlName="remoteServiceMLFB{{row.serviceNumber}}" siNewton>
                </si-newton-form-group>
              </ng-template>
            </siNewtonTableColumn>

            <siNewtonTableColumn key="serviceDescription" name="Description" [widthFactor]="1.3">
            </siNewtonTableColumn>

            <siNewtonTableColumn key="quantity" name="Quantity" [widthFactor]="0.7" [disableFilter]="true" [disableSort]="true">
            </siNewtonTableColumn>
            <siNewtonTableColumn key="serviceCost" name="Cost" [widthFactor]="0.8">
              <ng-template siNewtonTableCell let-row="row">
                <si-newton-form-group>
                  <input formControlName="remoteServiceCost{{row.serviceNumber}}" type="number" step="any" siNewton>
                </si-newton-form-group>
              </ng-template>
            </siNewtonTableColumn>
            <siNewtonTableColumn key="period" name="Period" [widthFactor]="0.8">
            </siNewtonTableColumn>

          
            <siNewtonTableColumn [widthFactor]="0.3" [disableFilter]="true" [disableSort]="true">
              <ng-template siNewtonTableCell let-value='value' let-row="row">
                <div class="form-check">
                  <input class="form-check-input" formControlName="{{row.serviceSelected}}" type="checkbox">
                </div>
              </ng-template>
            </siNewtonTableColumn>

            <siNewtonTableColumn [widthFactor]="0.3" [disableFilter]="true" [disableSort]="true">
              <ng-template siNewtonTableCell let-value='value' let-row="row">
                <button title="Delete Service" class="btn-danger" (click)="deleteService(row.serviceNumber)">
                  <i class="bi bi-trash"></i>
                </button>
              </ng-template>
            </siNewtonTableColumn>
      </si-newton-table> -->

      <div class="row" style="margin-bottom: 10px;">
        <div class="col-md-9">

        </div>

        <div class="col-md-3">
          <button type="button" class="btn btn-success"
        (click)="getPricesForAllMlfbs()" [disabled]="allRemoteServiceModules.length === 0" style="float: right">Update List Prices</button>
        </div>
      </div>
    </div>
  </div>
</form>
