import { Injectable } from '@angular/core';
import { AuthService, User } from '@auth0/auth0-angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import authConfig from '../../../auth_config.json';


@Injectable({
  providedIn: 'root'
})
export class UserManagementService {
  private profileSubject = new BehaviorSubject<User | null>(null);
  public profile$ = this.profileSubject.asObservable();

  constructor(public auth: AuthService) {
    this.auth.user$.pipe(
      filter(profile => profile !== null),
      take(1)
    ).subscribe(profile => {
      this.profileSubject.next(profile ?? null);
    });
  }

  public getUsername(): Observable<string> {
    return this.profile$.pipe(
      filter(profile => profile != null && profile.email != null),
      map(profile => {
        return profile?.email || '';
      })
    );
  }

  public getUserRole(): Observable<string> {

    return this.profile$.pipe(
      filter(profile => profile && profile[authConfig.rolesTag]),
      map(profile => {
        const roles = profile ? (profile[authConfig.rolesTag] || profile['role']) as string[] : [];
        if (roles.includes(authConfig.externalNameForAdmin)) {
          return 'admin';
        } else if (roles.includes(authConfig.externalNameForUser)) {
          return 'user';
        } else if (!roles){
          return 'noRole';
        } else {
          return 'noRole'
        }
      })
    );
  }

  // public isAllowed(role: string): Observable<boolean> {
  //   return this.getUserRole().pipe(
  //     map(userRole => userRole === role || this.isAllowedAdmin(userRole))
  //   );
  // }

  // private isAllowedAdmin(userRole: string): boolean {
  //   return userRole === 'admin';
  // }

  // public isAuthenticated(): Observable<boolean> {
  //   return this.auth.isAuthenticated$;
  // }


  public logout() {
    console.log('logout');
    this.auth.logout();
  }
}