<form [formGroup]="parentForm">
  <div formGroupName="selectedLicenses">
    <h5 class="subSectionHeader">Select licences in use
      <i style="float: right; cursor: pointer"
      [ngClass]="{'bi-arrow-up-circle-fill': isThisSectionVisible, 'bi-arrow-down-circle-fill': !isThisSectionVisible}"
      (click)="toggleVisibility()"></i>
    </h5>
    <div *ngIf="isThisSectionVisible">
      <si-newton-table [rows]="allSusPackages"
      [loading]="allSusPackages.length < 57" [rowsPerPage]="100">
  
      <siNewtonTableColumn key="mlfb" name="MLFB (USB Stick)">
      </siNewtonTableColumn>
  
      <siNewtonTableColumn key="mlfbOsd" name="MLFB (OSD)">
      </siNewtonTableColumn>
  
      <siNewtonTableColumn key="description" name="Description">
      </siNewtonTableColumn>
  
      <siNewtonTableColumn key="quantity" name="Quantity"></siNewtonTableColumn>
  
    </si-newton-table>
    </div>

    
  </div>
</form>
