import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-sw-engineering',
  templateUrl: './sw-engineering.component.html',
  styleUrls: ['./sw-engineering.component.scss']
})
export class SwEngineeringComponent {
  isThisSectionVisible = false;

  toggleVisibility(){
    console.log(this.isThisSectionVisible);
    this.isThisSectionVisible = !this.isThisSectionVisible;
  }

  @Input()
  parentForm!: FormGroup;

  constructor() { }

}
