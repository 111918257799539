import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DataTransferService } from 'src/app/services/data-transfer.service';
import { FormDataService } from 'src/app/services/form-data.service';

@Component({
  selector: 'app-basic-update-parameters',
  templateUrl: './basic-update-parameters.component.html',
  styleUrls: ['./basic-update-parameters.component.scss']
})
export class BasicUpdateParametersComponent implements OnInit {

  basicUpdateParametersFormParent: FormGroup;

  constructor(private formBuilder: FormBuilder, private dataTransferService: DataTransferService,
    private formDataService: FormDataService, public modalRef: BsModalRef) {
    this.basicUpdateParametersFormParent = this.formBuilder.group({
      basicUpdateParameters: this.formBuilder.group({
        osClientsEffortPcs7: [null, Validators.required],
        osClientsEffortPcsNeo: [null, Validators.required],
        osServerEffortPcs7: [null, Validators.required],
        osServerEffortPcsNeo: [null, Validators.required],
        engineeringStationEffortPcs7: [null, Validators.required],
        engineeringStationEffortPcsNeo: [null, Validators.required],
        batchServerEffortPcs7: [null, Validators.required],
        batchServerEffortPcsNeo: [null, Validators.required],
        webServerEffortPcs7: [null, Validators.required],
        rcServerEffortPcs7: [null, Validators.required],
        rcServerEffortPcsNeo: [null, Validators.required],
        amountFWUpdateEffortPcs7: [null, Validators.required],
        amountFWUpdateEffortPcsNeo: [null, Validators.required],
        hpServerEffortPcs7: [null, Validators.required],
        hpServerEffortPcsNeo: [null, Validators.required],
        phIsServerEffortPcs7: [null, Validators.required],
        phIsServerEffortPcsNeo: [null, Validators.required]
      })
    });
  }

  async ngOnInit(){

    await this.readData();
  }


  async update(){
    await this.submitValues();
  }

  async submitAndClose(){

    await this.submitValues();
    this.modalRef.hide();
  }

  async readData() {

    const keysFromDialog = await this.formDataService.getAllControlsFromDialogAsArray(this.basicUpdateParametersFormParent);

    console.log('KeysFromDialog');
    console.log(keysFromDialog);

    const formData = await this.dataTransferService.getValuesFromExcel(keysFromDialog) as any;

    this.formDataService.fillDataIntoDialog(this.basicUpdateParametersFormParent, formData);
  }

  async submitValues(){
    const jsonData = await this.formDataService.getDataFromDialog(this.basicUpdateParametersFormParent);

    await this.dataTransferService.writeDataToExcel(JSON.stringify(jsonData));

  }
}
