<div class="modal-header">
  <h4 class="modal-title">Assignment of {{projectName}}</h4>
</div>
<div class="modal-body">

  <div class="col-md-12">

    <div class="row">
      <div class="col-md-6 col-lg-6">
        <b>With whom do you want to share this project?</b>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 col-lg-6">
        <form [formGroup]="assignmentForm" class="col-md-8" style="align-items: center;">
          <si-newton-form-group label="Email" [errorResolverMap]="{required: 'Value is required', pattern: 'Valid email adress is needed'}">
            <input formControlName="email" type="email" email siNewton>
          </si-newton-form-group>
        </form>
      </div>

    </div>

    <div class="row">

      <div class="col-md-3">

      </div>

      <div class="col-md-3">
        <button type="button" class="btn btn-primary"  style="float: left;" (click)="modalRef.hide()">
          Cancel
        </button>
      </div>

      <div class="col-md-3">
        <button type="button" class="btn btn-success"  style="float: left;" [disabled]="!assignmentForm.valid"
        (click)="assignProject()">
          Assign project
        </button>
      </div>


      <div class="col-md-3">
      </div>
    </div>
  </div>

</div>

<div class="modal-footer">

</div>
