<form [formGroup]="parentForm">
  <div formGroupName="pricingFactors">

    <h5 class="subSectionHeader">Pricing Factors</h5>

    <p class="info">
      Use the calculated price calculation factors only for non-binding price indication.
    </p>
    <div class="row">
      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <div class="form-check">
          <input id="cb-enabled" class="form-check-input" formControlName="cBPriceCalculationRequested" type="checkbox">
          <label for="cb-enabled" class="form-check-label">
            Price calculation requested
          </label>
        </div>
      </div>

    </div>

    <div *ngIf="+parentForm.get('pricingFactors')!.get('cBPriceCalculationRequested')!.value === 1" >
    <div class="row">
      <div class="col">
        <b class="subSubSectionHeader">Gross Margin</b>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <si-newton-form-group label="PCS7 - IPC / 3rd Party PCs" [errorResolverMap]="{required: 'Value is required'}">
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
          <input formControlName="grossMarginPCS7_IPC" type="number" step="any" [min]="0" siNewton>
        </si-newton-form-group>
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <si-newton-form-group label="Services" [errorResolverMap]="{required: 'Value is required'}">
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
          <input formControlName="grossMarginServices" type="number" step="any" [min]="0" siNewton>
        </si-newton-form-group>
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <si-newton-form-group label="Others" [errorResolverMap]="{required: 'Value is required'}">
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
          <input formControlName="grossMarginOthers" type="number" step="any" [min]="0" siNewton>
        </si-newton-form-group>
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <si-newton-form-group label="PCS 7 SUS (Software Update Services)" [errorResolverMap]="{required: 'Value is required'}">
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
          <input formControlName="grossMarginPCS7SUS" type="number" step="any" [min]="0" siNewton>
        </si-newton-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <si-newton-form-group label="OnCall Service" [errorResolverMap]="{required: 'Value is required'}">
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
          <input formControlName="grossMarginOnCall" type="number" step="any" [min]="0" siNewton>
        </si-newton-form-group>
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <si-newton-form-group label="IT Infrastructure (physical)" [errorResolverMap]="{required: 'Value is required'}">
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
          <input formControlName="grossMarginItInfra" type="number" step="any" [min]="0" siNewton>
        </si-newton-form-group>
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <si-newton-form-group label="HW AddOns" [errorResolverMap]="{required: 'Value is required'}">
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
          <input formControlName="grossMarginHwAddons" type="number" step="any" [min]="0" siNewton>
        </si-newton-form-group>
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <si-newton-form-group label="SW AddOns" [errorResolverMap]="{required: 'Value is required'}">
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
          <input formControlName="grossMarginSwAddons" type="number" step="any" [min]="0" siNewton>
        </si-newton-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <si-newton-form-group label="SIVaaS VMs" [errorResolverMap]="{required: 'Value is required'}">
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
          <input formControlName="grossMarginSIVaasAddons" type="number" step="any" [min]="0" siNewton>
        </si-newton-form-group>
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <si-newton-form-group label="HP Hardware (Hosts / Thin Clients)" [errorResolverMap]="{required: 'Value is required'}">
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
          <input formControlName="grossMarginHpHardware" type="number" step="any" [min]="0" siNewton>
        </si-newton-form-group>
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <si-newton-form-group label="SIVaaS Service Contracts" [errorResolverMap]="{required: 'Value is required'}">
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
          <input formControlName="grossSivaasServiceContracts" type="number" step="any" [min]="0" siNewton>
        </si-newton-form-group>
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <si-newton-form-group label="vCenter Server" [errorResolverMap]="{required: 'Value is required'}">
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
          <input formControlName="grossMarginVCenterServer" type="number" step="any" [min]="0" siNewton>
        </si-newton-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <si-newton-form-group label="PCS neo SW & Maintenance Packages" [errorResolverMap]="{required: 'Value is required'}">
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
          <input formControlName="pcsNeoMarginSwMaintPackage" type="number" step="any" [min]="0" siNewton>
        </si-newton-form-group>
      </div>

    </div>

    <div class="row">
      <div class="col">
        <b class="subSubSectionHeader">Customer Discounts</b>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <si-newton-form-group label="HW-Discount Level 1" [errorResolverMap]="{required: 'Value is required'}">
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
          <input formControlName="customerDiscountsHWLevel1" type="number" step="any" [min]="0" siNewton>
        </si-newton-form-group>
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <si-newton-form-group label="HW-Discount Level 2" [errorResolverMap]="{required: 'Value is required'}">
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
          <input formControlName="customerDiscountsHWLevel2" type="number" step="any" [min]="0" siNewton>
        </si-newton-form-group>
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <si-newton-form-group label="SW-Discount Level 1" [errorResolverMap]="{required: 'Value is required'}">
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
          <input formControlName="customerDiscountsSWLevel1" type="number" step="any" [min]="0" siNewton>
        </si-newton-form-group>
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <si-newton-form-group label="SW-Discount Level 2" [errorResolverMap]="{required: 'Value is required'}">
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
          <input formControlName="customerDiscountsSWLevel2" type="number" step="any" [min]="0" siNewton>
        </si-newton-form-group>
      </div>
   
    </div>

    <div class="row">
      <div class="col">
        <b class="subSubSectionHeader">Administrative standards</b>
      </div>
    </div>

    

    <div class="row">
      

      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <si-newton-form-group label="Min. Total Margin for HW/SW products" [errorResolverMap]="{required: 'Value is required'}">
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
          <input formControlName="minTotalMarginAdminStandard" type="number" step="any" [min]="0" siNewton>
        </si-newton-form-group>
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <si-newton-form-group label="Calculative interest rate" [errorResolverMap]="{required: 'Value is required'}">
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
          <input formControlName="calculativeInterestRate" type="number" step="any" [min]="0" siNewton>
        </si-newton-form-group>
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <si-newton-form-group label="Negotiation margin" [errorResolverMap]="{required: 'Value is required'}">
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
          <input formControlName="negoationMargin" type="number" step="any" [min]="0" siNewton>
        </si-newton-form-group>
      </div>

    </div>

    <div class="row">
      

      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <si-newton-form-group label="Bail-out demand SW" [errorResolverMap]="{required: 'Value is required'}">
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
          <input formControlName="bailOutDemandSW" type="number" step="any" [min]="0" readonly siNewton #bailOutDemandSW>
        </si-newton-form-group>
        <i *ngIf="stringToNumber(bailOutDemandSW.value) > 0" class="bi bi-exclamation-circle-fill" style="color: #f0ad4e; margin-left: 3px;"></i>
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <si-newton-form-group label="Bail-out demand HW" [errorResolverMap]="{required: 'Value is required'}">
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
          <input formControlName="bailOutDemandHW" type="number" step="any" readonly siNewton #bailOutDemandHW>
          <i *ngIf="stringToNumber(bailOutDemandHW.value) > 0" class="bi bi-exclamation-circle-fill" style="color: #f0ad4e; margin-left: 3px;"></i>

        </si-newton-form-group>
        
      </div>

      <div class="col-lg-6 col-md-4 col-sm-12 col-xs-12">

        <p class="warnInfo" *ngIf="stringToNumber(bailOutDemandHW.value) > 0 || stringToNumber(bailOutDemandSW.value) > 0">
          Profit Targets can't be reached based on customer discounts and regional GA discounts
        </p>
      
      </div>

    </div>


  </div>

  </div>
</form>
