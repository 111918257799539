import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-pcs7-sw-maintenance-contract',
  templateUrl: './pcs7-sw-maintenance-contract.component.html',
  styleUrls: ['./pcs7-sw-maintenance-contract.component.scss']
})
export class Pcs7SwMaintenanceContractComponent implements OnInit {
  @Input()
  public parentForm!: FormGroup;


  constructor() { }

  ngOnInit(): void {
  }

}
