import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SiNewtonLoadingService } from '@simpl/newton-ng/loading-spinner';

@Component({
  selector: 'app-sw-maintenance-contract-timeline',
  templateUrl: './sw-maintenance-contract-timeline.component.html',
  styleUrls: ['./sw-maintenance-contract-timeline.component.scss']
})
export class SwMaintenanceContractTimelineComponent implements OnInit {

  @Input()
  public parentForm!: FormGroup;

  lcsStartYear!: number;
  lcsContractDuration!: number;
  contractYearsArray!: number[];
  pcsNeoEvolution!: number;

  startWithPcs7OrPcsneo: string;

  // Arrays for selected Checkboxs
  selectedPcs7Upgrades: boolean[] = [];
  selectedNeoHWUpgrades: boolean[] = [];
  selectedNeoSWUpgrades: boolean[] = [];
  selectedPCS7SwUpdates: boolean[] = [];
  selectedNeoSwUpdates: boolean[] = [];
  selectedSUSYears: boolean[] = [];
  selectedLSSYears: boolean[] = [];
  selectedPcs7SwMCYears: boolean[] = [];



  constructor(private loadingSpinner: SiNewtonLoadingService) { 
    this.startWithPcs7OrPcsneo = this.parentForm?.get('swMaintContractTimeline')?.get('startWithPcs7OrPcsNeo')?.value?.value;
  }

  ngOnInit(): void {
  }

  
  async updateContractTime(){
    this.loadingSpinner.startLoading();
    this.lcsStartYear = Number(this.parentForm.get('swMaintContractTimeline')?.get('yearOfStartFix')?.value);
    this.lcsContractDuration = Number(this.parentForm.get('swMaintContractTimeline')?.get('lcsContractDuration')?.value.value);
    this.contractYearsArray = Array.from({
      length: this.lcsContractDuration
    }, (_, i) => Number(this.lcsStartYear) + i);

    this.pcsNeoEvolution = Number(this.parentForm.get('swMaintContractTimeline')?.get('pcsNeoEvolution')?.value);

    this.loadingSpinner.stopLoading();
  }

  fillSelectedYears(varBaseName: string, amountOfPossibleSelectedYears: number){

    const tempIndices = [];

    for (let i = 0; i <= amountOfPossibleSelectedYears; i++){
      const yearValue = Number(this.parentForm.get('swMaintContractTimeline')?.get(varBaseName + 'Year' + (i + 1))?.value);

      if (!isNaN(yearValue)) {
        const index = this.contractYearsArray.indexOf(yearValue);
        tempIndices.push(index);
      }
    }


    // Check if years are valid regarding PCS neo Evolution year. Only then checkboxes are selected
    switch (varBaseName) {
      case 'pcs7Upgrade':
        this.selectedPcs7Upgrades = [];
        tempIndices.forEach(index => {
          if (this.contractYearsArray[index] < this.pcsNeoEvolution || this.pcsNeoEvolution === 0){
            this.selectedPcs7Upgrades[index] = true;
          } else {
            this.selectedPcs7Upgrades[index] = false;
          }
        });
        break;
      case 'pcsNeoHwUpgrade':
        this.selectedNeoHWUpgrades = [];
        tempIndices.forEach(index => {
          if (this.contractYearsArray[index] > this.pcsNeoEvolution || this.pcsNeoEvolution === 0){
            this.selectedNeoHWUpgrades[index] = true;
          } else {
            this.selectedNeoHWUpgrades[index] = false;
          }
        });
        break;
      case 'pcsNeoSwUpgrade':
        this.selectedNeoSWUpgrades = [];
        tempIndices.forEach(index => {
          if (this.contractYearsArray[index] > this.pcsNeoEvolution || this.pcsNeoEvolution === 0){
            this.selectedNeoSWUpgrades[index] = true;
          } else {
            this.selectedNeoHWUpgrades[index] = false;
          }
        });
        break;
      case 'pcs7SwUpdate':
        this.selectedPCS7SwUpdates = [];
        console.log('pcs7SwUpdate');
        tempIndices.forEach(index => {
          if (this.contractYearsArray[index] < this.pcsNeoEvolution || this.pcsNeoEvolution === 0){
            this.selectedPCS7SwUpdates[index] = true;
          } else {
            this.selectedPCS7SwUpdates[index] = false;
          }
        });
        break;
      case 'pcsNeoSwUpdate':
        this.selectedNeoSwUpdates = [];
        tempIndices.forEach(index => {
          if (this.contractYearsArray[index] > this.pcsNeoEvolution || this.pcsNeoEvolution === 0){
            this.selectedNeoSwUpdates[index] = true;
          } else {
            this.selectedNeoSwUpdates[index] = false;
          }
        });
        break;
      case 'pcs7Sus':
        this.selectedSUSYears = [];
        tempIndices.forEach(index => {
          if (this.contractYearsArray[index] < this.pcsNeoEvolution || this.pcsNeoEvolution === 0){
            this.selectedSUSYears[index] = true;
          } else {
            this.selectedSUSYears[index] = false;
          }
        });
        break;
      case 'pcsLss':
        this.selectedLSSYears = [];
        tempIndices.forEach(index => {
          if (this.contractYearsArray[index] < this.pcsNeoEvolution || this.pcsNeoEvolution === 0){
            this.selectedLSSYears[index] = true;
          } else {
            this.selectedLSSYears[index] = false;
          }
        });
        break;
      case 'pcs7SwMC':
        this.selectedPcs7SwMCYears = [];
        tempIndices.forEach(index => {
          if (this.contractYearsArray[index] < this.pcsNeoEvolution || this.pcsNeoEvolution === 0){
            this.selectedPcs7SwMCYears[index] = true;
          } else {
            this.selectedPcs7SwMCYears[index] = false;
          }
        });
        break;
    }
    
  }

  onCheckboxChange(event: any, type: 'pcs7Sus' | 'pcsLss' | 'pcs7SwMC', index: number) {

    this.loadingSpinner.startLoading();

    const isChecked = event.target.checked;

    switch (type) {
      case 'pcs7Sus':
        this.selectedSUSYears[index] = isChecked;
        this.updateFormControlsWithSelectedYears(this.selectedSUSYears, type);
        break;
      case 'pcsLss':
        this.selectedLSSYears[index] = isChecked;
        this.updateFormControlsWithSelectedYears(this.selectedLSSYears, type);
        break;
      case 'pcs7SwMC':
        this.selectedPcs7SwMCYears[index] = isChecked;
        this.updateFormControlsWithSelectedYears(this.selectedPcs7SwMCYears, type);
        break;
    }

    this.loadingSpinner.stopLoading();

  }

  updateFormControlsWithSelectedYears(checkboxArray: boolean[], varBaseName: string){

    const selectedYears = this.contractYearsArray.filter((year, i) => checkboxArray[i]);

    for(let i = 1; i <= 25; i++){
      this.parentForm.get('swMaintContractTimeline')?.get(varBaseName + 'Year' + i)?.setValue(selectedYears[i-1] || 0);
      this.parentForm.get('swMaintContractTimeline')?.get(varBaseName + 'Year' + i)?.markAsDirty();
    }

  }
}

