import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SiNewtonLoadingService } from '@simpl/newton-ng/loading-spinner';
import { CustomToastService } from 'src/app/services/custom-toast.service';
import { environment } from 'src/environments/environment';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-result-file',
  templateUrl: './result-file.component.html',
  styleUrls: ['./result-file.component.scss']
})
export class ResultFileComponent {

  @Input()
  parentForm!: FormGroup;
  constructor(private loadingSpinner: SiNewtonLoadingService, private customToastService: CustomToastService,
    private httpService: HttpClient) { }

  public generateResult() {

    const projectId = sessionStorage.getItem('SelectedProjectId')?.toString() || '';
    const projectName = sessionStorage.getItem('SelectedProjectName')?.toString() || '';

    this.customToastService.showInfoToast(`
      Please wait a few moments and don't close this page.
      Generating of result file can take few minutes.
    `);

    this.loadingSpinner.startLoading();

    const body = {};
    const options = {
      responseType: 'blob' as const,
      observe: 'body' as const
    };


    this.httpService.post(environment.baseUrl + '/calculation-operations/generate-result?projectId=' +
          projectId, body, options).subscribe(blob => {

      this.customToastService.showSuccessToast('Generating and Download of Result successfuel!');
      this.loadingSpinner.stopLoading();
      saveAs(blob, projectName + '-LCS_Calculation.xlsx');

    }, (error: HttpErrorResponse) => {
      this.customToastService.showErrorToast(error.error.message);
      this.loadingSpinner.stopLoading();
    });
  }

  updateStatusToResultGenerated(projectId: string){
    this.httpService.post(environment.baseUrl + '/updateStatusResultGenerated',
      { projectId }, { observe: 'response' }).subscribe();
  }
}


