import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-one-time-services',
  templateUrl: './one-time-services.component.html',
  styleUrls: ['./one-time-services.component.scss']
})
export class OneTimeServicesComponent {

  isThisSectionVisible = true;

  toggleVisibility(){
    console.log(this.isThisSectionVisible);
    this.isThisSectionVisible = !this.isThisSectionVisible;
  }

  @Input()
  public parentForm!: FormGroup;

  constructor() { }
}
