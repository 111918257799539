import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SiNewtonLoadingService } from '@simpl/newton-ng/loading-spinner';
import { ConstRestData } from 'src/app/const-data/rest';
import { DataTransferService } from 'src/app/services/data-transfer.service';
import { FormDataService } from 'src/app/services/form-data.service';

@Component({
  selector: 'app-sus-type',
  templateUrl: './sus-type.component.html',
  styleUrls: ['./sus-type.component.scss']
})
export class SusTypeComponent {

  REST_URLS = ConstRestData.restUrls;

  @Input()
  public parentForm!: FormGroup;

  susTypeRb!: string;
  constructor(private formDataService: FormDataService, private dataTransferService: DataTransferService,
    private loadingSpinner: SiNewtonLoadingService) { }

  async submit(){

    this.loadingSpinner.startLoading();

    const jsonData = await this.formDataService.getDataFromDialog(this.parentForm);

    await this.dataTransferService.writeDataToExcel(JSON.stringify(jsonData));

    this.loadingSpinner.stopLoading();
  }


}
