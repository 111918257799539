const baseUrl = '';


export const environment = {
  production: true,
  baseUrl: baseUrl,
  apiUrl: '/excel-api',
  auth: {
    domain: 'siemens-qa-00169.eu.auth0.com',
    clientId: 'aZHTsabGrmvdaHW8T03OspW96VT1SnCC',
    authorizationParams: {
      audience: 'excel-calculator-test',
      redirect_uri: window.location.origin,
    },
    errorPath: '/error',
    audience: 'excel-calculator-test'
  },
  cacheLocation: 'localstorage',
  httpInterceptor: {
    allowedList: [`${baseUrl}/*`],
  }
};

// For easier debugging in development mode, you can import the following file
// to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
//
// This import should be commented out in production mode because it will have a negative impact
// on performance if an error is thrown.
//
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
