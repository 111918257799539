<form [formGroup]="parentForm">
  <div formGroupName="serviceParameters">
    <h5 class="subSectionHeader">Service Parameters</h5>
    <div class="row">
      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <si-newton-form-group label="Working Hours" [errorResolverMap]="{required: 'Value is required'}">
          <div class="input-group-prepend">
            <span class="input-group-text">per day</span>
          </div>
          <input formControlName="workingHrsPerDay" type="number" step="any" [min]="0" siNewton>
        </si-newton-form-group>
      </div>

    </div>

  </div>
</form>
