import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormDataService } from 'src/app/services/form-data.service';

export interface DropDownOption {
  value: string;
}

@Component({
  selector: 'app-it-switch',
  templateUrl: './it-switch.component.html',
  styleUrls: ['./it-switch.component.scss']
})
export class ItSwitchComponent {

  formName = 'iadcItSwitch';

  stringToNumber(num: string){
    return parseInt(num, 10);
  }

  @Input()
  public parentForm!: FormGroup;

  constructor(private formDataService: FormDataService) { }

  public getDropdownOptions(formControlName: string){
    return this.formDataService.getDropDownOptions(formControlName);
  }

  public getDropdownValue(dropDownOption: DropDownOption): string {
    return dropDownOption.value;
  }
}
