
<form [formGroup]="parentForm">
    <div formGroupName="pcs7MaintenanceContract">
        <div>
            <div class="row">
                <div class="col">
                  <b class="subSubSectionHeader">PCS 7 SW Maintenance Contract</b>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <b><u>Relevant PCS 7 License Volume in year of calculation</u></b>
                </div>
              </div>
          
          
              <div class="row">
                <div class="col-md-4 col-lg-3">
                  <si-newton-form-group label="License Volume" [errorResolverMap]="{required: 'Value is required'}">
                    <div class="input-group-prepend">
                      <span class="input-group-text">€</span>
                    </div>
                    <input formControlName="relevantPcs7LicenseVolume" type="number" step="any" [min]="0" siNewton>
                  </si-newton-form-group>
                </div>

              </div>

              <div class="row">
                <div class="col">
                  <b><u>Share of PCS 7 License Volume w/o RT-Pos</u></b>
                </div>
              </div>
          
          
              <div class="row">
                <div class="col-md-4 col-lg-3">
                  <si-newton-form-group label="Share of License" [errorResolverMap]="{required: 'Value is required'}">
                    <div class="input-group-prepend">
                      <span class="input-group-text">% / year</span>
                    </div>
                    <input formControlName="shareOfPcs7LicenseVolumeWithoutRTperYear" type="number" step="any" [min]="0" siNewton>
                  </si-newton-form-group>
                </div>

              </div>
        </div>

    </div>
</form>
