<div class="modal-header">
  <h4 class="modal-title pull-left">Basic parameters for update</h4>

</div>
<div #modalBody class="modal-body">

  <form [formGroup]="basicUpdateParametersFormParent" class="col-md-12" style="align-items: center;">
    <div formGroupName="basicUpdateParameters">

    <div class="row">
      <div class="col">
        <b class="subSubSectionHeader">SW Update physical & virtual system </b>
        <label title="Installation of SW Packages on Site. (Service Packs, configuration, installation,
          virus protection, system backup, etc.)">
          <i class="bi bi-question-circle-fill" style="color: #eb780a" matTooltip="Info about the action"></i>
        </label>
      </div>
    </div>

    <div class="row">
      <div class="col-md-2">
        <b>OS Clients</b>
      </div>
      <div class="col-md-2">
        <si-newton-form-group label="PCS 7" [errorResolverMap]="{required: 'Value is required'}">
          <input formControlName="osClientsEffortPcs7" type="number" step="any" [min]="0" siNewton >
          <div class="input-group-prepend">
            <span class="input-group-text">h</span>
          </div>
        </si-newton-form-group>
      </div>
      <div class="col-md-2">
        <si-newton-form-group label="PCS Neo" [errorResolverMap]="{required: 'Value is required'}">
          <input formControlName="osClientsEffortPcsNeo" type="number" step="any" [min]="0" siNewton>
          <div class="input-group-prepend">
            <span class="input-group-text">h</span>
          </div>
        </si-newton-form-group>
      </div>
      <div class="col-md-2">
        <b>OS Server</b>
      </div>
      <div class="col-md-2">
        <si-newton-form-group label="PCS 7" [errorResolverMap]="{required: 'Value is required'}">
          <input formControlName="osServerEffortPcs7" type="number" step="any" [min]="0" siNewton >
          <div class="input-group-prepend">
            <span class="input-group-text">h</span>
          </div>
        </si-newton-form-group>
      </div>
      <div class="col-md-2">
        <si-newton-form-group label="PCS Neo" [errorResolverMap]="{required: 'Value is required'}">
          <input formControlName="osServerEffortPcsNeo" type="number" step="any" [min]="0" siNewton>
          <div class="input-group-prepend">
            <span class="input-group-text">h</span>
          </div>
        </si-newton-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col-md-2">
        <b>Engineering Station</b>
      </div>
      <div class="col-md-2">
        <si-newton-form-group label="PCS 7" [errorResolverMap]="{required: 'Value is required'}">
          <input formControlName="engineeringStationEffortPcs7" type="number" step="any" [min]="0" siNewton >
          <div class="input-group-prepend">
            <span class="input-group-text">h</span>
          </div>
        </si-newton-form-group>
      </div>
      <div class="col-md-2">
        <si-newton-form-group label="PCS Neo" [errorResolverMap]="{required: 'Value is required'}">
          <input formControlName="engineeringStationEffortPcsNeo" type="number" step="any" [min]="0"siNewton>
          <div class="input-group-prepend">
            <span class="input-group-text">h</span>
          </div>
        </si-newton-form-group>
      </div>
      <div class="col-md-2">
        <b>Batch Server</b>
      </div>
      <div class="col-md-2">
        <si-newton-form-group label="PCS 7" [errorResolverMap]="{required: 'Value is required'}">
          <input formControlName="batchServerEffortPcs7" type="number" step="any" [min]="0" siNewton >
          <div class="input-group-prepend">
            <span class="input-group-text">h</span>
          </div>
        </si-newton-form-group>
      </div>
      <div class="col-md-2">
        <si-newton-form-group label="PCS Neo" [errorResolverMap]="{required: 'Value is required'}">
          <input formControlName="batchServerEffortPcsNeo" type="number" step="any" [min]="0" siNewton>
          <div class="input-group-prepend">
            <span class="input-group-text">h</span>
          </div>
        </si-newton-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col-md-2">
        <b>Web Server</b>
      </div>
      <div class="col-md-2">
        <si-newton-form-group label="PCS 7" [errorResolverMap]="{required: 'Value is required'}">
          <input formControlName="webServerEffortPcs7" type="number" step="any" [min]="0" siNewton >
          <div class="input-group-prepend">
            <span class="input-group-text">h</span>
          </div>
        </si-newton-form-group>
      </div>
      <div class="col-md-2">
      </div>
      <div class="col-md-2">
        <b>PH-/IS-Server</b>
      </div>
      <div class="col-md-2">
        <si-newton-form-group label="PCS 7" [errorResolverMap]="{required: 'Value is required'}">
          <input formControlName="phIsServerEffortPcs7" type="number" step="any" [min]="0" siNewton >
          <div class="input-group-prepend">
            <span class="input-group-text">h</span>
          </div>
        </si-newton-form-group>
      </div>
      <div class="col-md-2">
        <si-newton-form-group label="PCS Neo" [errorResolverMap]="{required: 'Value is required'}">
          <input formControlName="phIsServerEffortPcsNeo" type="number" step="any" [min]="0" siNewton >
          <div class="input-group-prepend">
            <span class="input-group-text">h</span>
          </div>
        </si-newton-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col-md-2">
        <b>Route Control Server</b>
      </div>
      <div class="col-md-2">
        <si-newton-form-group label="PCS 7" [errorResolverMap]="{required: 'Value is required'}">
          <input formControlName="rcServerEffortPcs7" type="number" step="any" [min]="0" siNewton >
          <div class="input-group-prepend">
            <span class="input-group-text">h</span>
          </div>
        </si-newton-form-group>
      </div>
      <div class="col-md-2">
        <si-newton-form-group label="PCS Neo" [errorResolverMap]="{required: 'Value is required'}">
          <input formControlName="rcServerEffortPcsNeo" type="number" step="any" [min]="0" siNewton>
          <div class="input-group-prepend">
            <span class="input-group-text">h</span>
          </div>
        </si-newton-form-group>
      </div>
      <div class="col-md-2">
        <b>Amount of necessary FW-Update</b>
      </div>
      <div class="col-md-2">
        <si-newton-form-group label="PCS 7" [errorResolverMap]="{required: 'Value is required'}">
          <input formControlName="amountFWUpdateEffortPcs7" type="number" step="any" [min]="0" siNewton >
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
        </si-newton-form-group>
      </div>
      <div class="col-md-2">
        <si-newton-form-group label="PCS Neo" [errorResolverMap]="{required: 'Value is required'}">
          <input formControlName="amountFWUpdateEffortPcsNeo" type="number" step="any" [min]="0" siNewton>
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
        </si-newton-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <b class="subSubSectionHeader">SW Update - SIVaaS </b>
        <label title="FW-Update $ Hypervision-Update ">
          <i class="bi bi-question-circle-fill" style="color: #eb780a" matTooltip="Info about the action"></i>
        </label>
      </div>
    </div>

    <div class="row">
      <div class="col-md-2">
        <b>Virtualisation Server Platform</b>
      </div>
      <div class="col-md-2">
        <si-newton-form-group label="PCS 7" [errorResolverMap]="{required: 'Value is required'}">
          <input formControlName="hpServerEffortPcs7" type="number" step="any" [min]="0" siNewton >
          <div class="input-group-prepend">
            <span class="input-group-text">h</span>
          </div>
        </si-newton-form-group>
      </div>
      <div class="col-md-2">
        <si-newton-form-group label="PCS Neo" [errorResolverMap]="{required: 'Value is required'}">
          <input formControlName="hpServerEffortPcsNeo" type="number" step="any" [min]="0" siNewton>
          <div class="input-group-prepend">
            <span class="input-group-text">h</span>
          </div>
        </si-newton-form-group>
      </div>
    </div>
  </div>
</form>

</div>

<div class="modal-footer">
  <button type="button" class="btn btn-warning" (click)="modalRef.hide()">Cancel</button>
  <button type="button" class="btn btn-secondary" (click)="update()" [disabled]="!basicUpdateParametersFormParent.valid">Update</button>
  <button type="button" class="btn btn-success" [disabled]="!(basicUpdateParametersFormParent.valid)"
  (click)="submitAndClose()">Submit & Close</button>
</div>
