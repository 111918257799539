<form [formGroup]="parentForm">
  <div formGroupName="iadcOtSwitch">

    <!--Hilfsvariablen-->

    <input formControlName="lcsContractLastYear" hidden #lcsContractLastYear>


    <div class="row">
      <div class="col">
        <h5 class="subSectionHeader">General</h5>
      </div>
    </div>
    <div class="row">
      <div class="col">

        <p class="info">Please select if OT Switch is needed, without selecetion there is no calculation</p>
      </div>

    </div>

    <div class="row">

      <div class="col-xs-12 col-sm-4 col-md-3">
        <div class="form-check">
          <input id="cb-enabled" class="form-check-input" formControlName="iadcOtSwitchSelectedCb" type="checkbox">
          <label for="cb-enabled" class="form-check-label">
            OT Switch included?
          </label>
        </div>
      </div>

      <div class="col-xs-12 col-sm-4 col-md-3">
        <si-newton-form-group label="Product Type" [errorResolverMap]="{required: 'Please select an option'}">
          <si-newton-dropdown
            [dropdownOptions]="getDropdownOptions('iadcOtSwitchProductType')"
            [valueProvider]="getDropdownValue"
            formControlName="iadcOtSwitchProductType"></si-newton-dropdown>
        </si-newton-form-group>
      </div>

      <div class="col-xs-12 col-sm-4 col-md-3">
        <si-newton-form-group label="Quantity of OT Switches" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers are allowed here'}">
          <input formControlName="iadcOtSwitchQuantity" type="number" [min]="0" siNewton>
        </si-newton-form-group>
      </div>

    </div>


    <div class="row">
      <div class="col">
        <h5 class="subSectionHeader">Calculative parameters</h5>
      </div>
    </div>


    <div class="row">
      <div class="col-xs-12 col-sm-6 col-md-3">
        <si-newton-form-group label="Service Cost increase rate" [errorResolverMap]="{required: 'Value is required'}">
          <input formControlName="iadcOtSwitchServiceCostIncreaseRate" type="number" [min]="0" step="any" siNewton>
          <div class="input-group-prepend">
            <span class="input-group-text">% / year</span>
          </div>
        </si-newton-form-group>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-3">
        <si-newton-form-group label="Product Cost increase rate" [errorResolverMap]="{required: 'Value is required'}">
          <input formControlName="iadcOtSwitchProductCostIncreaseRate" type="number" [min]="0" step="any" siNewton>
          <div class="input-group-prepend">
            <span class="input-group-text">% / year</span>
          </div>
        </si-newton-form-group>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-3">
        <si-newton-form-group label="Risk addition" [errorResolverMap]="{required: 'Value is required'}">
          <input formControlName="iadcOtSwitchRiskAddition" type="number" [min]="0" step="any" siNewton>
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
        </si-newton-form-group>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-3">
        <si-newton-form-group label="Margin" [errorResolverMap]="{required: 'Value is required'}">
          <input formControlName="iadcOtSwitchMargin" type="number" [min]="0" step="any" siNewton>
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
        </si-newton-form-group>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-3">
        <si-newton-form-group label="Regional Discount" [errorResolverMap]="{required: 'Value is required'}">
          <input formControlName="iadcOtSwitchRegionalDiscount" type="number" [min]="0" step="any" siNewton>
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
        </si-newton-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h5 class="subSectionHeader">OT Switch Upgrade</h5>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <b class="subSubSectionHeader">Selected Upgrade years</b>
      </div>
    </div>

    <div class="row">

      <div class="col-xs-12 col-sm-6 col-md-3">
        <si-newton-form-group label="1st Upgrade">
          <input formControlName="firstUpgradeOverall" readOnly siNewton>
        </si-newton-form-group>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-3">
        <si-newton-form-group label="2nd Upgrade">
          <input formControlName="secondUpgradeOverall" readOnly siNewton>
        </si-newton-form-group>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-3">
        <si-newton-form-group label="3rd Upgrade">
          <input formControlName="thirdUpgradeOverall" readOnly siNewton>
        </si-newton-form-group>
      </div>
    </div>



    <div class="row">
      <div class="col">
        <b class="subSubSectionHeader">Services</b>
      </div>
    </div>

    <div *ngFor="let _ of [].constructor(2), let x = index">


      <input formControlName="iadcOtSwitchHwSwUpgrServiceTask{{x+1}}Name" hidden #serviceTaskName>

      <div class="row">
        <div class="col">
          <b class="labelAccent">{{serviceTaskName.value}}</b>
        </div>
      </div>

      <div class="row">

        <div class="col-xs-12 col-sm-6 col-md-3">
          <si-newton-form-group label="Description" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="iadcOtSwitchHwSwUpgrServiceTask{{x+1}}Description" readOnly siNewton>
          </si-newton-form-group>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-3">
          <si-newton-form-group label="Effort onSite per piece" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers are allowed here'}">
            <input formControlName="iadcOtSwitchHwSwUpgrServiceTask{{x+1}}EffortOnSite" type="number" [min]="0" siNewton>
            <div class="input-group-prepend">
              <span class="input-group-text">min</span>
            </div>
          </si-newton-form-group>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-3">
          <si-newton-form-group label="Effort offSite per piece" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers are allowed here'}">
            <input formControlName="iadcOtSwitchHwSwUpgrServiceTask{{x+1}}EffortOffSite" type="number" [min]="0" siNewton>
            <div class="input-group-prepend">
              <span class="input-group-text">min</span>
            </div>
          </si-newton-form-group>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-3">
          <si-newton-form-group label="Total Service Cost" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers are allowed here'}">
            <input formControlName="iadcOtSwitchHwSwUpgrServiceTask{{x+1}}ServiceCost" type="number" [min]="0" highlight readOnly siNewton>
            <div class="input-group-prepend">
              <span class="input-group-text">€</span>
            </div>
          </si-newton-form-group>
        </div>
      </div>

  </div>

    <div class="row">
      <div class="col">
        <b class="subSubSectionHeader">Products</b>
      </div>
    </div>

    <div class="row">
      <div class="col">

        <p class="info">To adapt the Product Increase Parameters please change the value in 'Calculative parameters' Section</p>
      </div>

    </div>

    <div *ngFor="let _ of [].constructor(1), let x = index">
      <input formControlName="iadcOtSwitchHwSwUpgrProduct{{x+1}}Name" hidden #productName>

      <div class="row">
        <div class="col">
          <b class="labelAccent">{{productName.value}}</b>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 col-sm-4 col-md-4">
          <si-newton-form-group label="Description / MLFB" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="iadcOtSwitchHwSwUpgrProduct{{x+1}}DescriptionMlfb" readOnly siNewton>
          </si-newton-form-group>
        </div>


        <div class="col-xs-12 col-sm-4 col-md-4">
          <si-newton-form-group label="Cost Increase Rate" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="iadcOtSwitchProductCostIncreaseRate" type="number" [min]="0" step="any" readOnly siNewton>
            <div class="input-group-prepend">
              <span class="input-group-text">%</span>
            </div>
          </si-newton-form-group>
        </div>

        <div class="col-xs-12 col-sm-4 col-md-4">
          <si-newton-form-group label="Component cost" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="iadcOtSwitchHwSwUpgrProduct{{x+1}}Cost" type="number" [min]="0" step="any" readOnly highlight siNewton>
            <div class="input-group-prepend">
              <span class="input-group-text">€</span>
            </div>
          </si-newton-form-group>
        </div>
      </div>


    </div>


    <div class="row">
      <div class="col">
        <h5 class="subSectionHeader">Inspection and Maintenance</h5>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6 col-md-4 col-lg-3">
        <si-newton-form-group label="Times per year" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers are allowed here'}">
          <input formControlName="iadcOtSwitchIuWTimesPerYear" type="number" [min]="0" siNewton>
        </si-newton-form-group>
      </div>
    </div>



    <!--IuW Tasks-->
    <div *ngFor="let _ of [].constructor(3), let x = index">
      <div class="row">
        <input formControlName="iadcOtSwitchIuWTask{{x+1}}Name" hidden #iuwTaskName>

        <div class="col">
          <b class="labelAccent">{{iuwTaskName.value}}</b>
        </div>
      </div>


      <div class="row">
        <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
          <si-newton-form-group label="Description" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="iadcOtSwitchIuWTask{{x+1}}Description" readOnly siNewton>
          </si-newton-form-group>
        </div>

        <div class="col-xs-12 col-sm-4 col-md-4 col-lg-2">
          <si-newton-form-group label="Effort onSite" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers are allowed here'}">
            <input formControlName="iadcOtSwitchIuWTask{{x+1}}EffortOnSite" type="number" [min]="0" siNewton>
            <div class="input-group-prepend">
              <span class="input-group-text">min</span>
            </div>
          </si-newton-form-group>
        </div>

        <div class="col-xs-12 col-sm-4 col-md-4 col-lg-2">
          <si-newton-form-group label="Material Cost" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="iadcOtSwitchIuWTask{{x+1}}MaterialCost" type="number" [min]="0" step="any" siNewton>
            <div class="input-group-prepend">
              <span class="input-group-text">€</span>
            </div>
          </si-newton-form-group>
        </div>

        <div class="col-xs-12 col-sm-4 col-md-4 col-lg-2">
          <si-newton-form-group label="Service Cost" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="iadcOtSwitchIuWTask{{x+1}}ServiceCost" type="number" [min]="0" readonly highlight siNewton>
            <div class="input-group-prepend">
              <span class="input-group-text">€</span>
            </div>
          </si-newton-form-group>
        </div>

        <div class="col-xs-12 col-sm-4 col-md-4 col-lg-2">
          <si-newton-form-group label="Total Cost" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="iadcOtSwitchIuWTask{{x+1}}TotalCost" type="number" [min]="0" readonly highlight siNewton>
            <div class="input-group-prepend">
              <span class="input-group-text">€</span>
            </div>
          </si-newton-form-group>
        </div>
    </div>

    </div>
    <!--Selection of Service Years-->

    <div class="row">
      <div class="col">
        <p class="info">Please select the years, where IuW is needed</p>
      </div>
    </div>

    <div class="row" style="width: 95%; margin: auto; padding-bottom: 15px;">

      <input type="number" formControlName="yearOfStartFix" hidden #yearOfStartFix>
      <input type="number" formControlName="lcsContractDuration" hidden #lcsContractDuration>

      <div class="col">
        <div class="row year">{{stringToNumber(yearOfStartFix.value)}}</div>
        <div class="row checkBox">
          <div class="form-check">
            <input id="cb-enabled" formControlName="iadcOtSwitchIuWSelectedYear1" type="checkbox">
          </div>
        </div>
      </div>


      <div class="col" *ngFor="let _ of [].constructor(14), let x = index">
        <div *ngIf="(stringToNumber(yearOfStartFix.value) + (x+1)) <= stringToNumber(lcsContractLastYear.value)">
          <div class="row year">{{stringToNumber(yearOfStartFix.value) + (x+1)}}</div>
          <div class="row checkBox">
            <div class="form-check">
              <input id="cb-enabled" formControlName="iadcOtSwitchIuWSelectedYear{{x+2}}" type="checkbox">
            </div>
          </div>
        </div>
      </div>

    </div>

    <!--Cost Overview of IADC Component-->

    <div class="row">
      <div class="col">
        <h5 class="subSectionHeader">Cost Overview of OT Switch</h5>
      </div>
    </div>


    <div class="row" style="width: 100%; margin: auto; padding-bottom: 15px; font-weight: bold;">

      <input type="number" formControlName="yearOfStartFix" hidden #yearOfStartFix>

      <div class="col-xs-12 col-md-2">
          <div class="row">&nbsp;</div>
          <div class="row" style="background-color: #9bafbe;">OT Switch Upgrade Services</div>
          <div class="row" style="background-color: #a5e1e1;">OT Switch Upgrade Products</div>
          <div class="row" style="background-color: #9bafbe;">Inspection & Maintenance</div>
          <div class="row" style="background-color: #d2d741;">Total Cost</div>
          <div class="row" style="background-color: #faa50a;">Included Risk</div>
      </div>

      <ng-container *ngFor="let _ of [].constructor(5), let x = index">
        <input formControlName="iadcOtSwitchCostOverviewHwSwUpgServicesYear{{x+1}}" hidden #upgrServiceCost>
        <input formControlName="iadcOtSwitchCostOverviewHwSwUpgProductsYear{{x+1}}" hidden #upgrProductCost>
        <input formControlName="iadcOtSwitchCostOverviewIuWYear{{x+1}}" hidden #iuwCost>
        <input formControlName="iadcOtSwitchCostOverviewTotalYear{{x+1}}" hidden #totalCost>
        <input formControlName="iadcOtSwitchCostOverviewIncludedRiskYear{{x+1}}" hidden #includedRiskCost>

        <div class="col-xs-12 col-md-2">
          <div class="row year">{{stringToNumber(yearOfStartFix.value) + x}}</div>
          <div class="row" style="background-color: #9bafbe; padding-left: 50%;">{{upgrServiceCost.value}} €</div>
          <div class="row" style="background-color: #a5e1e1; padding-left: 50%;">{{upgrProductCost.value}} €</div>
          <div class="row" style="background-color: #9bafbe; padding-left: 50%;">{{iuwCost.value}} €</div>
          <div class="row" style="background-color: #d2d741; padding-left: 50%;">{{totalCost.value}} €</div>
          <div class="row" style="background-color: #faa50a; padding-left: 50%;">{{includedRiskCost.value}} €</div>
        </div>

      </ng-container>

    </div>

    <div class="row" style="width: 100%; margin: auto; padding-bottom: 15px; font-weight: bold;">

      <input type="number" formControlName="yearOfStartFix" hidden #yearOfStartFix>

      <div class="col-xs-12 col-md-2">
          <div class="row">&nbsp;</div>
          <div class="row" style="background-color: #9bafbe;">OT Switch Upgrade Services</div>
          <div class="row" style="background-color: #a5e1e1;">OT Switch Upgrade Products</div>
          <div class="row" style="background-color: #9bafbe;">Inspection & Maintenance</div>
          <div class="row" style="background-color: #d2d741;">Total Cost</div>
          <div class="row" style="background-color: #faa50a;">Included Risk</div>
      </div>

      <ng-container *ngFor="let _ of [].constructor(5), let x = index">
        <input formControlName="iadcOtSwitchCostOverviewHwSwUpgServicesYear{{x+6}}" hidden #upgrServiceCost>
        <input formControlName="iadcOtSwitchCostOverviewHwSwUpgProductsYear{{x+6}}" hidden #upgrProductCost>
        <input formControlName="iadcOtSwitchCostOverviewIuWYear{{x+6}}" hidden #iuwCost>
        <input formControlName="iadcOtSwitchCostOverviewTotalYear{{x+6}}" hidden #totalCost>
        <input formControlName="iadcOtSwitchCostOverviewIncludedRiskYear{{x+6}}" hidden #includedRiskCost>

        <div class="col-xs-12 col-md-2">
          <div class="row year">{{stringToNumber(yearOfStartFix.value) + x+5}}</div>
          <div class="row" style="background-color: #9bafbe; padding-left: 50%;">{{upgrServiceCost.value}} €</div>
          <div class="row" style="background-color: #a5e1e1; padding-left: 50%;">{{upgrProductCost.value}} €</div>
          <div class="row" style="background-color: #9bafbe; padding-left: 50%;">{{iuwCost.value}} €</div>
          <div class="row" style="background-color: #d2d741; padding-left: 50%;">{{totalCost.value}} €</div>
          <div class="row" style="background-color: #faa50a; padding-left: 50%;">{{includedRiskCost.value}} €</div>
        </div>

      </ng-container>

    </div>
    <div class="row" style="width: 100%; margin: auto; padding-bottom: 15px; font-weight: bold;">

      <input type="number" formControlName="yearOfStartFix" hidden #yearOfStartFix>

      <div class="col-xs-12 col-md-2">
          <div class="row">&nbsp;</div>
          <div class="row" style="background-color: #9bafbe;">OT Switch Upgrade Services</div>
          <div class="row" style="background-color: #a5e1e1;">OT Switch Upgrade Products</div>
          <div class="row" style="background-color: #9bafbe;">Inspection & Maintenance</div>
          <div class="row" style="background-color: #d2d741;">Total Cost</div>
          <div class="row" style="background-color: #faa50a;">Included Risk</div>
      </div>

      <ng-container *ngFor="let _ of [].constructor(5), let x = index">
        <input formControlName="iadcOtSwitchCostOverviewHwSwUpgServicesYear{{x+11}}" hidden #upgrServiceCost>
        <input formControlName="iadcOtSwitchCostOverviewHwSwUpgProductsYear{{x+11}}" hidden #upgrProductCost>
        <input formControlName="iadcOtSwitchCostOverviewIuWYear{{x+11}}" hidden #iuwCost>
        <input formControlName="iadcOtSwitchCostOverviewTotalYear{{x+11}}" hidden #totalCost>
        <input formControlName="iadcOtSwitchCostOverviewIncludedRiskYear{{x+11}}" hidden #includedRiskCost>

        <div class="col-xs-12 col-md-2">
          <div class="row year">{{stringToNumber(yearOfStartFix.value) + x+10}}</div>
          <div class="row" style="background-color: #9bafbe; padding-left: 50%;">{{upgrServiceCost.value}} €</div>
          <div class="row" style="background-color: #a5e1e1; padding-left: 50%;">{{upgrProductCost.value}} €</div>
          <div class="row" style="background-color: #9bafbe; padding-left: 50%;">{{iuwCost.value}} €</div>
          <div class="row" style="background-color: #d2d741; padding-left: 50%;">{{totalCost.value}} €</div>
          <div class="row" style="background-color: #faa50a; padding-left: 50%;">{{includedRiskCost.value}} €</div>
        </div>

      </ng-container>

    </div>
  </div>
</form>
