import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GeneralInformationComponent } from './sections/general-information/general-information.component';
import { ProjectListComponent } from './project-list/project-list.component';
import { RegionalFactorsComponent } from './sections/regional-factors/regional-factors.component';
import { DefineServicesComponent } from './sections/define-services/define-services.component';
import { DefineConfigurationComponent } from './sections/define-configuration/define-configuration.component';
import { ResultOverviewComponent } from './sections/result-overview/result-overview.component';
import { HwSwAddonsComponent } from './sections/hw-sw-addons/hw-sw-addons.component';
import { IadcComponent } from './sections/iadc/iadc.component';
import { AuthGuard } from '@auth0/auth0-angular';
import { HomeComponent } from './home/home.component';
import { SwMaintenanceComponent } from './sections/sw-maintenance/sw-maintenance.component';
import { AdminViewComponent } from './admin-view/admin-view.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'projects',
    component: ProjectListComponent,
    data: {
      title: 'My Projects'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'general-information',
    component: GeneralInformationComponent,
    data: {
      title: 'Calculation'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'general-information',
    component: GeneralInformationComponent,
    data: {
      title: 'General Information'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'regional-factors',
    component: RegionalFactorsComponent,
    data: {
      title: 'Regional Factors'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'define-services',
    component: DefineServicesComponent,
    data: {
      title: 'Define Services'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'define-configuration',
    component: DefineConfigurationComponent,
    data: {
      title: 'Define Configuration'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'software-maintenance',
    component: SwMaintenanceComponent,
    data: {
      title: 'SW-Maintenance'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'result-overview',
    component: ResultOverviewComponent,
    data: {
      title: 'Result Overview'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'hw-sw-addons',
    component: HwSwAddonsComponent,
    data: {
      title: 'HW & SW Addons'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'iadc',
    component: IadcComponent,
    data: {
      title: 'IADC'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'admin-view',
    component: AdminViewComponent,
    data: {
      title: 'Admin View'
    },
    canActivate: [AuthGuard]
  },
  {
    path: '*',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
