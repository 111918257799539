<form [formGroup]="parentForm">
  <div formGroupName="systemUpgrades">

    <h5 class="subSectionHeader" style="margin-bottom: 10px">System Upgrades
      <i style="float: right; cursor: pointer"
      [ngClass]="{'bi-arrow-up-circle-fill': isThisSectionVisible, 'bi-arrow-down-circle-fill': !isThisSectionVisible}"
      (click)="toggleVisibility()"></i>
    </h5>

    <div [ngStyle]="{'display': isThisSectionVisible ? 'block' : 'none'}">

      <div class="row">
        <div class="col">
          <b class="subSubSectionHeader">PCS 7 / PCS Neo Overview</b>
        </div>
      </div>

      <div class="row">
        <div class="evolutionOverview">

            <!-- Checkbox Sections -->
            <div class="checkbox-sections">

                <div class="checkbox-row">
                  <div class="checkbox-label"></div>
                  <ul style="margin-bottom: 0px;">

                    <li class="years" *ngFor="let year of yearsArray; let i = index"
                    [ngClass]=
                    "pcsNeoEvolution && year === pcsNeoEvolution ? 'pcsNeoEvolution' :
                      year >= pcsNeoEvolution && pcsNeoEvolution !== 0 ? 'pcsNeo' : 'pcs7'">
                      {{ i+1 }}
                    </li>
                  </ul>
                </div>

                <div class="checkbox-row">
                  <div class="checkbox-label"></div>
                  <ul style="margin-bottom: 0px;">
                    <li class="year" *ngFor="let year of yearsArray">
                      {{ year }}
                    </li>
                  </ul>
                </div>

                <!-- PCS 7 Upgrades -->
                <div class="checkbox-row" style="background-color: rgba(80, 190, 215, 0.7); color: white"
                  [style.display]="startWithPcs7OrPcsneo === 'PCS neo' ? 'none' : 'flex'">
                    <div class="checkbox-label">PCS 7 Upgrades</div>
                    <ul>
                        <li *ngFor="let year of yearsArray; let i = index">
                            <input class="form-check form-check-inline custom-disabled" type="checkbox" style="margin-top: 5px"
                                   *ngIf="pcsNeoEvolution === 0 || year < pcsNeoEvolution"
                                  [checked]="selectedPcs7Upgrades[i]">
                        </li>
                    </ul>
                </div>


                <!-- PCS neo HW Upgrade -->
                <div class="checkbox-row" style="background-color: rgba(0, 95, 135, 0.7); color: white">
                  <div class="checkbox-label">PCS Neo HW Upgrades</div>
                  <ul>
                      <li *ngFor="let year of yearsArray; let i = index">
                          <input class="form-check form-check-inline custom-disabled" type="checkbox"
                                *ngIf="pcsNeoEvolution === 0 || year > pcsNeoEvolution"
                                [checked]="selectedNeoHWUpgrades[i]">
                      </li>
                  </ul>
                </div>

                <!-- PCS neo SW Upgrade -->
                <div class="checkbox-row" style="background-color: rgba(0, 95, 135, 0.7); color: white">
                  <div class="checkbox-label">PCS Neo SW Upgrades</div>
                  <ul>
                      <li *ngFor="let year of yearsArray; let i = index">
                          <input class="form-check form-check-inline custom-disabled" type="checkbox"
                                *ngIf="pcsNeoEvolution === 0 || year > pcsNeoEvolution"
                                [checked]="selectedNeoSWUpgrades[i]"
                                >
                      </li>
                  </ul>
                </div>

            </div>
        </div>
      </div>


      <tabset>
        <tab heading="Network Components">
          <div id="replacementOfNetworkComp">
            <div class="row">
              <div class="col">
                <b class="subSubSectionHeader">Network components</b>
              </div>
            </div>

            <div class="row">
              <div class="col-xs-12 col-md-6 col-lg-3">
                <si-newton-form-group label="Replacement Year" [errorResolverMap]="{required: 'Please select an option'}">
                  <si-newton-dropdown
                    [dropdownOptions]="getDropdownOptionsOfContractYears()"
                    [valueProvider]="getDropdownValue"
                    formControlName="replaceNetworkComponentsInYear"></si-newton-dropdown>
                </si-newton-form-group>
              </div>
              <div class="col-xs-12 col-md-6 col-lg-3">
                <si-newton-form-group label="Replacement effort per switch" [errorResolverMap]="{required: 'Value is required'}">
                  <div class="input-group-prepend">
                    <span class="input-group-text">h</span>
                  </div>
                  <input formControlName="networkComponentsReplacementEffort" type="number" step="any" [min]="0" siNewton>
                </si-newton-form-group>
              </div>

              <div class="col-xs-12 col-md-6 col-lg-3">
                <si-newton-form-group label="Replacement effort per suppl. component" [errorResolverMap]="{required: 'Value is required'}">
                  <div class="input-group-prepend">
                    <span class="input-group-text">h</span>
                  </div>
                  <input formControlName="replacementEffortPerSupplComponent" type="number" step="any" [min]="0" siNewton>
                </si-newton-form-group>
              </div>
            </div>

            <div class="row">
              <div class="col-xs-12 col-md-6 col-lg-3">
                <si-newton-form-group label="Relevant No. of Switches (PCS 7)" [errorResolverMap]="{required: 'Value is required'}">
                  <input formControlName="relevantNetworkComponentsPcs7" type="number" step="any" [min]="0" readOnly siNewton>
                </si-newton-form-group>
              </div>

              <div class="col-xs-12 col-md-6 col-lg-3">
                <si-newton-form-group label="Relevant No. of Switches (neo)" [errorResolverMap]="{required: 'Value is required'}">
                  <input formControlName="relevantNetworkComponentsNeo" type="number" step="any" [min]="0" readOnly siNewton>
                </si-newton-form-group>
              </div>
            </div>

          </div>

        </tab>
        <tab heading="HMI Adaption">
          <div id="hmiAdaption">
            <div class="row">
              <div class="col">
                <b class="subSubSectionHeader">HMI Adaption</b>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <p class="info">Please select the option of HMI Adaption Calculation. Option 1 is based on Share of Initial Engineering Cost,
                  Option 2 is based on Number of Pictures.
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 col-lg-3">
                <si-newton-form-group label="HMI Adaption Option" [errorResolverMap]="{required: 'Please select an option'}">
                    <si-newton-dropdown
                      [dropdownOptions]="getDropdownOptions('hmiAdaptionOptionSelection')"
                      [valueProvider]="getDropdownValue"
                      formControlName="hmiAdaptionOptionSelection"></si-newton-dropdown>
                  </si-newton-form-group>
              </div>
            </div>
            <div class="row" *ngIf="parentForm.get('systemUpgrades')?.get('hmiAdaptionOptionSelection')?.value?.value === 'based on [%] of ini. engineering'">

                <div class="col-md-4 col-lg-3">
                  <si-newton-form-group label="Initial Engineering Cost" [errorResolverMap]="{required: 'Value is required'}">
                    <div class="input-group-prepend">
                      <span class="input-group-text">€</span>
                    </div>
                    <input formControlName="hmiAdaptionOption1InitialEng" type="number" step="any" [min]="0" siNewton>
                  </si-newton-form-group>
                </div>
                <div class="col-md-4 col-lg-3">
                  <si-newton-form-group label="Share For Adaption" [errorResolverMap]="{required: 'Value is required'}">
                    <div class="input-group-prepend">
                      <span class="input-group-text">%</span>
                    </div>
                    <input formControlName="hmiAdaptionOption1ShareForAdaption" type="number" step="any" [min]="0" siNewton>
                  </si-newton-form-group>
                </div>
                <div class="col-md-4 col-lg-3">
                  <si-newton-form-group label="Calculated Adaption Cost" [errorResolverMap]="{required: 'Value is required'}">
                    <div class="input-group-prepend">
                      <span class="input-group-text">€</span>
                    </div>
                    <input formControlName="hmiAdaptionOption1AdaptionCost" readOnly siNewton>
                  </si-newton-form-group>
                </div>
            </div>
            <div class="row" *ngIf="parentForm.get('systemUpgrades')?.get('hmiAdaptionOptionSelection')?.value.value === 'based on cost per picture'">
              <div class="col-md-4 col-lg-3">
                <si-newton-form-group label="No of Pictures" [errorResolverMap]="{required: 'Value is required'}">
                  <input formControlName="hmiAdaptionOptio2NoOfPictures" type="number" [min]="0" siNewton>
                </si-newton-form-group>
              </div>
              <div class="col-md-4 col-lg-3">
                <si-newton-form-group label="Cost per Picture" [errorResolverMap]="{required: 'Value is required'}">
                  <div class="input-group-prepend">
                    <span class="input-group-text">€</span>
                  </div>
                  <input formControlName="hmiAdaptionOptio2CostPerPicture" type="number" step="any" [min]="0" siNewton>
                </si-newton-form-group>
              </div>
              <div class="col-md-4 col-lg-3">
                <si-newton-form-group label="Calculated Adaption Cost" [errorResolverMap]="{required: 'Value is required'}">
                  <div class="input-group-prepend">
                    <span class="input-group-text">€</span>
                  </div>
                  <input formControlName="hmiAdaptionOption2AdaptionCost" readOnly siNewton>
                </si-newton-form-group>
              </div>
            </div>
          </div>
        </tab>
        <tab heading="Upgrade Parameters" style="width: 110%; margin-left: -5%">

          <div class="row">
            <p class="info">This section refers to physical systems only</p>
          </div>

          <div class="row">
            <div class="col-lg-3">
              <b class="subSubSectionHeader">Installation effort per component</b>
            </div>
            <div class="col">
              <b><u>PCS 7</u></b>
            </div>
            <div class="col">
              <b><u>PCS neo Evolution</u></b>
            </div>
            <div class="col">
              <b><u>PCS neo</u></b>
            </div>
            <div class="col-lg-3">
              <b class="subSubSectionHeader">FAT / Integration / Documentation</b>
            </div>
            <div class="col">
              <b><u>PCS 7</u></b>
            </div>
            <div class="col">
              <b><u>PCS neo Evolution</u></b>
            </div>
            <div class="col">
              <b><u>PCS neo</u></b>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-3">
              OS Client
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="osClientsPcs7" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="osClientsPcsNeoEvolution" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="osClientsPcsNeo" type="number" step="any" [min]="0" siNewton>
              </si-newton-form-group>
            </div>
            <div class="col-lg-3">
              FAT Integration Test OS-Client / OS-Server
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="fatIntegrationTestClientServerPcs7" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="fatIntegrationTestClientServerPcsNeoEvolution" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="fatIntegrationTestClientServerPcsNeo" type="number" step="any" [min]="0" siNewton>
              </si-newton-form-group>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-3">
              Engineering Stations
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="engineeringStationsPcs7" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="engineeringStationsPcsNeoEvolution" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="engineeringStationsPcsNeo" type="number" step="any" [min]="0" siNewton>
              </si-newton-form-group>
            </div>
            <div class="col-lg-3">
              FAT Integration Test ES / ES-Server
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="fatIntegrationTestEsServerPcs7" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="fatIntegrationTestEsServerPcsNeoEvolution" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="fatIntegrationTestEsServerPcsNeo" type="number" step="any" [min]="0" siNewton>
              </si-newton-form-group>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-3">
              OS Server
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="osServerInstallationPcs7" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="osServerInstallationPcsNeoEvolution" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="osServerInstallationPcsNeo" type="number" step="any" [min]="0" siNewton>
              </si-newton-form-group>
            </div>
            <div class="col-lg-3">
              Update DCS Project documentation per component
            </div>

              <div class="col-lg-1" >
                <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                  <input style="width: 100%" formControlName="updateDocumentationPcs7" type="number" step="any" [min]="0" siNewton >
                </si-newton-form-group>
              </div>
              <div class="col-lg-1">
                <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                  <input formControlName="updateDocumentationPcsNeoEvolution" type="number" step="any" [min]="0" siNewton >
                </si-newton-form-group>
              </div>
              <div class="col-lg-1">
                <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                  <input formControlName="updateDocumentationPcsNeo" type="number" step="any" [min]="0" siNewton>
                </si-newton-form-group>
              </div>
            </div>


          <div class="row">
            <div class="col-lg-3">
              PH Server
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="phServerInstallationPcs7" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="phServerInstallationPcsNeoEvolution" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="phServerInstallationPcsNeo" type="number" step="any" [min]="0" siNewton>
              </si-newton-form-group>
            </div>
            <div class="col-lg-3">
              FAT Approval Documentation
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="fatApprovalDocumentationPcs7" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="fatApprovalDocumentationPcsNeoEvolution" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="fatApprovalDocumentationPcsNeo" type="number" step="any" [min]="0" siNewton>
              </si-newton-form-group>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-3">
              Batch Server
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="batchServerInstallationPcs7" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="batchServerInstallationPcsNeoEvolution" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="batchServerInstallationPcsNeo" type="number" step="any" [min]="0" siNewton>
              </si-newton-form-group>
            </div>

            <div class="col-lg-6">
            </div>

          </div>

          <div class="row">
            <div class="col-lg-3">
              RC Server
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="rcServerInstallationPcs7" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="rcServerInstallationPcsNeoEvolution" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="rcServerInstallationPcsNeo" type="number" step="any" [min]="0" siNewton>
              </si-newton-form-group>
            </div>

            <div class="col-lg-6">
            </div>

          </div>

          <div class="row">
            <div class="col-lg-3">
              Web Server
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="webServerInstallationPcs7" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">

            </div>
            <div class="col-lg-1">

            </div>

            <div class="col-lg-6">
            </div>

          </div>



          <div class="row">
            <div class="col-lg-3">
              <b class="subSubSectionHeader">Adaption of  actual data on site</b>
            </div>
            <div class="col">
              <b><u>PCS 7</u></b>
            </div>
            <div class="col">
              <b><u>PCS neo Evolution</u></b>
            </div>
            <div class="col">
              <b><u>PCS neo</u></b>
            </div>
            <div class="col-lg-3">
              <b class="subSubSectionHeader">On site services</b>
            </div>
            <div class="col">
              <b><u>PCS 7</u></b>
            </div>
            <div class="col">
              <b><u>PCS neo Evolution</u></b>
            </div>
            <div class="col">
              <b><u>PCS neo</u></b>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-3">
              CPUs / F-CPUs (H or single) Archiving and Upload
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="adaptionOfActualDataOnSitePcs7" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="adaptionOfActualDataOnSitePcsNeoEvolution" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="adaptionOfActualDataOnSitePcsNeo" type="number" step="any" [min]="0" siNewton>
              </si-newton-form-group>
            </div>
            <div class="col-lg-3">
              Update Firmware
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="updateFirmwarePcs7" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="updateFirmwarePcsNeoEvolution" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="updateFirmwarePcsNeo" type="number" step="any" [min]="0" siNewton>
              </si-newton-form-group>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-3">
              <b class="subSubSectionHeader">Adaption of application at Engineering Station</b>
            </div>

            <div class="col">
              <b><u>PCS 7</u></b>
            </div>
            <div class="col">
              <b><u>PCS neo Evolution</u></b>
            </div>
            <div class="col">
              <b><u>PCS neo</u></b>
            </div>

            <div class="col-lg-3">
              Onsite Installation (OS Clients, OS Servers, ES...)
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="onsiteInstallationPcs7" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="onsiteInstallationPcsNeoEvolution" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="onsiteInstallationPcsNeo" type="number" step="any" [min]="0" siNewton>
              </si-newton-form-group>
            </div>

          </div>

          <div class="row">
            <div class="col-lg-3">
              Save & Reorganize Multi Project
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="saveReorganizePcs7" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="saveReorganizePcsNeoEvolution" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="saveReorganizePcsNeo" type="number" step="any" [min]="0" siNewton>
              </si-newton-form-group>
            </div>

            <div class="col-lg-3">
              Runtime Data Migration OS Server
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="runtimeDataMigrationPcs7" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="runtimeDataMigrationPcsNeoEvolution" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="runtimeDataMigrationPcsNeo" type="number" step="any" [min]="0" siNewton>
              </si-newton-form-group>
            </div>

          </div>

          <div class="row">
            <div class="col-lg-3">
              CPU S7 -Format adaption, Compilation, Down- & Upload
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="formAdaptionPcs7" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="formAdaptionPcsNeoEvolution" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="formAdaptionPcsNeo" type="number" step="any" [min]="0" siNewton>
              </si-newton-form-group>
            </div>

            <div class="col-lg-3">
              Create PCS 7 project backup
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="createProjectBackupPcs7" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="createProjectBackupPcsNeoEvolution" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="createProjectBackupPcsNeo" type="number" step="any" [min]="0" siNewton>
              </si-newton-form-group>
            </div>

          </div>

          <div class="row">
            <div class="col-lg-3">
              OS-Server / Offline data
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="osServerOfflineDataPcs7" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="osServerOfflineDataPcsNeoEvolution" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="osServerOfflineDataPcsNeo" type="number" step="any" [min]="0" siNewton>
              </si-newton-form-group>
            </div>

            <div class="col-lg-3">
              Create Backup / System Image per PC
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="createBackupPerPCPcs7" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="createBackupPerPCPcsNeoEvolution" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="createBackupPerPCPcsNeo" type="number" step="any" [min]="0" siNewton>
              </si-newton-form-group>
            </div>

          </div>

          <div class="row">
            <div class="col-lg-3">
              OS-Client
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="osClientPcs7" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="osClientPcsNeoEvolution" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="osClientPcsNeo" type="number" step="any" [min]="0" siNewton>
              </si-newton-form-group>
            </div>

            <div class="col-lg-3">
              On site SIT / approval / health check
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="onSiteSITPcs7" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="onSiteSITPcsNeoEvolution" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="onSiteSITPcsNeo" type="number" step="any" [min]="0" siNewton>
              </si-newton-form-group>
            </div>

          </div>

          <div class="row">
            <div class="col-lg-3">
              SW-Batch Server, Installation / Compilation
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="swBatchServerInstallationPcs7" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="swBatchServerInstallationPcsNeoEvolution" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="swBatchServerInstallationPcsNeo" type="number" step="any" [min]="0" siNewton>
              </si-newton-form-group>
            </div>

            <div class="col-lg-3">
              Test effort per interconnection via CP
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="testEffortPcs7" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="testEffortPcsNeoEvolution" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="testEffortPcsNeo" type="number" step="any" [min]="0" siNewton>
              </si-newton-form-group>
            </div>

          </div>

          <div class="row">
            <div class="col-lg-3">
              SW Batch Client
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="swBatchClientPcs7" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="swBatchClientPcsNeoEvolution" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="swBatchClientPcsNeo" type="number" step="any" [min]="0" siNewton>
              </si-newton-form-group>
            </div>

            <div class="col-lg-3">
              <b class="subSubSectionHeader">Project Management</b>
            </div>
            <div class="col">
              <b><u>PCS 7</u></b>
            </div>
            <div class="col">
              <b><u>PCS neo Evolution</u></b>
            </div>
            <div class="col">
              <b><u>PCS neo</u></b>
            </div>

          </div>

          <div class="row">
            <div class="col-lg-3">
              Fail Safe CPU single / redundant
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="failSafeCPUPcs7" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="failSafeCPUPcsNeoEvolution" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="failSafeCPUPcsNeo" type="number" step="any" [min]="0" siNewton>
              </si-newton-form-group>
            </div>

            <div class="col-lg-3">
              Project management / Percentage of total cost of HW+SW upgrade
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="%" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="projectManagementPcs7" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="%" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="projectManagementPcsNeoEvolution" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="%" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="projectManagementPcsNeo" type="number" step="any" [min]="0" siNewton>
              </si-newton-form-group>
            </div>

          </div>

          <div class="row">
            <div class="col-lg-3">
              Fail Safe System
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="failSafeSystemPcs7" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="failSafeSystemPcsNeoEvolution" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="failSafeSystemPcsNeo" type="number" step="any" [min]="0" siNewton>
              </si-newton-form-group>
            </div>

            <div class="col-lg-3">
              <b class="subSubSectionHeader">Amount of Firmware Update</b>
            </div>
            <div class="col">
              <b><u>PCS 7</u></b>
            </div>
            <div class="col">
              <b><u>PCS neo Evolution</u></b>
            </div>
            <div class="col">
              <b><u>PCS neo</u></b>
            </div>

          </div>


          <div class="row">
            <div class="col-lg-3">
              Asset Management
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="assetManagementPcs7" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="assetManagementPcsNeoEvolution" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="assetManagementPcsNeo" type="number" step="any" [min]="0" siNewton>
              </si-newton-form-group>
            </div>

            <div class="col-lg-3">
              Firmware Update amount per HW & SW Upgrade
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="firmwareUpdatePcs7" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="firmwareUpdatePcsNeoEvolution" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="firmwareUpdatePcsNeo" type="number" step="any" [min]="0" siNewton>
              </si-newton-form-group>
            </div>

          </div>

          <div class="row">
            <div class="col-lg-3">
              Process Device Management PDM
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="processDeviceManagementPcs7" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="processDeviceManagementPcsNeoEvolution" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="processDeviceManagementPcsNeo" type="number" step="any" [min]="0" siNewton>
              </si-newton-form-group>
            </div>

            <div class="col-lg-3">
              <b class="subSubSectionHeader">IT Administration & security</b>
            </div>
            <div class="col">
              <b><u>PCS 7</u></b>
            </div>
            <div class="col">
              <b><u>PCS neo Evolution</u></b>
            </div>
            <div class="col">
              <b><u>PCS neo</u></b>
            </div>

          </div>

          <div class="row">
            <div class="col-lg-3">
              Route Control
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="routeControlPcs7" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="routeControlPcsNeoEvolution" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="routeControlPcsNeo" type="number" step="any" [min]="0" siNewton>
              </si-newton-form-group>
            </div>

            <div class="col-lg-3">
              User Management per PC (Configuration without DC.)
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="userManagementPcs7" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="userManagementPcsNeoEvolution" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="userManagementPcsNeo" type="number" step="any" [min]="0" siNewton>
              </si-newton-form-group>
            </div>

          </div>

          <div class="row">
            <div class="col-lg-3">
              CAS-/PH-/IS-Server
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="casPhIsServerPcs7" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="casPhIsServerPcsNeoEvolution" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="casPhIsServerPcsNeo" type="number" step="any" [min]="0" siNewton>
              </si-newton-form-group>
            </div>

            <div class="col-lg-3">
              Windows Domain Controller setup
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="windowsDomainControllerSetupPcs7" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="windowsDomainControllerSetupPcsNeoEvolution" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="windowsDomainControllerSetupPcsNeo" type="number" step="any" [min]="0" siNewton>
              </si-newton-form-group>
            </div>

          </div>

          <div class="row">
            <div class="col-lg-3">
              Web Server
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="webServerPcs7" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">

            </div>
            <div class="col-lg-1">

            </div>

            <div class="col-lg-3">
              Integration of a OS-Client in Windows Domain
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="integrationOfOsClientPcs7" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="integrationOfOsClientPcsNeoEvolution" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="integrationOfOsClientPcsNeo" type="number" step="any" [min]="0" siNewton>
              </si-newton-form-group>
            </div>

          </div>

          <div class="row">
            <div class="col-lg-3">
              Safety Matrix
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="safetyMatrixPcs7" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="safetyMatrixPcsNeoEvolution" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="safetyMatrixPcsNeo" type="number" step="any" [min]="0" siNewton>
              </si-newton-form-group>
            </div>

            <div class="col-lg-3">
              WSUS Server setup
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="wSusServerSetupPcs7" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="wSusServerSetupPcsNeoEvolution" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="wSusServerSetupPcsNeo" type="number" step="any" [min]="0" siNewton>
              </si-newton-form-group>
            </div>

          </div>

          <div class="row">
            <div class="col-lg-6">

            </div>

            <div class="col-lg-3">
              Firewall setup
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="firewallSetupPcs7" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="firewallSetupPcsNeoEvolution" type="number" step="any" [min]="0" siNewton >
              </si-newton-form-group>
            </div>
            <div class="col-lg-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="firewallSetupPcsNeo" type="number" step="any" [min]="0" siNewton>
              </si-newton-form-group>
            </div>

          </div>


        </tab>
        <tab heading="Other Costs">
          <div class="col-md-4 col-lg-2">
            <si-newton-form-group label="Other Costs" [errorResolverMap]="{required: 'Value is required'}">
              <div class="input-group-prepend">
                <span class="input-group-text">€</span>
              </div>
              <input formControlName="otherCostsRentalAso" type="number" step="any" [min]="0" siNewton>
            </si-newton-form-group>
          </div>
        </tab>
      </tabset>

    </div>


  </div>
</form>
