import { Component, ElementRef, ViewChild } from '@angular/core';
import { SiNewtonLoadingService } from '@simpl/newton-ng/loading-spinner';
import { DataTransferService } from 'src/app/services/data-transfer.service';
import { CustomToastService } from '../services/custom-toast.service';
import { AdminService } from '../services/admin.service';

@Component({
  selector: 'app-admin-view',
  templateUrl: './admin-view.component.html',
  styleUrls: ['./admin-view.component.scss']
})
export class AdminViewComponent {

  @ViewChild('fileSelection', { static: true })
  fileSelection: ElementRef<HTMLInputElement> | undefined;

  @ViewChild('fileSelectionReference', { static: true })
  fileSelectionReference: ElementRef<HTMLInputElement> | undefined;

  masterFile: File | undefined;
  referenceFile: File | undefined;

  masterFileData: {
    file_name: string,
    file_upload: string
  } | undefined

  referenceFileData: {
    file_name: string,
    file_upload: string
  } | undefined

  constructor(private loadingSpinner: SiNewtonLoadingService, private adminService: AdminService,
    private dataTransferService: DataTransferService, private toastService: CustomToastService) { 
      this.getFileInfo();
  }

  async getFileInfo(){
    this.adminService.getFileInfo('Masterfile').then(res => {
      this.masterFileData = res;
    });
    this.adminService.getFileInfo('Referencefile').then(res => {
      this.referenceFileData = res;
    });    
  }

  async downloadFile(fileType: string, fileName?: string) {

    this.loadingSpinner.startLoading();
    await this.dataTransferService.downloadFile(fileType, fileName);
    this.loadingSpinner.stopLoading();
  }

  async storeMasterFile() {

    this.masterFile = this.fileSelection?.nativeElement.files?.item(0) || undefined;
  }

  async storeReferenceFile() {

    this.referenceFile = this.fileSelectionReference?.nativeElement.files?.item(0) || undefined;1
  }

  async uploadMasterfile() {
    
    if (this.masterFile !== undefined) {

      if (!this.masterFile.name.includes('LCS_Master') || !this.masterFile.name.includes('.xlsx')) {
        this.toastService.showErrorToast('For Masterfile only filename LCS_Master with extension .xlsx is allowed');
        return;
      } else {
        await this.adminService.uploadConfigFile(this.masterFile, 'Masterfile');
        this.getFileInfo();
      }
    } else {
      this.toastService.showErrorToast('Please select a file first');
    }
  }

  async uploadReferencefile() {

    if (this.referenceFile !== undefined) {

      if (!this.referenceFile.name.includes('MLFB_Reference') || !this.referenceFile.name.includes('.xlsx')) {
        this.toastService.showErrorToast('For MLFB Reference only filenames with MLFB_Reference as start and .xlsx as extension is allowed');
        return;
      } else {
        await this.adminService.uploadConfigFile(this.referenceFile, 'Referencefile');
        this.getFileInfo();
      }
    } else {
      this.toastService.showErrorToast('Please select a file first');
    }
  }

}
