<form [formGroup]="parentForm">
    <div formGroupName="swMaintCostOverview">

        <div class="row">
            <div class="col">
                <b class="subSubSectionHeader">Cost Overview</b>
            </div>
        </div>

        <div class="row" style="width: 100%; margin: auto; padding-bottom: 15px; font-weight: bold;">

            <input formControlName="yearOfStartFix" hidden #yearOfStartFix>
        
            <div class="col-xs-12 col-md-2">
                <div class="row">&nbsp;</div>
                <div class="row" style="background-color:rgba(80, 190, 215, 0.7);">PCS 7 SUS</div>
                <div class="row" style="background-color: rgba(0, 95, 135, 0.7);">LSS</div>
                <div class="row" style="background-color:rgba(80, 190, 215, 0.7);">SW Maintenance Contract</div>
                <div class="row" style="background-color: rgba(0, 95, 135, 0.7);">PCS neo SW Lic. Procurement</div>
                <div class="row" style="background-color:rgba(80, 190, 215, 0.7);">PCS neo SWP Infinity</div>
        
            </div>
        
            <ng-container *ngFor="let _ of [].constructor(5), let x = index">
        
              <div class="col-xs-12 col-md-2">
                <div class="row year">{{stringToNumber(yearOfStartFix.value) + x}}</div>
                <div class="row" style="background-color:rgba(80, 190, 215, 0.7); padding-left: 50%;">{{parentForm.get('swMaintCostOverview')?.get('pcs7SusCostYear' + (x+1))?.value}} €</div>
                <div class="row" style="background-color: rgba(0, 95, 135, 0.7); padding-left: 50%;">{{parentForm.get('swMaintCostOverview')?.get('pcs7LssCostYear' + (x+1))?.value}} €</div>
                <div class="row" style="background-color:rgba(80, 190, 215, 0.7); padding-left: 50%;">{{parentForm.get('swMaintCostOverview')?.get('pcs7SwMaintConCostYear' + (x+1))?.value}} €</div>
                <div class="row" style="background-color: rgba(0, 95, 135, 0.7); padding-left: 50%;">{{parentForm.get('swMaintCostOverview')?.get('pcsNeoLicenseProcCostYear' + (x+1))?.value}} €</div>
                <div class="row" style="background-color:rgba(80, 190, 215, 0.7); padding-left: 50%;">{{parentForm.get('swMaintCostOverview')?.get('pcsNeoSwInfinityCostYear' + (x+1))?.value}} €</div>
              </div>
        
            </ng-container>
        
          </div>
          <div class="row" style="width: 100%; margin: auto; padding-bottom: 15px; font-weight: bold;">
        
            <input formControlName="yearOfStartFix" hidden #yearOfStartFix>
        
            <div class="col-xs-12 col-md-2">
                <div class="row">&nbsp;</div>
                <div class="row" style="background-color:rgba(80, 190, 215, 0.7);">PCS 7 SUS</div>
                <div class="row" style="background-color: rgba(0, 95, 135, 0.7);">LSS</div>
                <div class="row" style="background-color:rgba(80, 190, 215, 0.7);">SW Maintenance Contract</div>
                <div class="row" style="background-color: rgba(0, 95, 135, 0.7);">PCS neo SW Lic. Procurement</div>
                <div class="row" style="background-color:rgba(80, 190, 215, 0.7);">PCS neo SWP Infinity</div>
            </div>
        
            <ng-container *ngFor="let _ of [].constructor(5), let x = index">
              <div class="col-xs-12 col-md-2">
                <div class="row year">{{stringToNumber(yearOfStartFix.value) + (x+5)}}</div>
                <div class="row" style="background-color:rgba(80, 190, 215, 0.7); padding-left: 50%;">{{parentForm.get('swMaintCostOverview')?.get('pcs7SusCostYear' + (x+6))?.value}} €</div>
                <div class="row" style="background-color: rgba(0, 95, 135, 0.7); padding-left: 50%;">{{parentForm.get('swMaintCostOverview')?.get('pcs7LssCostYear' + (x+6))?.value}} €</div>
                <div class="row" style="background-color:rgba(80, 190, 215, 0.7); padding-left: 50%;">{{parentForm.get('swMaintCostOverview')?.get('pcs7SwMaintConCostYear' + (x+6))?.value}} €</div>
                <div class="row" style="background-color: rgba(0, 95, 135, 0.7); padding-left: 50%;">{{parentForm.get('swMaintCostOverview')?.get('pcsNeoLicenseProcCostYear' + (x+6))?.value}} €</div>
                <div class="row" style="background-color:rgba(80, 190, 215, 0.7); padding-left: 50%;">{{parentForm.get('swMaintCostOverview')?.get('pcsNeoSwInfinityCostYear' + (x+6))?.value}} €</div>
              </div>
        
            </ng-container>
        
          </div>
          <div class="row" style="width: 100%; margin: auto; padding-bottom: 15px; font-weight: bold;">
        
            <input formControlName="yearOfStartFix" hidden #yearOfStartFix>
        
            <div class="col-xs-12 col-md-2">
                <div class="row">&nbsp;</div>
                <div class="row" style="background-color:rgba(80, 190, 215, 0.7);">PCS 7 SUS</div>
                <div class="row" style="background-color: rgba(0, 95, 135, 0.7);">LSS</div>
                <div class="row" style="background-color:rgba(80, 190, 215, 0.7);">SW Maintenance Contract</div>
                <div class="row" style="background-color: rgba(0, 95, 135, 0.7);">PCS neo SW Lic. Procurement</div>
                <div class="row" style="background-color:rgba(80, 190, 215, 0.7);">PCS neo SWP Infinity</div>
            </div>
        
            <ng-container *ngFor="let _ of [].constructor(5), let x = index">
        
              <div class="col-xs-12 col-md-2">
                <div class="row year">{{stringToNumber(yearOfStartFix.value) + (x+10)}}</div>
                <div class="row" style="background-color:rgba(80, 190, 215, 0.7); padding-left: 50%;">{{parentForm.get('swMaintCostOverview')?.get('pcs7SusCostYear' + (x+11))?.value}} €</div>
                <div class="row" style="background-color: rgba(0, 95, 135, 0.7); padding-left: 50%;">{{parentForm.get('swMaintCostOverview')?.get('pcs7LssCostYear' + (x+11))?.value}} €</div>
                <div class="row" style="background-color:rgba(80, 190, 215, 0.7); padding-left: 50%;">{{parentForm.get('swMaintCostOverview')?.get('pcs7SwMaintConCostYear' + (x+11))?.value}} €</div>
                <div class="row" style="background-color: rgba(0, 95, 135, 0.7); padding-left: 50%;">{{parentForm.get('swMaintCostOverview')?.get('pcsNeoLicenseProcCostYear' + (x+11))?.value}} €</div>
                <div class="row" style="background-color:rgba(80, 190, 215, 0.7); padding-left: 50%;">{{parentForm.get('swMaintCostOverview')?.get('pcsNeoSwInfinityCostYear' + (x+11))?.value}} €</div>
              </div>
        
            </ng-container>
        
          </div>
    </div>
</form>
