import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ConstRestData } from 'src/app/const-data/rest';
import { NavigationComponent } from 'src/app/navigation/navigation.component';
import { DataTransferService } from 'src/app/services/data-transfer.service';
import { FormDataService } from 'src/app/services/form-data.service';
import { PhysicalSystemComponent } from './sub-sections/physical-system/physical-system.component';
import { SivaasSystemComponent } from './sub-sections/sivaas-system/sivaas-system.component';

export interface DropDownOption {
  value: string;
}

@Component({
  selector: 'app-define-configuration',
  templateUrl: './define-configuration.component.html',
  styleUrls: ['./define-configuration.component.scss']
})
export class DefineConfigurationComponent implements OnInit {

  REST_URLS = ConstRestData.restUrls;

  defineConfigurationForm: FormGroup;

  @ViewChild(SivaasSystemComponent) sivaasSystemForm!: SivaasSystemComponent;
  @ViewChild(PhysicalSystemComponent) physicalSystemForm!: PhysicalSystemComponent;

  @ViewChild(NavigationComponent) navigation!: NavigationComponent;
  @ViewChild('first-element', { static: false }) firstElement!: ElementRef;

  public getDropdownOptions(formControlName: string){
    return this.formDataService.getDropDownOptions(formControlName);
  }

  public getDropdownValue(dropDownOption: DropDownOption): string {
    return dropDownOption.value;
  }

  constructor(private formDataService: FormDataService, private dataTransferService: DataTransferService,
    private formBuilder: FormBuilder) {
    this.defineConfigurationForm = this.formBuilder.group({
      generalConfiguration: this.formBuilder.group({
        numOfPlantSections: [null, Validators.compose([Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)])]
      }),
      swEngineering: this.formBuilder.group({
        pcs7MultiProjects: [null, Validators.compose([Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)])]
      }),
      systemOverview: this.formBuilder.group({
        quantityPCS7OSClientHW: [null, Validators.compose([Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        quantityPCS7OSServerHW: [null, Validators.compose([Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        quantityPCS7ESHW: [null, Validators.compose([Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        quantityPCS7BatchServerHW: [null, Validators.compose([Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        quantityPCS7WebServerHW: [null, Validators.compose([Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        quantityPCS7PHServerHW: [null, Validators.compose([Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        quantityPCS7RCServerHW: [null, Validators.compose([Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        quantityPCS7WinDomainServerHW: [null, Validators.compose([Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        quantityPCS7WSUSServerHW: [null, Validators.compose([Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        pcs7assetManagementIncluded: [null, Validators.required],
        quantityPCS7PdmServerSW: [null, Validators.compose([Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        quantityPCS7WebServerSW:
          [null, Validators.compose([Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        quantityPCS7BatchServerSW: [null, Validators.compose([Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        quantityPCS7BatchClientsSW: [null, Validators.compose([Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        quantityPCS7RCServerSW: [null, Validators.compose([Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        quantityPCS7RcClientsSW: [null, Validators.compose([Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        quantityPCS7RcEsSW:
          [null, Validators.compose([Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        quantityPCS7CPs: [null, Validators.compose([Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        quantityPCS7FieldbusComponents: [null, Validators.compose([Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        quantityPCS7ASSingle: [null, Validators.compose([Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        quantityPCS7ASRedundand: [null, Validators.compose([Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        quantityPCS7FSYS: [null, Validators.compose([Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        quantityPCS7NetSegments: [null, Validators.compose([Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        quantityPCS7NetworkComponents: [null, Validators.compose([Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        typesOfPCS7Switches: [null],
        totalLPriceOfPCS7Switches: [null],
        quantityPCS7MaintenanceStationSW: [null],
        quantityPCS7OPCServerHW: [null],
        quantityPCS7PdmServerHW: [null],
        quantityPCS7PhServerSW: [null],
        quantityPCS7OPCServerSW: [null],
        quantityPcsNeoMCServerHW: [null],
        quantityPcsNeoPlantClientsHW: [null],
        quantityPcsNeoESHW: [null],
        quantityPcsNeoPHServerHW: [null],
        quantityPcsNeoBatchServerHW: [null],
        quantityPcsNeoRCServerHW: [null],
        quantityPcsNeoDMServerHW: [null],
        quantityPcsNeoWinDomainServerHW: [null],
        quantityPcsNeoWSUSServerHW: [null],
        quantityPcsNeoOPCHW: [null],
        quantityPcsNeoBatchSW: [null],
        quantityPcsNeoBatchClientSW: [null],
        quantityPcsNeoRouteControlSW: [null],
        quantityPcsNeoMCOpcUAServerSW: [null],
        quantityPcsNeoHistorianSW: [null],
        quantityPcsNeoPDMSW: [null],
        quantityPcsNeoPlcAsSingle: [null],
        quantityPcsNeoPlcAsRedundant: [null],
        quantityPcsNeoFES: [null, Validators.compose([Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        quantityPcsNeoSwitches: [null],
        quantityPcsNeoNetSegments: [null],
        quantityPcsNeoCPs: [null],
        quantityPcsNeoFieldbusComponents: [null],
        quantityPcsNeoFSYS: [null],
        totalLPriceOfPcsNeoSwitches: [null],
        typesOfPcsNeoSwitches: [null],
        pcsNeoAssetManagementIncluded: [null, Validators.required],
        pcs7SupplComponentTotalCost: [0, Validators.required],
        quantityPCS7NetworkCompSuppl: [0, Validators.required],
        pcsNeoSupplComponentTotalCost: [0, Validators.required],
        pcsNeoSupplComponentsQuantity: [0, Validators.required],
        typesOfPCS7NetworkComponentsSuppl: [0, Validators.required],
        typesOfPcsNeoNetworkComponentsSuppl: [0, Validators.required]
        // quantityPcsNeoFsWithFSystemEngineering: [null]

      }),
      physicalSystemBOM: this.formBuilder.group({
        newComponentMlfb: [''],
        newComponentDescription: [''],
        newComponentQuantity: [0, Validators.pattern(/^-?(0|[1-9]\d*)?$/)],
        newComponentListPrice: [0],
        newComponentType: [null],
        newNeoComponentMlfb: [''],
        newNeoComponentDescription: [''],
        newNeoComponentQuantity: [0, Validators.pattern(/^-?(0|[1-9]\d*)?$/)],
        newNeoComponentListPrice: [0],
        newNeoComponentType: [null]
      }),
      sivaasSystem: this.formBuilder.group({
        SivaasConfProjectName: [null, Validators.required],
        SivaasConfProjectId: [null, Validators.required],
        SivaasConfProjectRevision: [null, Validators.required],
        SivaasConfProjectRevisionCommitName: [null, Validators.required],
        newComponentMlfb: [''],
        newComponentName: [''],
        newComponentQuantity: [0, Validators.pattern(/^-?(0|[1-9]\d*)?$/)],
        newComponentListPrice: [0],
        SivaasConfCategory: [null]
      }),
      plantTopology: this.formBuilder.group({
        centralControlRooms: [null, Validators.compose([Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        centralCabinets: [null, Validators.compose([Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        distributedCabinets: [null, Validators.compose([Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        transferTimeToDistributedCabinet:
          [null, Validators.compose([Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        transferTimeToCentralCabinet:
          [null, Validators.compose([Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        ASesNoOfComponents: [null, Validators.compose([Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        ASesAddEffort: [null, Validators.compose([Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        PcsNoOfComponents: [null, Validators.compose([Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        PcsAddEffort: [null, Validators.compose([Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        CabinetsNoOfComponents:
          [null, Validators.compose([Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        CabinetsAddEffort:
          [null, Validators.compose([Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        SwitchesNoOfComponents:
          [null, Validators.compose([Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        SwitchesAddEffort: [null, Validators.compose([Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)])]
      })
    });

    //Add repeating FormControls for Sivaas System
    for (let i = 1; i <= 100; i++){

      (this.defineConfigurationForm.get('sivaasSystem') as FormGroup)!.
        addControl('SivaasConfCategory' + i, new FormControl(null));

      (this.defineConfigurationForm.get('sivaasSystem') as FormGroup)!.
        addControl('SivaasConfMLFB' + i, new FormControl(null));

      (this.defineConfigurationForm.get('sivaasSystem') as FormGroup)!.
        addControl('SivaasConfName' + i, new FormControl(null));

      (this.defineConfigurationForm.get('sivaasSystem') as FormGroup)!.
        addControl('SivaasConfQuantity' + i,
          new FormControl(null, Validators.pattern(/^-?(0|[1-9]\d*)?$/)));

      (this.defineConfigurationForm.get('sivaasSystem') as FormGroup)!.
        addControl('SivaasConfListPrice' + i, new FormControl(null));

      (this.defineConfigurationForm.get('sivaasSystem') as FormGroup)!.
        addControl('SivaasConfTotalListPrice' + i, new FormControl(null));

      (this.defineConfigurationForm.get('sivaasSystem') as FormGroup)!.
        addControl('SivaasConfTotalGAPrice' + i, new FormControl(null));
    }

    //Add repeating FormControls for Physical System
    for (let i = 1; i <= 200; i++){

      (this.defineConfigurationForm.get('physicalSystemBOM') as FormGroup)!.
        addControl('physicalSystemMlfb' + i, new FormControl(null));

      (this.defineConfigurationForm.get('physicalSystemBOM') as FormGroup)!.
        addControl('physicalSystemPrice' + i, new FormControl(null));

      (this.defineConfigurationForm.get('physicalSystemBOM') as FormGroup)!.
        addControl('physicalSystemComponentType' + i, new FormControl(null));

      (this.defineConfigurationForm.get('physicalSystemBOM') as FormGroup)!.
        addControl('physicalSystemQuantity' + i,
          new FormControl(null, Validators.pattern(/^-?(0|[1-9]\d*)?$/)));

      (this.defineConfigurationForm.get('physicalSystemBOM') as FormGroup)!.
        addControl('physicalSystemTotalQuantity' + i, new FormControl(null));

      (this.defineConfigurationForm.get('physicalSystemBOM') as FormGroup)!.
        addControl('physicalSystemDesc' + i, new FormControl(null));

      //PCS neo

      (this.defineConfigurationForm.get('physicalSystemBOM') as FormGroup)!.
        addControl('physicalSystemNeoMlfb' + i, new FormControl(null));

      (this.defineConfigurationForm.get('physicalSystemBOM') as FormGroup)!.
        addControl('physicalSystemNeoPrice' + i, new FormControl(null));

      (this.defineConfigurationForm.get('physicalSystemBOM') as FormGroup)!.
        addControl('physicalSystemNeoComponentType' + i, new FormControl(null));

      (this.defineConfigurationForm.get('physicalSystemBOM') as FormGroup)!.
        addControl('physicalSystemNeoQuantity' + i,
          new FormControl(null, Validators.pattern(/^-?(0|[1-9]\d*)?$/)));

      (this.defineConfigurationForm.get('physicalSystemBOM') as FormGroup)!.
        addControl('physicalSystemNeoTotalQuantity' + i, new FormControl(null));

      (this.defineConfigurationForm.get('physicalSystemBOM') as FormGroup)!.
        addControl('physicalSystemNeoDesc' + i, new FormControl(null));


    }
  }

  ngOnInit(): void {
    this.readData();
  }

  async logInvalidControls(formGroup: FormGroup) {

    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.get(key);

      if (control instanceof FormGroup) {
        this.logInvalidControls(control);
      } else {
        if (control?.invalid) {

          console.log(`Control '${key}' is invalid`);
        }
      }
    });
  }

  async readData() {

    const keysFromDialog = await this.formDataService.getAllControlsFromDialogAsArray(this.defineConfigurationForm);

    const formData = await this.dataTransferService.getValuesFromExcel(keysFromDialog) as any;

    await this.formDataService.fillDataIntoDialog(this.defineConfigurationForm, formData);

    await this.sivaasSystemForm.fillTableWithSivaasComponents();
    await this.physicalSystemForm.fillTableWithPhysicalSystemComponents();

  }

  async writeData() {
    const jsonData = await this.formDataService.getDataFromDialog(this.defineConfigurationForm);

    await this.dataTransferService.writeDataToExcel(JSON.stringify(jsonData));

    await this.readData();
  }

  scrollToTop(){
    const element = document.getElementById('first-element');
    element?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  async submit(){
    await this.writeData();
  }

}
