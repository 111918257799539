import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormDataService } from 'src/app/services/form-data.service';

export interface DropDownOption {
  value: string;
}


@Component({
  selector: 'app-information-service',
  templateUrl: './information-service.component.html',
  styleUrls: ['./information-service.component.scss']
})
export class InformationServiceComponent {

  isThisSectionVisible = false;

  toggleVisibility(){
    console.log(this.isThisSectionVisible);
    this.isThisSectionVisible = !this.isThisSectionVisible;
  }

  @Input()
  parentForm!: FormGroup;
  rBInfoService!: string;

  constructor(private formDataService: FormDataService) { }

  public getDropdownOptions(formControlName: string){

    return this.formDataService.getDropDownOptions(formControlName);
  }

  public getDropdownValue(dropDownOption: DropDownOption): string {
    return dropDownOption.value;
  }


}
