<form [formGroup]="parentForm">
  <div formGroupName="contractManagement">

    <h5 class="subSectionHeader">Contract Management
      <i style="float: right; cursor: pointer"
      [ngClass]="{'bi-arrow-up-circle-fill': isThisSectionVisible, 'bi-arrow-down-circle-fill': !isThisSectionVisible}"
      (click)="toggleVisibility()"></i>
    </h5>

    <div  [ngStyle]="{'display': isThisSectionVisible ? 'block' : 'none'}">
      <div class="row">
        <div class="col">
          <div class="form-check">
            <input id="rBContractManagement0" class="form-check form-check-inline" formControlName="rBContractManagement"
                  type="radio"
                  [(ngModel)]="rBContractManagement"
                  value="standard calculation">
            <label for="rBContractManagement0" class="form-check-label">
              standard calculation
            </label>
          </div>
        </div>
        <div class="col"></div>

        <div class="col">
          <div class="form-check">
            <input id="rBContractManagement1" class="form-check form-check-inline" formControlName="rBContractManagement"
                  type="radio"
                  [(ngModel)]="rBContractManagement"
                  value="alternative value for Contract Management">
            <label for="rBContractManagement1" class="form-check-label">
              alternative value for Contract Management
            </label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-2">
          <si-newton-form-group label="Basic effort Standard" [errorResolverMap]="{required: 'Value is required'}">
            <div class="input-group-prepend">
              <span class="input-group-text">hour</span>
            </div>
            <input formControlName="basicEffortStandard" type="number" step="any" [min]="0" siNewton>
          </si-newton-form-group>
        </div>

        <div class="col-md-2">
          <si-newton-form-group label="Perc. of total LCS cost" [errorResolverMap]="{required: 'Value is required'}">
            <div class="input-group-prepend">
              <span class="input-group-text">%</span>
            </div>
            <input formControlName="percentageOfTotalLcsCost" type="number" step="any" [min]="0" siNewton>
          </si-newton-form-group>
        </div>

        <div class="col-md-2"></div>

        <div class="col-md-4">
          <si-newton-form-group label="Cost for alternative Management" [errorResolverMap]="{required: 'Value is required'}">
            <div class="input-group-prepend">
              <span class="input-group-text">€</span>
            </div>
            <input formControlName="costForAlternativeManagement" type="number" step="any" [min]="0" siNewton>
          </si-newton-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col"></div>
        <div class="col">
          <si-newton-form-group label="Calculated Costs" [errorResolverMap]="{required: 'Value is required'}" class="highlight">
            <div class="input-group-prepend">
              <span class="input-group-text">€ / year</span>
            </div>
            <input formControlName="textFieldCurrentCostPerYearContractManagement" readOnly type="number" step="any" [min]="0" siNewton highlight>
          </si-newton-form-group>
        </div>
        <div class="col"></div>
      </div>

    </div>


  </div>

</form>
