<si-newton-section>

  <app-navigation [currentSectionForm]=defineConfigurationForm></app-navigation>

  <form [formGroup]="defineConfigurationForm" class="col-md-12" style="align-items: center;">
    <app-general-configuration id="first-element" [parentForm]="defineConfigurationForm"></app-general-configuration>
    <app-sw-engineering [parentForm]="defineConfigurationForm"></app-sw-engineering>
    <app-physical-system [parentForm]="defineConfigurationForm" (readData)="readData()" (submitValues)="submit()"></app-physical-system>
    <app-system-overview [parentForm]="defineConfigurationForm"></app-system-overview>
    <app-sivaas-system [parentForm]="defineConfigurationForm" (readData)="readData()" (submitValues)="submit()"></app-sivaas-system>
    <app-plant-topology [parentForm]="defineConfigurationForm"></app-plant-topology>
    <button type="button" class="btn btn-success" style="position: fixed; float:right; bottom: 50px; right: 120px;" (click)="submit()"
    [disabled]="!(defineConfigurationForm.valid)">Submit</button>
    <button type="button" class="btn btn-primary" style="position: fixed; float:right; bottom: 50px; right: 20px;"
    (click)="scrollToTop()">Go to top</button>
    <button *ngIf="!(defineConfigurationForm.valid)" type="button" class="btn btn-primary" style="position: fixed; float:right; bottom: 0px; right: 20px;"
    (click)="logInvalidControls(defineConfigurationForm)">
      Log Invalid Values
    </button>
  </form>
</si-newton-section>
