  <form [formGroup]="softwareUpdateServiceForm" class="col-md-12" style="align-items: center;">
    <app-sus-type id="first-element" [parentForm]="softwareUpdateServiceForm"></app-sus-type>
    <app-pcs-software-update-service [parentForm]="softwareUpdateServiceForm" [selectedSusType]="softwareUpdateServiceForm.get('susType')?.get('susTypeRb')?.value"></app-pcs-software-update-service>
    <app-selected-licenses [parentForm]="softwareUpdateServiceForm" [allSusPackages]="allSusPackages"></app-selected-licenses>
    <app-upgrade-packages [parentForm]="softwareUpdateServiceForm"></app-upgrade-packages>
    <button type="button" class="btn btn-success" style="position: fixed; float:right; bottom: 50px; right: 120px;" (click)="submit()"
    [disabled]="!(softwareUpdateServiceForm.valid)">Submit</button>
    <button type="button" class="btn btn-primary" style="position: fixed; float:right; bottom: 50px; right: 20px;"
    (click)="scrollToTop()">Go to top</button>
  </form>
