/* eslint-disable guard-for-in */
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UiElements } from '../const-data/ui-elements';

interface KeyValuePair {
  [key: string]: string | number;
}

@Injectable({
  providedIn: 'root'
})
export class FormDataService {

  constructor() { }

  public fillDataIntoDialog(parentForm: FormGroup, dataToWrite: any){

    return new Promise((resolve, reject) => {
      if (dataToWrite === false){
        return resolve(true);
      }

      for (const field in parentForm.controls) {
        const subForm = parentForm.get(field) as FormGroup;

        for (const subField in subForm.controls) {

          if (subForm.get(subField)){

            if (UiElements.checkBoxes.includes(subField)){

              //+ before a boolean sets a number. +false = 0, +true = 1
              subForm.get(subField)!.setValue(+dataToWrite[subField]);

            } else if (UiElements.dropDowns.filter(dd => dd.element === subField).length > 0) {

              try {
                subForm.get(subField)!.setValue(
                  UiElements.dropDowns.filter(dd => dd.element === subField)[0].options
                    .filter(opt => opt.value.toString() === dataToWrite[subField].toString())[0]
                    || undefined);
              } catch {
                subForm.get(subField)!.setValue(undefined);
              }

              subForm.get(subField)!.markAsTouched();
            } else {
              if(dataToWrite[subField]){
                subForm.get(subField)!.setValue(dataToWrite[subField]);

              }
            }
          }
        }
      }

      return resolve(true);
    });

  }

  public async getDataFromDialog(parentForm: FormGroup){

    const keyValuePair: KeyValuePair = {};

    for (const field in parentForm.controls) {

      const subForm = parentForm.get(field) as FormGroup;

      for (const subField in subForm.controls) {

        
        if (!subForm.get(subField)?.dirty || subForm.get(subField)?.value == null){
          continue;
        }

        if (UiElements.checkBoxes.includes(subField)){
          keyValuePair[subField] =
            ((subForm.get(subField)?.value === true || subForm.get(subField)?.value == 1) ? 1 : 0);
          
        } else if (UiElements.dropDowns.filter(dd => dd.element === subField).length > 0) {
          keyValuePair[subField] = subForm.get(subField)?.value?.value;
        } else {
          keyValuePair[subField] = subForm.get(subField)?.value;
        }

        subForm.get(subField)?.markAsPristine();
      }
    }

    return keyValuePair;
  }

  public async getAllControlsFromDialogAsArray(parentForm: FormGroup){
    const allControls = [];
    for (const field in parentForm.controls) {
      const subForm = parentForm.get(field) as FormGroup;

      for (const subField in subForm.controls) {
        allControls.push(subField);
      }
    }

    return allControls;
  }

  public getDropDownOptions(formControlName: string){
    return UiElements.dropDowns.filter(dd => dd.element === formControlName)[0].options;
  }

  public logInvalidControls(formGroup: FormGroup) {

    const invalidControls: string[] = [];
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.get(key);

      if (control instanceof FormGroup) {
        this.logInvalidControls(control);
      } else {
        if (control?.invalid) {

          invalidControls.push(key);
        }
      }
    });
  }
}
