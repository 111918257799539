<nav *ngIf="navigationService.activeSection !== 'admin-view' && navigationService.activeSection !== 'project-management' && navigationService.activeSection !== 'help-section'"
  style="position: fixed; z-index: 500; top: 124px; background-color: white;">

  <div style="margin-left: auto; margin-right: auto;">
    <button mat-button (click)="navigateToSection('general-information')"
    [ngClass]="navigationService.activeSection === 'general-information' ? 'activeSection' : 'inactiveSection'"
    style="margin-left: 3px;">
    <span [ngClass]="navigationService.activeSection === 'general-information' ? 'activeCircle' : 'inactiveCircle'">1</span>
    General Information
  </button>

  <button mat-button (click)="navigateToSection('regional-factors');" [ngClass]="navigationService.activeSection === 'regional-factors' ? 'activeSection' : 'inactiveSection'"
    style="margin-left: 3px;">
    <span [ngClass]="navigationService.activeSection === 'regional-factors' ? 'activeCircle' : 'inactiveCircle'">2</span>
    Regional Factors
  </button>

  <button mat-button (click)="navigateToSection('define-configuration');" [ngClass]="navigationService.activeSection === 'define-configuration' ? 'activeSection' : 'inactiveSection'"
    style="margin-left: 3px;">
    <span [ngClass]="navigationService.activeSection === 'define-configuration' ? 'activeCircle' : 'inactiveCircle'">3</span>
    Define Configuration
  </button>

  <button mat-button (click)="navigateToSection('hw-sw-addons');" [ngClass]="navigationService.activeSection === 'hw-sw-addons' ? 'activeSection' : 'inactiveSection'"
    style="margin-left: 3px;">
    <span [ngClass]="navigationService.activeSection === 'hw-sw-addons' ? 'activeCircle' : 'inactiveCircle'">4</span>
    HW and SW AddOns
  </button>

  <button mat-button (click)="navigateToSection('software-maintenance');" [ngClass]="navigationService.activeSection === 'software-maintenance' ? 'activeSection' : 'inactiveSection'"
    style="margin-left: 3px;">
    <span [ngClass]="navigationService.activeSection === 'software-maintenance' ? 'activeCircle' : 'inactiveCircle'">5</span>
    SW-Maintenance
  </button>

  <button mat-button (click)="navigateToSection('define-services');" [ngClass]="navigationService.activeSection === 'define-services' ? 'activeSection' : 'inactiveSection'"
    style="margin-left: 3px;">
    <span [ngClass]="navigationService.activeSection === 'define-services' ? 'activeCircle' : 'inactiveCircle'">6</span>
    Define Services
  </button>

  <button mat-button (click)="navigateToSection('iadc');" [ngClass]="navigationService.activeSection === 'iadc' ? 'activeSection' : 'inactiveSection'"
  style="margin-left: 3px;">
  <span [ngClass]="navigationService.activeSection === 'iadc' ? 'activeCircle' : 'inactiveCircle'">7</span>
  IADC
</button>

  <button mat-button (click)="navigateToSection('result-overview');" [ngClass]="navigationService.activeSection === 'result-overview' ? 'activeSection' : 'inactiveSection'"
    style="margin-left: 3px;">
    <span [ngClass]="navigationService.activeSection === 'result-overview' ? 'activeCircle' : 'inactiveCircle'">8</span>
    Results
  </button>
  </div>


</nav>
