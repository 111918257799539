import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-neo-sw-proc-maint',
  templateUrl: './neo-sw-proc-maint.component.html',
  styleUrls: ['./neo-sw-proc-maint.component.scss']
})
export class NeoSwProcMaintComponent implements OnInit {
  @Input()
  public parentForm!: FormGroup;

  constructor() { }

  ngOnInit(): void {
  }

}
