import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ProjectManagementService } from '../services/project-management.service';

@Component({
  selector: 'app-project-assignment',
  templateUrl: './project-assignment.component.html',
  styleUrls: ['./project-assignment.component.scss']
})
export class ProjectAssignmentComponent {

  projectId!: string;
  projectName!: string;
  assignmentForm!: FormGroup;

  constructor(public modalRef: BsModalRef, private formBuilder: FormBuilder, private projectManagementService: ProjectManagementService) {
    this.assignmentForm = this.formBuilder.group(
      {
        email: [null, Validators.compose([Validators.required,
          // eslint-disable-next-line max-len
          Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)])]
      }
    );
  }

  async assignProject(){

    const assignUser = this.assignmentForm.get('email')?.value;
    await this.projectManagementService.assignProject(assignUser, this.projectId);

    this.modalRef.hide();
  }

}
