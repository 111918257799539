<si-newton-section>

  <app-navigation id="first-element" [currentSectionForm]=hwAndSwAddonsForm></app-navigation>

  <form [formGroup]="hwAndSwAddonsForm" class="col-md-12" style="align-items: center;">


    <tabset>
      <tab heading="HW Addons per Upgrade">
        <app-hw-addons [parentForm]="hwAndSwAddonsForm"></app-hw-addons>

      </tab>
      <tab heading="SW Addons per Upgrade">
        <app-sw-addons [parentForm]="hwAndSwAddonsForm"></app-sw-addons>

      </tab>
    </tabset>
    <button type="button" class="btn btn-success" style="position: fixed; float:right; bottom: 50px; right: 120px;" (click)="submit()"
    [disabled]="!(hwAndSwAddonsForm.valid)">Submit</button>
    <button type="button" class="btn btn-primary" style="position: fixed; float:right; bottom: 50px; right: 20px;"
    (click)="scrollToTop()">Go to top</button>
    <button *ngIf="!(hwAndSwAddonsForm.valid)" type="button" class="btn btn-primary" style="position: fixed; float:right; bottom: 0px; right: 20px;"
    (click)="logInvalidControls(hwAndSwAddonsForm)">
      Log Invalid Values
    </button>
  </form>
</si-newton-section>
