<form [formGroup]="parentForm">
  <div formGroupName="iadcUSV">
    <!--Hilfsvariablen-->

    <input formControlName="lcsContractLastYear" hidden #lcsContractLastYear>

    <div class="row">
      <div class="col">
        <h5 class="subSectionHeader">General</h5>
      </div>
    </div>
    <div class="row">
      <div class="col">

        <p class="info">Please select if UPS is needed, without selecetion there is no calculation</p>
      </div>

    </div>

    <div class="row">

      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
        <div class="form-check">
          <input id="cb-enabled" class="form-check-input" formControlName="iadcUSVSelectedCb" type="checkbox">
          <label for="cb-enabled" class="form-check-label">
            UPS included?
          </label>
        </div>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3">
        <si-newton-form-group label="Necessary Quantity of Battery Packs per exchange" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers are allowed here'}">
          <input formControlName="iadcUSVQuantityBattery" type="number" [min]="0" siNewton>
        </si-newton-form-group>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-12 col-lg-3">
        <div class="col">
          <b class="labelAccent">Select size of Battery</b>
        </div>
      </div>

    </div>

    <div class="row">

      <div class="col-lg-6 col-md-4 col-sm-0 col-xs-0">

      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12" style="float: right">
        <div class="form-check">
          <input id="susTypeRb0" class="form-check form-check-inline" formControlName="iadcUSVSizeRb"
                type="radio"
                [(ngModel)]="iadcUSVSizeRb"
                value="SIDSI USV Large (Exchange)">
          <label for="susTypeRb0" class="form-check-label">
            SIDSI UPS Large (Exchange)
          </label>
        </div>
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <div class="form-check">
          <input id="susTypeRb1" class="form-check form-check-inline" formControlName="iadcUSVSizeRb"
                type="radio"
                [(ngModel)]="iadcUSVSizeRb"
                value="SIDSI USV Small (Exchange)">
          <label for="susTypeRb1" class="form-check-label">
            SIDSI UPS Small (Exchange)
          </label>
        </div>
      </div>


    </div>


    <div class="row">
      <div class="col">
        <h5 class="subSectionHeader">Calculative parameters</h5>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 col-sm-6 col-md-3">
        <si-newton-form-group label="Service Cost increase rate" [errorResolverMap]="{required: 'Value is required'}">
          <input formControlName="iadcUSVServiceCostIncreaseRate" type="number" [min]="0" step="any" siNewton>
          <div class="input-group-prepend">
            <span class="input-group-text">% / year</span>
          </div>
        </si-newton-form-group>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-3">
        <si-newton-form-group label="Product Cost increase rate" [errorResolverMap]="{required: 'Value is required'}">
          <input formControlName="iadcUSVProductCostIncreaseRate" type="number" [min]="0" step="any" siNewton>
          <div class="input-group-prepend">
            <span class="input-group-text">% / year</span>
          </div>
        </si-newton-form-group>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-3">
        <si-newton-form-group label="Risk addition" [errorResolverMap]="{required: 'Value is required'}">
          <input formControlName="iadcUSVRiskAddition" type="number" [min]="0" step="any" siNewton>
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
        </si-newton-form-group>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-3">
        <si-newton-form-group label="Margin" [errorResolverMap]="{required: 'Value is required'}">
          <input formControlName="iadcUSVMargin" type="number" [min]="0" step="any" siNewton>
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
        </si-newton-form-group>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-3">
        <si-newton-form-group label="Regional Discount" [errorResolverMap]="{required: 'Value is required'}">
          <input formControlName="iadcUSVRegionalDiscount" type="number" [min]="0" step="any" siNewton>
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
        </si-newton-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h5 class="subSectionHeader">HW & SW Upgrade</h5>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <b class="subSubSectionHeader">Selected Upgrade years</b>
      </div>
    </div>

    <div class="row">

      <div class="col-xs-12 col-sm-6 col-md-3">
        <si-newton-form-group label="1st Upgrade">
          <input formControlName="firstUpgradeOverall" readOnly siNewton>
        </si-newton-form-group>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-3">
        <si-newton-form-group label="2nd Upgrade">
          <input formControlName="secondUpgradeOverall" readOnly siNewton>
        </si-newton-form-group>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-3">
        <si-newton-form-group label="3rd Upgrade">
          <input formControlName="thirdUpgradeOverall" readOnly siNewton>
        </si-newton-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <b class="subSubSectionHeader">Services</b>
      </div>
    </div>

    <div *ngFor="let _ of [].constructor(2), let x = index">


      <input formControlName="iadcUSVHwSwUpgrServiceTask{{x+1}}Name" hidden #serviceTaskName>

      <div class="row">
        <div class="col">
          <b class="labelAccent">{{serviceTaskName.value}}</b>
        </div>
      </div>

      <div class="row">

        <div class="col-xs-12 col-sm-6 col-md-3">
          <si-newton-form-group label="Description" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="iadcUSVHwSwUpgrServiceTask{{x+1}}Description" readOnly siNewton>
          </si-newton-form-group>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-3">
          <si-newton-form-group label="Effort onSite per piece" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers are allowed here'}">
            <input formControlName="iadcUSVHwSwUpgrServiceTask{{x+1}}EffortOnSite" type="number" [min]="0" siNewton>
            <div class="input-group-prepend">
              <span class="input-group-text">min</span>
            </div>
          </si-newton-form-group>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-3">
          <si-newton-form-group label="Effort offSite per piece" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers are allowed here'}">
            <input formControlName="iadcUSVHwSwUpgrServiceTask{{x+1}}EffortOffSite" type="number" [min]="0" siNewton>
            <div class="input-group-prepend">
              <span class="input-group-text">min</span>
            </div>
          </si-newton-form-group>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-3">
          <si-newton-form-group label="Total Service Cost" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers are allowed here'}">
            <input formControlName="iadcUSVHwSwUpgrServiceTask{{x+1}}ServiceCost" type="number" [min]="0" highlight readOnly siNewton>
            <div class="input-group-prepend">
              <span class="input-group-text">€</span>
            </div>
          </si-newton-form-group>
        </div>
      </div>

  </div>

    <div class="row">
      <div class="col">
        <b class="subSubSectionHeader">Products</b>
      </div>
    </div>

    <div class="row">
      <div class="col">

        <p class="info">To adapt the Product Increase Parameters please change the value in 'Calculative parameters' Section</p>
      </div>

    </div>

    <div *ngFor="let _ of [].constructor(3), let x = index">
      <input formControlName="iadcUSVHwSwUpgrProduct{{x+1}}Name" hidden #productName>

      <div class="row">
        <div class="col">
          <b class="labelAccent">{{productName.value}}</b>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 col-sm-4 col-md-3">
          <si-newton-form-group label="Description / MLFB" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="iadcUSVHwSwUpgrProduct{{x+1}}DescriptionMlfb" readOnly siNewton>
          </si-newton-form-group>
        </div>

        <div class="col-xs-12 col-sm-4 col-md-3">
          <si-newton-form-group label="Quantity" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="iadcUSVHwSwUpgrProduct{{x+1}}Quantity" type="number" [min]="0" step="any" readOnly siNewton>
            <div class="input-group-prepend">
              <span class="input-group-text">€</span>
            </div>
          </si-newton-form-group>
        </div>

        <div class="col-xs-12 col-sm-4 col-md-3">
          <si-newton-form-group label="Cost Increase Rate" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="iadcUSVProductCostIncreaseRate" type="number" [min]="0" step="any" siNewton readOnly>
            <div class="input-group-prepend">
              <span class="input-group-text">%</span>
            </div>
          </si-newton-form-group>
        </div>

        <div class="col-xs-12 col-sm-4 col-md-3">
          <si-newton-form-group label="Component cost" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="iadcUSVHwSwUpgrProduct{{x+1}}Cost" type="number" [min]="0" step="any" readOnly siNewton>
            <div class="input-group-prepend">
              <span class="input-group-text">€</span>
            </div>
          </si-newton-form-group>
        </div>
      </div>


    </div>


    <div class="row">
      <div class="col">
        <h5 class="subSectionHeader">Inspection and Maintenance</h5>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6 col-md-4 col-lg-3">
        <si-newton-form-group label="Times per year" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers are allowed here'}">
          <input formControlName="iadcUSVIuWTimesPerYear" type="number" [min]="0" siNewton>
        </si-newton-form-group>
      </div>
    </div>



    <!--IuW Tasks-->
    <div *ngFor="let _ of [].constructor(3), let x = index">
      <div class="row">
        <input formControlName="iadcUSVIuWTask{{x+1}}Name" hidden #iuwTaskName>

        <div class="col">
          <b class="labelAccent">{{iuwTaskName.value}}</b>
        </div>
      </div>


      <div class="row">
        <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
          <si-newton-form-group label="Description" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="iadcUSVIuWTask{{x+1}}Description" readOnly siNewton>
          </si-newton-form-group>
        </div>

        <div class="col-xs-12 col-sm-4 col-md-4 col-lg-2">
          <si-newton-form-group label="Effort onSite" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers are allowed here'}">
            <input formControlName="iadcUSVIuWTask{{x+1}}EffortOnSite" type="number" [min]="0" siNewton>
            <div class="input-group-prepend">
              <span class="input-group-text">min</span>
            </div>
          </si-newton-form-group>
        </div>

        <div class="col-xs-12 col-sm-4 col-md-4 col-lg-2">
          <si-newton-form-group label="Material Cost" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="iadcUSVIuWTask{{x+1}}MaterialCost" type="number" [min]="0" step="any" siNewton>
            <div class="input-group-prepend">
              <span class="input-group-text">€</span>
            </div>
          </si-newton-form-group>
        </div>

        <div class="col-xs-12 col-sm-4 col-md-4 col-lg-2">
          <si-newton-form-group label="Service Cost" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="iadcUSVIuWTask{{x+1}}ServiceCost" type="number" [min]="0" readonly highlight siNewton>
            <div class="input-group-prepend">
              <span class="input-group-text">€</span>
            </div>
          </si-newton-form-group>
        </div>

        <div class="col-xs-12 col-sm-4 col-md-4 col-lg-2">
          <si-newton-form-group label="Total Cost" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="iadcUSVIuWTask{{x+1}}TotalCost" type="number" [min]="0" readonly highlight siNewton>
            <div class="input-group-prepend">
              <span class="input-group-text">€</span>
            </div>
          </si-newton-form-group>
        </div>
    </div>

    </div>
    <!--Selection of Service Years-->

    <div class="row">
      <div class="col">
        <p class="info">Please select the years, where IuW is needed</p>
      </div>
    </div>

    <div class="row" style="width: 95%; margin: auto; padding-bottom: 15px;">

      <input type="number" formControlName="yearOfStartFix" hidden #yearOfStartFix>
      <input type="number" formControlName="lcsContractDuration" hidden #lcsContractDuration>

      <div class="col">
        <div class="row year">{{stringToNumber(yearOfStartFix.value)}}</div>
        <div class="row checkBox">
          <div class="form-check">
            <input id="cb-enabled" formControlName="iadcUSVIuWSelectedYear1" type="checkbox">
          </div>
        </div>
      </div>


      <div class="col" *ngFor="let _ of [].constructor(14), let x = index">
        <div *ngIf="(stringToNumber(yearOfStartFix.value) + (x+1)) <= stringToNumber(lcsContractLastYear.value)">
          <div class="row year">{{stringToNumber(yearOfStartFix.value) + (x+1)}}</div>
          <div class="row checkBox">
            <div class="form-check">
              <input id="cb-enabled" formControlName="iadcUSVIuWSelectedYear{{x+2}}" type="checkbox">
            </div>
          </div>
        </div>
      </div>

    </div>

    <!--Cost Overview of IADC Component-->

    <div class="row">
      <div class="col">
        <h5 class="subSectionHeader">Cost Overview of UPS</h5>
      </div>
    </div>


    <div class="row" style="width: 100%; margin: auto; padding-bottom: 15px; font-weight: bold;">

      <input type="number" formControlName="yearOfStartFix" hidden #yearOfStartFix>

      <div class="col-xs-12 col-md-2">
          <div class="row">&nbsp;</div>
          <div class="row" style="background-color: #9bafbe;">USV Upgrade Services</div>
          <div class="row" style="background-color: #a5e1e1;">USV Upgrade Products</div>
          <div class="row" style="background-color: #9bafbe;">Inspection & Maintenance</div>
          <div class="row" style="background-color: #d2d741;">Total Cost</div>
          <div class="row" style="background-color: #faa50a;">Included Risk</div>
      </div>

      <ng-container *ngFor="let _ of [].constructor(5), let x = index">
        <input formControlName="iadcUSVCostOverviewHwSwUpgServicesYear{{x+1}}" hidden #upgrServiceCost>
        <input formControlName="iadcUSVCostOverviewHwSwUpgProductsYear{{x+1}}" hidden #upgrProductCost>
        <input formControlName="iadcUSVCostOverviewIuWYear{{x+1}}" hidden #iuwCost>
        <input formControlName="iadcUSVCostOverviewTotalYear{{x+1}}" hidden #totalCost>
        <input formControlName="iadcUSVCostOverviewIncludedRiskYear{{x+1}}" hidden #includedRiskCost>

        <div class="col-xs-12 col-md-2">
          <div class="row year">{{stringToNumber(yearOfStartFix.value) + x}}</div>
          <div class="row" style="background-color: #9bafbe; padding-left: 50%;">{{upgrServiceCost.value}} €</div>
          <div class="row" style="background-color: #a5e1e1; padding-left: 50%;">{{upgrProductCost.value}} €</div>
          <div class="row" style="background-color: #9bafbe; padding-left: 50%;">{{iuwCost.value}} €</div>
          <div class="row" style="background-color: #d2d741; padding-left: 50%;">{{totalCost.value}} €</div>
          <div class="row" style="background-color: #faa50a; padding-left: 50%;">{{includedRiskCost.value}} €</div>
        </div>

      </ng-container>

    </div>

    <div class="row" style="width: 100%; margin: auto; padding-bottom: 15px; font-weight: bold;">

      <input type="number" formControlName="yearOfStartFix" hidden #yearOfStartFix>

      <div class="col-xs-12 col-md-2">
          <div class="row">&nbsp;</div>
          <div class="row" style="background-color: #9bafbe;">USV Upgrade Services</div>
          <div class="row" style="background-color: #a5e1e1;">USV Upgrade Products</div>
          <div class="row" style="background-color: #9bafbe;">Inspection & Maintenance</div>
          <div class="row" style="background-color: #d2d741;">Total Cost</div>
          <div class="row" style="background-color: #faa50a;">Included Risk</div>
      </div>

      <ng-container *ngFor="let _ of [].constructor(5), let x = index">
        <input formControlName="iadcUSVCostOverviewHwSwUpgServicesYear{{x+6}}" hidden #upgrServiceCost>
        <input formControlName="iadcUSVCostOverviewHwSwUpgProductsYear{{x+6}}" hidden #upgrProductCost>
        <input formControlName="iadcUSVCostOverviewIuWYear{{x+6}}" hidden #iuwCost>
        <input formControlName="iadcUSVCostOverviewTotalYear{{x+6}}" hidden #totalCost>
        <input formControlName="iadcUSVCostOverviewIncludedRiskYear{{x+6}}" hidden #includedRiskCost>

        <div class="col-xs-12 col-md-2">
          <div class="row year">{{stringToNumber(yearOfStartFix.value) + x+5}}</div>
          <div class="row" style="background-color: #9bafbe; padding-left: 50%;">{{upgrServiceCost.value}} €</div>
          <div class="row" style="background-color: #a5e1e1; padding-left: 50%;">{{upgrProductCost.value}} €</div>
          <div class="row" style="background-color: #9bafbe; padding-left: 50%;">{{iuwCost.value}} €</div>
          <div class="row" style="background-color: #d2d741; padding-left: 50%;">{{totalCost.value}} €</div>
          <div class="row" style="background-color: #faa50a; padding-left: 50%;">{{includedRiskCost.value}} €</div>
        </div>

      </ng-container>

    </div>
    <div class="row" style="width: 100%; margin: auto; padding-bottom: 15px; font-weight: bold;">

      <input type="number" formControlName="yearOfStartFix" hidden #yearOfStartFix>

      <div class="col-xs-12 col-md-2">
          <div class="row">&nbsp;</div>
          <div class="row" style="background-color: #9bafbe;">USV Upgrade Services</div>
          <div class="row" style="background-color: #a5e1e1;">USV Upgrade Products</div>
          <div class="row" style="background-color: #9bafbe;">Inspection & Maintenance</div>
          <div class="row" style="background-color: #d2d741;">Total Cost</div>
          <div class="row" style="background-color: #faa50a;">Included Risk</div>
      </div>

      <ng-container *ngFor="let _ of [].constructor(5), let x = index">
        <input formControlName="iadcUSVCostOverviewHwSwUpgServicesYear{{x+11}}" hidden #upgrServiceCost>
        <input formControlName="iadcUSVCostOverviewHwSwUpgProductsYear{{x+11}}" hidden #upgrProductCost>
        <input formControlName="iadcUSVCostOverviewIuWYear{{x+11}}" hidden #iuwCost>
        <input formControlName="iadcUSVCostOverviewTotalYear{{x+11}}" hidden #totalCost>
        <input formControlName="iadcUSVCostOverviewIncludedRiskYear{{x+11}}" hidden #includedRiskCost>

        <div class="col-xs-12 col-md-2">
          <div class="row year">{{stringToNumber(yearOfStartFix.value) + x+10}}</div>
          <div class="row" style="background-color: #9bafbe; padding-left: 50%;">{{upgrServiceCost.value}} €</div>
          <div class="row" style="background-color: #a5e1e1; padding-left: 50%;">{{upgrProductCost.value}} €</div>
          <div class="row" style="background-color: #9bafbe; padding-left: 50%;">{{iuwCost.value}} €</div>
          <div class="row" style="background-color: #d2d741; padding-left: 50%;">{{totalCost.value}} €</div>
          <div class="row" style="background-color: #faa50a; padding-left: 50%;">{{includedRiskCost.value}} €</div>
        </div>

      </ng-container>

    </div>
  </div>
</form>
