<form [formGroup]="parentForm">
  <div formGroupName="informationService">

    <h5 class="subSectionHeader">Information Service
      <i style="float: right; cursor: pointer"
      [ngClass]="{'bi-arrow-up-circle-fill': isThisSectionVisible, 'bi-arrow-down-circle-fill': !isThisSectionVisible}"
      (click)="toggleVisibility()"></i>
    </h5>

    <div [ngStyle]="{'display': isThisSectionVisible ? 'block' : 'none'}">
      <div class="row">

        <div class="col">
          <si-newton-form-group label="Times per year" [errorResolverMap]="{required: 'Please select an option'}">
            <si-newton-dropdown
              [dropdownOptions]="getDropdownOptions('dDTimesPerYear')"
              [valueProvider]="getDropdownValue"
              formControlName="dDTimesPerYear"></si-newton-dropdown>
          </si-newton-form-group>
        </div>

        <div class="col">
          <p class="info">Mandatory service</p>
        </div>
        <div class="col"></div>
        <div class="col"></div>

      </div>

      <div class="row">

        <div class="col">
          <div class="form-check">
            <input id="rBInfoService0" class="form-check form-check-inline" formControlName="rBInfoService"
                  type="radio"
                  [(ngModel)]="rBInfoService"
                  value="Standard LIS Comprehensive">
            <label for="rBInfoService0" class="form-check-label">
              Standard LIS Comprehensive
            </label>
          </div>
        </div>
        <div class="col">

        </div>
        <div class="col">
          <div class="form-check">
            <input id="rBInfoService1" class="form-check form-check-inline" formControlName="rBInfoService"
                  type="radio"
                  [(ngModel)]="rBInfoService"
                  value="alternative value per Information Service">
            <label for="rBInfoService1" class="form-check-label">
              alternative value per Information Service
            </label>
          </div>
        </div>


      </div>

    <div class="row">
      <div class="col">
        <si-newton-form-group label="Size" [errorResolverMap]="{required: 'Please select an option'}">
          <si-newton-dropdown
            [dropdownOptions]="getDropdownOptions('dDStandardLISComp')"
            [valueProvider]="getDropdownValue"
            formControlName="dDStandardLISComp"></si-newton-dropdown>
        </si-newton-form-group>
      </div>
      <div class="col">
        <si-newton-form-group label="Order ID" [errorResolverMap]="{required: 'Value is required'}">
          <input formControlName="orderID" readOnly siNewton>
        </si-newton-form-group>
      </div>
      <div class="col">
        <si-newton-form-group label="Alternative costs" [errorResolverMap]="{required: 'Value is required'}">
          <div class="input-group-prepend">
            <span class="input-group-text">€</span>
          </div>
          <input formControlName="alternativeCost" type="number" step="any" [min]="0" siNewton>
        </si-newton-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col"></div>
      <div class="col">
        <si-newton-form-group label="Calculated Costs" [errorResolverMap]="{required: 'Value is required'}" class="highlight">
          <div class="input-group-prepend">
            <span class="input-group-text">€ / year</span>
          </div>
          <input formControlName="textFieldCurrentCostPerYear" readOnly siNewton highlight>
        </si-newton-form-group>
      </div>
      <div class="col"></div>
    </div>
    </div>


  </div>
</form>
