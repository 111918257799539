<form [formGroup]="parentForm">
  <div formGroupName="pcsSoftwareUpdateServices">
    <h5 class="subSectionHeader">PCS 7 Software Update Services
      <i style="float: right; cursor: pointer"
      [ngClass]="{'bi-arrow-up-circle-fill': isThisSectionVisible, 'bi-arrow-down-circle-fill': !isThisSectionVisible}"
      (click)="toggleVisibility()"></i>
    </h5>

    <div *ngIf="isThisSectionVisible">

      <div class="row">
        <div class="col">
          <b class="subSubSectionHeader">{{this.selectedSusType}}</b>
        </div>
      </div>
  
      <div class="row" *ngFor="let _ of [].constructor(numberOfSusPackages), let x = index">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-12">
          <b class="labelAccent">{{parentForm.get('pcsSoftwareUpdateServices')?.get('susName' + (x+1))?.value}}</b>
        </div>
  
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4" *ngIf="this.selectedSusType === 'Standard SUS'">
          <si-newton-form-group label="MLFB / Order ID">
            <input style="height: fit-content;" formControlName="mlfbStandard{{(x+1)}}" readOnly siNewton highlight>
          </si-newton-form-group>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4" *ngIf="this.selectedSusType === 'LCS SUS'">
          <si-newton-form-group label="MLFB / Order ID">
            <input style="height: fit-content;" formControlName="mlfbLCS{{(x+1)}}" readOnly siNewton highlight>
          </si-newton-form-group>
        </div>
  
  
        <div class="col-xs-12 col-sm-6 col-md-3 col-lg-2" *ngIf="this.selectedSusType === 'Standard SUS'">
          <si-newton-form-group label="L-Price">
            <input formControlName="StandardPrice{{(x+1)}}" readOnly siNewton highlight>
            <div class="input-group-prepend">
              <span class="input-group-text">€</span>
            </div>
          </si-newton-form-group>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-3 col-lg-2" *ngIf="this.selectedSusType === 'LCS SUS'">
          <si-newton-form-group label="L-Price">
            <input formControlName="LCSPrice{{(x+1)}}" readOnly siNewton highlight>
            <div class="input-group-prepend">
              <span class="input-group-text">€</span>
            </div>
          </si-newton-form-group>
        </div>
  
        <div class="col-xs-12 col-sm-6 col-md-3 col-lg-2">
          <si-newton-form-group label="No. of packages">
            <input formControlName="numberOfPackages{{(x+1)}}" readOnly siNewton>
          </si-newton-form-group>
        </div>
  
        <div class="col-xs-12 col-sm-6 col-md-3 col-lg-2">
          <si-newton-form-group label="L-Price total">
            <input formControlName="lPriceTotal{{(x+1)}}" readOnly siNewton>
            <div class="input-group-prepend">
              <span class="input-group-text">€</span>
            </div>
          </si-newton-form-group>
        </div>
  
        <div class="col-xs-12 col-sm-6 col-md-3 col-lg-2">
          <si-newton-form-group label="G/GA price total">
            <input formControlName="gGaPriceTotal{{(x+1)}}" readOnly siNewton>
            <div class="input-group-prepend">
              <span class="input-group-text">€</span>
            </div>
          </si-newton-form-group>
        </div>
      </div>
  
      <div class="row">
        <div class="col">
          <b class="subSubSectionHeader">Total Price</b>
        </div>
      </div>
  
      <div class="row">
        <div class="col-xs-12 col-sm-6 col-md-3">
          <b class="labelAccent">Current Year</b>
        </div>
  
        <div class="col-xs-12 col-sm-6 col-md-3">
          <si-newton-form-group label="Year" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="currentYear" readOnly siNewton highlight>
          </si-newton-form-group>
        </div>
  
        <div class="col-xs-12 col-sm-6 col-md-3">
          <si-newton-form-group label="L Price" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="currentYearLPrice" readOnly siNewton highlight>
            <div class="input-group-prepend">
              <span class="input-group-text">€</span>
            </div>
          </si-newton-form-group>
  
        </div>
  
        <div class="col-xs-12 col-sm-6 col-md-3">
          <si-newton-form-group label="G / GA Price" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="currentYeargGaPrice" readOnly siNewton highlight>
            <div class="input-group-prepend">
              <span class="input-group-text">€</span>
            </div>
          </si-newton-form-group>
        </div>
  
        <div class="col-xs-12 col-sm-6 col-md-3">
          <b class="labelAccent">Year of LCS Start</b>
        </div>
  
        <div class="col-xs-12 col-sm-6 col-md-3">
          <si-newton-form-group label="Year" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="yearOfLcsStart" readOnly siNewton highlight>
          </si-newton-form-group>
        </div>
  
        <div class="col-xs-12 col-sm-6 col-md-3">
          <si-newton-form-group label="L Price" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="yearOfLcsStartLPrice" readOnly siNewton highlight>
            <div class="input-group-prepend">
              <span class="input-group-text">€</span>
            </div>
          </si-newton-form-group>
        </div>
  
        <div class="col-xs-12 col-sm-6 col-md-3">
          <si-newton-form-group label="G / GA Price" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="yearOfLcsStartGaPrice" readOnly siNewton highlight>
            <div class="input-group-prepend">
              <span class="input-group-text">€</span>
            </div>
          </si-newton-form-group>
        </div>
      </div>
    </div>

  </div>
</form>
