import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductPriceServiceService {

  constructor(private httpService: HttpClient) { }

  async getCurrentPricesByMlfbList(mlfbCommaSpeerated: string) {
    const endpoint =
    `${environment.baseUrl}/product-information-hub/get-price-information?mlfbList=${mlfbCommaSpeerated}`;

    const options = {
      observe: 'body' as const,
      responseType: 'json' as const
    };

    const result = await this.httpService.get(endpoint, options).toPromise();

    return result;
  }
}
