import { Injectable } from '@angular/core';
import { SiNewtonToastService, SiToastTypes } from '@simpl/newton-ng/toast';

@Injectable({
  providedIn: 'root'
})
export class CustomToastService {


  showInfoToast(infoText: string) {
    this.toastService.showToast({
      content: infoText,
      type: SiToastTypes.INFO,
      timeout: 3000
    });
  }

  showSuccessToast(infoText: string) {
    this.toastService.showToast({
      content: infoText,
      type: SiToastTypes.SUCCESS,
      timeout: 3000
    });
  }

  showErrorToast(errorText: string) {
    
    if(!errorText){
      this.toastService.showToast({
        content: 'Undefined error',
        type: SiToastTypes.DANGER
      });
    } else {
      this.toastService.showToast({
        content: errorText.toString(),
        type: SiToastTypes.DANGER
      });
    }

  }

  constructor(private toastService: SiNewtonToastService) { }
}
