import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-regional-discounts',
  templateUrl: './regional-discounts.component.html',
  styleUrls: ['./regional-discounts.component.scss']
})
export class RegionalDiscountsComponent {

  @Input()
  public parentForm!: FormGroup;

  constructor() { }

}
