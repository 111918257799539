<form [formGroup]="parentForm">
  <div>

    <h5 class="subSectionHeader">Result Files</h5>

    <div class="row">
      <div class="col">
        <button type="button" class="btn btn-success col-md-3" (click)="generateResult()">
          Generate new Result File
        </button>
      </div>
    </div>
  </div>
</form>
