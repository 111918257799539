<form [formGroup]="parentForm" style="margin: auto;">
  <div formGroupName="swEngineering">
    <h5 class="subSectionHeader">SW Engineering
      <i style="float: right; cursor: pointer"
      [ngClass]="{'bi-arrow-up-circle-fill': isThisSectionVisible, 'bi-arrow-down-circle-fill': !isThisSectionVisible}"
      (click)="toggleVisibility()"></i>
    </h5>

    <div *ngIf="isThisSectionVisible">
      <div class="row">
        <div class="col-lg-2, col-md-3, col-sm-4">
          <si-newton-form-group label="PCS 7 Multiprojects" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers are allowed here'}">
            <input formControlName="pcs7MultiProjects" type="number" [min]="0" siNewton>
          </si-newton-form-group>
        </div>
      </div>
    </div>

  </div>
</form>
