<div class="modal-header">
  <h4 class="modal-title">Save / Discard changes</h4>
</div>
<div class="modal-body">

  <div class="col-md-12">

    <div class="row">
      <div class="col">
        <b>You have unsaved changes in this dialog. Do you want to save or discard changes?</b>
      </div>
    </div>
    <div class="row">

      <div class="col-md-3">
        <button type="button" class="btn btn-success"  style="float: left;" (click)="saveAndLeave()">
          Save Changes
        </button>
      </div>

      <div class="col-md-3">
        <button type="button" class="btn btn-danger"  style="float: left;" (click)="discardAndLeave()">
          Discard Changes
        </button>
      </div>

      <div class="col-md-3">
        <button type="button" class="btn btn-secondary" style="float: left;" (click)="modalRef.hide()">
          Go Back to dialog
        </button>
      </div>
    </div>
  </div>

</div>

<div class="modal-footer">

</div>
