<form [formGroup]="parentForm">
  <div formGroupName="iadcBackupRestore">

    <!--Hilfsvariablen-->

    <input formControlName="lcsContractLastYear" hidden #lcsContractLastYear>

    <div class="row">
      <div class="col">
        <h5 class="subSectionHeader">General</h5>
      </div>
    </div>
    <div class="row">

      <!-- <div class="col-xs-12 col-sm-6 col-md-3">
        <div class="form-check">
          <input id="cb-enabled" class="form-check-input" formControlName="iadcBackupRestoreSelectedCb" type="checkbox">
          <label for="cb-enabled" class="form-check-label">
            Backup & Restore included?
          </label>
        </div>
      </div> -->

      <div class="col-xs-12 col-sm-6 col-md-3">


        <si-newton-form-group label="Type of Backup & Restore Server" [errorResolverMap]="{required: 'Please select an option'}">
          <si-newton-dropdown
            [dropdownOptions]="getDropdownOptions('iadcBackupRestoreProductType')"
            [valueProvider]="getDropdownValue"
            formControlName="iadcBackupRestoreProductType"></si-newton-dropdown>
        </si-newton-form-group>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-3">
        <si-newton-form-group label="Quantity" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers are allowed here'}">
          <input formControlName="iadcBackupRestoreQuantity" type="number" [min]="0" readOnly siNewton>
        </si-newton-form-group>
      </div>

    </div>

    <div class="row">
      <div class="col">
        <h5 class="subSectionHeader">Calculative parameters</h5>
      </div>
    </div>



    <div class="row">


      <div class="col-xs-12 col-sm-6 col-md-3">
        <si-newton-form-group label="Service Cost increase rate" [errorResolverMap]="{required: 'Value is required'}">
          <input formControlName="iadcBackupRestoreServiceCostIncreaseRate" type="number" [min]="0" step="any" siNewton>
          <div class="input-group-prepend">
            <span class="input-group-text">% / year</span>
          </div>
        </si-newton-form-group>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-3">
        <si-newton-form-group label="Product Cost increase rate" [errorResolverMap]="{required: 'Value is required'}">
          <input formControlName="iadcBackupRestoreProductCostIncreaseRate" type="number" [min]="0" step="any" siNewton>
          <div class="input-group-prepend">
            <span class="input-group-text">% / year</span>
          </div>
        </si-newton-form-group>
      </div>


      <div class="col-xs-12 col-sm-6 col-md-3">
        <si-newton-form-group label="Risk addition" [errorResolverMap]="{required: 'Value is required'}">
          <input formControlName="iadcBackupRestoreRiskAddition" type="number" [min]="0" step="any" siNewton>
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
        </si-newton-form-group>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-3">
        <si-newton-form-group label="Margin" [errorResolverMap]="{required: 'Value is required'}">
          <input formControlName="iadcBackupRestoreMargin" type="number" [min]="0" step="any" siNewton>
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
        </si-newton-form-group>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-3">
        <si-newton-form-group label="Regional Discount" [errorResolverMap]="{required: 'Value is required'}">
          <input formControlName="iadcBackupRestoreRegionalDiscount" type="number" [min]="0" step="any" siNewton>
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
        </si-newton-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h5 class="subSectionHeader">B & R Upgrade</h5>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <b class="subSubSectionHeader">Selected Upgrade years</b>
      </div>
    </div>

    <div class="row">

      <div class="col-xs-12 col-sm-6 col-md-3">
        <si-newton-form-group label="1st Upgrade">
          <input formControlName="firstUpgradeOverall" readOnly siNewton>
        </si-newton-form-group>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-3">
        <si-newton-form-group label="2nd Upgrade">
          <input formControlName="secondUpgradeOverall" readOnly siNewton>
        </si-newton-form-group>
      </div>

      <div class="col-xs-12 col-sm-6 col-md-3">
        <si-newton-form-group label="3rd Upgrade">
          <input formControlName="thirdUpgradeOverall" readOnly siNewton>
        </si-newton-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <b class="subSubSectionHeader">Services</b>
      </div>
    </div>

    <div *ngFor="let _ of [].constructor(2), let x = index">


      <input formControlName="iadcBackupRestoreHwSwUpgrServiceTask{{x+1}}Name" hidden #serviceTaskName>

      <div class="row">
        <div class="col">
          <b class="labelAccent">{{serviceTaskName.value}}</b>
        </div>
      </div>

      <div class="row">

        <div class="col-xs-12 col-sm-6 col-md-3">
          <si-newton-form-group label="Description" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="iadcBackupRestoreHwSwUpgrServiceTask{{x+1}}Description" readOnly siNewton>
          </si-newton-form-group>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-3">
          <si-newton-form-group label="Effort onSite per piece" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers are allowed here'}">
            <input formControlName="iadcBackupRestoreHwSwUpgrServiceTask{{x+1}}EffortOnSite" type="number" [min]="0" siNewton>
            <div class="input-group-prepend">
              <span class="input-group-text">min</span>
            </div>
          </si-newton-form-group>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-3">
          <si-newton-form-group label="Effort offSite per piece" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers are allowed here'}">
            <input formControlName="iadcBackupRestoreHwSwUpgrServiceTask{{x+1}}EffortOffSite" type="number" [min]="0" siNewton>
            <div class="input-group-prepend">
              <span class="input-group-text">min</span>
            </div>
          </si-newton-form-group>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-3">
          <si-newton-form-group label="Total Service Cost" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers are allowed here'}">
            <input formControlName="iadcBackupRestoreHwSwUpgrServiceTask{{x+1}}ServiceCost" type="number" [min]="0" highlight readOnly siNewton>
            <div class="input-group-prepend">
              <span class="input-group-text">€</span>
            </div>
          </si-newton-form-group>
        </div>
      </div>

  </div>

    <div class="row">
      <div class="col">
        <b class="subSubSectionHeader">Products</b>
      </div>
    </div>

    <div class="row">
      <div class="col">

        <p class="info">To adapt the Product Increase Parameters please change the value in 'Calculative parameters' Section</p>
      </div>

    </div>

    <div *ngFor="let _ of [].constructor(1), let x = index">
      <input formControlName="iadcBackupRestoreHwSwUpgrProduct{{x+1}}Name" hidden #productName>

      <div class="row">
        <div class="col">
          <b class="labelAccent">{{productName.value}}</b>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 col-sm-4 col-md-3">
          <si-newton-form-group label="MLFB" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="iadcBackupRestoreHwSwUpgrProduct{{x+1}}Mlfb" readOnly siNewton>
          </si-newton-form-group>
        </div>

        <div class="col-xs-12 col-sm-4 col-md-3">
          <si-newton-form-group label="Cost Increase Rate" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="iadcBackupRestoreProductCostIncreaseRate" type="number" [min]="0" step="any" readOnly siNewton>
            <div class="input-group-prepend">
              <span class="input-group-text">%</span>
            </div>
          </si-newton-form-group>
        </div>

        <div class="col-xs-12 col-sm-4 col-md-3">
          <si-newton-form-group label="Component cost" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="iadcBackupRestoreHwSwUpgrProduct{{x+1}}Cost" type="number" [min]="0" step="any" readOnly highlight siNewton>
            <div class="input-group-prepend">
              <span class="input-group-text">€</span>
            </div>
          </si-newton-form-group>
        </div>
      </div>


    </div>
    <!--Service Contracts-->

    <div class="row">
      <div class="col">
        <h5 class="subSectionHeader">Service Contracts</h5>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6 col-md-4 col-lg-3">
        <si-newton-form-group label="Service Cost increase rate" [errorResolverMap]="{required: 'Value is required'}">
          <input formControlName="iadcBackupRestoreServiceContractCostIncreaseRate" type="number" [min]="0" siNewton>
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
        </si-newton-form-group>
      </div>
    </div>


    <!--Service Contract 1-->
    <div class="row">

      <input formControlName="iadcBackupRestoreServiceContract1Name" hidden #iadcBackupRestoreServiceContract1Name>
      <input formControlName="iadcBackupRestoreServiceContract1MLFB" hidden #iadcBackupRestoreServiceContract1MLFB>

      <div class="col">
        <b class="labelAccent"><u>{{iadcBackupRestoreServiceContract1Name.value}} ({{iadcBackupRestoreServiceContract1MLFB.value}})</u></b>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 col-sm-6 col-md-3">
        <si-newton-form-group label="Initial Year" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers are allowed here'}">
          <input formControlName="iadcBackupRestoreServiceContract1InitialYear" type="number" [min]="0" #iadcBackupRestoreServiceContract1InitialYear readOnly siNewton>
        </si-newton-form-group>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-3">

        <si-newton-form-group label="Service Contract Duration" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers are allowed here'}">
          <input formControlName="iadcBackupRestoreServiceContract1Duration" type="number" [min]="0" #iadcBackupRestoreServiceContract1Duration siNewton>
          <div class="input-group-prepend">
            <span class="input-group-text">years</span>
          </div>
        </si-newton-form-group>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-3">
        <si-newton-form-group label="Service Contract Costs" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers are allowed here'}">
          <input formControlName="iadcBackupRestoreServiceContract1Cost" type="number" [min]="0" readOnly highlight siNewton>
          <div class="input-group-prepend">
            <span class="input-group-text">€</span>
          </div>
        </si-newton-form-group>
      </div>
      
    </div>

    <div [hidden]="+(parentForm.get(formName)?.get('iadcBackupRestoreServiceContract1Selected')?.value) !== 1">
      <div class="row" >
        <div class="col">
          <b class="labelAccent">Calculated Service Years</b>
        </div>
      </div>

      <div class="row">
        <p class="info">You can deselect the calculated years if service contract is not needed in the specific year</p>
      </div>

      <div class="row" style="width: 50%; margin: auto; padding-bottom: 15px;">

          <input type="number" formControlName="yearOfStartFix" hidden #yearOfStartFix>
          <input type="number" formControlName="lcsContractDuration" hidden #lcsContractDuration>

          <div class="col"
            *ngIf="(stringToNumber(yearOfStartFix.value)-stringToNumber(iadcBackupRestoreServiceContract1InitialYear.value))
              %stringToNumber(iadcBackupRestoreServiceContract1Duration.value)===0 && stringToNumber(yearOfStartFix.value) >=
              stringToNumber(iadcBackupRestoreServiceContract1InitialYear.value)">
            <div class="row year">{{stringToNumber(yearOfStartFix.value)}}</div>
            <div class="row checkBox">
              <div class="form-check">
                <input id="cb-enabled" formControlName="iadcBackupRestoreServiceContract1SelectedYear1" type="checkbox">
              </div>
            </div>
          </div>


          <ng-container *ngFor="let _ of [].constructor(14), let x = index">
            <div class="col" *ngIf="(stringToNumber(yearOfStartFix.value)+(x+1)-stringToNumber(iadcBackupRestoreServiceContract1InitialYear.value))
            %stringToNumber(iadcBackupRestoreServiceContract1Duration.value)===0 && stringToNumber(yearOfStartFix.value) + (x+1) >= stringToNumber(iadcBackupRestoreServiceContract1InitialYear.value)
            && (stringToNumber(yearOfStartFix.value) + (x+1)) <= stringToNumber(lcsContractLastYear.value)">
              <div class="row year">{{stringToNumber(yearOfStartFix.value) + (x+1)}}</div>
              <div class="row checkBox">
                <div class="form-check">
                  <input id="cb-enabled" formControlName="iadcBackupRestoreServiceContract1SelectedYear{{x+2}}" type="checkbox">
                </div>
              </div>
            </div>

          </ng-container>
      </div>
    </div>


    <!--Cost Overview of IADC Component-->

    <div class="row">
      <div class="col">
        <h5 class="subSectionHeader">Cost Overview of Backup & Restore</h5>
      </div>
    </div>


    <div class="row" style="width: 100%; margin: auto; padding-bottom: 15px; font-weight: bold;">

      <input type="number" formControlName="yearOfStartFix" hidden #yearOfStartFix>

      <div class="col-xs-12 col-md-2">
          <div class="row">&nbsp;</div>
          <div class="row" style="background-color: #9bafbe;">B & R Upgrade Services</div>
          <div class="row" style="background-color: #a5e1e1;">B & R Upgrade Products</div>
          <div class="row" style="background-color: #9bafbe;">Service Contracts</div>
          <div class="row" style="background-color: #d2d741;">Total Cost</div>
          <div class="row" style="background-color: #faa50a;">Included Risk</div>
      </div>

      <ng-container *ngFor="let _ of [].constructor(5), let x = index">
        <input formControlName="iadcBackupRestoreCostOverviewHwSwUpgServicesYear{{x+1}}" hidden #upgrServiceCost>
        <input formControlName="iadcBackupRestoreCostOverviewHwSwUpgProductsYear{{x+1}}" hidden #upgrProductCost>
        <input formControlName="iadcBackupRestoreCostOverviewServiceContractsYear{{x+1}}" hidden #serviceContractCost>
        <input formControlName="iadcBackupRestoreCostOverviewTotalYear{{x+1}}" hidden #totalCost>
        <input formControlName="iadcBackupRestoreCostOverviewIncludedRiskYear{{x+1}}" hidden #includedRiskCost>

        <div class="col-xs-12 col-md-2">
          <div class="row year">{{stringToNumber(yearOfStartFix.value) + x}}</div>
          <div class="row" style="background-color: #9bafbe; padding-left: 50%;">{{upgrServiceCost.value}} €</div>
          <div class="row" style="background-color: #a5e1e1; padding-left: 50%;">{{upgrProductCost.value}} €</div>
          <div class="row" style="background-color: #9bafbe; padding-left: 50%;">{{serviceContractCost.value}} €</div>
          <div class="row" style="background-color: #d2d741; padding-left: 50%;">{{totalCost.value}} €</div>
          <div class="row" style="background-color: #faa50a; padding-left: 50%;">{{includedRiskCost.value}} €</div>
        </div>

      </ng-container>

    </div>

    <div class="row" style="width: 100%; margin: auto; padding-bottom: 15px; font-weight: bold;">

      <input type="number" formControlName="yearOfStartFix" hidden #yearOfStartFix>

      <div class="col-xs-12 col-md-2">
          <div class="row">&nbsp;</div>
          <div class="row" style="background-color: #9bafbe;">B & R Upgrade Services</div>
          <div class="row" style="background-color: #a5e1e1;">B & R Upgrade Products</div>
          <div class="row" style="background-color: #9bafbe;">Service Contracts</div>
          <div class="row" style="background-color: #d2d741;">Total Cost</div>
          <div class="row" style="background-color: #faa50a;">Included Risk</div>
      </div>

      <ng-container *ngFor="let _ of [].constructor(5), let x = index">
        <input formControlName="iadcBackupRestoreCostOverviewHwSwUpgServicesYear{{x+6}}" hidden #upgrServiceCost>
        <input formControlName="iadcBackupRestoreCostOverviewHwSwUpgProductsYear{{x+6}}" hidden #upgrProductCost>
        <input formControlName="iadcBackupRestoreCostOverviewServiceContractsYear{{x+6}}" hidden #serviceContractCost>
        <input formControlName="iadcBackupRestoreCostOverviewTotalYear{{x+6}}" hidden #totalCost>
        <input formControlName="iadcBackupRestoreCostOverviewIncludedRiskYear{{x+6}}" hidden #includedRiskCost>

        <div class="col-xs-12 col-md-2">
          <div class="row year">{{stringToNumber(yearOfStartFix.value) + x+5}}</div>
          <div class="row" style="background-color: #9bafbe; padding-left: 50%;">{{upgrServiceCost.value}} €</div>
          <div class="row" style="background-color: #a5e1e1; padding-left: 50%;">{{upgrProductCost.value}} €</div>
          <div class="row" style="background-color: #9bafbe; padding-left: 50%;">{{serviceContractCost.value}} €</div>
          <div class="row" style="background-color: #d2d741; padding-left: 50%;">{{totalCost.value}} €</div>
          <div class="row" style="background-color: #faa50a; padding-left: 50%;">{{includedRiskCost.value}} €</div>
        </div>

      </ng-container>

    </div>
    <div class="row" style="width: 100%; margin: auto; padding-bottom: 15px; font-weight: bold;">

      <input type="number" formControlName="yearOfStartFix" hidden #yearOfStartFix>

      <div class="col-xs-12 col-md-2">
          <div class="row">&nbsp;</div>
          <div class="row" style="background-color: #9bafbe;">B & R Upgrade Services</div>
          <div class="row" style="background-color: #a5e1e1;">B & R Upgrade Products</div>
          <div class="row" style="background-color: #9bafbe;">Service Contracts</div>
          <div class="row" style="background-color: #d2d741;">Total Cost</div>
          <div class="row" style="background-color: #faa50a;">Included Risk</div>
      </div>

      <ng-container *ngFor="let _ of [].constructor(5), let x = index">
        <input formControlName="iadcBackupRestoreCostOverviewHwSwUpgServicesYear{{x+11}}" hidden #upgrServiceCost>
        <input formControlName="iadcBackupRestoreCostOverviewHwSwUpgProductsYear{{x+11}}" hidden #upgrProductCost>
        <input formControlName="iadcBackupRestoreCostOverviewServiceContractsYear{{x+11}}" hidden #serviceContractCost>
        <input formControlName="iadcBackupRestoreCostOverviewTotalYear{{x+11}}" hidden #totalCost>
        <input formControlName="iadcBackupRestoreCostOverviewIncludedRiskYear{{x+11}}" hidden #includedRiskCost>

        <div class="col-xs-12 col-md-2">
          <div class="row year">{{stringToNumber(yearOfStartFix.value) + x+10}}</div>
          <div class="row" style="background-color: #9bafbe; padding-left: 50%;">{{upgrServiceCost.value}} €</div>
          <div class="row" style="background-color: #a5e1e1; padding-left: 50%;">{{upgrProductCost.value}} €</div>
          <div class="row" style="background-color: #9bafbe; padding-left: 50%;">{{serviceContractCost.value}} €</div>
          <div class="row" style="background-color: #d2d741; padding-left: 50%;">{{totalCost.value}} €</div>
          <div class="row" style="background-color: #faa50a; padding-left: 50%;">{{includedRiskCost.value}} €</div>
        </div>

      </ng-container>

    </div>
  </div>
</form>
