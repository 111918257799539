import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SiNewtonLoadingService } from '@simpl/newton-ng/loading-spinner';
import { NavigationService } from '../services/navigation.service';
import { CustomToastService } from './custom-toast.service';
import { TimeService } from './time.service';
import { saveAs } from 'file-saver';
import { PhysicalSystemComponentModel } from '../data-models/PhysicalSystemComponent';

interface PhysicalSystemVariables { 
  [key: string]: string | number 
}


@Injectable({
  providedIn: 'root'
})
export class DataTransferService {

  constructor(private loadingSpinner: SiNewtonLoadingService,
    private navigationService: NavigationService, private httpService: HttpClient,
    private toastService: CustomToastService, private timeService: TimeService) { }


  public getValuesFromExcel(keys: string[]) {

    return new Promise(async (resolve, reject) => {
      if (sessionStorage.getItem('SelectedProjectId') === '') {

        //this.dialogService.showErrorAlert('No project selected');

        this.toastService.showErrorToast('No project selected. Please open a project first');
        this.navigationService.naviClick('projects');

        return resolve;
      } else {
        this.loadingSpinner.startLoading();

        const body = keys;
        const options = {
          observe: 'response' as const
        };

        this.httpService.post(environment.baseUrl + environment.apiUrl +
        `/calculation/read/lcs/${sessionStorage.getItem('SelectedProjectId')}`,
        body, options)
          .subscribe(async response => {
        
            this.loadingSpinner.stopLoading();

            return resolve(response.body);
          }, (errorResponse: HttpErrorResponse) => {

            console.log('ErrorResponse');
            console.log(errorResponse);
            this.loadingSpinner.stopLoading();

            this.toastService.showErrorToast(errorResponse.error.message);

            sessionStorage.setItem('SelectedProjectId', '');
            sessionStorage.setItem('SelectedProjectName', '');

            this.navigationService.naviClick('projects');
            return reject();
          });
      }
    });
  };

  public async writeDataToExcel(dataToWrite: any): Promise<boolean> {

    try {
      const projectId = sessionStorage.getItem('SelectedProjectId')?.toString() || '';

      if (typeof dataToWrite === 'string'){
        dataToWrite = JSON.parse(dataToWrite);
      }

      if (Object.keys(dataToWrite).length === 0) {
        this.toastService.showSuccessToast('Submit of Data successfully');
        return true;
      }

      this.loadingSpinner.startLoading();

      const body = dataToWrite;
      const options = {
        observe: 'response' as const
      };

      await this.httpService.post(environment.baseUrl + environment.apiUrl +
        `/calculation/write/lcs/${projectId}`, body, options).toPromise();

      this.updateLastEditTime(projectId);
      this.toastService.showSuccessToast('Submitted Data successfully');
      return true;
    } catch (err: any){
      console.log('Fehler beim Write');
      console.log(err);
      this.toastService.showErrorToast(err.error.message || err.message || 'Error while try to update values');
      return false;
    } finally {
      this.loadingSpinner.stopLoading();
    }

  }

  public updateMetaData(customerName: string, projectName: string) {

    return new Promise(async (resolve, reject) => {

      const projectId = sessionStorage.getItem('SelectedProjectId');

      const body = {
        projectId,
        customerName,
        projectName
      };
      this.httpService.post(environment.baseUrl + `/updateMetaData`, body, {}).subscribe();


    });
  }

  public getMetaData() {
    return new Promise(async (resolve, reject) => {

      this.loadingSpinner.startLoading();

      const projectId = sessionStorage.getItem('SelectedProjectId');

      const endpoint =
      `${environment.baseUrl}/getMetaData?projectId=${projectId}`;

      const options = {
        observe: 'body' as const,
        responseType: 'json' as const
      };

      this.httpService.get(endpoint, options).subscribe(async (result: any) => {

        console.log(result);
        this.loadingSpinner.stopLoading();
        return resolve(result);

      }, (errorResponse: HttpErrorResponse) => {
        this.loadingSpinner.stopLoading();

        console.log(errorResponse);
      });


    });
  }

  async importSystem(file: File, pcsVersion: string){

    const endpoint = environment.baseUrl + '/calculation-operations/import-system';
    const formData: FormData = new FormData();
    formData.append('bom', file);
    formData.append('pcsVersion', pcsVersion);

    const options = {
      observe: 'response' as const
    };

    this.loadingSpinner.startLoading();

    const response = await this.httpService.post(endpoint, formData, options).toPromise().catch((errorResponse: HttpErrorResponse) => {
      this.loadingSpinner.stopLoading();
      this.toastService.showErrorToast('Failed to import BOM File: ' + errorResponse.error.message);

    });

    this.toastService.showSuccessToast((response as any).body.message);
    this.loadingSpinner.stopLoading();

    return (response as any).body.values;

  }

  async downloadFile(fileType: string, fileName?: string){

    let endpoint = '';
    if(fileType == 'LCS_Master'){
      endpoint = environment.baseUrl + environment.apiUrl + '/masterfile/download/lcs';
    } else {
      endpoint = environment.baseUrl + '/file-transfer/download/' + fileType;
    }

    const options = {
      headers: new HttpHeaders({
        accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }),
      responseType: 'blob' as const,
      observe: 'body' as const
    };

    this.httpService.get(endpoint, options).subscribe(blob => {
      saveAs(blob, fileName ? fileName : fileType + '.xlsx');

    }, (errorResponse: HttpErrorResponse) => {

      console.log(errorResponse);
      this.toastService.showErrorToast('Could not download the requested file');

    });

  }

  async generateBomFile(projectName: string, pcsType: String, data: PhysicalSystemComponentModel[]){
    const endpoint = environment.baseUrl + '/calculation-operations/generate-bom-file/';

    const body = {
      physicalSystem: data
    };

    const options = {
      headers: new HttpHeaders({
        accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }),
      responseType: 'blob' as const,
      observe: 'body' as const
    };

    this.httpService.post(endpoint, body, options).subscribe(blob => {
      saveAs(blob, projectName + '-' + pcsType + '-BOM.xlsx');

    }, (errorResponse: HttpErrorResponse) => {

      this.toastService.showErrorToast(errorResponse.error.message);

    });

  }

  async detectIPCs(pcsVersion: string, data: PhysicalSystemComponentModel[], plantStructureFile: File): Promise<any> {
    const endpoint = environment.baseUrl + '/calculation-operations/assign-ipc-types/';

    const formData: FormData = new FormData();
    formData.append('plantStructureFile', plantStructureFile);
    formData.append('pcsVersion', pcsVersion);
    formData.append('physicalSystemData', JSON.stringify(data));


    const options = {
      observe: 'response' as const,
      body: 'json' as const
    };

    let result: any = { body: { values: null } };

    try {
      result = await this.httpService.post<PhysicalSystemVariables>(endpoint, formData, options).toPromise();
    } catch (errorResponse){
      this.toastService.showErrorToast('Something went wrong');
    }

    if (result && result.body && result.body.values) {
      return result.body.values;
    } else {
      return { error: true, message: 'No values found in the response', values: null };
    }


  }

  updateLastEditTime(projectId: string){
    this.httpService.post(environment.baseUrl + '/updateLastEdit', {
      projectId, time: this.timeService.getCurrentTime()
    },
    {}).subscribe();
  }

}
