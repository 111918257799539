/* eslint-disable max-len */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SiNewtonLoadingService } from '@simpl/newton-ng/loading-spinner';
import { ManagedServiceIndividual } from 'src/app/data-models/ManagedServiceIndividual';
import { DataTransferService } from 'src/app/services/data-transfer.service';
import { ProductPriceServiceService } from 'src/app/services/product-price-service.service';

interface KeyValuePair {
  [key: string]: string | number;
}


@Component({
  selector: 'app-managed-system-services',
  templateUrl: './managed-system-services.component.html',
  styleUrls: ['./managed-system-services.component.scss']
})
export class ManagedSystemServicesComponent implements OnInit {

  isThisSectionVisible = false;
  lcsContractDuration = 0;

  allIndividualMss: ManagedServiceIndividual[] = [];

  @Output()
  readData: EventEmitter<any> = new EventEmitter();

  
  @Output()
  submitValues: EventEmitter<any> = new EventEmitter();


  getLcsContractDuration(){
    return this.parentForm.get('managedSystemServices')?.get('lcsContractDuration')?.value.value;
  }

  toggleVisibility(){
    console.log(this.lcsContractDuration);
    this.isThisSectionVisible = !this.isThisSectionVisible;
  }

  stringToNumber(num: string){
    return parseInt(num, 10);
  }

  @Input()
  parentForm!: FormGroup;

  constructor(private loadingSpinner: SiNewtonLoadingService, private dataTransferService: DataTransferService,
    private productPriceService: ProductPriceServiceService) {}

  ngOnInit(){
    this.lcsContractDuration = this.parentForm.get('managedSystemServices')?.get('lcsContractDuration')?.value.value;
  }


  isNewOfferingValid(){

    try {
      if (!this.parentForm.get('managedSystemServices')?.get('newMssName')?.value){
        return false;
      } else if (!this.parentForm.get('managedSystemServices')?.get('newMssDescription')?.value){
        return false;
      } else if (!this.parentForm.get('managedSystemServices')?.get('newMssCost')?.value){
        return false;
      } else if (!this.parentForm.get('managedSystemServices')?.get('newMssCostIncreaseRate')?.value){
        return false;
      } else if (this.allIndividualMss.length >= 2){
        return false;
      } else {
        return true;
      }
    } catch {
      return false;
    }
  }

  isIndividualServicePresent(compNumber: number): boolean {

    try {
      if (this.parentForm.get('managedSystemServices')?.get('managedSystemIndService' + compNumber + 'Name')?.value){
        return true;
      } else {
        return false;
      }
    } catch {
      return false;
    }
  }

  async deleteService(serviceNumber: number){

    const updatedListOfIndMssToWrite: KeyValuePair = {};


    for (let i = serviceNumber; i <= this.allIndividualMss.length; i++){

      if (i === this.allIndividualMss.length){
        updatedListOfIndMssToWrite['managedSystemIndService' + i + 'Name'] = '';
        updatedListOfIndMssToWrite['managedSystemIndService' + i + 'Description'] = '';
        updatedListOfIndMssToWrite['managedSystemIndService' + i + 'CostInEuro'] = 0;
        updatedListOfIndMssToWrite['managedSystemIndService' + i + 'CostIncReate'] = 0;
        updatedListOfIndMssToWrite['managedSystemIndService' + i + 'RiskAddition'] = 0;

        for (let j = 1; j <= 25; j++){
          updatedListOfIndMssToWrite['managedSystemIndService' + i + 'Year' + j + 'Selected'] = 0;
        }

      } else {

        updatedListOfIndMssToWrite['managedSystemIndService' + i + 'Name'] =
          this.parentForm.get('managedSystemServices')?.get('managedSystemIndService' + (i + 1) + 'Name')?.value;
        updatedListOfIndMssToWrite['managedSystemIndService' + i + 'Description'] =
          this.parentForm.get('managedSystemServices')?.get('managedSystemIndService' + (i + 1) + 'Description')?.value;
        updatedListOfIndMssToWrite['managedSystemIndService' + i + 'CostInEuro'] =
          this.parentForm.get('managedSystemServices')?.get('managedSystemIndService' + (i + 1) + 'CostInEuro')?.value || 0;
        updatedListOfIndMssToWrite['managedSystemIndService' + i + 'CostIncReate'] =
          this.parentForm.get('managedSystemServices')?.get('managedSystemIndService' + (i + 1) + 'CostIncReate')?.value || 0;
        updatedListOfIndMssToWrite['managedSystemIndService' + i + 'RiskAddition'] =
          this.parentForm.get('managedSystemServices')?.get('managedSystemIndService' + (i + 1) + 'RiskAddition')?.value || 0;

        for (let j = 1; j <= 25; j++){
          updatedListOfIndMssToWrite['managedSystemIndService' + i + 'Year' + j + 'Selected'] =
            this.parentForm.get('managedSystemServices')?.get('managedSystemIndService' + (i + 1) + 'Year' + j + 'Selected')?.value || 0;
        }
      }

    }

    await this.dataTransferService.writeDataToExcel(JSON.stringify(updatedListOfIndMssToWrite));

    this.readData.emit();
  }

  async fillTableWithServiceModules(){
    this.allIndividualMss = [];

    for (let i = 1; i <= 4; i++){

      if (this.isIndividualServicePresent(i)){

        this.allIndividualMss.push({
          'serviceNumber': i,
          'name': this.parentForm.get('managedSystemServices')?.get('managedSystemIndService' + i + 'Name')?.value,
          'description': this.parentForm.get('managedSystemServices')?.get('managedSystemIndService' + i + 'Description')?.value,
          'cost': this.parentForm.get('managedSystemServices')?.get('managedSystemIndService' + i + 'CostInEuro')?.value,
          'costIncreaseRate': this.parentForm.get('managedSystemServices')?.get('managedSystemIndService' + i + 'CostIncReate')?.value,
          'neededInYear1': this.parentForm.get('managedSystemServices')?.get('managedSystemIndService' + i + 'Year1Selected')?.value,
          'neededInYear2': this.parentForm.get('managedSystemServices')?.get('managedSystemIndService' + i + 'Year2Selected')?.value,
          'neededInYear3': this.parentForm.get('managedSystemServices')?.get('managedSystemIndService' + i + 'Year3Selected')?.value,
          'neededInYear4': this.parentForm.get('managedSystemServices')?.get('managedSystemIndService' + i + 'Year4Selected')?.value,
          'neededInYear5': this.parentForm.get('managedSystemServices')?.get('managedSystemIndService' + i + 'Year5Selected')?.value,
          'neededInYear6': this.parentForm.get('managedSystemServices')?.get('managedSystemIndService' + i + 'Year6Selected')?.value,
          'neededInYear7': this.parentForm.get('managedSystemServices')?.get('managedSystemIndService' + i + 'Year7Selected')?.value,
          'neededInYear8': this.parentForm.get('managedSystemServices')?.get('managedSystemIndService' + i + 'Year8Selected')?.value,
          'neededInYear9': this.parentForm.get('managedSystemServices')?.get('managedSystemIndService' + i + 'Year9Selected')?.value,
          'neededInYear10': this.parentForm.get('managedSystemServices')?.get('managedSystemIndService' + i + 'Year10Selected')?.value,
          'neededInYear11': this.parentForm.get('managedSystemServices')?.get('managedSystemIndService' + i + 'Year11Selected')?.value,
          'neededInYear12': this.parentForm.get('managedSystemServices')?.get('managedSystemIndService' + i + 'Year12Selected')?.value,
          'neededInYear13': this.parentForm.get('managedSystemServices')?.get('managedSystemIndService' + i + 'Year13Selected')?.value,
          'neededInYear14': this.parentForm.get('managedSystemServices')?.get('managedSystemIndService' + i + 'Year14Selected')?.value,
          'neededInYear15': this.parentForm.get('managedSystemServices')?.get('managedSystemIndService' + i + 'Year15Selected')?.value,
          'neededInYear16': this.parentForm.get('managedSystemServices')?.get('managedSystemIndService' + i + 'Year16Selected')?.value,
          'neededInYear17': this.parentForm.get('managedSystemServices')?.get('managedSystemIndService' + i + 'Year17Selected')?.value,
          'neededInYear18': this.parentForm.get('managedSystemServices')?.get('managedSystemIndService' + i + 'Year18Selected')?.value,
          'neededInYear19': this.parentForm.get('managedSystemServices')?.get('managedSystemIndService' + i + 'Year19Selected')?.value,
          'neededInYear20': this.parentForm.get('managedSystemServices')?.get('managedSystemIndService' + i + 'Year20Selected')?.value,
          'neededInYear21': this.parentForm.get('managedSystemServices')?.get('managedSystemIndService' + i + 'Year21Selected')?.value,
          'neededInYear22': this.parentForm.get('managedSystemServices')?.get('managedSystemIndService' + i + 'Year22Selected')?.value,
          'neededInYear23': this.parentForm.get('managedSystemServices')?.get('managedSystemIndService' + i + 'Year23Selected')?.value,
          'neededInYear24': this.parentForm.get('managedSystemServices')?.get('managedSystemIndService' + i + 'Year24Selected')?.value,
          'neededInYear25': this.parentForm.get('managedSystemServices')?.get('managedSystemIndService' + i + 'Year25Selected')?.value
        });
      }
    }

  }

  async updateRemoteServiceList(){

    this.loadingSpinner.startLoading();
    const updatedIndividualOfferings: KeyValuePair = {};

    for (let i = 1; i <= this.allIndividualMss.length; i++){

      updatedIndividualOfferings['managedSystemIndService' + i + 'Name'] = this.allIndividualMss[i - 1].name;
      updatedIndividualOfferings['managedSystemIndService' + i + 'Description'] = this.allIndividualMss[i - 1].description;
      updatedIndividualOfferings['managedSystemIndService' + i + 'CostInEuro'] = this.allIndividualMss[i - 1].cost;
      updatedIndividualOfferings['managedSystemIndService' + i + 'CostIncReate'] = this.allIndividualMss[i - 1].costIncreaseRate;
      updatedIndividualOfferings['managedSystemIndService' + i + 'Year1Selected'] = this.allIndividualMss[i - 1].neededInYear1;
      updatedIndividualOfferings['managedSystemIndService' + i + 'Year2Selected'] = this.allIndividualMss[i - 1].neededInYear2;
      updatedIndividualOfferings['managedSystemIndService' + i + 'Year3Selected'] = this.allIndividualMss[i - 1].neededInYear3;
      updatedIndividualOfferings['managedSystemIndService' + i + 'Year4Selected'] = this.allIndividualMss[i - 1].neededInYear4;
      updatedIndividualOfferings['managedSystemIndService' + i + 'Year5Selected'] = this.allIndividualMss[i - 1].neededInYear5;
      updatedIndividualOfferings['managedSystemIndService' + i + 'Year6Selected'] = this.allIndividualMss[i - 1].neededInYear6;
      updatedIndividualOfferings['managedSystemIndService' + i + 'Year7Selected'] = this.allIndividualMss[i - 1].neededInYear7;
      updatedIndividualOfferings['managedSystemIndService' + i + 'Year8Selected'] = this.allIndividualMss[i - 1].neededInYear8;
      updatedIndividualOfferings['managedSystemIndService' + i + 'Year9Selected'] = this.allIndividualMss[i - 1].neededInYear9;
      updatedIndividualOfferings['managedSystemIndService' + i + 'Year10Selected'] = this.allIndividualMss[i - 1].neededInYear10;
      updatedIndividualOfferings['managedSystemIndService' + i + 'Year11Selected'] = this.allIndividualMss[i - 1].neededInYear11;
      updatedIndividualOfferings['managedSystemIndService' + i + 'Year12Selected'] = this.allIndividualMss[i - 1].neededInYear12;
      updatedIndividualOfferings['managedSystemIndService' + i + 'Year13Selected'] = this.allIndividualMss[i - 1].neededInYear13;
      updatedIndividualOfferings['managedSystemIndService' + i + 'Year14Selected'] = this.allIndividualMss[i - 1].neededInYear14;
      updatedIndividualOfferings['managedSystemIndService' + i + 'Year15Selected'] = this.allIndividualMss[i - 1].neededInYear15;
      updatedIndividualOfferings['managedSystemIndService' + i + 'Year16Selected'] = this.allIndividualMss[i - 1].neededInYear16;
      updatedIndividualOfferings['managedSystemIndService' + i + 'Year17Selected'] = this.allIndividualMss[i - 1].neededInYear17;
      updatedIndividualOfferings['managedSystemIndService' + i + 'Year18Selected'] = this.allIndividualMss[i - 1].neededInYear18;
      updatedIndividualOfferings['managedSystemIndService' + i + 'Year19Selected'] = this.allIndividualMss[i - 1].neededInYear19;
      updatedIndividualOfferings['managedSystemIndService' + i + 'Year20Selected'] = this.allIndividualMss[i - 1].neededInYear20;
      updatedIndividualOfferings['managedSystemIndService' + i + 'Year21Selected'] = this.allIndividualMss[i - 1].neededInYear21;
      updatedIndividualOfferings['managedSystemIndService' + i + 'Year22Selected'] = this.allIndividualMss[i - 1].neededInYear22;
      updatedIndividualOfferings['managedSystemIndService' + i + 'Year23Selected'] = this.allIndividualMss[i - 1].neededInYear23;
      updatedIndividualOfferings['managedSystemIndService' + i + 'Year24Selected'] = this.allIndividualMss[i - 1].neededInYear24;
      updatedIndividualOfferings['managedSystemIndService' + i + 'Year25Selected'] = this.allIndividualMss[i - 1].neededInYear25;

      if (i === this.allIndividualMss.length){
        await this.addNewService(updatedIndividualOfferings, (i + 1));
      }
    }

    if (this.allIndividualMss.length === 0){
      await this.addNewService(updatedIndividualOfferings, 1);
    }

    await this.dataTransferService.writeDataToExcel(JSON.stringify(updatedIndividualOfferings));

    this.loadingSpinner.stopLoading();

    this.readData.emit();

  }

  async addNewService(updatedIndividualOfferings: KeyValuePair, id: number){

    updatedIndividualOfferings['managedSystemIndService' + id + 'Name'] =
      this.parentForm.get('managedSystemServices')?.get('newMssName')?.value;

    console.log(this.parentForm.get('remoteServiceModules')?.get('newMssName')?.value);

    updatedIndividualOfferings['managedSystemIndService' + id + 'Description'] =
      this.parentForm.get('managedSystemServices')?.get('newMssDescription')?.value;

    updatedIndividualOfferings['managedSystemIndService' + id + 'CostInEuro'] =
      this.parentForm.get('managedSystemServices')?.get('newMssCost')?.value;

    updatedIndividualOfferings['managedSystemIndService' + id + 'CostIncReate'] =
      this.parentForm.get('managedSystemServices')?.get('newMssCostIncreaseRate')?.value;

    updatedIndividualOfferings['managedSystemIndService' + id + 'Year1Selected'] = 0;
    updatedIndividualOfferings['managedSystemIndService' + id + 'Year2Selected'] = 0;
    updatedIndividualOfferings['managedSystemIndService' + id + 'Year3Selected'] = 0;
    updatedIndividualOfferings['managedSystemIndService' + id + 'Year4Selected'] = 0;
    updatedIndividualOfferings['managedSystemIndService' + id + 'Year5Selected'] = 0;
    updatedIndividualOfferings['managedSystemIndService' + id + 'Year6Selected'] = 0;
    updatedIndividualOfferings['managedSystemIndService' + id + 'Year7Selected'] = 0;
    updatedIndividualOfferings['managedSystemIndService' + id + 'Year8Selected'] = 0;
    updatedIndividualOfferings['managedSystemIndService' + id + 'Year9Selected'] = 0;
    updatedIndividualOfferings['managedSystemIndService' + id + 'Year10Selected'] = 0;
    updatedIndividualOfferings['managedSystemIndService' + id + 'Year11Selected'] = 0;
    updatedIndividualOfferings['managedSystemIndService' + id + 'Year12Selected'] = 0;
    updatedIndividualOfferings['managedSystemIndService' + id + 'Year13Selected'] = 0;
    updatedIndividualOfferings['managedSystemIndService' + id + 'Year14Selected'] = 0;
    updatedIndividualOfferings['managedSystemIndService' + id + 'Year15Selected'] = 0;
    updatedIndividualOfferings['managedSystemIndService' + id + 'Year16Selected'] = 0;
    updatedIndividualOfferings['managedSystemIndService' + id + 'Year17Selected'] = 0;
    updatedIndividualOfferings['managedSystemIndService' + id + 'Year18Selected'] = 0;
    updatedIndividualOfferings['managedSystemIndService' + id + 'Year19Selected'] = 0;
    updatedIndividualOfferings['managedSystemIndService' + id + 'Year20Selected'] = 0;
    updatedIndividualOfferings['managedSystemIndService' + id + 'Year21Selected'] = 0;
    updatedIndividualOfferings['managedSystemIndService' + id + 'Year22Selected'] = 0;
    updatedIndividualOfferings['managedSystemIndService' + id + 'Year23Selected'] = 0;
    updatedIndividualOfferings['managedSystemIndService' + id + 'Year24Selected'] = 0;
    updatedIndividualOfferings['managedSystemIndService' + id + 'Year25Selected'] = 0;

  }

  async getPricesForAllMlfbs(){

    this.loadingSpinner.startLoading();

    let mlfbElementsCommaSeperated = '';
    for (let i = 1; i <= 4; i++){
      mlfbElementsCommaSeperated += this.parentForm.get('managedSystemServices')?.get('managedSystemService' + i + 'Mlfb')?.value + ',';
    }

    const priceMflbList =
      await this.productPriceService.getCurrentPricesByMlfbList(mlfbElementsCommaSeperated.replace(/.$/, '')) as any;

    for (let i = 1; i <= 4; i++){

      const mlfb = this.parentForm.get('managedSystemServices')?.get('managedSystemService' + i + 'Mlfb')?.value;
      const oldServiceCost = this.parentForm.get('managedSystemServices')?.get('managedSystemService' + i + 'CostInEuro')?.value;

      if (priceMflbList[mlfb] !== undefined && priceMflbList[mlfb] !== oldServiceCost){
        // eslint-disable-next-line max-len
        this.parentForm.get('managedSystemServices')!.get('managedSystemService' + i + 'CostInEuro')!.setValue(priceMflbList[mlfb]);
        this.parentForm.get('managedSystemServices')!.get('managedSystemService' + i + 'CostInEuro')!.markAsDirty();
        this.parentForm.get('managedSystemServices')!.get('managedSystemService' + i + 'CostInEuro')!.markAsUntouched();
      }
    }

    this.loadingSpinner.stopLoading();

    this.submitValues.emit();
  }

}
