<si-newton-section>
    
    <tabset>
        <tab heading="English">
            <p>
                <img src="assets/LCS-Home-Image.png" alt="Logo" height="200px" style="float:left;">
        
                The “LCS Calculation Tool” is suitable for physical and virtualized PCS 7- / PCS neo- system configurations and provides the capability to 
                calculate LCS cost over a contract period up to 15 years, comprising an evolution from PCS 7 to PCS neo, including all necessary service 
                expenditures and products for modernization. 
            </p>
        
            <p>
                Calculation results can be obtained via download of an Excel-file containing cost-overviews in different formats and if requested also in foreign currencies.  
                Based on parametrizable margin-targets also a budgetary price overview for first discussions with customers can be provided.
            </p>
            
           <p>
                The system configurations (HW and SW) for PCS 7 / PCS neo or a virtualized platform of the respective system can be imported based on dedicated 
                structured component lists (BOM Bill Of Material), which leads to a more precise cost-calculation of installed products in terms of 
                modernization services. 
           </p>
            
           <p>
                A preferred format of the BOMs is the output of the <a href="https://cdt-guest.pcs7.siemens.com/areaConfig" target="_blank"><b>Configuration & Design-Tool (CDT)</b></a> for PCS 7 / PCS neo which is provided by 
                DI PA AE. Alternatively, a BOM template can be downloaded within the tool in the System 
                Configuration section as a basis for BOM-import. 
           </p>
            
           <p>
                Prices for imported components in use are based on L1-prices in EURO and can be obtained via an integral update function which provides 
                product pricing based on valid MLFBs.  
           </p>
        
           <p>
                Regional conditions for e.g. hourly rates, GA discounts, cost increase rates etc. can be individually adapted and applied. 
           </p>
        
           <p>
                The more and more increasing complexity of SW-Maintenance for PCS 7 Versions and the evolution to PCS neo can also be easily handled within 
                the tool, coming from PCS 7 LCS SUS, including a PCS 7 LSS if applicable or a PCS 7 Maintenance contract up to the procurement of 
                PCS neo-SW-Licenses as a basis for evolution and integration of a subsequent PCS neo-SW Maintenance contract “Infinity”.   
           </p>
        
           <p>
                Standard Service modules can be integrated within the LCS contract by selection and parameterization of the related properties 
                Even “Individual Services” which may be provided by regional organization can be integrated into the LCS service portfolio fully aligned 
                to customer needs. 
           </p>
             
            <p>
                Based on the fact, that LCS business is a local business, and the regional RC-CS organization will be the owner of the respective 
                LCS contract, the results / output of the LCS calculation tool should be verified with the local service organization, which will implement 
                the dedicated services. 
            </p>
            
            <p>
                Capability of service implementation within the LCS contract is in responsibility of the regional RC-CS organization and is to be verified 
                and secured upfront before hand-over of a LCS- offer to the customer. 
            </p>
        
        </tab>
        <tab heading="Deutsch">
            <p>
                <img src="assets/LCS-Home-Image.png" alt="Logo" height="200px" style="float:left;">
        
                Das "LCS Calculation Tool" eignet sich für physische und virtualisierte PCS 7- / PCS neo-Systemkonfigurationen und bietet die Möglichkeit, 
                LCS-Kosten über eine Vertragslaufzeit von bis zu 15 Jahren zu berechnen, eine Evolution von PCS 7 zu PCS neo umfasst, einschließlich aller notwendigen 
                Serviceausgaben und Produkte für die Modernisierung. 

            </p>
        
            <p>
                Die Kalkulationsergebnisse können über den Download einer Excel-Datei mit Kostenübersichten in verschiedenen Formaten und auf Wunsch auch für 
                Fremdwährungen bezogen werden. Basierend auf parametrisierbaren Margenzielen kann auch eine budgetäre Preisübersicht für erste Kundengespräche erstellt werden. 
            </p>
            
           <p>
                Die Systemkonfigurationen (HW und SW) für PCS 7 / PCS neo oder eine virtualisierte Plattform des jeweiligen Systems können auf Basis von dedizierten 
                strukturierten Komponentenlisten (BOM Bill Of Material) importiert werden, was zu einer präziseren Kostenkalkulation der installierten Produkte im 
                Hinblick auf Modernisierungsleistungen führt. 
           </p>
            
           <p>
                Ein bevorzugtes Format der Stücklisten ist der Output des <a href="https://cdt-guest.pcs7.siemens.com/areaConfig" target="_blank"><b>Configuration & Design-Tool (CDT)</b></a>, 
                für PCS 7 / PCS neo, das von DI PA AE bereitgestellt wird. 
           </p>
            
           <p>
                Alternativ kann eine Stücklistenvorlage innerhalb des Tools im Bereich Systemkonfiguration als Basis für den Stücklistenimport heruntergeladen werden. 
           </p>
        
           <p>
                Die Preise für importierte Komponenten basieren auf L1-Preisen in Euro, die über eine integrierte Aktualisierungsfunktion bezogen werden können, 
                die Produktpreise auf der Grundlage gültiger MLFBs liefert. 
            </p>
        
           <p>
                Regionale Konditionen für z.B. Stundensätze, GA-Rabatte, Kostensteigerungsraten etc. können individuell angepasst und angewendet werden. 
                Auch die immer komplexer werdende SW-Wartung für PCS 7 Versionen und die Evolution zu PCS neo kann innerhalb des Tools einfach gehandhabt werden, 
                ausgehend von PCS 7 LCS SUS, ggf. inklusive eines PCS 7 LSS oder eines PCS 7 Wartungsvertrages bis hin zur Beschaffung von PCS neo-SW-Lizenzen als 
                Basis für die Evolution und Integration eines nachfolgenden PCS neo-SW Wartungsvertrages "Infinity".  
            </p>
        
           <p>
                Standard-Service-Module können durch Auswahl und Parametrisierung der zugehörigen Eigenschaften in den LCS-Vertrag integriert werden. 
                Auch "Individual Services", die von regionalen Organisationen erbracht werden können, können in das LCS-Service-Portfolio integriert werden, 
                das vollständig auf die Kundenbedürfnisse abgestimmt ist. 
           </p>
             
            <p>
                Da es sich bei LCS um ein lokales Geschäft handelt und die regionale RC-CS Organisation der Eigentümer des jeweiligen LCS-Vertrages ist, 
                sollten die Ergebnisse / der Output des LCS Kalkulationstools mit der lokalen Serviceorganisation, die die entsprechenden Dienste implementieren wird, 
                verifiziert werden. 
            </p>
            
            <p>
                Die Fähigkeit zur Service-Implementierung im Rahmen des LCS-Vertrages liegt in der Verantwortung der regionalen RC-CS Organisation und ist vor 
                der Übergabe eines LCS-Angebotes an den Kunden zu überprüfen und sicherzustellen. 
            </p>
        
        </tab>
    </tabset>
   
</si-newton-section>
