<si-newton-section>
  <div class="row" style="width: 130%; margin-left: -15%;">
    <div class="col-md-12">

    <tabset #tabset>
      <tab heading="Projects" id="projects">

      <div #ProjectList>
      <si-newton-table [rows]="projects" [loading]="(projects) === null" [rowsPerPage]="15">
            <siNewtonTableColumn key="project_name" [widthFactor]="0.9" name="Project Name">
              <ng-template siNewtonTableCell let-value='value' let-row="row">
                {{row.project_name}}
                <i *ngIf="row.opened_by?.length > 0" class="bi bi-calculator-fill"
                  style="color: red; font-size: 1.5em; float:right"
                  title="Project was opened within the last 30 minutes by user(s): {{row.opened_by}}">
                </i>
            </ng-template>
            </siNewtonTableColumn>

            <siNewtonTableColumn key="customer_name" [widthFactor]="0.9" name="Customer">
            </siNewtonTableColumn>

            <siNewtonTableColumn
              key="created_time"
              name="Created (UTC)"
              [sortFunction]="dateSort"
              [sort]="'desc'"
              [widthFactor]="0.8"
              [disableFilter]="true">
            </siNewtonTableColumn>

            <siNewtonTableColumn
              key="lastedit_time"
              name="Last edit (UTC)"
              [sortFunction]="lastEditSort"
              [widthFactor]="0.8">
            </siNewtonTableColumn>

            <siNewtonTableColumn key="status" name="Status" [widthFactor]="0.8">
              <ng-template siNewtonTableCell let-row="row">
                <div style="display: flex; justify-content: space-between; align-items: center;">
                  <div style="flex: 1; min-width: 0; white-space: normal;">{{row.status}}</div>
                  <button *ngIf="row.status == 'Deleted by owner'" class="btn btn-secondary" title="Restore project"
                  style="flex-shrink: 0; margin: 1px; border: 0px;" 
                  (click)="restoreProject(row.project_id)"><i class="bi bi-cloud-plus-fill"></i></button>
                </div>
              </ng-template>
            </siNewtonTableColumn>
            <siNewtonTableColumn key="owner" [widthFactor]="0.8" name="Owner" *ngIf="(getUserRole() | async) === 'admin'">
            </siNewtonTableColumn>

            <siNewtonTableColumn key="role" [widthFactor]="0.8" name="Role" *ngIf="(getUserRole() | async) !== 'admin'">
            </siNewtonTableColumn>

            <siNewtonTableColumn key="assignmentpartner" [widthFactor]="0.8" name="Assigned to/from">
            </siNewtonTableColumn>

            <siNewtonTableColumn
            [disableFilter]="true"
            [disableSort]="true"
            key="useractions"
            [widthFactor]="1.5"
          >
            <ng-template siNewtonTableCell let-value='value' let-row="row">

              <button
                title="Open Project"
                class="btn btn-success"
                style="float: left; margin: 1px; border: 0px;"
                [style.background-color]="(row.project_name === (projectManagementService.projectName$ | async)) ? 'gray' : 'green'"
                (click)="openProject(row.project_name, row.project_id, row.accepted_disclaimer, row.customer_name, row.version)"
                >
                <i class="bi bi-calculator-fill"></i>
              </button>

              <button
              title="Copy Project"
              class="btn btn-secondary"
              style="float: left; margin: 1px; border: 0px;"
              (click)="copyProject(row.project_id, row.project_name, row.customer_name)"
              >
              <i class="bi bi-clipboard-plus"></i>

            </button>

              <button
              title="Close Project"
              class="btn btn-danger"
              style="float: left; margin: 1px; border: 0px;"
              (click)="closeProject(row.project_id)"
              >
              <i class="bi bi-x-circle-fill"></i>
            </button>

              <button
              *ngIf="(row.role === 'Owner' || (getUserRole() | async) === 'admin') && row.assignmentpartner === null"
              title="Assign Project"
              class="btn btn-primary"
              style="float: left; margin: 1px; border: 0px;"
              (click)="assignProject(row.project_id, row.project_name)"
              >
              <i class="bi bi-share-fill"></i>
              </button>

              <button
              *ngIf="(row.role === 'Owner' || (getUserRole() | async) === 'admin') && row.assignmentpartner !== null"
              title="Unassign Project"
              class="btn btn-danger"
              style="float: left; margin: 1px; border: 0px;"
              (click)="unassignProject(row.project_id, row.project_name, row.assignmentpartner)"
              >
              <i class="bi bi-share"></i>
              </button>

              <button
              *ngIf="(row.role === 'Editor') && row.assignmentpartner !== null"
              title="Reject Assignment"
              class="btn btn-danger"
              style="float: left; margin: 1px; border: 0px;"
              (click)="rejectAssignment(row.project_id, row.project_name)"
              >
              <i class="bi bi-share"></i>
              </button>

              <button
              *ngIf="(getUserRole() | async) === 'admin'"
              title="Download Project"
              class="btn btn-success"
              style="float: left; margin: 1px; border: 0px;"
              (click)="downloadProject(row.project_id, row.project_name)"
              >
              <i class="bi bi-download"></i>
              </button>

              <button
              *ngIf="(getUserRole() | async) === 'admin'"
              title="Upload Project"
              class="btn btn-success"
              style="float: left; margin: 1px; border: 0px;"
              (click)="uploadProject(row.project_id)"
              >
              <i class="bi bi-upload"></i>
              </button>

              <button
                *ngIf="row.role === 'Owner' || (getUserRole() | async) === 'admin'"
                title="Delete Project"
                class="btn btn-danger"
                style="float: left; margin: 1px; border: 0px;"
                (click)="deleteProject(row.project_id, row.project_name)"
                >
                  <i class="bi bi-trash"></i>
                </button>

            </ng-template>
          </siNewtonTableColumn>
            <div no-data>
              No projects found
            </div>

      </si-newton-table>
      </div>
    </tab>
      <tab heading="Create Project" id="new_project">
        <form [formGroup]="projectCreationForm" class="col-md-8" style="align-items: center;">
          <si-newton-form-group label="Project name" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="projectName"
              siNewton>
          </si-newton-form-group>

          <si-newton-form-group label="Customer name" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="customerName"
              siNewton>
          </si-newton-form-group>

          <button type="button" class="btn btn-success col-md-3" style="float: right;"
          (click)="createProject()" [disabled]="!(projectCreationForm.valid)">Create project</button>
        </form>
      </tab>
    </tabset>

</div></div>
</si-newton-section>
