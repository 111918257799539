import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-show-disclaimer-modal',
  templateUrl: './show-disclaimer-modal.component.html',
  styleUrls: ['./show-disclaimer-modal.component.scss']
})
export class ShowDisclaimerModalComponent {

  constructor(public modalRef: BsModalRef) { }

  acceptDelete(){
    this.modalRef.content.confirm = true;
    this.modalRef.hide();
  }
}
