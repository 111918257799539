<form [formGroup]="parentForm">
  <div formGroupName="regionalDiscounts">

    <h5 class="subSectionHeader">Regional Discounts</h5>


    
    <div class="row">
      <div class="col">
        <b class="subSubSectionHeader">PCs - OS-Server / OS-Client</b>

      </div>
    </div>

    <div class="row">
      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <si-newton-form-group label="PCS7 / PCS neo IPCs" [errorResolverMap]="{required: 'Value is required'}">
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
          <input formControlName="pcs7pcsNeoIpcsRegionalDiscount" type="number" step="any" [min]="0" siNewton>
        </si-newton-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <b class="subSubSectionHeader">Network</b>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <si-newton-form-group label="Network - Switches Scalances" [errorResolverMap]="{required: 'Value is required'}">
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
          <input formControlName="netWorkSwitcdhesScalanes" type="number" step="any" [min]="0" siNewton>
        </si-newton-form-group>
      </div>
    </div>


    <div class="row">
      <div class="col">
        <b class="subSubSectionHeader">PCS 7 Software</b>
      </div>
    </div>


    <div class="row">
      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <si-newton-form-group label="PCS7 - SW / SUS" [errorResolverMap]="{required: 'Value is required'}">
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
          <input formControlName="pcs7SUSRegionalDiscount" type="number" step="any" [min]="0" siNewton>
        </si-newton-form-group>
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <si-newton-form-group label="Legacy System Service" [errorResolverMap]="{required: 'Value is required'}">
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
          <input formControlName="pcs7LssDiscount" type="number" step="any" [min]="0" siNewton>
        </si-newton-form-group>
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <si-newton-form-group label="SW Maintenance Contract" [errorResolverMap]="{required: 'Value is required'}">
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
          <input formControlName="pcs7SwMainDiscount" type="number" step="any" [min]="0" siNewton>
        </si-newton-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <b class="subSubSectionHeader">PCS neo Software</b>
      </div>
      
    </div>

    <div class="row">
      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <si-newton-form-group label="PCS neo SW Discount" [errorResolverMap]="{required: 'Value is required'}">
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
          <input formControlName="pcsNeoSwDiscount" type="number" step="any" [min]="0" siNewton>
        </si-newton-form-group>
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <si-newton-form-group label="SW Maintenance Contract" [errorResolverMap]="{required: 'Value is required'}">
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
          <input formControlName="pcsNeoSwMainDiscount" type="number" step="any" [min]="0" siNewton>
        </si-newton-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <b class="subSubSectionHeader">SIVaaS-Virtual System</b>
      </div>

    </div>
      
    <div class="row">
      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <si-newton-form-group label="Hosts / Thin Clients VM-Templates" [errorResolverMap]="{required: 'Value is required'}">
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
          <input formControlName="hostThinClientsVmTemplates" type="number" step="any" [min]="0" siNewton>
        </si-newton-form-group>
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <si-newton-form-group label="Service Contracts vCenter Server" [errorResolverMap]="{required: 'Value is required'}">
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
          <input formControlName="serviceContractsvCenterServer" type="number" step="any" [min]="0" siNewton>
        </si-newton-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <b class="subSubSectionHeader">IT Infrastructure (physical)</b>
      </div>

    </div>

    <div class="row">
      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <si-newton-form-group label="Windows Domain Controller" [errorResolverMap]="{required: 'Value is required'}">
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
          <input formControlName="windowsDomainController" type="number" step="any" [min]="0" siNewton>
        </si-newton-form-group>
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <si-newton-form-group label="WSUS Server" [errorResolverMap]="{required: 'Value is required'}">
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
          <input formControlName="wsusServer" type="number" step="any" [min]="0" siNewton>
        </si-newton-form-group>
      </div>


    </div>





  </div>
</form>
