<form [formGroup]="parentForm">
  <div formGroupName="upgradePackages">
    <h5 class="subSectionHeader">Upgrade Packages</h5>

    <div class="row">
      <p class="info">
        Die SUS-Kalkulation berücksichtigt nur den Fall, dass die installierte PCS 7 SW Version der aktuell neuesten PCS 7 Produkt-Version entspricht.
        Sollte dies nicht der Fall sein, müssen entsprechende Upgrade Packages beim Start des LCS als Einmalkosten im 1. Jahr der Kalkulation berücksichtigt werden
      </p>
    </div>

    <div class="row">
      <p class="info">
        The SUS Calculation considers the use case that the installed PCS 7 SW Version corresponds to the newest PCS 7 Product Version. If this is not the case the relevant upgrade packages must be calculated as one time cost before start of LCS in the 1. year at the start of LCS.
      </p>
    </div>

    <div class="row">
      <div class="col">
        <div class="form-check">
          <input id="cb-enabled" class="form-check-input" formControlName="upgradePackageRequiredCheckBox" type="checkbox">
          <label for="cb-enabled" class="form-check-label">
            Upgrade package required
          </label>
        </div>
      </div>
    </div>

    <div *ngIf="+(parentForm.get('upgradePackages')?.get('upgradePackageRequiredCheckBox')?.value) === 1">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-2">
          <b class="labelAccent">Current Year</b>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
          <si-newton-form-group label="L Price total" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="upgradeLpriceTotalcurrentYear" type="number" [min]="0" siNewton>
            <div class="input-group-prepend">
              <span class="input-group-text">€</span>
            </div>
          </si-newton-form-group>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
          <si-newton-form-group label="G / GA Price total" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="gGaPriceTotalCurrentYear" readOnly siNewton highlight>
            <div class="input-group-prepend">
              <span class="input-group-text">€</span>
            </div>
          </si-newton-form-group>
        </div>


        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-2">
          <b class="labelAccent">Year of LCS Start</b>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
          <si-newton-form-group label="L Price total" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="lPriceTotalNextYear" readOnly siNewton highlight>
            <div class="input-group-prepend">
              <span class="input-group-text">€</span>
            </div>
          </si-newton-form-group>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2">
          <si-newton-form-group label="G / GA Price total" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="gGaPriceTotalNextYear" readOnly siNewton highlight>
            <div class="input-group-prepend">
              <span class="input-group-text">€</span>
            </div>
          </si-newton-form-group>
        </div>
      </div>
    </div>

  </div>
</form>
