import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormDataService } from 'src/app/services/form-data.service';

export interface DropDownOption {
  value: string;
}
@Component({
  selector: 'app-backup-restore',
  templateUrl: './backup-restore.component.html',
  styleUrls: ['./backup-restore.component.scss']
})
export class BackupRestoreComponent {

  formName = 'iadcBackupRestore';

  stringToNumber(num: string){
    return parseInt(num, 10);
  }

  @Input()
  public parentForm!: FormGroup;

  constructor(private formDataService: FormDataService) { }

  public getDropdownOptions(formControlName: string){
    return this.formDataService.getDropDownOptions(formControlName);
  }

  public getDropdownValue(dropDownOption: DropDownOption): string {
    return dropDownOption.value;
  }
}
