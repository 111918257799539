import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-cost-factors',
  templateUrl: './cost-factors.component.html',
  styleUrls: ['./cost-factors.component.scss']
})
export class CostFactorsComponent {

  @Input()
  public parentForm!: FormGroup;

  constructor() { }

}
