import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DataTransferService } from 'src/app/services/data-transfer.service';
import { FormDataService } from 'src/app/services/form-data.service';
import { MaintenanceParametersComponent } from '../../modals/maintenance-parameters/maintenance-parameters.component';

@Component({
  selector: 'app-preventive-maintenance',
  templateUrl: './preventive-maintenance.component.html',
  styleUrls: ['./preventive-maintenance.component.scss']
})
export class PreventiveMaintenanceComponent {
  isThisSectionVisible = false;

  toggleVisibility(){
    console.log(this.isThisSectionVisible);
    this.isThisSectionVisible = !this.isThisSectionVisible;
  }

  @Input()
  parentForm!: FormGroup;
  prevMainSelection!: string;

  modalRef!: BsModalRef;

  constructor(private modalService: BsModalService, private formDataService: FormDataService,
    private dataTransferService: DataTransferService) { }

  public openMaintenanceParameters(){

    const initialState = {};

    this.modalRef = this.modalService.show(MaintenanceParametersComponent, { initialState, backdrop: 'static' });
    this.modalRef.content.closeBtnName = 'Close';

    this.modalRef.onHidden.subscribe(async (res: any) => {
      const keysFromDialog = await this.formDataService.getAllControlsFromDialogAsArray(this.parentForm);

      const formData = await this.dataTransferService.getValuesFromExcel(keysFromDialog) as any;
      this.formDataService.fillDataIntoDialog(this.parentForm, formData);

    });
  }

}
