export class ConstRestData {

  public static restUrls = {
    generalInformation: 'general',
    regionalFactors: 'regional-factors',
    defineServices: 'define-services',
    maintenanceParameters: 'maintenance-parameters',
    defineConfiguration: 'define-configuration',
    softwareUpdateServices: 'software-update-service'
  };

}
