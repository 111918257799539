import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ProjectManagementService } from '../services/project-management.service';

@Component({
  selector: 'app-project-unassignment',
  templateUrl: './project-unassignment.component.html',
  styleUrls: ['./project-unassignment.component.scss']
})
export class ProjectUnassignmentComponent {

  projectId!: string;
  projectName!: string;
  assignmentPartner!: string;

  constructor(public modalRef: BsModalRef, private projectManagementService: ProjectManagementService) { }

  async unassignProject(){
    await this.projectManagementService.unassignProject(this.projectId, this.assignmentPartner);
    this.modalRef.hide();
  }

}
