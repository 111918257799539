import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Project } from 'src/app/data-models/Project';
import { UserManagementService } from './user-management.service';
import { TimeService } from './time.service';
import { SiNewtonLoadingService } from '@simpl/newton-ng/loading-spinner';
import { CustomToastService } from './custom-toast.service';
import { NavigationService } from './navigation.service';
import { DataTransferService } from './data-transfer.service';
import { take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ProjectManagementService implements OnInit{

  projects: Project[] = [];
  selectedProjectId = '';
  selectedProjectCustomer = '';
  openWorkbooks: any;
  projectNameSource = new BehaviorSubject<string>('');

  projectName$ = this.projectNameSource.asObservable();


  constructor(private httpService: HttpClient, private loadingSpinner: SiNewtonLoadingService,
    private userManagementService: UserManagementService, private timeService: TimeService, private toastService: CustomToastService,
    private navigationService: NavigationService, private dataTransferService: DataTransferService) {}

  private updateSelectedProjectInfo(projectId: string, projectName: string, customer: string, versionOfCalculationFile: string){
    sessionStorage.setItem('SelectedProjectId', projectId.toString());
    sessionStorage.setItem('SelectedProjectName', projectName);
    sessionStorage.setItem('selectedProjectCustomer', customer);
    sessionStorage.setItem('calculationVersion', versionOfCalculationFile.toString());

    this.selectedProjectId = projectId;
    this.selectedProjectCustomer = customer;

    this.updateProjectName();
  }

  ngOnInit(): void{
    this.updateProjectName();;
  }

  async getProjects(): Promise<Project[]>{

    console.log('getProjects()');

    const userRole = await this.userManagementService.getUserRole().pipe(take(1)).toPromise();
    
    const userName = await this.userManagementService.getUsername().pipe(take(1)).toPromise();
    const endpoint = environment.baseUrl + (userRole === 'admin' ?
      '/get-all-projects' : `/get-user-projects?user=${userName}`);

    this.projects = await this.httpService.get<Project[]>(endpoint).toPromise();

    // Da der eigene Name bei der Warnung "Opend in the last 30 minutes" nicht relevant ist, muss er raus
    if (userRole === 'user'){
      for (const project of this.projects){

        if (project.opened_by){
          const indexOfUsername = project.opened_by.indexOf(userName);

          if (indexOfUsername > -1){
            project.opened_by.splice(indexOfUsername, 1);
          }
        }

      }
    }

    return this.projects;
  }

  async getOpenProjects() {
    const projectsOpenBy = await this.httpService.get(environment.baseUrl + '/backend/getOpenWorkbooks').toPromise();

    return projectsOpenBy;
  }

  async openProject(projectName: string, projectId: string, customer: string,
    versionOfCalculationFile: number | string, routeToStart: boolean) {

    this.loadingSpinner.startLoading();

    this.updateSelectedProjectInfo(projectId.toString(), projectName, customer, versionOfCalculationFile.toString());

    this.loadingSpinner.stopLoading();

    // const userName = await this.userManagementService.getUsername().pipe(take(1)).toPromise();
    // const oldProjectParam = '&oldProject=' + sessionStorage.getItem('SelectedProjectId') || '';

    const endpoint = environment.baseUrl + environment.apiUrl + '/calculation/open/lcs/' + projectId;

    const body = {};

    const options = {
      observe: 'response' as const
    };

    const response = await this.httpService.post(endpoint, body, options).toPromise();

    if (response.status === 200){

      this.updateSelectedProjectInfo(projectId.toString(), projectName, customer, versionOfCalculationFile.toString());

      this.loadingSpinner.stopLoading();

      if (routeToStart){
        this.navigationService.naviClick('general-information');
      }

      this.toastService.showSuccessToast((response as any).body.message);

    } else {
      this.loadingSpinner.stopLoading();

      this.toastService.showErrorToast('Project could not opened');

    }
  }

  updateAcceptStatusOfDisclaimer(projectId: string) {
    return new Promise((resolve, reject) => {
      this.httpService.post(environment.baseUrl + '/updateAcceptedDisclaimer', { projectId }, { observe: 'body' })
        .subscribe(() => resolve(true), (errorResponse: HttpErrorResponse) => resolve(true));
    });
  }

  async createProject(projectName: string, customerName: string){

    this.loadingSpinner.startLoading();

    const userName = await this.userManagementService.getUsername().pipe(take(1)).toPromise();

    if (userName === ''){
      return;
    }

    const body = { projectName, customerName, owner: userName, time: this.timeService.getCurrentTime() };

    this.httpService.post<{ projectId: string }>(environment.baseUrl + '/create-project-in-database', body).subscribe((res) => {

      const idOfNewProject = res.projectId;

      const url = environment.baseUrl + environment.apiUrl + '/projects/create/lcs/' + idOfNewProject;

      this.httpService.post(url, null).subscribe((res) => {

        this.loadingSpinner.stopLoading();
        this.toastService.showInfoToast((res as any));

      }, (error: HttpErrorResponse) => {
        console.log(error.message);
        this.loadingSpinner.stopLoading();
  
        this.toastService.showErrorToast(error.message);
      });
    }, (error: HttpErrorResponse) => {
      console.log(error.message);
      this.loadingSpinner.stopLoading();

      this.toastService.showErrorToast(error.message);
    });
  }

  async copyCalculationFile(originalProjectId: string, projectName: string, customerName: string){

    const userName = await this.userManagementService.getUsername().pipe(take(1)).toPromise();
    const endpoint = environment.baseUrl + '/create-project-in-database';
    
    const body = { projectName, customerName, owner: userName, time: this.timeService.getCurrentTime() };

    this.httpService.post<{ projectId: string }>(endpoint, body).subscribe(res => {
      const newProjectId = res.projectId;

      const endpointProjectCopy = environment.baseUrl + environment.apiUrl + '/projects/copy/lcs/' + originalProjectId + '/' + newProjectId;

      this.httpService.post<{ message: string }>(endpointProjectCopy, body).subscribe(res => {
        this.toastService.showInfoToast(res.message);
      });
    });
      
  }

  deleteProjectOrRelation(projectId: string){

    return new Promise(async (resolve, reject) => {

      let endpoint = '';

      const userRole = await this.userManagementService.getUserRole().pipe(take(1)).toPromise();


      if (userRole === 'admin'){
        endpoint = environment.baseUrl + '/delete-project';
      } else {
        endpoint = environment.baseUrl + '/project/delete-all-project-relations';
      }

      const body = { projectId };
      const options = { observe: 'response' as const };

      this.httpService.post(endpoint, body, options).subscribe(response => {
        this.toastService.showSuccessToast((response as any).body.message);
        return resolve(true);
      });
    });
  }

  async closeCalculationFile(projectId: string, saveBeforeClose: boolean){
    this.loadingSpinner.startLoading();
    this.toastService.showInfoToast('Closing Calculation File');

    //const userName = this.userManagementService.getUsername().pipe(take(1)).toPromise();

    return new Promise((resolve, reject) => {
      const endpoint = environment.baseUrl + environment.apiUrl + '/calculation/close/lcs/' + projectId + '/save/' + saveBeforeClose;
      const body = { };
      const options = { observe: 'body' as const };

      this.httpService.post(endpoint, body, options).subscribe(response => {
        this.toastService.showSuccessToast((response as any).message);
        this.loadingSpinner.stopLoading();
        this.updateSelectedProjectInfo('', '', '', '');
        return resolve(true);
      }, (errorResponse: HttpErrorResponse) => {

        this.toastService.showErrorToast('Could not close the project file');
        this.loadingSpinner.stopLoading();

        return resolve(true);
      });
    });
  }

  async updateCalculationFile(projectName: string, projectId: string, acceptedDisclaimer: boolean,
    customerName: string, version: number | string){
    this.loadingSpinner.startLoading();

    this.toastService.showInfoToast('Updating Calculation to current version');

    const endpoint = environment.baseUrl + '/project/update-calculation-version';
    const body = {
      projectId
    };
    const options = { observe: 'body' as const };

    this.httpService.post(endpoint, body, options).subscribe(async response => {

      const originalValues = (response as any);

      await this.openProject(projectName, projectId, customerName, version, false);

      await this.dataTransferService.writeDataToExcel(originalValues);

      this.toastService.showSuccessToast('Update successful');

      this.navigationService.naviClick('general-information');

      this.projects = (response as any).projects;
      this.loadingSpinner.stopLoading();

    }, (errorResponse: HttpErrorResponse) => {

      this.toastService.showErrorToast('Could not update the project file');
      this.loadingSpinner.stopLoading();

    });
  }
  
  closeCalculationFileForAllUsers(projectId: string, saveBeforeClose: boolean){
    this.loadingSpinner.startLoading();
    this.toastService.showInfoToast('Closing Calculation File');

    return new Promise((resolve, reject) => {
      const endpoint = environment.baseUrl + environment.apiUrl + '/calculation/close/lcs/' + projectId + '/save/' + saveBeforeClose;
      const body = { };
      const options = { observe: 'body' as const };

      this.httpService.post(endpoint, body, options).subscribe(response => {
        this.toastService.showSuccessToast((response as any).message);
        this.loadingSpinner.stopLoading();
        return resolve(true);
      }, (errorResponse: HttpErrorResponse) => {

        this.toastService.showErrorToast('Could not close the project file');
        this.loadingSpinner.stopLoading();

        return reject(true);
      });
    });
  }

  async restoreProject(projectId: string){

      const endpoint = environment.baseUrl + '/project/restore-project';
      const body = {
        projectId
      };

      const options = {
        observe: 'response' as const
      };

      const result = await this.httpService.post(endpoint, body, options).toPromise();

      this.toastService.showInfoToast((result as any).body.message)

  }

  assignProject(assignmentPartner: string, projectId: string){

    return new Promise((resolve, reject) => {

      this.loadingSpinner.startLoading();
      const endpoint = environment.baseUrl + '/project/assign-project';
      const body = {
        assignTo: assignmentPartner.toLowerCase(), projectId
      };

      const options = {
        observe: 'response' as const
      };

      this.httpService.post(endpoint, body, options).subscribe(response => {
        this.loadingSpinner.stopLoading();
        this.toastService.showSuccessToast((response as any).body.message);
        return resolve(true);
      }, (errorResponse: HttpErrorResponse) => {
        this.loadingSpinner.stopLoading();

        console.log(errorResponse);
        this.toastService.showErrorToast('Could not assign project: ' + errorResponse.error);
        return reject();
      });
    });
  }


  unassignProject(projectId: string, assignmentPartner?: string){
    return new Promise((resolve, reject) => {
      this.loadingSpinner.startLoading();
      const endpoint = environment.baseUrl + '/project/unassign-project';
      const body = {
        editor: assignmentPartner?.toLowerCase() || null, projectId
      };

      const options = {
        observe: 'response' as const
      };

      this.httpService.post(endpoint, body, options).subscribe(response => {
        this.loadingSpinner.stopLoading();
        this.toastService.showSuccessToast((response as any).body.message);
        return resolve(true);
      }, (errorResponse: HttpErrorResponse) => {
        this.loadingSpinner.stopLoading();
        reject(new Error(errorResponse.error.message));
      });

    });
  }

  updateProjectName(): void {
    const newProjectName = sessionStorage.getItem('SelectedProjectName') || '';
    this.projectNameSource.next(newProjectName);
  }

}
