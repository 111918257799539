
<form [formGroup]="parentForm">
    <div formGroupName="legacySystemServiceForm">

        <!-- Radio Buttons -->
        <div style="display: block">
            <div class="row">
                <div class="col-lg-2 col-md-3 col-sm-4 col-xs-12">
                    <div class="form-check">
                        <input id="lssPackageSelection1" class="form-check form-check-inline" [(ngModel)]="selectedLssPackage"
                            formControlName="lssPackageSelection" type="radio" value="PCS 7 V7.0">
                        <label for="lssPackageSelection1" class="form-check-label">
                            PCS 7 V7.0
                        </label>
                    </div>
                </div>
                <div class="col-lg-2 col-md-3 col-sm-4 col-xs-12">
                    <div class="form-check">
                        <input id="lssPackageSelection2" class="form-check form-check-inline" [(ngModel)]="selectedLssPackage"
                            formControlName="lssPackageSelection" type="radio" value="PCS 7 V8.0 / 8.1">
                        <label for="lssPackageSelection2" class="form-check-label">
                            PCS 7 V8.0 / 8.1
                        </label>
                    </div>
                </div>
                <div class="col-lg-2 col-md-3 col-sm-4 col-xs-12">
                    <div class="form-check">
                        <input id="lssPackageSelection3" class="form-check form-check-inline" [(ngModel)]="selectedLssPackage"
                            formControlName="lssPackageSelection" type="radio" value="PCS 7 V9.0">
                        <label for="lssPackageSelection3" class="form-check-label">
                            PCS 7 V9.0
                        </label>
                    </div>
                </div>
            </div>
        
        
        </div>
      
        <div *ngIf="selectedLssPackage=='PCS 7 V7.0'">
            
            <!-- Package Info -->
            <div class="customCard">
                <div class="row">
                    <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                        <p><b>MLFB</b></p>
                        {{parentForm.get('legacySystemServiceForm')?.get('lssPackageV7Mlfb')?.value}} 
                    </div>
                        
                    <div class="col-lg-6 col-md-4 col-sm-6 col-xs-12">
                        <p><b>Description</b></p>
                        {{parentForm.get('legacySystemServiceForm')?.get('lssPackageV7Description')?.value}} 
                    </div>

                    <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                        <p></p>
                        <si-newton-form-group label="L Price" [errorResolverMap]="{required: 'Value is required', priceNotFound: 'Price could not be found in API'}">
                            <div class="input-group-prepend">
                              <span class="input-group-text">€</span>
                            </div>
                            <input formControlName="lssPackageV7LPrice" type="number" step="any" [min]="0" siNewton>
                          </si-newton-form-group>
                    </div>

                    <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                        <p></p>
                        <si-newton-form-group label="GA Price" [errorResolverMap]="{required: 'Value is required'}">
                            <div class="input-group-prepend">
                              <span class="input-group-text">€</span>
                            </div>
                            <input formControlName="lssPackageV7GAPrice" type="number" step="any" [min]="0" highlight readonly siNewton>
                          </si-newton-form-group>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <div class="form-check">
                        <input id="cb-enabled" class="form-check-input" formControlName="lssPackageExtension" type="checkbox">
                        <label for="cb-enabled" class="form-check-label">
                            Extension Package
                        </label>
                    </div>
                </div>
            
            </div>

            <!-- Extension Package -->
            <div class="customCard" *ngIf="parentForm.get('legacySystemServiceForm')?.get('lssPackageExtension')?.value == 1">
                <div class="row">
                    <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                        <p><b>MLFB</b></p>
                        {{parentForm.get('legacySystemServiceForm')?.get('lssPackageV7ExtensionMlfb')?.value}} 
                    </div>
                        
                    <div class="col-lg-6 col-md-4 col-sm-6 col-xs-12">
                        <p><b>Description</b></p>
                        {{parentForm.get('legacySystemServiceForm')?.get('lssPackageV7ExtensionDescription')?.value}} 
                    </div>

                    <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                        <p></p>
                        <si-newton-form-group label="L Price" [errorResolverMap]="{required: 'Value is required', priceNotFound: 'Price could not be found in API'}">
                            <div class="input-group-prepend">
                              <span class="input-group-text">€</span>
                            </div>
                            <input formControlName="lssPackageV7ExtensionLPrice" type="number" step="any" [min]="0" siNewton>
                          </si-newton-form-group>
                    </div>

                    <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                        <p></p>
                        <si-newton-form-group label="GA Price" [errorResolverMap]="{required: 'Value is required'}">
                            <div class="input-group-prepend">
                              <span class="input-group-text">€</span>
                            </div>
                            <input formControlName="lssPackageV7ExtensionGAPrice" type="number" step="any" [min]="0" highlight readonly siNewton>
                          </si-newton-form-group>
                    </div>
                </div>
            </div>

        </div>

        <div *ngIf="selectedLssPackage=='PCS 7 V8.0 / 8.1'">
                   <!-- Package Info -->
                   <div class="customCard">
                    <div class="row">
                        <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                            <p><b>MLFB</b></p>
                            {{parentForm.get('legacySystemServiceForm')?.get('lssPackageV8Mlfb')?.value}} 
                        </div>
                            
                        <div class="col-lg-6 col-md-4 col-sm-6 col-xs-12">
                            <p><b>Description</b></p>
                            {{parentForm.get('legacySystemServiceForm')?.get('lssPackageV8Description')?.value}} 
                        </div>
    
                        <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                            <p></p>
                            <si-newton-form-group label="L Price" [errorResolverMap]="{required: 'Value is required', priceNotFound: 'Price could not be found in API'}">
                                <div class="input-group-prepend">
                                  <span class="input-group-text">€</span>
                                </div>
                                <input formControlName="lssPackageV8LPrice" type="number" step="any" [min]="0" siNewton>
                              </si-newton-form-group>
                        </div>
    
                        <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                            <p></p>
                            <si-newton-form-group label="GA Price" [errorResolverMap]="{required: 'Value is required'}">
                                <div class="input-group-prepend">
                                  <span class="input-group-text">€</span>
                                </div>
                                <input formControlName="lssPackageV8GAPrice" type="number" step="any" [min]="0" highlight readonly siNewton>
                              </si-newton-form-group>
                        </div>
                    </div>
                </div>
    
                <div class="row">
                    <div class="col">
                        <div class="form-check">
                            <input id="cb-enabled" class="form-check-input" formControlName="lssPackageExtension" type="checkbox">
                            <label for="cb-enabled" class="form-check-label">
                                Extension Package
                            </label>
                        </div>
                    </div>
                
                </div>
    
                <!-- Extension Package -->
                <div class="customCard" *ngIf="parentForm.get('legacySystemServiceForm')?.get('lssPackageExtension')?.value == 1">
                    <div class="row">
                        <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                            <p><b>MLFB</b></p>
                            {{parentForm.get('legacySystemServiceForm')?.get('lssPackageV8ExtensionMlfb')?.value}} 
                        </div>
                            
                        <div class="col-lg-6 col-md-4 col-sm-6 col-xs-12">
                            <p><b>Description</b></p>
                            {{parentForm.get('legacySystemServiceForm')?.get('lssPackageV8ExtensionDescription')?.value}} 
                        </div>
    
                        <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                            <p></p>
                            <si-newton-form-group label="L Price" [errorResolverMap]="{required: 'Value is required', priceNotFound: 'Price could not be found in API'}">
                                <div class="input-group-prepend">
                                  <span class="input-group-text">€</span>
                                </div>
                                <input formControlName="lssPackageV8ExtensionLPrice" type="number" step="any" [min]="0" siNewton>
                              </si-newton-form-group>
                        </div>
    
                        <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                            <p></p>
                            <si-newton-form-group label="GA Price" [errorResolverMap]="{required: 'Value is required'}">
                                <div class="input-group-prepend">
                                  <span class="input-group-text">€</span>
                                </div>
                                <input formControlName="lssPackageV8ExtensionGAPrice" type="number" step="any" [min]="0" highlight readonly siNewton>
                              </si-newton-form-group>
                        </div>
                    </div>
                </div>
        </div>

        <div *ngIf="selectedLssPackage=='PCS 7 V9.0'">
                  <!-- Package Info -->
                  <div class="customCard">
                    <div class="row">
                        <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                            <p><b>MLFB</b></p>
                            {{parentForm.get('legacySystemServiceForm')?.get('lssPackageV9Mlfb')?.value}} 
                        </div>
                            
                        <div class="col-lg-6 col-md-4 col-sm-6 col-xs-12">
                            <p><b>Description</b></p>
                            {{parentForm.get('legacySystemServiceForm')?.get('lssPackageV9Description')?.value}} 
                        </div>
    
                        <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                            <p></p>
                            <si-newton-form-group label="L Price" [errorResolverMap]="{required: 'Value is required', priceNotFound: 'Price could not be found in API'}">
                                <div class="input-group-prepend">
                                  <span class="input-group-text">€</span>
                                </div>
                                <input formControlName="lssPackageV9LPrice" type="number" step="any" [min]="0" siNewton>
                              </si-newton-form-group>
                        </div>
    
                        <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                            <p></p>
                            <si-newton-form-group label="GA Price" [errorResolverMap]="{required: 'Value is required'}">
                                <div class="input-group-prepend">
                                  <span class="input-group-text">€</span>
                                </div>
                                <input formControlName="lssPackageV9GAPrice" type="number" step="any" [min]="0" highlight readonly siNewton>
                              </si-newton-form-group>
                        </div>
                    </div>
                </div>
    
                <div class="row">
                    <div class="col">
                        <div class="form-check">
                            <input id="cb-enabled" class="form-check-input" formControlName="lssPackageExtension" type="checkbox">
                            <label for="cb-enabled" class="form-check-label">
                                Extension Package
                            </label>
                        </div>
                    </div>
                
                </div>
    
                <!-- Extension Package -->
                <div class="customCard" *ngIf="parentForm.get('legacySystemServiceForm')?.get('lssPackageExtension')?.value == 1">
                    <div class="row">
                        <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                            <p><b>MLFB</b></p>
                            {{parentForm.get('legacySystemServiceForm')?.get('lssPackageV9ExtensionMlfb')?.value}} 
                        </div>
                            
                        <div class="col-lg-6 col-md-4 col-sm-6 col-xs-12">
                            <p><b>Description</b></p>
                            {{parentForm.get('legacySystemServiceForm')?.get('lssPackageV9ExtensionDescription')?.value}} 
                        </div>
    
                        <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                            <p></p>
                            <si-newton-form-group label="L Price" [errorResolverMap]="{required: 'Value is required', priceNotFound: 'Price could not be found in API'}">
                                <div class="input-group-prepend">
                                  <span class="input-group-text">€</span>
                                </div>
                                <input formControlName="lssPackageV9ExtensionLPrice" type="number" step="any" [min]="0" siNewton>
                              </si-newton-form-group>
                        </div>
    
                        <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                            <p></p>
                            <si-newton-form-group label="GA Price" [errorResolverMap]="{required: 'Value is required'}">
                                <div class="input-group-prepend">
                                  <span class="input-group-text">€</span>
                                </div>
                                <input formControlName="lssPackageV9ExtensionGAPrice" type="number" step="any" [min]="0" highlight readonly siNewton>
                              </si-newton-form-group>
                        </div>
                    </div>
                </div>
        </div>

        <div class="row" style="margin-bottom: 10px;">
            <div class="col-12" style="float: right;">
                <button type="button" class="btn btn-success" (click)="updateListPricesOfLssPackages()" style="float: right">Update List Prices</button>
            </div>
        </div>
    </div>
</form>