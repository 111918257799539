<div class="modal-header">
  <h4 class="modal-title pull-left">Upload Calculation File</h4>
</div>
<div class="modal-body">

  <div class="row" style="margin-bottom: 25px;">
    <label class="btn btn-primary" style="margin: auto;">
      Select Calculation File
      <span class="newton-search"></span>
      <input #fileSelection type="file"
        accept="application/msexcel"
        (change)="storeBomFile()">
    </label>
  </div>

  <div class="row">
    <div class="col-md-4">

    </div>
    <div class="col-md-2">
      <button type="button" class="btn btn-primary" style="width: 100%" (click)="modalRef.hide()">Cancel</button>
    </div>
    <div class="col-md-2">
      <button type="button"
      class="btn btn-success"
      style="width: 100%;"
      (click)="uploadProject()"
      [disabled]="fileSelection === undefined">
      Upload Calculation File
    </button>
    </div>
    <div class="col-md-4">

    </div>
  </div>
</div>
<div class="modal-footer">

</div>
