import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-plant-topology',
  templateUrl: './plant-topology.component.html',
  styleUrls: ['./plant-topology.component.scss']
})
export class PlantTopologyComponent {
  isThisSectionVisible = false;

  toggleVisibility(){
    console.log(this.isThisSectionVisible);
    this.isThisSectionVisible = !this.isThisSectionVisible;
  }

  @Input()
  parentForm!: FormGroup;

  constructor() { }
}
