import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-sivaas-system-services',
  templateUrl: './sivaas-system-services.component.html',
  styleUrls: ['./sivaas-system-services.component.scss']
})
export class SivaasSystemServicesComponent {
  isThisSectionVisible = false;

  toggleVisibility(){
    console.log(this.isThisSectionVisible);
    this.isThisSectionVisible = !this.isThisSectionVisible;
  }

  @Input()
  parentForm!: FormGroup;

  constructor() {

  }
}
