/* eslint-disable max-len */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ConstRestData } from 'src/app/const-data/rest';
import { IndividualMaintenanceParameter } from 'src/app/data-models/IndividualMaintenanceParameter';
import { MaintenanceParameter } from 'src/app/data-models/MaintenanceParameter';
import { CustomToastService } from 'src/app/services/custom-toast.service';
import { DataTransferService } from 'src/app/services/data-transfer.service';
import { FormDataService } from 'src/app/services/form-data.service';

export interface DropDownOption {
  value: string;
}

@Component({
  selector: 'app-maintenance-parameters',
  templateUrl: './maintenance-parameters.component.html',
  styleUrls: ['./maintenance-parameters.component.scss']
})
export class MaintenanceParametersComponent implements OnInit{

  allMaintenanceParameters: MaintenanceParameter[] = [];
  allIndividualMaintenanceParameters: IndividualMaintenanceParameter[] = [];

  isAllIndividualParametersUpdating = false;

  numberOfMaintenanceParameters = 51;
  maxNumberOfNeoMaintenanceParameters = 20;
  maxNumberOfIndividualMaintenanceParameters = 20;

  REST_URLS = ConstRestData.restUrls;

  maintenanceParametersForm: FormGroup;
  maintenanceParametersFormMain!: FormGroup;
  maintenanceParametersFormIndividual!: FormGroup;

  maintenanceParameterData!: any;

  constructor(private formBuilder: FormBuilder, private dataTransferService: DataTransferService,
    private formDataService: FormDataService, public modalRef: BsModalRef, private toastService: CustomToastService) {

    this.maintenanceParametersForm = this.formBuilder.group({
      main: this.formBuilder.group({}),
      individualParameters: this.formBuilder.group({})
    });

    this.createStandardParameterForm();
    this.createIndividualParameterForm();

  }

  public getDropdownOptions(formControlName: string, formControlName2: string){
    const dropdownOptions1 = this.formDataService.getDropDownOptions(formControlName);
    const dropdownOptions2 = this.formDataService.getDropDownOptions(formControlName2);

    const mergedOptions = [...dropdownOptions1, ...dropdownOptions2];

    const uniqueOptions = mergedOptions.filter((option, index, self) =>
      index === self.findIndex(opt => (
        opt.value === option.value
      ))
    );

    return uniqueOptions;
  }

  public getDropdownValue(dropDownOption: DropDownOption): string {
    return dropDownOption.value;
  }

  isNewTaskValid(){

    try {
      if (this.maintenanceParametersFormIndividual.get('newIndividualIuWTaskComponent')?.value == null){
        return false;
      } else if (this.maintenanceParametersFormIndividual.get('newIndividualIuWTask')?.value == null){
        return false;
      } else if (this.maintenanceParametersFormIndividual.get('newIndividualIuWTaskComment')?.value == null){
        return false;
      } else if (this.maintenanceParametersFormIndividual.get('newIndividualIuWTaskEffort')?.value == null){
        return false;
      } else if (this.maintenanceParametersFormIndividual.get('newIndividualIuWTaskMaterialCost')?.value == null){
        return false;
      } else if (this.maintenanceParametersFormIndividual.get('newIndividualIuWTaskTimesPerYear')?.value == null){
        return false;
      } else if (this.maintenanceParametersFormIndividual.get('newIndidivualTaskQuantity')?.value == null){
        return false;
      } else
        return true;
    } catch {
      return false;
    }
  }

  async updateIndividualMaintenanceTasks(){

    console.log(this.allIndividualMaintenanceParameters);
    if (this.allIndividualMaintenanceParameters.length < this.maxNumberOfIndividualMaintenanceParameters){
      this.allIndividualMaintenanceParameters.push({
        component: this.maintenanceParametersFormIndividual.get('newIndividualIuWTaskComponent')?.value,
        comment: this.maintenanceParametersFormIndividual.get('newIndividualIuWTaskComment')?.value,
        task: this.maintenanceParametersFormIndividual.get('newIndividualIuWTask')?.value,
        effort: this.maintenanceParametersFormIndividual.get('newIndividualIuWTaskEffort')?.value,
        materialCost: this.maintenanceParametersFormIndividual.get('newIndividualIuWTaskMaterialCost')?.value,
        timesPerYear: this.maintenanceParametersFormIndividual.get('newIndividualIuWTaskTimesPerYear')?.value,
        quantity: this.maintenanceParametersFormIndividual.get('newIndidivualTaskQuantity')?.value
      });

      this.writeDataFromArrayToFormControlData();

      await this.update();
      await this.readData();
      this.fillDataIntoTableArrays();
    } else {
      this.toastService.showErrorToast('Maximum number of individual maintenance tasks reached');
    }
  }

  createStandardParameterForm(){

    this.maintenanceParametersFormMain = this.maintenanceParametersForm.get('main') as FormGroup;

    for (let i = 1; i <= this.numberOfMaintenanceParameters; i++){

      this.maintenanceParametersFormMain.addControl('mainParaComp' + i,
        new FormControl(null));
      this.maintenanceParametersFormMain.addControl('mainParaSubComp' + i,
        new FormControl(null));
      this.maintenanceParametersFormMain.addControl('mainParaTooltip' + i,
        new FormControl(null));
      this.maintenanceParametersFormMain.addControl('mainParaPCS7' + i,
        new FormControl(null, Validators.compose([Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)])));
      this.maintenanceParametersFormMain.addControl('mainParaPCSNeo' + i,
        new FormControl(null, Validators.compose([Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)])));
    }

    this.maintenanceParametersFormMain.addControl('materialExchangeCost1', new FormControl(null));
    this.maintenanceParametersFormMain.addControl('materialExchangeCost2', new FormControl(null));
    this.maintenanceParametersFormMain.addControl('materialExchangeCost3', new FormControl(null));

  }


  createIndividualParameterForm(){
    this.maintenanceParametersFormIndividual = this.maintenanceParametersForm.get('individualParameters') as FormGroup;

    this.maintenanceParametersFormIndividual.addControl('newIndividualIuWTaskComponent',
      new FormControl(null));

    this.maintenanceParametersFormIndividual.addControl('newIndividualIuWTask',
      new FormControl(null));

    this.maintenanceParametersFormIndividual.addControl('newIndividualIuWTaskComment',
      new FormControl(null));

    this.maintenanceParametersFormIndividual.addControl('newIndividualIuWTaskEffort',
      new FormControl(0, Validators.pattern(/^-?(0|[1-9]\d*)?$/)));

    this.maintenanceParametersFormIndividual.addControl('newIndividualIuWTaskMaterialCost',
      new FormControl(0, Validators.pattern(/^-?(0|[1-9]\d*)?$/)));

    this.maintenanceParametersFormIndividual.addControl('newIndividualIuWTaskTimesPerYear',
      new FormControl(null));

    this.maintenanceParametersFormIndividual.addControl('newIndidivualTaskQuantity',
      new FormControl(null));
      

    for (let i = 1; i <= this.maxNumberOfIndividualMaintenanceParameters; i++){

      this.maintenanceParametersFormIndividual.addControl('individualIuWTaskComponent' + i,
        new FormControl(null));
      this.maintenanceParametersFormIndividual.addControl('individualIuWTask' + i,
        new FormControl(null));
      this.maintenanceParametersFormIndividual.addControl('individualIuWTaskComment' + i,
        new FormControl(null));
      this.maintenanceParametersFormIndividual.addControl('individualIuWTaskEffort' + i,
        new FormControl(0, Validators.pattern(/^-?(0|[1-9]\d*)?$/)));
      this.maintenanceParametersFormIndividual.addControl('individualIuWTaskMaterialCost' + i,
        new FormControl(0, Validators.pattern(/^-?(0|[1-9]\d*)?$/)));
      this.maintenanceParametersFormIndividual.addControl('individualIuWTaskTimesPerYear' + i,
        new FormControl(1));
      this.maintenanceParametersFormIndividual.addControl('individualIuWTaskQuantity' + i,
        new FormControl(0));
    }
  }

  async ngOnInit(){

    await this.readData();
    this.fillDataIntoTableArrays();
  }

  logInvalidControls() {
    Object.keys(this.maintenanceParametersFormIndividual.controls).forEach(key => {
      const control = this.maintenanceParametersFormIndividual.controls[key];

      console.log(control);
      if (control instanceof FormGroup) {
        console.log(`control instanceof FormGroup`);
      } else if (control instanceof FormControl && control.invalid) {
        console.log(`FormControl mit dem Namen ${key} ist invalid.`);
      }
    });
  }

  fillDataIntoTableArrays(){

    this.allMaintenanceParameters = [];
    this.allIndividualMaintenanceParameters = [];

    for (let i = 1; i <= this.numberOfMaintenanceParameters; i++){
      this.allMaintenanceParameters.push({
        'nr': i,
        'component': this.maintenanceParametersFormMain.get('mainParaComp' + i)?.value,
        'subComponent': this.maintenanceParametersFormMain.get('mainParaSubComp' + i)?.value,
        'tooltip': this.maintenanceParametersFormMain.get('mainParaTooltip' + i)?.value,
        'idForPCS7Effort': 'mainParaPCS7' + i,
        'idForPCSNeoEffort': 'mainParaPCSNeo' + i,
        'idForExchangeMaterialCost': 'materialExchangeCost' + (i - 1)
      });
    }

    this.isAllIndividualParametersUpdating = true;
    for (let i = 1; i <= this.maxNumberOfIndividualMaintenanceParameters; i++){

      if (this.maintenanceParametersFormIndividual.get('individualIuWTaskComponent' + i)?.value?.length > 0){
        this.allIndividualMaintenanceParameters.push({
          'component': this.maintenanceParametersFormIndividual.get('individualIuWTaskComponent' + i)?.value,
          'comment': this.maintenanceParametersFormIndividual.get('individualIuWTaskComment' + i)?.value,
          'task': this.maintenanceParametersFormIndividual.get('individualIuWTask' + i)?.value,
          'effort': this.maintenanceParametersFormIndividual.get('individualIuWTaskEffort' + i)?.value,
          'materialCost': this.maintenanceParametersFormIndividual.get('individualIuWTaskMaterialCost' + i)?.value,
          'timesPerYear': this.maintenanceParametersFormIndividual.get('individualIuWTaskTimesPerYear' + i)?.value,
          'quantity': this.maintenanceParametersFormIndividual.get('individualIuWTaskQuantity' + i)?.value
        });
      }
    }

    this.isAllIndividualParametersUpdating = false;
  }
  async deleteTask(row: any){
    this.allIndividualMaintenanceParameters = this.allIndividualMaintenanceParameters.filter(value => value !== row);

    this.writeDataFromArrayToFormControlData();

    await this.update();
  }

  // Array with Individual Parameters has to be synced with FormControls to write the Data to Backend, since Array is just used for temp
  // data handling in Frontend
  writeDataFromArrayToFormControlData(){

    let taskNumber = 1;

    for (const individualParameter of this.allIndividualMaintenanceParameters){
      this.maintenanceParametersFormIndividual.get('individualIuWTaskComponent' + taskNumber)?.setValue(individualParameter.component);
      this.maintenanceParametersFormIndividual.get('individualIuWTask' + taskNumber)?.setValue(individualParameter.task);
      this.maintenanceParametersFormIndividual.get('individualIuWTaskComment' + taskNumber)?.setValue(individualParameter.comment);
      this.maintenanceParametersFormIndividual.get('individualIuWTaskEffort' + taskNumber)?.setValue(individualParameter.effort);
      this.maintenanceParametersFormIndividual.get('individualIuWTaskMaterialCost' + taskNumber)?.setValue(individualParameter.materialCost);
      this.maintenanceParametersFormIndividual.get('individualIuWTaskTimesPerYear' + taskNumber)?.setValue(individualParameter.timesPerYear);
      this.maintenanceParametersFormIndividual.get('individualIuWTaskQuantity' + taskNumber)?.setValue(individualParameter.timesPerYear);


      this.maintenanceParametersFormIndividual.get('individualIuWTaskComponent' + taskNumber)?.markAsDirty();
      this.maintenanceParametersFormIndividual.get('individualIuWTask' + taskNumber)?.markAsDirty();
      this.maintenanceParametersFormIndividual.get('individualIuWTaskComment' + taskNumber)?.markAsDirty();
      this.maintenanceParametersFormIndividual.get('individualIuWTaskEffort' + taskNumber)?.markAsDirty();
      this.maintenanceParametersFormIndividual.get('individualIuWTaskMaterialCost' + taskNumber)?.markAsDirty();
      this.maintenanceParametersFormIndividual.get('individualIuWTaskTimesPerYear' + taskNumber)?.markAsDirty();
      this.maintenanceParametersFormIndividual.get('individualIuWTaskQuantity' + taskNumber)?.markAsDirty();
      taskNumber++;
    }

    // Fill up to the set Maximum number of tasks with empty values

    for (taskNumber; taskNumber <= this.maxNumberOfIndividualMaintenanceParameters; taskNumber++){
      this.maintenanceParametersFormIndividual.get('individualIuWTaskComponent' + taskNumber)?.setValue('');
      this.maintenanceParametersFormIndividual.get('individualIuWTask' + taskNumber)?.setValue('');
      this.maintenanceParametersFormIndividual.get('individualIuWTaskComment' + taskNumber)?.setValue('');
      this.maintenanceParametersFormIndividual.get('individualIuWTaskEffort' + taskNumber)?.setValue(0);
      this.maintenanceParametersFormIndividual.get('individualIuWTaskMaterialCost' + taskNumber)?.setValue(0);

      this.maintenanceParametersFormIndividual.get('individualIuWTaskTimesPerYear' + taskNumber)?.setValue(0);
      this.maintenanceParametersFormIndividual.get('individualIuWTaskQuantity' + taskNumber)?.setValue(0);

      this.maintenanceParametersFormIndividual.get('individualIuWTaskComponent' + taskNumber)?.markAsDirty();
      this.maintenanceParametersFormIndividual.get('individualIuWTask' + taskNumber)?.markAsDirty();
      this.maintenanceParametersFormIndividual.get('individualIuWTaskComment' + taskNumber)?.markAsDirty();
      this.maintenanceParametersFormIndividual.get('individualIuWTaskEffort' + taskNumber)?.markAsDirty();
      this.maintenanceParametersFormIndividual.get('individualIuWTaskMaterialCost' + taskNumber)?.markAsDirty();

      this.maintenanceParametersFormIndividual.get('individualIuWTaskTimesPerYear' + taskNumber)?.markAsDirty();
      this.maintenanceParametersFormIndividual.get('individualIuWTaskQuantity' + taskNumber)?.setValue(0);
    }
  }

  async update(){
    await this.submitValues();
  }

  async submitAndClose(){

    await this.submitValues();
    this.modalRef.hide();
  }

  async readData() {

    const keysFromDialog = await this.formDataService.getAllControlsFromDialogAsArray(this.maintenanceParametersForm);

    const formData = await this.dataTransferService.getValuesFromExcel(keysFromDialog) as any;

    this.formDataService.fillDataIntoDialog(this.maintenanceParametersForm, formData);
  }

  async submitValues(){
    const jsonData = await this.formDataService.getDataFromDialog(this.maintenanceParametersForm);

    console.log(jsonData);

    await this.dataTransferService.writeDataToExcel(JSON.stringify(jsonData));

  }

  async checkNotValid(){

    // eslint-disable-next-line guard-for-in
    for (const field in this.maintenanceParametersForm.controls) {
      const subForm = this.maintenanceParametersForm.get(field) as FormGroup;

      for (const subField in subForm.controls) {

        if (!subForm.get(subField)!.valid){
          console.log(subField);
        }
      }
    }

  }

}
