import { Component } from '@angular/core';
import { routerFadeAnimation } from '@simpl/newton-ng/tools';
import { UserManagementService } from './services/user-management.service';
import { ProjectManagementService } from './services/project-management.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  animations: [routerFadeAnimation]
})
export class AppComponent {
  title = 'app';


  public searchChanged(searchTerm: string): void {
    console.log('Searching for Sugar Man: ', searchTerm);
  }

  getRouteAnimation(outlet: any): any {
    return (outlet.activated ? outlet.activatedRoute : null);
  }

  constructor(public userManagementService: UserManagementService, public projectManagementService: ProjectManagementService){}


}
