<form [formGroup]="parentForm" style="margin: auto;">
  <div formGroupName="generalConfiguration">
    <h5 class="subSectionHeader">General
      <i style="float: right; cursor: pointer"
      [ngClass]="{'bi-arrow-up-circle-fill': isThisSectionVisible, 'bi-arrow-down-circle-fill': !isThisSectionVisible}"
      (click)="toggleVisibility()"></i>
    </h5>
    <div *ngIf="isThisSectionVisible">
      <div class="row">
        <div class="col-lg-2, col-md-3, col-sm-4">
          <si-newton-form-group label="No. of plant sections" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers are allowed here'}">
            <input formControlName="numOfPlantSections" type="number" [min]="0" siNewton>
          </si-newton-form-group>
        </div>
      </div>
    </div>

  </div>
</form>

