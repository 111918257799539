import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-sw-maintenance-cost-overview',
  templateUrl: './sw-maintenance-cost-overview.component.html',
  styleUrls: ['./sw-maintenance-cost-overview.component.scss']
})
export class SwMaintenanceCostOverviewComponent implements OnInit {

  
  @Input()
  public parentForm!: FormGroup;

  stringToNumber(num: string){
    return parseInt(num, 10);
  }

  
  
  constructor() { }

  ngOnInit(): void {
  }

}
