import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormDataService } from 'src/app/services/form-data.service';

export interface DropDownOption {
  value: string;
}

@Component({
  selector: 'app-general-project-parameter',
  templateUrl: './general-project-parameter.component.html',
  styleUrls: ['./general-project-parameter.component.scss']
})
export class GeneralProjectParameterComponent {

  @Input()
  public parentForm!: FormGroup;

  constructor(private formDataService: FormDataService) {}

  public getDropdownOptions(formControlName: string){
    return this.formDataService.getDropDownOptions(formControlName);
  }

  public getDropdownValue(dropDownOption: DropDownOption): string {
    return dropDownOption.value;
  }

}
