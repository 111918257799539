/* eslint-disable max-len */
import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SiNewtonLoadingService } from '@simpl/newton-ng/loading-spinner';
import { FormDataService } from 'src/app/services/form-data.service';

export interface DropDownOption {
  value: string;
}

@Component({
  selector: 'app-contractual-parameters',
  templateUrl: './contractual-parameters.component.html',
  styleUrls: ['./contractual-parameters.component.scss']
})
export class ContractualParametersComponent implements AfterViewInit {

  @Input()
  public parentForm!: FormGroup;

  @Output()
  submitValues: EventEmitter<any> = new EventEmitter();

  lcsStartYear!: number;
  lcsContractDuration!: number;
  yearsArray!: number[];
  pcsNeoEvolution: number = 0;

  startWithPcs7OrPcsneo: string;

  // Arrays for selected Checkboxs
  selectedPcs7Upgrades: boolean[] = [];
  selectedNeoHWUpgrades: boolean[] = [];
  selectedNeoSWUpgrades: boolean[] = [];
  selectedPCS7SwUpdates: boolean[] = [];
  selectedNeoSwUpdates: boolean[] = [];

  constructor(private formDataService: FormDataService, private loadingSpinner: SiNewtonLoadingService) {
    this.startWithPcs7OrPcsneo = this.parentForm?.get('contractualParameters')?.get('startWithPcs7OrPcsNeo')?.value?.value;

  }

  ngAfterViewInit(){

    if (this.parentForm.get('contractualParameters')?.get('pcsNeoEvolution')?.value === null){
      this.parentForm.get('contractualParameters')?.get('pcsNeoEvolution')?.setValue(this.pcsNeoEvolution || 0);
    }
  }


  yearOfEvolutionChanged(event: any){

    console.log('yearOfEvolutionChanged');
    this.pcsNeoEvolution = Number(this.parentForm.get('contractualParameters')?.get('pcsNeoEvolution')?.value);

    console.log(this.pcsNeoEvolution);

    this.checkContractTimelinePlausibility();

  }

  checkContractTimelinePlausibility(){

    console.log('checkContractTimelinePlausibility');

    this.startWithPcs7OrPcsneo = this.parentForm.get('contractualParameters')?.get('startWithPcs7OrPcsNeo')?.value.value;

    if (this.parentForm.get('contractualParameters')?.get('pcsNeoEvolution')?.value === null){

      this.pcsNeoEvolution = 0;
      
      this.parentForm.get('contractualParameters')?.get('pcsNeoEvolution')?.setValue(0);
      this.parentForm.get('contractualParameters')?.get('pcsNeoEvolution')?.markAsDirty();

    }

    if (this.startWithPcs7OrPcsneo === 'PCS neo'){
      if (this.pcsNeoEvolution !== 0){
        this.parentForm.get('contractualParameters')?.get('pcsNeoEvolution')?.setValue(0);
        this.parentForm.get('contractualParameters')?.get('pcsNeoEvolution')?.markAsDirty();
      };

      // Set selected PCS 7 HW Upgrades in FormControl to 0
      for (let i = 1; i <= 3; i++){

        this.parentForm.get('contractualParameters')?.get('pcs7UpgradeYear' + i)?.setValue(0);
        this.parentForm.get('contractualParameters')?.get('pcs7UpgradeYear' + i)?.markAsDirty();

      }

      // Update UI Checkboxes
      this.fillSelectedYears('pcs7UpgradeYear', 3);

      // Set selected PCS 7 SW Updates in FormControl to 0
      for (let i = 1; i <= 25; i++){
        this.parentForm.get('contractualParameters')?.get('pcs7SwUpdateYear' + i)?.setValue(0);
        this.parentForm.get('contractualParameters')?.get('pcs7SwUpdateYear' + i)?.markAsDirty();
      }

      // Update UI Checkboxes
      this.fillSelectedYears('pcs7SwUpdateYear', 25);

    } else if (this.startWithPcs7OrPcsneo === 'PCS 7'){

      // Set PCS 7 Software HW Uphgade Years to 0 if Year is greater than or equal PCS neo Evolution
      for (let i = 1; i <= 3; i++){

        if (Number(this.parentForm.get('contractualParameters')?.get('pcs7UpgradeYear' + i)?.value) >= this.pcsNeoEvolution && this.pcsNeoEvolution !== 0){
          this.parentForm.get('contractualParameters')?.get('pcs7UpgradeYear' + i)?.setValue(0);
          this.parentForm.get('contractualParameters')?.get('pcs7UpgradeYear' + i)?.markAsDirty();
        }
      }

      // Update UI Checkboxes

      this.fillSelectedYears('pcs7Upgrade', 3);

      // Set PCS 7 Software Update Years to 0 if Year is greater than or equal PCS neo Evolution
      for (let i = 1; i <= 25; i++){

        if (Number(this.parentForm.get('contractualParameters')?.get('pcs7SwUpdateYear' + i)?.value) >= this.pcsNeoEvolution && this.pcsNeoEvolution !== 0){
          this.parentForm.get('contractualParameters')?.get('pcs7SwUpdateYear' + i)?.setValue(0);
          this.parentForm.get('contractualParameters')?.get('pcs7SwUpdateYear' + i)?.markAsDirty();
        }
      }

      // Update UI Checkboxes

      this.fillSelectedYears('pcs7SwUpdate', 25);

      // Set PCS 7 Neo HW Upgrade Years to 0 if Year is less than or equal PCS neo Evolution
      for (let i = 1; i <= 3; i++){

        if (Number(this.parentForm.get('contractualParameters')?.get('pcsNeoHwUpgradeYear' + i)?.value) <= this.pcsNeoEvolution && this.pcsNeoEvolution !== 0){
          this.parentForm.get('contractualParameters')?.get('pcsNeoHwUpgradeYear' + i)?.setValue(0);
          this.parentForm.get('contractualParameters')?.get('pcsNeoHwUpgradeYear' + i)?.markAsDirty();
        }
      }

      // Update UI Checkboxes

      this.fillSelectedYears('pcsNeoHwUpgrade', 3);

      // Set PCS 7 Neo SW Upgrade Years to 0 if Year is less than or equal PCS neo Evolution
      for (let i = 1; i <= 3; i++){

        if (Number(this.parentForm.get('contractualParameters')?.get('pcsNeoSwUpgradeYear' + i)?.value) <= this.pcsNeoEvolution && this.pcsNeoEvolution !== 0){
          this.parentForm.get('contractualParameters')?.get('pcsNeoSwUpgradeYear' + i)?.setValue(0);
          this.parentForm.get('contractualParameters')?.get('pcsNeoSwUpgradeYear' + i)?.markAsDirty();
        }
      }

      // Update UI Checkboxes

      this.fillSelectedYears('pcsNeoSwUpgrade', 3);

      // Set PCS 7 Neo SW Update Years to 0 if Year is less than or equal PCS neo Evolution
      for (let i = 1; i <= 25; i++){

        if (Number(this.parentForm.get('contractualParameters')?.get('pcsNeoSwUpdateYear' + i)?.value) <= this.pcsNeoEvolution && this.pcsNeoEvolution !== 0){
          this.parentForm.get('contractualParameters')?.get('pcsNeoSwUpdateYear' + i)?.setValue(0);
          this.parentForm.get('contractualParameters')?.get('pcsNeoSwUpdateYear' + i)?.markAsDirty();
        }
      }

      // Update UI Checkboxes

      this.fillSelectedYears('pcsNeoSwUpdate', 25);

    }

  }

  onCheckboxChange(event: any, type: 'pcs7Upgrade' | 'pcsNeoHwUpgrade' | 'pcsNeoSwUpgrade' | 'pcs7SwUpdate' | 'pcsNeoSwUpdate', index: number) {

    this.loadingSpinner.startLoading();

    const isChecked = event.target.checked;

    switch (type) {
      case 'pcs7Upgrade':
        this.selectedPcs7Upgrades[index] = isChecked;
        this.updateFormControlsWithSelectedYears(this.selectedPcs7Upgrades, type);
        break;
      case 'pcsNeoHwUpgrade':
        this.selectedNeoHWUpgrades[index] = isChecked;
        this.updateFormControlsWithSelectedYears(this.selectedNeoHWUpgrades, type);
        break;
      case 'pcsNeoSwUpgrade':
        this.selectedNeoSWUpgrades[index] = isChecked;
        this.updateFormControlsWithSelectedYears(this.selectedNeoSWUpgrades, type);
        break;
      case 'pcs7SwUpdate':
        this.selectedPCS7SwUpdates[index] = isChecked;
        this.updateFormControlsWithSelectedYears(this.selectedPCS7SwUpdates, type);
        break;
      case 'pcsNeoSwUpdate':
        this.selectedNeoSwUpdates[index] = isChecked;
        this.updateFormControlsWithSelectedYears(this.selectedNeoSwUpdates, type);
        break;
    }

    this.loadingSpinner.stopLoading();

  }

  updateFormControlsWithSelectedYears(checkboxArray: boolean[], varBaseName: string){
    const selectedYears = this.yearsArray.filter((year, i) => checkboxArray[i]);

    for (let i = 1; i <= 25; i++){
      this.parentForm.get('contractualParameters')?.get(varBaseName + 'Year' + (i))?.setValue(selectedYears[i-1] || 0);
      this.parentForm.get('contractualParameters')?.get(varBaseName + 'Year' + (i))?.markAsDirty();
    }
  }

  async updateContractTime(){
    this.loadingSpinner.startLoading();
    this.lcsStartYear = Number(this.parentForm.get('contractualParameters')?.get('yearOfStartFix')?.value);
    this.lcsContractDuration = Number(this.parentForm.get('contractualParameters')?.get('lcsContractDuration')?.value.value);
    this.yearsArray = Array.from({
      length: this.lcsContractDuration
    }, (_, i) => Number(this.lcsStartYear) + i);

    this.pcsNeoEvolution = Number(this.parentForm.get('contractualParameters')?.get('pcsNeoEvolution')?.value);

    this.checkIfPcsNeoEvolutionYearIsValid();

    this.loadingSpinner.stopLoading();
  }

  checkIfPcsNeoEvolutionYearIsValid(){
    if (this.pcsNeoEvolution === 0 || !isNaN(this.pcsNeoEvolution)){
      return;
    }
    if ((this.pcsNeoEvolution > 0 && this.pcsNeoEvolution < this.lcsStartYear) || this.pcsNeoEvolution > (this.lcsStartYear + this.lcsContractDuration - 1)){
      this.parentForm.get('contractualParameters')?.get('pcsNeoEvolution')?.setValue(0);
      this.parentForm.get('contractualParameters')?.get('pcsNeoEvolution')?.markAsDirty();
      this.submitValues.emit();
    }
  }

  fillSelectedYears(varBaseName: string, amountOfPossibleSelectedYears: number){

    const tempIndices = [];

    for (let i = 0; i <= amountOfPossibleSelectedYears; i++){
      const yearValue = Number(this.parentForm.get('contractualParameters')?.get(varBaseName + 'Year' + (i + 1))?.value);

      if (this.yearsArray && !isNaN(yearValue)) {
        const index = this.yearsArray.indexOf(yearValue);
        tempIndices.push(index);
      }
    }

    // Check if years are valid regarding PCS neo Evolution year. Only then checkboxes are selected
    switch (varBaseName) {
      case 'pcs7Upgrade':
        this.selectedPcs7Upgrades = [];
        tempIndices.forEach(index => {
          if (this.yearsArray[index] < this.pcsNeoEvolution || this.pcsNeoEvolution === 0){
            this.selectedPcs7Upgrades[index] = true;
          } else {
            this.selectedPcs7Upgrades[index] = false;
          }
        });
        break;
      case 'pcsNeoHwUpgrade':
        this.selectedNeoHWUpgrades = [];
        tempIndices.forEach(index => {
          if (this.yearsArray[index] > this.pcsNeoEvolution || this.pcsNeoEvolution === 0){
            this.selectedNeoHWUpgrades[index] = true;
          } else {
            this.selectedNeoHWUpgrades[index] = false;
          }
        });
        break;
      case 'pcsNeoSwUpgrade':
        this.selectedNeoSWUpgrades = [];
        tempIndices.forEach(index => {
          if (this.yearsArray[index] > this.pcsNeoEvolution || this.pcsNeoEvolution === 0){
            this.selectedNeoSWUpgrades[index] = true;
          } else {
            this.selectedNeoHWUpgrades[index] = false;
          }
        });
        break;
      case 'pcs7SwUpdate':
        this.selectedPCS7SwUpdates = [];
        tempIndices.forEach(index => {
          if (this.yearsArray[index] < this.pcsNeoEvolution || this.pcsNeoEvolution === 0){
            this.selectedPCS7SwUpdates[index] = true;
          } else {
            this.selectedPCS7SwUpdates[index] = false;
          }
        });
        break;
      case 'pcsNeoSwUpdate':
        this.selectedNeoSwUpdates = [];
        tempIndices.forEach(index => {
          if (this.yearsArray[index] > this.pcsNeoEvolution || this.pcsNeoEvolution === 0){
            this.selectedNeoSwUpdates[index] = true;
          } else {
            this.selectedNeoSwUpdates[index] = false;
          }
        });
        break;
    }


  }
  
  public getDropdownOptions(formControlName: string){
    return this.formDataService.getDropDownOptions(formControlName);
  }

  public getDropdownValue(dropDownOption: DropDownOption): string {
    return dropDownOption.value;
  }

}
