import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SiNewtonLoadingService } from '@simpl/newton-ng/loading-spinner';
import { RemoteServiceModule } from 'src/app/data-models/RemoteServiceModule';
import { DataTransferService } from 'src/app/services/data-transfer.service';
import { FormDataService } from 'src/app/services/form-data.service';
import { ProductPriceServiceService } from 'src/app/services/product-price-service.service';
import uiConfig from 'src/app/config/ui-config.json';

export interface DropDownOption {
  value: string;
}

interface KeyValuePair {
  [key: string]: string | number | boolean;
}

@Component({
  selector: 'app-remote-service-modules',
  templateUrl: './remote-service-modules.component.html',
  styleUrls: ['./remote-service-modules.component.scss']
})
export class RemoteServiceModulesComponent {

  isThisSectionVisible = false;

  toggleVisibility(){
    console.log(this.isThisSectionVisible);
    this.isThisSectionVisible = !this.isThisSectionVisible;
  }

  @Input()
  parentForm!: FormGroup;

  @Output()
  readData: EventEmitter<any> = new EventEmitter();

  @Output()
  submitValues: EventEmitter<any> = new EventEmitter();

  allRemoteServiceModules: RemoteServiceModule[] = [];
  areNullValuesInPricesPresent = false;

  constructor(private formDataService: FormDataService, private loadingSpinner: SiNewtonLoadingService,
    private dataTransferService: DataTransferService, private productPriceService: ProductPriceServiceService) { }

  public getDropdownOptions(formControlName: string){
    return this.formDataService.getDropDownOptions(formControlName);
  }

  public getDropdownValue(dropDownOption: DropDownOption): string {
    return dropDownOption.value;
  }

  async fillTableWithServiceModules(){
    this.allRemoteServiceModules = [];

    this.areNullValuesInPricesPresent = false;

    for (let i = 1; i <= uiConfig.remoteServiceModules.maxNumberOfServices; i++){

      if (this.isRemoteServiceModuleSelected(i)){

        this.allRemoteServiceModules.push({
          'serviceNumber': i,
          'serviceName': this.parentForm.get('remoteServiceModules')?.get('remoteServiceName' + i)?.value,
          'serviceDescription': this.parentForm.get('remoteServiceModules')?.get('remoteServiceDescription' + i)?.value,
          'mlfb': this.parentForm.get('remoteServiceModules')?.get('remoteServiceMLFB' + i)?.value,
          'quantity': this.parentForm.get('remoteServiceModules')?.get('remoteServiceQuantity' + i)?.value,
          'serviceCost': this.parentForm.get('remoteServiceModules')?.get('remoteServiceCost' + i)?.value,
          'period': this.parentForm.get('remoteServiceModules')?.get('remoteServicePeriod' + i)?.value,
          'serviceSelected': this.parentForm.get('remoteServiceModules')?.get('remoteServiceSelected' + i)?.value
        });

        if (!this.parentForm.get('remoteServiceModules')?.get('remoteServiceCost' + i) ||
          this.parentForm.get('remoteServiceModules')?.get('remoteServiceCost' + i)?.value === '0'){
          this.areNullValuesInPricesPresent = true;
        }
      }
    }

  }

  isNewServiceValid(){

    try {
      if (!this.parentForm.get('remoteServiceModules')?.get('newRemoteServicePeriod')?.value.value){
        return false;
      } else if (!this.parentForm.get('remoteServiceModules')?.get('newRemoteServiceMlfb')?.value){
        return false;
      } else if (!this.parentForm.get('remoteServiceModules')?.get('newRemoteServiceQuantity')?.value){
        return false;
      } else if (!this.parentForm.get('remoteServiceModules')?.get('newRemoteServiceName')?.value){
        return false;
      } else {
        return true;
      }
    } catch {
      return false;
    }
  }

  async updateRemoteServiceList(){

    this.loadingSpinner.startLoading();
    const updatedRemoteServices: KeyValuePair = {};

    for (let i = 1; i <= this.allRemoteServiceModules.length; i++){

      updatedRemoteServices['remoteServiceName' + i] = this.allRemoteServiceModules[i - 1].serviceName;
      updatedRemoteServices['remoteServiceDescription' + i] = this.allRemoteServiceModules[i - 1].serviceDescription;
      updatedRemoteServices['remoteServiceMLFB' + i] = this.allRemoteServiceModules[i - 1].mlfb;
      updatedRemoteServices['remoteServiceQuantity' + i] = this.allRemoteServiceModules[i - 1].quantity;
      updatedRemoteServices['remoteServiceCost' + i] = this.allRemoteServiceModules[i - 1].serviceCost;
      updatedRemoteServices['remoteServicePeriod' + i] = this.allRemoteServiceModules[i - 1].period;
      updatedRemoteServices['remoteServiceSelected' + i] = this.allRemoteServiceModules[i - 1].serviceSelected;



      if (i === this.allRemoteServiceModules.length){
        await this.addNewService(updatedRemoteServices, (i + 1));
      }
    }

    if (this.allRemoteServiceModules.length === 0){
      await this.addNewService(updatedRemoteServices, 1);
    }

    await this.dataTransferService.writeDataToExcel(JSON.stringify(updatedRemoteServices));

    this.loadingSpinner.stopLoading();
    this.readData.emit();

  }

  async addNewService(updatedRemoteServices: KeyValuePair, id: number){

    const mlfbNewService = this.parentForm.get('remoteServiceModules')?.get('newRemoteServiceMlfb')?.value;

    updatedRemoteServices['remoteServiceName' + id] =
      this.parentForm.get('remoteServiceModules')?.get('newRemoteServiceName')?.value;

    updatedRemoteServices['remoteServiceDescription' + id] =
      this.parentForm.get('remoteServiceModules')?.get('newRemoteServiceDescription')?.value;

    updatedRemoteServices['remoteServiceMLFB' + id] = mlfbNewService;

    updatedRemoteServices['remoteServiceQuantity' + id] =
      this.parentForm.get('remoteServiceModules')?.get('newRemoteServiceQuantity')?.value;

    updatedRemoteServices['remoteServicePeriod' + id] =
      this.parentForm.get('remoteServiceModules')?.get('newRemoteServicePeriod')?.value.value;

    updatedRemoteServices['remoteServiceCost' + id] = 0;
  
    updatedRemoteServices['remoteServiceSelected' + id] = true;

    const priceMflbList =
      await this.productPriceService.getCurrentPricesByMlfbList(mlfbNewService) as any;

    updatedRemoteServices['remoteServiceCost' + id] = priceMflbList[mlfbNewService] || 0;

    await this.dataTransferService.writeDataToExcel(JSON.stringify(updatedRemoteServices));

    this.readData.emit();



  }

  isRemoteServiceModuleSelected(compNumber: number): boolean {

    try {
      if (this.parentForm.get('remoteServiceModules')?.get('remoteServiceName' + compNumber)?.value){
        return true;
      } else {
        return false;
      }
    } catch {
      return false;
    }
  }

  async deleteService(serviceNumber: number){


    
    const updatedRemoteServiceList: KeyValuePair = {};

    console.log(serviceNumber);
    console.log(this.allRemoteServiceModules.length);
    for (let i = serviceNumber; i <= this.allRemoteServiceModules.length; i++){
      console.log(i);
      // Delete if i === this.allRemoteServiceModules.length
      if (i === this.allRemoteServiceModules.length){
        updatedRemoteServiceList['remoteServiceName' + i] = '';
        updatedRemoteServiceList['remoteServiceDescription' + i] = '';
        updatedRemoteServiceList['remoteServiceMLFB' + i] = '';
        updatedRemoteServiceList['remoteServiceQuantity' + i] = 0;
        updatedRemoteServiceList['remoteServiceCost' + i] = 0;
        updatedRemoteServiceList['remoteServicePeriod' + i] = '';
        updatedRemoteServiceList['remoteServiceSelected' + i] = false;

      } else {

        updatedRemoteServiceList['remoteServiceName' + i] =
          this.parentForm.get('remoteServiceModules')?.get('remoteServiceName' + (i + 1))?.value;
        updatedRemoteServiceList['remoteServiceDescription' + i] =
          this.parentForm.get('remoteServiceModules')?.get('remoteServiceDescription' + (i + 1))?.value;
        updatedRemoteServiceList['remoteServiceMLFB' + i] =
          this.parentForm.get('remoteServiceModules')?.get('remoteServiceMLFB' + (i + 1))?.value;
        updatedRemoteServiceList['remoteServiceQuantity' + i] =
          this.parentForm.get('remoteServiceModules')?.get('remoteServiceQuantity' + (i + 1))?.value || 0;
        updatedRemoteServiceList['remoteServiceCost' + i] =
          this.parentForm.get('remoteServiceModules')?.get('remoteServiceCost' + (i + 1))?.value || 0;

        updatedRemoteServiceList['remoteServicePeriod' + i] =
          this.parentForm.get('remoteServiceModules')?.get('remoteServicePeriod' + (i + 1))?.value;
        updatedRemoteServiceList['remoteServiceSelected' + i] =
          this.parentForm.get('remoteServiceModules')?.get('remoteServiceSelected' + (i + 1))?.value;
      }

    }

    await this.dataTransferService.writeDataToExcel(JSON.stringify(updatedRemoteServiceList));

    this.readData.emit();
  }

  async getPricesForAllMlfbs(){

    this.loadingSpinner.startLoading();

    let mlfbElementsCommaSeperated = '';
    for (const remoteService of this.allRemoteServiceModules){
      mlfbElementsCommaSeperated += remoteService.mlfb + ',';
    }

    const priceMflbList =
      await this.productPriceService.getCurrentPricesByMlfbList(mlfbElementsCommaSeperated.replace(/.$/, '')) as any;

    for (const remoteService of this.allRemoteServiceModules){

      if (priceMflbList[remoteService.mlfb] !== undefined && priceMflbList[remoteService.mlfb] !== remoteService.serviceCost){
        // eslint-disable-next-line max-len
        this.parentForm.get('remoteServiceModules')!.get('remoteServiceCost' + (remoteService.serviceNumber))!.setValue(priceMflbList[remoteService.mlfb]);
        this.parentForm.get('remoteServiceModules')!.get('remoteServiceCost' + (remoteService.serviceNumber))!.markAsDirty();
        this.parentForm.get('remoteServiceModules')!.get('remoteServiceCost' + (remoteService.serviceNumber))!.markAsUntouched();
      }
    }

    this.loadingSpinner.stopLoading();

    this.submitValues.emit();
  }


}
