import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-project-deletion-modal',
  templateUrl: './project-deletion-modal.component.html',
  styleUrls: ['./project-deletion-modal.component.scss']
})
export class ProjectDeletionModalComponent {

  projectName!: string;

  constructor(public modalRef: BsModalRef) {}

  acceptDelete(){
    this.modalRef.content.confirm = true;
    this.modalRef.hide();
  }
}
