<form [formGroup]="parentForm">
  <div formGroupName="costFactorsInternal">

    <h5 class="subSectionHeader">Cost Factors (internal)</h5>
    <div class="row">
      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">

        <si-newton-form-group label="Service Cost for plannable measures onsite" [errorResolverMap]="{required: 'Value is required'}">
          <div class="input-group-prepend">
            <span class="input-group-text">€ / h</span>
          </div>
          <input formControlName="serviceCostFactor" type="number" step="any" [min]="0"siNewton>
        </si-newton-form-group>
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <si-newton-form-group label="Engineering Cost (inhouse)" [errorResolverMap]="{required: 'Value is required'}">
          <div class="input-group-prepend">
            <span class="input-group-text">€ / h</span>
          </div>
          <input formControlName="engineeringCostInhouse" type="number" step="any" [min]="0" siNewton>
        </si-newton-form-group>
      </div>

    </div>

  </div>
</form>
