import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SiNewtonLoadingService } from '@simpl/newton-ng/loading-spinner';
import { PhysicalSystemComponentModel } from 'src/app/data-models/PhysicalSystemComponent';
import { DataTransferService } from 'src/app/services/data-transfer.service';
import { FormDataService } from 'src/app/services/form-data.service';
import { ProductPriceServiceService } from 'src/app/services/product-price-service.service';
import { UiElements } from 'src/app/const-data/ui-elements';

export interface DropDownOption {
  value: string;
  type?: string
}

interface KeyValuePair {
  [key: string]: string | number;
}

@Component({
  selector: 'app-physical-system',
  templateUrl: './physical-system.component.html',
  styleUrls: ['./physical-system.component.scss']
})
export class PhysicalSystemComponent {
  @ViewChild('fileSelection', { static: false })
  fileSelection: ElementRef<HTMLInputElement> | undefined;

  @ViewChild('neoFileSelection', { static: false })
  neoFileSelection: ElementRef<HTMLInputElement> | undefined;

  @ViewChild('plantStructurefileSelectionPcs7', { static: false })
  plantStructurefileSelectionPcs7: ElementRef<HTMLInputElement> | undefined;

  @ViewChild('plantStructurefileSelectionPcsNeo', { static: false })
  plantStructurefileSelectionPcsNeo: ElementRef<HTMLInputElement> | undefined;

  bomFile: File | undefined;
  plantStructureFile: File | undefined;

  @Input()
  parentForm!: FormGroup;

  @Output()
  readData: EventEmitter<any> = new EventEmitter();

  @Output()
  submitValues: EventEmitter<any> = new EventEmitter();

  isThisSectionVisible = false;

  toggleVisibility(){
    this.isThisSectionVisible = !this.isThisSectionVisible;
  }

  // Variables for PCS 7 System
  allPhysicalSystemComponents: PhysicalSystemComponentModel[] = [];
  allPhysicalSystemComponentsFiltered: PhysicalSystemComponentModel[] = [];
  areNullValuesInPricesPresent = false;
  // hideNotRelevantComponents = false;

  // Variables for PCS neo System
  allPhysicalSystemNeoComponents: PhysicalSystemComponentModel[] = [];
  areNullValuesInNeoPricesPresent = false;

  constructor(private dataTransferService: DataTransferService, private productPriceService: ProductPriceServiceService,
    private loadingSpinner: SiNewtonLoadingService, private formDataService: FormDataService) { }

  // filterComponentList(checked: boolean){

  //   this.hideNotRelevantComponents = checked;
  //   console.log(this.hideNotRelevantComponents);
  //   if (this.hideNotRelevantComponents){
  //     this.allPhysicalSystemComponentsFiltered =
  //       this.allPhysicalSystemComponents.filter(element => element.componentType !== 'Not relevant for LCS');
  //   } else {
  //     this.allPhysicalSystemComponentsFiltered = this.allPhysicalSystemComponents;
  //   }
  // }

  async downloadFile(fileType: string){
    this.loadingSpinner.startLoading();
    await this.dataTransferService.downloadFile(fileType);
    this.loadingSpinner.stopLoading();
  }

  async generateBomFile(pcsVersion: string){

    this.loadingSpinner.startLoading();


    let bomData: PhysicalSystemComponentModel[] = [];

    if(pcsVersion == 'PCS 7'){
      bomData = this.allPhysicalSystemComponents;
    } else if (pcsVersion == 'PCS neo'){
      bomData = this.allPhysicalSystemNeoComponents;
    }

    const projectName = sessionStorage.getItem('SelectedProjectName')?.toString() || 'LCS-Calculation';
    await this.dataTransferService.generateBomFile(projectName, pcsVersion, bomData);

    this.loadingSpinner.stopLoading();


  }

  public getNumOfUnclassifiedElements(pcsVersion: string): number{

    let physicalSystemComponentDropdownVar = '';

    if (pcsVersion === 'PCS neo'){
      physicalSystemComponentDropdownVar = 'physicalSystemPcsNeoComponentTypesDropdown';
    } else {
      physicalSystemComponentDropdownVar = 'physicalSystemPCS7ComponentTypesDropdown';
    }

    const componentTypeDropdownData = UiElements.dropDowns.find(dropdown => dropdown.element === physicalSystemComponentDropdownVar);
    const possibleComponentTypes = componentTypeDropdownData ? componentTypeDropdownData.options.map((option: DropDownOption) => option.value) : [];

    let unclassifiedElements = [];


    if (pcsVersion === 'PCS neo'){
      unclassifiedElements = this.allPhysicalSystemNeoComponents.filter(element =>
        !possibleComponentTypes.includes(element.componentType) || element.componentType === 'Unclassified'
        || element.componentType === 'IPC Unclassified');
    } else {
      unclassifiedElements = this.allPhysicalSystemComponents.filter(element =>
        !possibleComponentTypes.includes(element.componentType) || element.componentType === 'Unclassified'
        || element.componentType === 'IPC Unclassified');

    }

    return unclassifiedElements.length;
  }

  public getNumOfNotRelevantElements(pcsVersion: string): number{
    let notRelevantElements = [];

    if (pcsVersion === 'PCS neo'){
      notRelevantElements = this.allPhysicalSystemNeoComponents.filter(element =>
        element.componentType === 'Not relevant for LCS');
    } else {
      notRelevantElements = this.allPhysicalSystemComponents.filter(element =>
        element.componentType === 'Not relevant for LCS');

    }

    return notRelevantElements.length;
  }

  public getDropdownOptions(formControlName: string) : DropDownOption[]{
    return this.formDataService.getDropDownOptions(formControlName);
  }

  public getDropdownValue(dropDownOption: DropDownOption): string {
    return dropDownOption.value;
  }


  async storePCS7BomFile(){

    this.bomFile = this.fileSelection?.nativeElement.files?.item(0) || undefined;

  }

  async storePCSNeoBomFile(){

    this.bomFile = this.neoFileSelection?.nativeElement.files?.item(0) || undefined;

  }

  async storePlantStructureFileForPCS7(){
    this.plantStructureFile = this.plantStructurefileSelectionPcs7?.nativeElement.files?.item(0) || undefined;
  }

  async storePlantStructureFileForPCSNeo(){
    this.plantStructureFile = this.plantStructurefileSelectionPcsNeo?.nativeElement.files?.item(0) || undefined;

  }

  async uploadBOM(pcsVersion: string){

    if (this.bomFile !== undefined){

      const bomValues = await this.dataTransferService.importSystem(this.bomFile, pcsVersion);
      await this.setNewPhysicalSystem(bomValues);

      this.submitValues.emit();

    }
  }

  async detectIPCs(pcsVersion: string){

    try {
      if (this.plantStructureFile !== undefined){

        if(pcsVersion === 'PCS 7'){
  
          const newBomValues = await this.dataTransferService.detectIPCs(pcsVersion, this.allPhysicalSystemComponents, this.plantStructureFile) as any;
          await this.setNewPhysicalSystem(newBomValues);
  
          this.submitValues.emit();
  
        } else if(pcsVersion === 'PCS neo'){
          const newBomValues = await this.dataTransferService.detectIPCs(pcsVersion, this.allPhysicalSystemNeoComponents, this.plantStructureFile) as any;
          await this.setNewPhysicalSystem(newBomValues);
  
        this.submitValues.emit();
        }
  
      }
    } catch (err){
      console.log(err);
    }

  }

  isPhysicalSystemComponentPresent(compNumber: number, pcsVersion: string): boolean {

    let componentTypeVarName = '';
    if (pcsVersion === 'PCS neo'){
      componentTypeVarName = 'physicalSystemNeoComponentType';
    } else {
      componentTypeVarName = 'physicalSystemComponentType';
    }

    try {
      if (this.parentForm.get('physicalSystemBOM')?.get(componentTypeVarName + compNumber)?.value){
        return true;
      } else {
        return false;
      }
    } catch {
      return false;
    }
  }

  isNewComponentValid(pcsVersion: string){

    if (pcsVersion === 'PCS neo'){

      try {
        if (!this.parentForm.get('physicalSystemBOM')?.get('newNeoComponentType')?.value){
          return false;
        } else if (!this.parentForm.get('physicalSystemBOM')?.get('newNeoComponentMlfb')?.value){
          return false;
        } else if (!this.parentForm.get('physicalSystemBOM')?.get('newNeoComponentQuantity')?.value){
          return false;
        } else {
          return true;
        }
      } catch {
        return false;
      }
    } else {
      try {

        if (!this.parentForm.get('physicalSystemBOM')?.get('newComponentType')?.value){
          return false;
        } else if (!this.parentForm.get('physicalSystemBOM')?.get('newComponentMlfb')?.value){
          return false;
        } else if (!this.parentForm.get('physicalSystemBOM')?.get('newComponentQuantity')?.value){
          return false;
        } else {
          return true;
        }
      } catch {
        return false;
      }
    }

  }

  async fillTableWithPhysicalSystemComponents(){
    this.allPhysicalSystemComponents = [];
    this.allPhysicalSystemNeoComponents = [];

    this.areNullValuesInPricesPresent = false;

    this.areNullValuesInNeoPricesPresent = false;

    for (let i = 1; i <= 200; i++){

      if (this.isPhysicalSystemComponentPresent(i, 'PCS 7')){

        this.allPhysicalSystemComponents.push({
          'componentNumber': i,
          'componentType': this.parentForm.get('physicalSystemBOM')?.get('physicalSystemComponentType' + i)?.value,
          'mlfb': this.parentForm.get('physicalSystemBOM')?.get('physicalSystemMlfb' + i)?.value,
          'quantity': this.parentForm.get('physicalSystemBOM')?.get('physicalSystemQuantity' + i)?.value,
          'listPrice': this.parentForm.get('physicalSystemBOM')?.get('physicalSystemPrice' + i)?.value,
          'description': this.parentForm.get('physicalSystemBOM')?.get('physicalSystemDesc' + i)?.value
        });

        if (this.parentForm.get('physicalSystemBOM')?.get('physicalSystemPrice' + i)?.value === '0'){
          this.areNullValuesInPricesPresent = true;
        }

      }

      if (this.isPhysicalSystemComponentPresent(i, 'PCS neo')){

        this.allPhysicalSystemNeoComponents.push({
          'componentNumber': i,
          'componentType': this.parentForm.get('physicalSystemBOM')?.get('physicalSystemNeoComponentType' + i)?.value,
          'mlfb': this.parentForm.get('physicalSystemBOM')?.get('physicalSystemNeoMlfb' + i)?.value,
          'quantity': this.parentForm.get('physicalSystemBOM')?.get('physicalSystemNeoQuantity' + i)?.value,
          'listPrice': this.parentForm.get('physicalSystemBOM')?.get('physicalSystemNeoPrice' + i)?.value,
          'description': this.parentForm.get('physicalSystemBOM')?.get('physicalSystemNeoDesc' + i)?.value
        });


        if (this.parentForm.get('physicalSystemBOM')?.get('physicalSystemNeoPrice' + i)?.value === '0'){
          this.areNullValuesInNeoPricesPresent = true;
        }

      }
    }

    this.allPhysicalSystemComponentsFiltered = this.allPhysicalSystemComponents;

  }

  async deleteComponent(componentNumber: number, pcsVersion: string){

    const updatedPhysicalSystemComponents: KeyValuePair = {};

    if (pcsVersion === 'PCS neo'){
      for (let i = componentNumber; i <= this.allPhysicalSystemNeoComponents.length; i++){

        if (i === this.allPhysicalSystemNeoComponents.length){
          updatedPhysicalSystemComponents['physicalSystemNeoComponentType' + i] = '';
          updatedPhysicalSystemComponents['physicalSystemNeoMlfb' + i] = '';
          updatedPhysicalSystemComponents['physicalSystemNeoDesc' + i] = '';
          updatedPhysicalSystemComponents['physicalSystemNeoQuantity' + i] = 0;
          updatedPhysicalSystemComponents['physicalSystemNeoPrice' + i] = 0;

        } else {
          updatedPhysicalSystemComponents['physicalSystemNeoComponentType' + i] =
            this.parentForm.get('physicalSystemBOM')?.get('physicalSystemNeoComponentType' + (i + 1))?.value;
          updatedPhysicalSystemComponents['physicalSystemNeoMlfb' + i] =
            this.parentForm.get('physicalSystemBOM')?.get('physicalSystemNeoMlfb' + (i + 1))?.value;
          updatedPhysicalSystemComponents['physicalSystemNeoDesc' + i] =
            this.parentForm.get('physicalSystemBOM')?.get('physicalSystemNeoDesc' + (i + 1))?.value;
          updatedPhysicalSystemComponents['physicalSystemNeoQuantity' + i] =
            this.parentForm.get('physicalSystemBOM')?.get('physicalSystemNeoQuantity' + (i + 1))?.value || 0;
          updatedPhysicalSystemComponents['physicalSystemNeoPrice' + i] =
            this.parentForm.get('physicalSystemBOM')?.get('physicalSystemNeoPrice' + (i + 1))?.value || 0;
        }

      }
    } else {
      for (let i = componentNumber; i <= this.allPhysicalSystemComponents.length; i++){

        if (i === this.allPhysicalSystemComponents.length){
          updatedPhysicalSystemComponents['physicalSystemComponentType' + i] = '';
          updatedPhysicalSystemComponents['physicalSystemMlfb' + i] = '';
          updatedPhysicalSystemComponents['physicalSystemDesc' + i] = '';
          updatedPhysicalSystemComponents['physicalSystemQuantity' + i] = 0;
          updatedPhysicalSystemComponents['physicalSystemPrice' + i] = 0;

        } else {
          updatedPhysicalSystemComponents['physicalSystemComponentType' + i] =
            this.parentForm.get('physicalSystemBOM')?.get('physicalSystemComponentType' + (i + 1))?.value;
          updatedPhysicalSystemComponents['physicalSystemMlfb' + i] =
            this.parentForm.get('physicalSystemBOM')?.get('physicalSystemMlfb' + (i + 1))?.value;
          updatedPhysicalSystemComponents['physicalSystemDesc' + i] =
            this.parentForm.get('physicalSystemBOM')?.get('physicalSystemDesc' + (i + 1))?.value;
          updatedPhysicalSystemComponents['physicalSystemQuantity' + i] =
            this.parentForm.get('physicalSystemBOM')?.get('physicalSystemQuantity' + (i + 1))?.value || 0;
          updatedPhysicalSystemComponents['physicalSystemPrice' + i] =
            this.parentForm.get('physicalSystemBOM')?.get('physicalSystemPrice' + (i + 1))?.value || 0;
        }
      }
    }
    await this.dataTransferService.writeDataToExcel(JSON.stringify(updatedPhysicalSystemComponents));

    this.readData.emit();
  }

  async updatePhysicalSystem(pcsVersion: string){

    this.loadingSpinner.startLoading();

    if (pcsVersion === 'PCS neo'){
      const updatedPhysicalSystem: KeyValuePair = {};

      for (let i = 1; i <= this.allPhysicalSystemNeoComponents.length; i++){

        updatedPhysicalSystem['physicalSystemNeoComponentType' + i] = this.allPhysicalSystemNeoComponents[i - 1].componentType;
        updatedPhysicalSystem['physicalSystemNeoMlfb' + i] = this.allPhysicalSystemNeoComponents[i - 1].mlfb;
        updatedPhysicalSystem['physicalSystemNeoDesc' + i] = this.allPhysicalSystemNeoComponents[i - 1].description;
        updatedPhysicalSystem['physicalSystemNeoQuantity' + i] = this.allPhysicalSystemNeoComponents[i - 1].quantity;
        updatedPhysicalSystem['physicalSystemNeoPrice' + i] = this.allPhysicalSystemNeoComponents[i - 1].listPrice;

        if (i === this.allPhysicalSystemNeoComponents.length){
          await this.addNewPcsNeoComponent(updatedPhysicalSystem, (i + 1));
        }
      }

      if (this.allPhysicalSystemNeoComponents.length === 0){
        await this.addNewPcsNeoComponent(updatedPhysicalSystem, 1);
      }

      await this.dataTransferService.writeDataToExcel(JSON.stringify(updatedPhysicalSystem));

      this.loadingSpinner.stopLoading();
      this.readData.emit();
    } else {
      const updatedPhysicalSystem: KeyValuePair = {};

      for (let i = 1; i <= this.allPhysicalSystemComponents.length; i++){

        updatedPhysicalSystem['physicalSystemComponentType' + i] = this.allPhysicalSystemComponents[i - 1].componentType;
        updatedPhysicalSystem['physicalSystemMlfb' + i] = this.allPhysicalSystemComponents[i - 1].mlfb;
        updatedPhysicalSystem['physicalSystemDesc' + i] = this.allPhysicalSystemComponents[i - 1].description;
        updatedPhysicalSystem['physicalSystemQuantity' + i] = this.allPhysicalSystemComponents[i - 1].quantity;
        updatedPhysicalSystem['physicalSystemPrice' + i] = this.allPhysicalSystemComponents[i - 1].listPrice;

        if (i === this.allPhysicalSystemComponents.length){
          await this.addNewPCS7Component(updatedPhysicalSystem, (i + 1));
        }
      }

      if (this.allPhysicalSystemComponents.length === 0){
        await this.addNewPCS7Component(updatedPhysicalSystem, 1);
      }

      await this.dataTransferService.writeDataToExcel(JSON.stringify(updatedPhysicalSystem));

      this.loadingSpinner.stopLoading();
      this.readData.emit();
    }

  }

  async addNewPCS7Component(updatedSivaasComponents: KeyValuePair, id: number){
    updatedSivaasComponents['physicalSystemComponentType' + id] =
    this.parentForm.get('physicalSystemBOM')?.get('newComponentType')?.value;

    updatedSivaasComponents['physicalSystemMlfb' + id] =
      this.parentForm.get('physicalSystemBOM')?.get('newComponentMlfb')?.value;

    updatedSivaasComponents['physicalSystemDesc' + id] =
    this.parentForm.get('physicalSystemBOM')?.get('newComponentDescription')?.value;

    updatedSivaasComponents['physicalSystemQuantity' + id] =
      this.parentForm.get('physicalSystemBOM')?.get('newComponentQuantity')?.value;

    const priceMflbList =
      await this.productPriceService.getCurrentPricesByMlfbList(updatedSivaasComponents['physicalSystemMlfb' + id].toString()) as any;

    updatedSivaasComponents['physicalSystemPrice' + id] = priceMflbList[updatedSivaasComponents['physicalSystemMlfb' + id]];

    this.parentForm.get('physicalSystemBOM')?.get('newComponentListPrice')?.
      setValue(priceMflbList[updatedSivaasComponents['physicalSystemMlfb' + id]]);
  }

  async addNewPcsNeoComponent(updatedSivaasComponents: KeyValuePair, id: number){
    updatedSivaasComponents['physicalSystemNeoComponentType' + id] =
    this.parentForm.get('physicalSystemBOM')?.get('newNeoComponentType')?.value;

    updatedSivaasComponents['physicalSystemNeoMlfb' + id] =
      this.parentForm.get('physicalSystemBOM')?.get('newNeoComponentMlfb')?.value;

    updatedSivaasComponents['physicalSystemNeoDesc' + id] =
    this.parentForm.get('physicalSystemBOM')?.get('newNeoComponentDescription')?.value;

    updatedSivaasComponents['physicalSystemNeoQuantity' + id] =
      this.parentForm.get('physicalSystemBOM')?.get('newNeoComponentQuantity')?.value;

    const priceMflbList =
      await this.productPriceService.getCurrentPricesByMlfbList(updatedSivaasComponents['physicalSystemNeoMlfb' + id].toString()) as any;

    updatedSivaasComponents['physicalSystemNeoPrice' + id] = priceMflbList[updatedSivaasComponents['physicalSystemNeoMlfb' + id]];

    this.parentForm.get('physicalSystemBOM')?.get('newNeoComponentListPrice')?.
      setValue(priceMflbList[updatedSivaasComponents['physicalSystemNeoMlfb' + id]]);
  }

  async getPricesForAllPhysicalSystemComponents(pcsVersion: string){

    this.loadingSpinner.startLoading();

    let componentsCommaSeperated = '';

    if (pcsVersion === 'PCS neo'){
      for (const physicalComponent of this.allPhysicalSystemNeoComponents){

        if (physicalComponent.componentType !== 'Not relevant for LCS'){
          componentsCommaSeperated += physicalComponent.mlfb + ',';
        }

      }
      const priceMflbList =
      await this.productPriceService.getCurrentPricesByMlfbList(componentsCommaSeperated.replace(/.$/, '')) as any;

      for (const physicalComponent of this.allPhysicalSystemNeoComponents){

        if (priceMflbList[physicalComponent.mlfb] !== undefined && priceMflbList[physicalComponent.mlfb] !== physicalComponent.listPrice){
          // eslint-disable-next-line max-len
          this.parentForm.get('physicalSystemBOM')!.get('physicalSystemNeoPrice' + (physicalComponent.componentNumber))!.setValue(priceMflbList[physicalComponent.mlfb]);
          this.parentForm.get('physicalSystemBOM')!.get('physicalSystemNeoPrice' + (physicalComponent.componentNumber))!.markAsDirty();
          this.parentForm.get('physicalSystemBOM')!.get('physicalSystemNeoPrice' + (physicalComponent.componentNumber))!.markAsUntouched();
        }
      }
    } else {
      for (const physicalComponent of this.allPhysicalSystemComponents){

        if (physicalComponent.componentType !== 'Not relevant for LCS'){
          componentsCommaSeperated += physicalComponent.mlfb + ',';
        }
      }

      const priceMflbList =
      await this.productPriceService.getCurrentPricesByMlfbList(componentsCommaSeperated.replace(/.$/, '')) as any;

      for (const physicalComponent of this.allPhysicalSystemComponents){

        if (priceMflbList[physicalComponent.mlfb] !== undefined && priceMflbList[physicalComponent.mlfb] !== physicalComponent.listPrice){
          // eslint-disable-next-line max-len
          this.parentForm.get('physicalSystemBOM')!.get('physicalSystemPrice' + (physicalComponent.componentNumber))!.setValue(priceMflbList[physicalComponent.mlfb]);
          this.parentForm.get('physicalSystemBOM')!.get('physicalSystemPrice' + (physicalComponent.componentNumber))!.markAsDirty();
          this.parentForm.get('physicalSystemBOM')!.get('physicalSystemPrice' + (physicalComponent.componentNumber))!.markAsUntouched();
        }
      }
    }


    this.loadingSpinner.stopLoading();

    this.submitValues.emit();
  }

  async setNewPhysicalSystem(valuesAsJson: { [key: string]: string | number }){
    for (const key in valuesAsJson) {
      if (valuesAsJson.hasOwnProperty(key)) {
        const value = valuesAsJson[key];
        this.parentForm.get('physicalSystemBOM')!.get(key)!.setValue(value);
        this.parentForm.get('physicalSystemBOM')!.get(key)!.markAsDirty();
      }
    }
  }

}
