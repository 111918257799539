<form [formGroup]="parentForm">
  <div formGroupName="susType">
    <h5 class="subSectionHeader">SUS Type</h5>
    <div class="row">

      <div class="col-lg-2 col-md-3 col-sm-4 col-xs-6">
        <div class="form-check">
          <input id="susTypeRb0" class="form-check form-check-inline" formControlName="susTypeRb"
                type="radio"
                [(ngModel)]="susTypeRb"
                (click)="submit()"
                value="Standard SUS">
          <label for="susTypeRb0" class="form-check-label">
            Standard SUS
          </label>
        </div>
      </div>

      <div class="col-lg-2 col-md-3 col-sm-4 col-xs-6">
        <div class="form-check">
          <input id="susTypeRb1" class="form-check form-check-inline" formControlName="susTypeRb"
                type="radio"
                [(ngModel)]="susTypeRb"
                (click)="submit()"
                value="LCS SUS">
          <label for="susTypeRb1" class="form-check-label">
            LCS SUS
          </label>
        </div>
      </div>


    </div>
  </div>
</form>
