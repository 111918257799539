import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormDataService } from 'src/app/services/form-data.service';

export interface DropDownOption {
  value: string;
}

@Component({
  selector: 'app-mvdi',
  templateUrl: './mvdi.component.html',
  styleUrls: ['./mvdi.component.scss']
})
export class MvdiComponent {
  isThisSectionVisible = false;

  toggleVisibility(){
    console.log(this.isThisSectionVisible);
    this.isThisSectionVisible = !this.isThisSectionVisible;
  }

  @Input()
  public parentForm!: FormGroup;

  constructor(private formDataService: FormDataService) { }

  public getDropdownOptions(formControlName: string){
    return this.formDataService.getDropDownOptions(formControlName);
  }

  public getDropdownValue(dropDownOption: DropDownOption): string {
    return dropDownOption.value;
  }

}
