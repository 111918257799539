import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  activeSection = '';

  constructor(private router: Router) {

    const activeUrlSplitted = window.location.href.toString().split('/');
    this.activeSection = activeUrlSplitted[activeUrlSplitted.length - 1];
  }


  public naviClick(targetSection: string) {

    this.routeToNextSite(targetSection);

  }

  private async routeToNextSite(activeSection: string) {
    this.activeSection = activeSection;

    this.router.navigate([activeSection]);

  }
}
