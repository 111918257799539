/* eslint-disable max-len */
export class UiElements {



  constructor(){
    for (let i = 1; i <= 4; i++){
      for (let j = 1; j <= 25; j++){
        console.log(`'managedSystemService${i}Year${j}Selected',`);
      }
    }
  }

  public static checkBoxes = [
    'cbHwUSwUpgrade', 'cBSecondHwSwUpgr', 'cBThirdHwSwUpgr', 'cBfirstUpgrade',
    'cBCurrencyConversion', 'cBPriceCalculationRequested', 'pcs7OutOfWarranty', 'checkBoxOnCallService',
    'checkBoxOnlineSupport', 'checkBoxIndustryServiceCard', 'cBSISC36', 'cBSISCperYear', 'cBSISCatUpgrades',
    'checkBoxRepairTime', 'checkBoxalternativeLumpSum', 'checkBoxRegionalParmaterUpgrade', 'lssPackageExtension',
    'checkBoxRegionalParmaterUpdate', 'checkBoxSoftwareUpdate',
    'disRiskAdditionInclService01', 'disRiskAdditionInclService02', 'disRiskAdditionInclService03', 'disRiskAdditionInclService04',
    'disRiskAdditionInclService05', 'disRiskAdditionInclService06', 'disRiskAdditionInclService07', 'disRiskAdditionInclService08',
    'disRiskAdditionInclService09', 'disRiskAdditionInclService10', 'disRiskAdditionInclService11', 'disRiskAdditionInclService12',
    'disRiskAdditionInclService13', 'disRiskAdditionInclService14', 'disRiskAdditionInclService15', 'disRiskAdditionInclService16',
    'disRiskAdditionInclService17', 'disRiskAdditionInclService18', 'disRiskAdditionInclService19', 'disRiskAdditionInclService20',
    'checkBoxExtAivalability', 'upgradePackageRequiredCheckBox', 'ivmSelectedYear1', 'ivmSelectedYear2', 'ivmSelectedYear3',
    'ivmSelectedYear4', 'ivmSelectedYear5', 'ivmSelectedYear6', 'ivmSelectedYear7', 'ivmSelectedYear8', 'ivmSelectedYear9',
    'ivmSelectedYear10', 'ivmSelectedYear11', 'ivmSelectedYear12', 'ivmSelectedYear13', 'ivmSelectedYear14', 'ivmSelectedYear15',
    'mvdiEvaluierungPcs7LcsDiscountCb', 'mvdiEvaluierungPcsNeoLcsDiscountCb', 'mvdiASsDevelopmentPcsNeoLcsDiscountCb',
    'mvdiOsDevelopmentPcsNeoLcsDiscountCb', 'mvdiAsServicevertragLcsDiscountCb', 'mvdiOsServicevertragLcsDiscountCb',
    'mvdiFirstContractExtensionCb', 'mvdiSecondContractExtensionCb', 
    'iadcFirewallsServiceContractRelevantForIADC1', 'iadcFirewallsServiceContractRelevantForIADC2', 'iadcFirewallsServiceContractRelevantForIADC3',
    'iadcFirewallsServiceContractRelevantForIADC4', 'iadcFirewallsServiceContractRelevantForIADC5', 'iadcFirewallsServiceContractRelevantForIADC6',
    'iadcFirewallsServiceContractRelevantForIADC7', 'iadcFirewallsServiceContractRelevantForIADC8', 'iadcFirewallsServiceContractRelevantForIADC9',
    'iadcFirewallsServiceContractRelevantForIADC10', 'iadcFirewallsServiceContractRelevantForIADC11', 'iadcFirewallsServiceContractRelevantForIADC12',
    'iadcFirewallsServiceContractRelevantForIADC13', 'iadcFirewallsServiceContractRelevantForIADC14', 'iadcFirewallsServiceContractRelevantForIADC15',
    'iadcFirewallsIuWSelectedYear1',
    'iadcFirewallsIuWSelectedYear2', 'iadcFirewallsIuWSelectedYear3', 'iadcFirewallsIuWSelectedYear4',
    'iadcFirewallsIuWSelectedYear5', 'iadcFirewallsIuWSelectedYear6', 'iadcFirewallsIuWSelectedYear7',
    'iadcFirewallsIuWSelectedYear8', 'iadcFirewallsIuWSelectedYear9', 'iadcFirewallsIuWSelectedYear10',
    'iadcFirewallsIuWSelectedYear11', 'iadcFirewallsIuWSelectedYear12', 'iadcFirewallsIuWSelectedYear13',
    'iadcFirewallsIuWSelectedYear14', 'iadcFirewallsIuWSelectedYear15', 'iadcFirewallsServiceContract1Selected',
    'iadcFirewallsServiceContract1SelectedYear1', 'iadcFirewallsServiceContract1SelectedYear2', 'iadcFirewallsServiceContract1SelectedYear3',
    'iadcFirewallsServiceContract1SelectedYear4', 'iadcFirewallsServiceContract1SelectedYear5', 'iadcFirewallsServiceContract1SelectedYear6',
    'iadcFirewallsServiceContract1SelectedYear7', 'iadcFirewallsServiceContract1SelectedYear8', 'iadcFirewallsServiceContract1SelectedYear9',
    'iadcFirewallsServiceContract1SelectedYear10', 'iadcFirewallsServiceContract1SelectedYear11', 'iadcFirewallsServiceContract1SelectedYear12',
    'iadcFirewallsServiceContract1SelectedYear13', 'iadcFirewallsServiceContract1SelectedYear14', 'iadcFirewallsServiceContract1SelectedYear15',
    'iadcFirewallsServiceContract2Selected',
    'iadcFirewallsServiceContract2SelectedYear1', 'iadcFirewallsServiceContract2SelectedYear2', 'iadcFirewallsServiceContract2SelectedYear3',
    'iadcFirewallsServiceContract2SelectedYear4', 'iadcFirewallsServiceContract2SelectedYear5', 'iadcFirewallsServiceContract2SelectedYear6',
    'iadcFirewallsServiceContract2SelectedYear7', 'iadcFirewallsServiceContract2SelectedYear8', 'iadcFirewallsServiceContract2SelectedYear9',
    'iadcFirewallsServiceContract2SelectedYear10', 'iadcFirewallsServiceContract2SelectedYear11', 'iadcFirewallsServiceContract2SelectedYear12',
    'iadcFirewallsServiceContract2SelectedYear13', 'iadcFirewallsServiceContract2SelectedYear14', 'iadcFirewallsServiceContract2SelectedYear15',
    'iadcFirewallsServiceContract3Selected',
    'iadcFirewallsServiceContract3SelectedYear1', 'iadcFirewallsServiceContract3SelectedYear2', 'iadcFirewallsServiceContract3SelectedYear3',
    'iadcFirewallsServiceContract3SelectedYear4', 'iadcFirewallsServiceContract3SelectedYear5', 'iadcFirewallsServiceContract3SelectedYear6',
    'iadcFirewallsServiceContract3SelectedYear7', 'iadcFirewallsServiceContract3SelectedYear8', 'iadcFirewallsServiceContract3SelectedYear9',
    'iadcFirewallsServiceContract3SelectedYear10', 'iadcFirewallsServiceContract3SelectedYear11', 'iadcFirewallsServiceContract3SelectedYear12',
    'iadcFirewallsServiceContract3SelectedYear13', 'iadcFirewallsServiceContract3SelectedYear14', 'iadcFirewallsServiceContract3SelectedYear15',
    'iadcFirewallsServiceContract4Selected',
    'iadcFirewallsServiceContract4SelectedYear1', 'iadcFirewallsServiceContract4SelectedYear2', 'iadcFirewallsServiceContract4SelectedYear3',
    'iadcFirewallsServiceContract4SelectedYear4', 'iadcFirewallsServiceContract4SelectedYear5', 'iadcFirewallsServiceContract4SelectedYear6',
    'iadcFirewallsServiceContract4SelectedYear7', 'iadcFirewallsServiceContract4SelectedYear8', 'iadcFirewallsServiceContract4SelectedYear9',
    'iadcFirewallsServiceContract4SelectedYear10', 'iadcFirewallsServiceContract4SelectedYear11', 'iadcFirewallsServiceContract4SelectedYear12',
    'iadcFirewallsServiceContract4SelectedYear13', 'iadcFirewallsServiceContract4SelectedYear14', 'iadcFirewallsServiceContract4SelectedYear15',
    'iadcFirewallsServiceContract5SelectedYear1', 'iadcFirewallsServiceContract5SelectedYear2', 'iadcFirewallsServiceContract5SelectedYear3',
    'iadcFirewallsServiceContract5SelectedYear4', 'iadcFirewallsServiceContract5SelectedYear5', 'iadcFirewallsServiceContract5SelectedYear6',
    'iadcFirewallsServiceContract5SelectedYear7', 'iadcFirewallsServiceContract5SelectedYear8', 'iadcFirewallsServiceContract5SelectedYear9',
    'iadcFirewallsServiceContract5SelectedYear10', 'iadcFirewallsServiceContract5SelectedYear11', 'iadcFirewallsServiceContract5SelectedYear12',
    'iadcFirewallsServiceContract5SelectedYear13', 'iadcFirewallsServiceContract5SelectedYear14', 'iadcFirewallsServiceContract5SelectedYear15',
    'iadcFirewallsServiceContract6SelectedYear1', 'iadcFirewallsServiceContract6SelectedYear2', 'iadcFirewallsServiceContract6SelectedYear3',
    'iadcFirewallsServiceContract6SelectedYear4', 'iadcFirewallsServiceContract6SelectedYear5', 'iadcFirewallsServiceContract6SelectedYear6',
    'iadcFirewallsServiceContract6SelectedYear7', 'iadcFirewallsServiceContract6SelectedYear8', 'iadcFirewallsServiceContract6SelectedYear9',
    'iadcFirewallsServiceContract6SelectedYear10', 'iadcFirewallsServiceContract6SelectedYear11', 'iadcFirewallsServiceContract6SelectedYear12',
    'iadcFirewallsServiceContract6SelectedYear13', 'iadcFirewallsServiceContract6SelectedYear14', 'iadcFirewallsServiceContract6SelectedYear15',
    'iadcFirewallsServiceContract7SelectedYear1', 'iadcFirewallsServiceContract7SelectedYear2', 'iadcFirewallsServiceContract7SelectedYear3',
    'iadcFirewallsServiceContract7SelectedYear4', 'iadcFirewallsServiceContract7SelectedYear5', 'iadcFirewallsServiceContract7SelectedYear6',
    'iadcFirewallsServiceContract7SelectedYear7', 'iadcFirewallsServiceContract7SelectedYear8', 'iadcFirewallsServiceContract7SelectedYear9',
    'iadcFirewallsServiceContract7SelectedYear10', 'iadcFirewallsServiceContract7SelectedYear11', 'iadcFirewallsServiceContract7SelectedYear12',
    'iadcFirewallsServiceContract7SelectedYear13', 'iadcFirewallsServiceContract7SelectedYear14', 'iadcFirewallsServiceContract7SelectedYear15',
    'iadcFirewallsServiceContract8SelectedYear1', 'iadcFirewallsServiceContract8SelectedYear2', 'iadcFirewallsServiceContract8SelectedYear3',
    'iadcFirewallsServiceContract8SelectedYear4', 'iadcFirewallsServiceContract8SelectedYear5', 'iadcFirewallsServiceContract8SelectedYear6',
    'iadcFirewallsServiceContract8SelectedYear7', 'iadcFirewallsServiceContract8SelectedYear8', 'iadcFirewallsServiceContract8SelectedYear9',
    'iadcFirewallsServiceContract8SelectedYear10', 'iadcFirewallsServiceContract8SelectedYear11', 'iadcFirewallsServiceContract8SelectedYear12',
    'iadcFirewallsServiceContract8SelectedYear13', 'iadcFirewallsServiceContract8SelectedYear14', 'iadcFirewallsServiceContract8SelectedYear15',
    'iadcFirewallsServiceContract9SelectedYear1', 'iadcFirewallsServiceContract9SelectedYear2', 'iadcFirewallsServiceContract9SelectedYear3',
    'iadcFirewallsServiceContract9SelectedYear4', 'iadcFirewallsServiceContract9SelectedYear5', 'iadcFirewallsServiceContract9SelectedYear6',
    'iadcFirewallsServiceContract9SelectedYear7', 'iadcFirewallsServiceContract9SelectedYear8', 'iadcFirewallsServiceContract9SelectedYear9',
    'iadcFirewallsServiceContract9SelectedYear10', 'iadcFirewallsServiceContract9SelectedYear11', 'iadcFirewallsServiceContract9SelectedYear12',
    'iadcFirewallsServiceContract9SelectedYear13', 'iadcFirewallsServiceContract9SelectedYear14', 'iadcFirewallsServiceContract9SelectedYear15',
    'iadcFirewallsServiceContract10SelectedYear1', 'iadcFirewallsServiceContract10SelectedYear2', 'iadcFirewallsServiceContract10SelectedYear3',
    'iadcFirewallsServiceContract10SelectedYear4', 'iadcFirewallsServiceContract10SelectedYear5', 'iadcFirewallsServiceContract10SelectedYear6',
    'iadcFirewallsServiceContract10SelectedYear7', 'iadcFirewallsServiceContract10SelectedYear8', 'iadcFirewallsServiceContract10SelectedYear9',
    'iadcFirewallsServiceContract10SelectedYear10', 'iadcFirewallsServiceContract10SelectedYear11', 'iadcFirewallsServiceContract10SelectedYear12',
    'iadcFirewallsServiceContract10SelectedYear13', 'iadcFirewallsServiceContract10SelectedYear14', 'iadcFirewallsServiceContract10SelectedYear15',
    'iadcFirewallsServiceContract11SelectedYear1', 'iadcFirewallsServiceContract11SelectedYear2', 'iadcFirewallsServiceContract11SelectedYear3',
    'iadcFirewallsServiceContract11SelectedYear4', 'iadcFirewallsServiceContract11SelectedYear5', 'iadcFirewallsServiceContract11SelectedYear6',
    'iadcFirewallsServiceContract11SelectedYear7', 'iadcFirewallsServiceContract11SelectedYear8', 'iadcFirewallsServiceContract11SelectedYear9',
    'iadcFirewallsServiceContract11SelectedYear10', 'iadcFirewallsServiceContract11SelectedYear11', 'iadcFirewallsServiceContract11SelectedYear12',
    'iadcFirewallsServiceContract11SelectedYear13', 'iadcFirewallsServiceContract11SelectedYear14', 'iadcFirewallsServiceContract11SelectedYear15',
    'iadcFirewallsServiceContract12SelectedYear1', 'iadcFirewallsServiceContract12SelectedYear2', 'iadcFirewallsServiceContract12SelectedYear3',
    'iadcFirewallsServiceContract12SelectedYear4', 'iadcFirewallsServiceContract12SelectedYear5', 'iadcFirewallsServiceContract12SelectedYear6',
    'iadcFirewallsServiceContract12SelectedYear7', 'iadcFirewallsServiceContract12SelectedYear8', 'iadcFirewallsServiceContract12SelectedYear9',
    'iadcFirewallsServiceContract12SelectedYear10', 'iadcFirewallsServiceContract12SelectedYear11', 'iadcFirewallsServiceContract12SelectedYear12',
    'iadcFirewallsServiceContract12SelectedYear13', 'iadcFirewallsServiceContract12SelectedYear14', 'iadcFirewallsServiceContract12SelectedYear15',
    'iadcFirewallsServiceContract13SelectedYear1', 'iadcFirewallsServiceContract13SelectedYear2', 'iadcFirewallsServiceContract13SelectedYear3',
    'iadcFirewallsServiceContract13SelectedYear4', 'iadcFirewallsServiceContract13SelectedYear5', 'iadcFirewallsServiceContract13SelectedYear6',
    'iadcFirewallsServiceContract13SelectedYear7', 'iadcFirewallsServiceContract13SelectedYear8', 'iadcFirewallsServiceContract13SelectedYear9',
    'iadcFirewallsServiceContract13SelectedYear10', 'iadcFirewallsServiceContract13SelectedYear11', 'iadcFirewallsServiceContract13SelectedYear12',
    'iadcFirewallsServiceContract13SelectedYear13', 'iadcFirewallsServiceContract13SelectedYear14', 'iadcFirewallsServiceContract13SelectedYear15',
    'iadcFirewallsServiceContract14SelectedYear1', 'iadcFirewallsServiceContract14SelectedYear2', 'iadcFirewallsServiceContract14SelectedYear3',
    'iadcFirewallsServiceContract14SelectedYear4', 'iadcFirewallsServiceContract14SelectedYear5', 'iadcFirewallsServiceContract14SelectedYear6',
    'iadcFirewallsServiceContract14SelectedYear7', 'iadcFirewallsServiceContract14SelectedYear8', 'iadcFirewallsServiceContract14SelectedYear9',
    'iadcFirewallsServiceContract14SelectedYear10', 'iadcFirewallsServiceContract14SelectedYear11', 'iadcFirewallsServiceContract14SelectedYear12',
    'iadcFirewallsServiceContract14SelectedYear13', 'iadcFirewallsServiceContract14SelectedYear14', 'iadcFirewallsServiceContract14SelectedYear15',
    'iadcFirewallsServiceContract15SelectedYear1', 'iadcFirewallsServiceContract15SelectedYear2', 'iadcFirewallsServiceContract15SelectedYear3',
    'iadcFirewallsServiceContract15SelectedYear4', 'iadcFirewallsServiceContract15SelectedYear5', 'iadcFirewallsServiceContract15SelectedYear6',
    'iadcFirewallsServiceContract15SelectedYear7', 'iadcFirewallsServiceContract15SelectedYear8', 'iadcFirewallsServiceContract15SelectedYear9',
    'iadcFirewallsServiceContract15SelectedYear10', 'iadcFirewallsServiceContract15SelectedYear11', 'iadcFirewallsServiceContract15SelectedYear12',
    'iadcFirewallsServiceContract15SelectedYear13', 'iadcFirewallsServiceContract15SelectedYear14', 'iadcFirewallsServiceContract15SelectedYear15',
    'iadcUSVSelectedCb', 'iadcUSVIuWSelectedYear1', 'iadcUSVIuWSelectedYear2', 'iadcUSVIuWSelectedYear3', 'iadcUSVIuWSelectedYear4',
    'iadcUSVIuWSelectedYear5', 'iadcUSVIuWSelectedYear6', 'iadcUSVIuWSelectedYear7', 'iadcUSVIuWSelectedYear8',
    'iadcUSVIuWSelectedYear9', 'iadcUSVIuWSelectedYear10', 'iadcUSVIuWSelectedYear11', 'iadcUSVIuWSelectedYear12',
    'iadcUSVIuWSelectedYear13', 'iadcUSVIuWSelectedYear14', 'iadcUSVIuWSelectedYear15',
    'iadcOtSwitchSelectedCb', 'iadcOtSwitchIuWSelectedYear1', 'iadcOtSwitchIuWSelectedYear2', 'iadcOtSwitchIuWSelectedYear3', 'iadcOtSwitchIuWSelectedYear4',
    'iadcOtSwitchIuWSelectedYear5', 'iadcOtSwitchIuWSelectedYear6', 'iadcOtSwitchIuWSelectedYear7', 'iadcOtSwitchIuWSelectedYear8',
    'iadcOtSwitchIuWSelectedYear9', 'iadcOtSwitchIuWSelectedYear10', 'iadcOtSwitchIuWSelectedYear11', 'iadcOtSwitchIuWSelectedYear12',
    'iadcOtSwitchIuWSelectedYear13', 'iadcOtSwitchIuWSelectedYear14', 'iadcOtSwitchIuWSelectedYear15',
    'iadcItSwitchSelectedCb', 'iadcItSwitchIuWSelectedYear1', 'iadcItSwitchIuWSelectedYear2', 'iadcItSwitchIuWSelectedYear3', 'iadcItSwitchIuWSelectedYear4',
    'iadcItSwitchIuWSelectedYear5', 'iadcItSwitchIuWSelectedYear6', 'iadcItSwitchIuWSelectedYear7', 'iadcItSwitchIuWSelectedYear8',
    'iadcItSwitchIuWSelectedYear9', 'iadcItSwitchIuWSelectedYear10', 'iadcItSwitchIuWSelectedYear11', 'iadcItSwitchIuWSelectedYear12',
    'iadcItSwitchIuWSelectedYear13', 'iadcItSwitchIuWSelectedYear14', 'iadcItSwitchIuWSelectedYear15',
    'iadcItSwitchServiceContract1Selected',
    'iadcItSwitchServiceContract1SelectedYear1', 'iadcItSwitchServiceContract1SelectedYear2', 'iadcItSwitchServiceContract1SelectedYear3',
    'iadcItSwitchServiceContract1SelectedYear4', 'iadcItSwitchServiceContract1SelectedYear5', 'iadcItSwitchServiceContract1SelectedYear6',
    'iadcItSwitchServiceContract1SelectedYear7', 'iadcItSwitchServiceContract1SelectedYear8', 'iadcItSwitchServiceContract1SelectedYear9',
    'iadcItSwitchServiceContract1SelectedYear10', 'iadcItSwitchServiceContract1SelectedYear11', 'iadcItSwitchServiceContract1SelectedYear12',
    'iadcItSwitchServiceContract1SelectedYear13', 'iadcItSwitchServiceContract1SelectedYear14', 'iadcItSwitchServiceContract1SelectedYear15',
    'iadcPhSelectedCb', 'iadcPhIuWSelectedYear1', 'iadcPhIuWSelectedYear2', 'iadcPhIuWSelectedYear3', 'iadcPhIuWSelectedYear4',
    'iadcPhIuWSelectedYear5', 'iadcPhIuWSelectedYear6', 'iadcPhIuWSelectedYear7', 'iadcPhIuWSelectedYear8',
    'iadcPhIuWSelectedYear9', 'iadcPhIuWSelectedYear10', 'iadcPhIuWSelectedYear11', 'iadcPhIuWSelectedYear12',
    'iadcPhIuWSelectedYear13', 'iadcPhIuWSelectedYear14', 'iadcPhIuWSelectedYear15',
    'iadcBackupRestoreSelectedCb', 'cbIsRepairTimeSelectable', 'isBasicEffortExtAvailabilitySelectable',
    'iadcBackupRestoreServiceContract1Selected', 'iadcBackupRestoreServiceContract1SelectedYear1',
    'iadcBackupRestoreServiceContract1SelectedYear2', 'iadcBackupRestoreServiceContract1SelectedYear3',
    'iadcBackupRestoreServiceContract1SelectedYear4', 'iadcBackupRestoreServiceContract1SelectedYear5',
    'iadcBackupRestoreServiceContract1SelectedYear6', 'iadcBackupRestoreServiceContract1SelectedYear7',
    'iadcBackupRestoreServiceContract1SelectedYear8', 'iadcBackupRestoreServiceContract1SelectedYear9',
    'iadcBackupRestoreServiceContract1SelectedYear10', 'iadcBackupRestoreServiceContract1SelectedYear11',
    'iadcBackupRestoreServiceContract1SelectedYear12', 'iadcBackupRestoreServiceContract1SelectedYear13',
    'iadcBackupRestoreServiceContract1SelectedYear14', 'iadcBackupRestoreServiceContract1SelectedYear15',
    'iadcPhServiceContract1Selected', 'iadcPhServiceContract1SelectedYear1',
    'iadcPhServiceContract1SelectedYear2', 'iadcPhServiceContract1SelectedYear3',
    'iadcPhServiceContract1SelectedYear4', 'iadcPhServiceContract1SelectedYear5',
    'iadcPhServiceContract1SelectedYear6', 'iadcPhServiceContract1SelectedYear7',
    'iadcPhServiceContract1SelectedYear8', 'iadcPhServiceContract1SelectedYear9',
    'iadcPhServiceContract1SelectedYear10', 'iadcPhServiceContract1SelectedYear11',
    'iadcPhServiceContract1SelectedYear12', 'iadcPhServiceContract1SelectedYear13',
    'iadcPhServiceContract1SelectedYear14', 'iadcPhServiceContract1SelectedYear15', 'managedSystemService1IsSelected',
    'managedSystemService2IsSelected', 'managedSystemService3IsSelected', 'managedSystemService4IsSelected',
    'managedSystemService1Year1Selected', 'managedSystemService1Year2Selected', 'managedSystemService1Year3Selected', 'managedSystemService1Year4Selected',
    'managedSystemService1Year5Selected', 'managedSystemService1Year6Selected', 'managedSystemService1Year7Selected', 'managedSystemService1Year8Selected',
    'managedSystemService1Year9Selected', 'managedSystemService1Year10Selected', 'managedSystemService1Year11Selected', 'managedSystemService1Year12Selected',
    'managedSystemService1Year13Selected', 'managedSystemService1Year14Selected', 'managedSystemService1Year15Selected', 'managedSystemService1Year16Selected',
    'managedSystemService1Year17Selected', 'managedSystemService1Year18Selected', 'managedSystemService1Year19Selected', 'managedSystemService1Year20Selected',
    'managedSystemService1Year21Selected', 'managedSystemService1Year22Selected', 'managedSystemService1Year23Selected', 'managedSystemService1Year24Selected',
    'managedSystemService1Year25Selected', 'managedSystemService2Year1Selected', 'managedSystemService2Year2Selected', 'managedSystemService2Year3Selected', 'managedSystemService2Year4Selected',
    'managedSystemService2Year5Selected', 'managedSystemService2Year6Selected', 'managedSystemService2Year7Selected', 'managedSystemService2Year8Selected',
    'managedSystemService2Year9Selected', 'managedSystemService2Year10Selected', 'managedSystemService2Year11Selected', 'managedSystemService2Year12Selected',
    'managedSystemService2Year13Selected', 'managedSystemService2Year14Selected', 'managedSystemService2Year15Selected', 'managedSystemService2Year16Selected',
    'managedSystemService2Year17Selected', 'managedSystemService2Year18Selected', 'managedSystemService2Year19Selected', 'managedSystemService2Year20Selected',
    'managedSystemService2Year21Selected', 'managedSystemService2Year22Selected', 'managedSystemService2Year23Selected', 'managedSystemService2Year24Selected',
    'managedSystemService2Year25Selected', 'managedSystemService3Year1Selected', 'managedSystemService3Year2Selected', 'managedSystemService3Year3Selected', 'managedSystemService3Year4Selected',
    'managedSystemService3Year5Selected', 'managedSystemService3Year6Selected', 'managedSystemService3Year7Selected', 'managedSystemService3Year8Selected',
    'managedSystemService3Year9Selected', 'managedSystemService3Year10Selected', 'managedSystemService3Year11Selected', 'managedSystemService3Year12Selected',
    'managedSystemService3Year13Selected', 'managedSystemService3Year14Selected', 'managedSystemService3Year15Selected', 'managedSystemService3Year16Selected',
    'managedSystemService3Year17Selected', 'managedSystemService3Year18Selected', 'managedSystemService3Year19Selected', 'managedSystemService3Year20Selected',
    'managedSystemService3Year21Selected', 'managedSystemService3Year22Selected', 'managedSystemService3Year23Selected', 'managedSystemService3Year24Selected',
    'managedSystemService3Year25Selected', 'managedSystemService4Year1Selected', 'managedSystemService4Year2Selected', 'managedSystemService4Year3Selected', 'managedSystemService4Year4Selected',
    'managedSystemService4Year5Selected', 'managedSystemService4Year6Selected', 'managedSystemService4Year7Selected', 'managedSystemService4Year8Selected',
    'managedSystemService4Year9Selected', 'managedSystemService4Year10Selected', 'managedSystemService4Year11Selected', 'managedSystemService4Year12Selected',
    'managedSystemService4Year13Selected', 'managedSystemService4Year14Selected', 'managedSystemService4Year15Selected', 'managedSystemService4Year16Selected',
    'managedSystemService4Year17Selected', 'managedSystemService4Year18Selected', 'managedSystemService4Year19Selected', 'managedSystemService4Year20Selected',
    'managedSystemService4Year21Selected', 'managedSystemService4Year22Selected', 'managedSystemService4Year23Selected', 'managedSystemService4Year24Selected',
    'managedSystemService4Year25Selected',
    'managedSystemIndService1Year1Selected', 'managedSystemIndService1Year2Selected', 'managedSystemIndService1Year3Selected', 'managedSystemIndService1Year4Selected',
    'managedSystemIndService1Year5Selected', 'managedSystemIndService1Year6Selected', 'managedSystemIndService1Year7Selected', 'managedSystemIndService1Year8Selected',
    'managedSystemIndService1Year9Selected', 'managedSystemIndService1Year10Selected', 'managedSystemIndService1Year11Selected', 'managedSystemIndService1Year12Selected',
    'managedSystemIndService1Year13Selected', 'managedSystemIndService1Year14Selected', 'managedSystemIndService1Year15Selected', 'managedSystemIndService1Year16Selected',
    'managedSystemIndService1Year17Selected', 'managedSystemIndService1Year18Selected', 'managedSystemIndService1Year19Selected', 'managedSystemIndService1Year20Selected',
    'managedSystemIndService1Year21Selected', 'managedSystemIndService1Year22Selected', 'managedSystemIndService1Year23Selected', 'managedSystemIndService1Year24Selected',
    'managedSystemIndService1Year25Selected', 'managedSystemIndService2Year1Selected', 'managedSystemIndService2Year2Selected', 'managedSystemIndService2Year3Selected', 'managedSystemIndService2Year4Selected',
    'managedSystemIndService2Year5Selected', 'managedSystemIndService2Year6Selected', 'managedSystemIndService2Year7Selected', 'managedSystemIndService2Year8Selected',
    'managedSystemIndService2Year9Selected', 'managedSystemIndService2Year10Selected', 'managedSystemIndService2Year11Selected', 'managedSystemIndService2Year12Selected',
    'managedSystemIndService2Year13Selected', 'managedSystemIndService2Year14Selected', 'managedSystemIndService2Year15Selected', 'managedSystemIndService2Year16Selected',
    'managedSystemIndService2Year17Selected', 'managedSystemIndService2Year18Selected', 'managedSystemIndService2Year19Selected', 'managedSystemIndService2Year20Selected',
    'managedSystemIndService2Year21Selected', 'managedSystemIndService2Year22Selected', 'managedSystemIndService2Year23Selected', 'managedSystemIndService2Year24Selected',
    'managedSystemIndService2Year25Selected', 'managedSystemIndService3Year1Selected', 'managedSystemIndService3Year2Selected', 'managedSystemIndService3Year3Selected', 'managedSystemIndService3Year4Selected',
    'managedSystemIndService3Year5Selected', 'managedSystemIndService3Year6Selected', 'managedSystemIndService3Year7Selected', 'managedSystemIndService3Year8Selected',
    'managedSystemIndService3Year9Selected', 'managedSystemIndService3Year10Selected', 'managedSystemIndService3Year11Selected', 'managedSystemIndService3Year12Selected',
    'managedSystemIndService3Year13Selected', 'managedSystemIndService3Year14Selected', 'managedSystemIndService3Year15Selected', 'managedSystemIndService3Year16Selected',
    'managedSystemIndService3Year17Selected', 'managedSystemIndService3Year18Selected', 'managedSystemIndService3Year19Selected', 'managedSystemIndService3Year20Selected',
    'managedSystemIndService3Year21Selected', 'managedSystemIndService3Year22Selected', 'managedSystemIndService3Year23Selected', 'managedSystemIndService3Year24Selected',
    'managedSystemIndService3Year25Selected', 'managedSystemIndService4Year1Selected', 'managedSystemIndService4Year2Selected', 'managedSystemIndService4Year3Selected', 'managedSystemIndService4Year4Selected',
    'managedSystemIndService4Year5Selected', 'managedSystemIndService4Year6Selected', 'managedSystemIndService4Year7Selected', 'managedSystemIndService4Year8Selected',
    'managedSystemIndService4Year9Selected', 'managedSystemIndService4Year10Selected', 'managedSystemIndService4Year11Selected', 'managedSystemIndService4Year12Selected',
    'managedSystemIndService4Year13Selected', 'managedSystemIndService4Year14Selected', 'managedSystemIndService4Year15Selected', 'managedSystemIndService4Year16Selected',
    'managedSystemIndService4Year17Selected', 'managedSystemIndService4Year18Selected', 'managedSystemIndService4Year19Selected', 'managedSystemIndService4Year20Selected',
    'managedSystemIndService4Year21Selected', 'managedSystemIndService4Year22Selected', 'managedSystemIndService4Year23Selected', 'managedSystemIndService4Year24Selected',
    'managedSystemIndService4Year25Selected',
    'hwAddonsPCS7Related1', 'hwAddonsPCS7Related2', 'hwAddonsPCS7Related3', 'hwAddonsPCS7Related4',
    'hwAddonsPCS7Related5', 'hwAddonsPCS7Related6', 'hwAddonsPCS7Related7', 'hwAddonsPCS7Related8',
    'hwAddonsPCS7Related9', 'hwAddonsPCS7Related10', 'hwAddonsPCSneoRelated1', 'hwAddonsPCSneoRelated2',
    'hwAddonsPCSneoRelated3', 'hwAddonsPCSneoRelated4', 'hwAddonsPCSneoRelated5', 'hwAddonsPCSneoRelated6',
    'hwAddonsPCSneoRelated7', 'hwAddonsPCSneoRelated8', 'hwAddonsPCSneoRelated9', 'hwAddonsPCSneoRelated10',
    'swAddonsPCS7Related1', 'swAddonsPCS7Related2', 'swAddonsPCS7Related3', 'swAddonsPCS7Related4',
    'swAddonsPCS7Related5', 'swAddonsPCS7Related6', 'swAddonsPCS7Related7', 'swAddonsPCS7Related8',
    'swAddonsPCS7Related9', 'swAddonsPCS7Related10', 'swAddonsPCSneoRelated1', 'swAddonsPCSneoRelated2',
    'swAddonsPCSneoRelated3', 'swAddonsPCSneoRelated4', 'swAddonsPCSneoRelated5', 'swAddonsPCSneoRelated6',
    'swAddonsPCSneoRelated7', 'swAddonsPCSneoRelated8', 'swAddonsPCSneoRelated9', 'swAddonsPCSneoRelated10',
    'iadcFirewallsProductRelevantForIADC1', 'iadcFirewallsProductRelevantForIADC2', 'iadcFirewallsProductRelevantForIADC3',
    'iadcFirewallsProductRelevantForIADC4', 'iadcFirewallsProductRelevantForIADC5', 'iadcFirewallsProductRelevantForIADC6',
    'iadcFirewallsProductRelevantForIADC7', 'iadcFirewallsProductRelevantForIADC8', 'iadcFirewallsProductRelevantForIADC9',
    'iadcFirewallsProductRelevantForIADC10', 'iadcFirewallsProductRelevantForIADC11', 'iadcFirewallsProductRelevantForIADC12',
    'iadcFirewallsProductRelevantForIADC13', 'iadcFirewallsProductRelevantForIADC14', 'iadcFirewallsProductRelevantForIADC15',
    'remoteServiceSelected1', 'remoteServiceSelected2', 'remoteServiceSelected3', 'remoteServiceSelected4', 'remoteServiceSelected5',
    'remoteServiceSelected6', 'remoteServiceSelected7', 'remoteServiceSelected8', 'remoteServiceSelected9', 'remoteServiceSelected10',
    'remoteServiceSelected11', 'remoteServiceSelected12', 'remoteServiceSelected13', 'remoteServiceSelected14', 'remoteServiceSelected15',
    'remoteServiceSelected16', 'remoteServiceSelected17', 'remoteServiceSelected18', 'remoteServiceSelected19', 'remoteServiceSelected20'

  ];
  public static dropDowns = [
    {
      element: 'startWithPcs7OrPcsNeo',
      options: [
        { value: 'PCS 7' },
        { value: 'PCS neo' }
      ]
    },
    {
      element: 'lcsOfferType',
      options: [
        { value: 'Price Information' },
        { value: 'Budgetary Proposal' },
        { value: 'Binding Offer' }
      ]
    },
    {
      element: 'mvdiDevelopmentPcs7InitYear',
      options: [
        { value: '0' },
        { value: '2019' },
        { value: '2020' },
        { value: '2021' },
        { value: '2022' },
        { value: '2023' },
        { value: '2024' },
        { value: '2025' },
        { value: '2026' },
        { value: '2027' },
        { value: '2028' },
        { value: '2029' },
        { value: '2030' },
        { value: '2031' },
        { value: '2032' },
        { value: '2033' },
        { value: '2034' },
        { value: '2035' },
        { value: '2036' },
        { value: '2037' },
        { value: '2038' },
        { value: '2039' },
        { value: '2040' },
        { value: '2041' },
        { value: '2042' },
        { value: '2043' },
        { value: '2044' },
        { value: '2045' },
        { value: '2046' },
        { value: '2047' },
        { value: '2048' },
        { value: '2049' },
        { value: '2050' }
      ]
    },
    {
      element: 'mvdiEvaluierungPcs7InitYear',
      options: [
        { value: '0' },
        { value: '2019' },
        { value: '2020' },
        { value: '2021' },
        { value: '2022' },
        { value: '2023' },
        { value: '2024' },
        { value: '2025' },
        { value: '2026' },
        { value: '2027' },
        { value: '2028' },
        { value: '2029' },
        { value: '2030' },
        { value: '2031' },
        { value: '2032' },
        { value: '2033' },
        { value: '2034' },
        { value: '2035' },
        { value: '2036' },
        { value: '2037' },
        { value: '2038' },
        { value: '2039' },
        { value: '2040' },
        { value: '2041' },
        { value: '2042' },
        { value: '2043' },
        { value: '2044' },
        { value: '2045' },
        { value: '2046' },
        { value: '2047' },
        { value: '2048' },
        { value: '2049' },
        { value: '2050' }
      ]
    },
    {
      element: 'mvdiAsServicevertragInitYear',
      options: [
        { value: '0' },
        { value: '2019' },
        { value: '2020' },
        { value: '2021' },
        { value: '2022' },
        { value: '2023' },
        { value: '2024' },
        { value: '2025' },
        { value: '2026' },
        { value: '2027' },
        { value: '2028' },
        { value: '2029' },
        { value: '2030' },
        { value: '2031' },
        { value: '2032' },
        { value: '2033' },
        { value: '2034' },
        { value: '2035' },
        { value: '2036' },
        { value: '2037' },
        { value: '2038' },
        { value: '2039' },
        { value: '2040' },
        { value: '2041' },
        { value: '2042' },
        { value: '2043' },
        { value: '2044' },
        { value: '2045' },
        { value: '2046' },
        { value: '2047' },
        { value: '2048' },
        { value: '2049' },
        { value: '2050' }
      ]
    },
    {
      element: 'mvdiOsServicevertragInitYear',
      options: [
        { value: '0' },
        { value: '2019' },
        { value: '2020' },
        { value: '2021' },
        { value: '2022' },
        { value: '2023' },
        { value: '2024' },
        { value: '2025' },
        { value: '2026' },
        { value: '2027' },
        { value: '2028' },
        { value: '2029' },
        { value: '2030' },
        { value: '2031' },
        { value: '2032' },
        { value: '2033' },
        { value: '2034' },
        { value: '2035' },
        { value: '2036' },
        { value: '2037' },
        { value: '2038' },
        { value: '2039' },
        { value: '2040' },
        { value: '2041' },
        { value: '2042' },
        { value: '2043' },
        { value: '2044' },
        { value: '2045' },
        { value: '2046' },
        { value: '2047' },
        { value: '2048' },
        { value: '2049' },
        { value: '2050' }
      ]
    },
    {
      element: 'yearOfLCSCalculation',
      options: [
        { value: '2022' },
        { value: '2023' },
        { value: '2024' },
        { value: '2025' },
        { value: '2026' }
      ]
    },
    {
      element: 'yearOfStart',
      options: [
        { value: '2022' },
        { value: '2023' },
        { value: '2024' },
        { value: '2025' },
        { value: '2026' },
        { value: '2027' },
        { value: '2028' },
        { value: '2029' },
        { value: '2030' },
        { value: '2032' },
        { value: '2033' },
        { value: '2034' },
        { value: '2035' },
        { value: '2036' },
        { value: '2037' },
        { value: '2038' },
        { value: '2039' },
        { value: '2040' },
        { value: '2041' },
        { value: '2042' }
      ]
    },
    {
      element: 'plantType',
      options: [
        { value: 'Continuous' },
        { value: 'Batch' },
        { value: 'Conti & Batch' },
        { value: 'unknown' }
      ]
    },
    {
      element: 'projectType',
      options: [
        { value: 'Brownfield (Legacy DCS migration)' },
        { value: 'Greenfield' },
        { value: 'PCS 7 Migration' }
      ]
    },
    {
      element: 'swApplicationBasedOn',
      options: [
        { value: 'PCS 7 Standard Library' },
        { value: 'APL' },
        { value: 'BASF_Toolkit' },
        { value: 'Bayer_Toolkit' },
        { value: 'APF' },
        { value: 'special/others' }
      ]
    },
    {
      element: 'lcsContractDuration',
      options: [
        { value: '1' },
        { value: '2' },
        { value: '3' },
        { value: '4' },
        { value: '5' },
        { value: '6' },
        { value: '7' },
        { value: '8' },
        { value: '9' },
        { value: '10' },
        { value: '11' },
        { value: '12' },
        { value: '13' },
        { value: '14' },
        { value: '15' }
      ]
    },
    {
      element: 'susContractDuration',
      options: [
        { value: '1' },
        { value: '2' },
        { value: '3' },
        { value: '4' },
        { value: '5' },
        { value: '6' },
        { value: '7' },
        { value: '8' },
        { value: '9' },
        { value: '10' },
        { value: '11' },
        { value: '12' },
        { value: '13' },
        { value: '14' },
        { value: '15' }
      ]
    },
    {
      element: 'firstUpgradeDropdown',
      options: [
        { value: '2022' },
        { value: '2023' },
        { value: '2024' },
        { value: '2025' },
        { value: '2026' },
        { value: '2027' },
        { value: '2028' },
        { value: '2029' },
        { value: '2030' },
        { value: '2031' },
        { value: '2032' },
        { value: '2033' },
        { value: '2034' },
        { value: '2035' },
        { value: '2036' },
        { value: '2037' },
        { value: '2038' },
        { value: '2039' },
        { value: '2040' },
        { value: '2041' },
        { value: '2042' }
      ]
    },
    {
      element: 'dDTimesPerYear',
      options: [
        { value: '1' },
        { value: '2' },
        { value: '4' }
      ]
    },
    {
      element: 'dDStandardLISComp',
      options: [
        { value: 'small  1....50 uniqe order-Ids' },
        { value: 'medium 1...150 uniqe order-Ids' },
        { value: 'large  1...300 uniqe order-Ids' }
      ]
    },
    {
      element: 'dD36Cases',
      options: [
        { value: '0' },
        { value: '5' },
        { value: '10' }
      ]
    },
    {
      element: 'dD36Qty',
      options: [
        { value: '0' }, { value: '1' }, { value: '2' }, { value: '3' }, { value: '4' }, { value: '5' },
        { value: '6' }, { value: '7' }, { value: '8' }, { value: '9' }, { value: '10' }
      ]
    },
    {
      element: 'dDperYearCases',
      options: [
        { value: '0' },
        { value: '5' },
        { value: '10' }
      ]
    },
    {
      element: 'dDperYearQty',
      options: [
        { value: '0' }, { value: '1' }, { value: '2' }, { value: '3' }, { value: '4' }, { value: '5' },
        { value: '6' }, { value: '7' }, { value: '8' }, { value: '9' }, { value: '10' }
      ]
    },
    {
      element: 'dDperYearCases15',
      options: [
        { value: '0' },
        { value: '5' },
        { value: '10' }
      ]
    },
    {
      element: 'dDperYearQty15',
      options: [
        { value: '0' }, { value: '1' }, { value: '2' }, { value: '3' }, { value: '4' }, { value: '5' },
        { value: '6' }, { value: '7' }, { value: '8' }, { value: '9' }, { value: '10' }
      ]
    },
    {
      element: 'dDyearsWithUpdatesCases',
      options: [
        { value: '0' },
        { value: '5' },
        { value: '10' }
      ]
    },
    {
      element: 'dDYearsWithUpdatesQty',
      options: [
        { value: '0' }, { value: '1' }, { value: '2' }, { value: '3' }, { value: '4' }, { value: '5' },
        { value: '6' }, { value: '7' }, { value: '8' }, { value: '9' }, { value: '10' }
      ]
    },
    {
      element: 'pcsNeoAssetManagementIncluded',
      options: [
        { value: 'N' }, { value: 'Y' }]
    },
    {
      element: 'SivaasConfCategory',
      options: [
        { value: 'Service Contract HP' }, { value: 'Service Contract VM' },
        { value: 'HW-Virtualization Host' }, { value: 'Thin Clients' }, { value: 'HW Addons' },
        { value: 'SW Addons' }, { value: 'SIDSI Host' }, { value: 'SIDSI SW' }, { value: 'SIDSI PH' },
        { value: 'VM Windows Server' }, { value: 'VM SIMATIC OS Client' }, { value: 'VM SIMATIC OS Server' },
        { value: 'VM SIMATIC Batch Server' }, { value: 'VM SIMATIC BATCH / OS Client' }, { value: 'VM SIMATIC RC Server' },
        { value: 'VM SIMATIC RC Client' }, { value: 'VM WinCC Server' }, { value: 'VM WinCC Client' },
        { value: 'VM vCenter Server' }, { value: 'VM SIMIT Simulation Software' }, { value: 'VM SIMIT Virtual Controller' },
        { value: 'VM Sinema Remote Connect' }, { value: 'VM SIMATIC ES' }, { value: 'VM PH_IS' }, { value: 'IADC PH SC' },
        { value: 'IADC PH Host' }, { value: 'IADC BR SL' }, { value: 'IADC BR SC' }, { value: 'IADC BR' }]
    },
    {
      element: 'ivmPlatform',
      options: [
        { value: 'Edge' },
        { value: 'MindSphere' },
        { value: 'AWS' },
        { value: 'On Premise' }
      ]
    },
    {
      element: 'iadcOtSwitchProductType',
      options: [
        { value: 'SCALANCE XR526-8C' }
      ]
    },
    {
      element: 'iadcItSwitchProductType',
      options: [
        { value: 'HP FF 5710 Switch 24XGT' },
        { value: 'HP FF 5130 Switch 6x SFP+' }
      ]
    },
    {
      element: 'iadcPhProductType',
      options: [
        { value: 'HP Server XS' },
        { value: 'HP Server S' },
        { value: 'HP Server M' },
        { value: 'HP Server L' }
      ]
    },
    {
      element: 'iadcBackupRestoreProductType',
      options: [
        { value: 'HP Server' }
      ]
    },
    {
      element: 'sivaasComponentTypesDropdown',
      options: [
        { value: 'Unclassified', type: 'option'},
        { value: 'Not relevant for LCS', type: 'option'},
        { value: 'Sivaas Addon SW', type: 'option'},
        { value: 'Sivaas Addon HW', type: 'option'},
        { value: 'Sivaas SC', type: 'option'},
        { value: 'Sivaas Invalid SC', type: 'option'},
        { value: 'Sivaas Client HW', type: 'option'},
        { value: 'Sivaas Server HW', type: 'option'},
        { value: 'Sivaas VM', type: 'option'},
        { value: 'IADC FW SC', type: 'option'},
        { value: 'IADC FW Invalid SC', type: 'option'},
        { value: 'IADC FW HW', type: 'option'},
        { value: 'IADC FW HW red', type: 'option'},
        { value: 'IADC VM', type: 'option'},
        { value: 'IADC Server HW', type: 'option'},
        { value: 'IADC IT/OT', type: 'option'},
        { value: 'IADC PH Server', type: 'option'},
        { value: 'IADC PH SC', type: 'option'},
        { value: 'IADC BR Server', type: 'option'},
        { value: 'IADC BR SC', type: 'option'},
        { value: 'IADC BR SL', type: 'option'}
      ]
    },
    {
      element: 'physicalSystemPCS7ComponentTypesDropdown',
      options: [
        { value: 'General', type: 'category'}, 
        { value: 'Unclassified', type: 'option'}, 
        { value: 'IPC Unclassified', type: 'option'}, 
        { value: 'Not relevant for LCS', type: 'option'},
        { value: 'PCS 7 HW', type: 'category'}, 
        { value: 'Domain Controller HW', type: 'option'}, 
        { value: 'Engineering HW', type: 'option'}, 
        { value: 'Batch HW', type: 'option'}, 
        { value: 'Maintenance Station HW', type: 'option'},
        { value: 'OPC Server HW', type: 'option'},
        { value: 'OS Client HW', type: 'option'},
        { value: 'OS Server HW', type: 'option'},
        { value: 'PH&IS HW', type: 'option'},
        { value: 'Plant Device Management HW', type: 'option'},
        { value: 'Route Control HW', type: 'option'},
        { value: 'Web Server HW', type: 'option'},
        { value: 'WSUS HW', type: 'option'},
        { value: 'CP-Server', type: 'option'},
        { value: 'PCS 7 SW', type: 'category'}, 
        { value: 'Maintenance Station SW & Lic', type: 'option'},
        { value: 'OPC Server SW', type: 'option'},
        { value: 'PCS 7 Addon, Libaries, ….', type: 'option'},
        { value: 'PH&IS SW', type: 'option'},
        { value: 'Plant Device Management', type: 'option'},
        { value: 'SIMATIC BATCH SW Client', type: 'option'},
        { value: 'SIMATIC BATCH SW Server', type: 'option'},
        { value: 'SIMATIC Route Control SW Client', type: 'option'},
        { value: 'SIMATIC Route Control SW Eng', type: 'option'},
        { value: 'SIMATIC Route Control SW Server', type: 'option'},
        { value: 'System Administration/Management', type: 'option'},
        { value: 'Web Server SW', type: 'option'},
        { value: 'Software Update Service', type: 'option'},
        { value: 'Upgrade Package - Attention', type: 'option'},
        { value: 'F-SYS SW', type: 'option'},
        { value: 'Safety-Matrix SW', type: 'option'},
        { value: 'Field', type: 'category'}, 
        { value: 'Communication Processor (CP)', type: 'option'},
        { value: 'Controller-redundant', type: 'option'},
        { value: 'Controller', type: 'option'},
        { value: 'Fieldbus components', type: 'option'},
        { value: 'Network components', type: 'option'},
        { value: 'Network components suppl.', type: 'option'},
        { value: 'IADC', type: 'category'}, 
        { value: 'IADC FW SC', type: 'option'},
        { value: 'IADC FW Invalid SC', type: 'option'},
        { value: 'IADC FW HW', type: 'option'},
        { value: 'IADC FW HW red', type: 'option'},
        { value: 'IADC VM', type: 'option'},
        { value: 'IADC Server HW', type: 'option'},
        { value: 'IADC IT/OT', type: 'option'},
        { value: 'IADC PH Server', type: 'option'},
        { value: 'IADC PH SC', type: 'option'},
        { value: 'IADC BR Server', type: 'option'},
        { value: 'IADC BR SC', type: 'option'},
        { value: 'IADC BR SL', type: 'option'},
        { value: 'Other', type: 'category'}, 
        { value: '3-Party SW Addons', type: 'option'},
        { value: '3-Party HW Addons', type: 'option'}
      ]
    },
    {
      element: 'physicalSystemPcsNeoComponentTypesDropdown',
      options: [
        { value: 'General', type: 'category'}, 
        { value: 'Unclassified', type: 'option'}, 
        { value: 'IPC Unclassified', type: 'option'}, 
        { value: 'Not relevant for LCS', type: 'option'},
        { value: 'PCS neo HW', type: 'category'}, 
        { value: 'Connectivity Station HW', type: 'option'}, 
        { value: 'Diagnostic & Maintenance Server HW', type: 'option'}, 
        { value: 'Domain Controller HW', type: 'option'}, 
        { value: 'ES Server HW', type: 'option'},
        { value: 'M&C Server HW', type: 'option'},
        { value: 'PCS Neo RC-Server HW', type: 'option'},
        { value: 'PH&IS HW', type: 'option'},
        { value: 'Plant Clients HW', type: 'option'},
        { value: 'SIMATIC PCS neo BATCH HW', type: 'option'},
        { value: 'Terminal Server HW', type: 'option'},
        { value: 'White Listing HW', type: 'option'},
        { value: 'WSUS HW', type: 'option'},
        { value: 'CP-Server', type: 'option'},
        { value: 'OPC Server HW', type: 'option'},
        { value: 'PCS neo SW', type: 'category'}, 
        { value: 'SIMATIC BATCH SW & Lic Server ', type: 'option'},
        { value: 'Diagnostic & Maintenance SW & Lic', type: 'option'},
        { value: 'M&C Server SW & Lic OPC UA', type: 'option'},
        { value: 'Module Type Package', type: 'option'},
        { value: 'Neo PH&IS SW', type: 'option'},
        { value: 'Open Connectivity', type: 'option'},
        { value: 'PCS neo Addon, Libaries, ….', type: 'option'},
        { value: 'PCS Neo Route Control SW', type: 'option'},
        { value: 'Plant & Web Client SW & Lic', type: 'option'},
        { value: 'SIMATIC BATCH SW & Lic Client', type: 'option'},
        { value: 'Software Maintenance Package', type: 'option'},
        { value: 'PCS Neo Operation Management SW', type: 'option'},
        { value: 'F-SYS SW', type: 'option'},
        { value: 'Safety-Matrix SW', type: 'option'},
        { value: 'Field', type: 'category'}, 
        { value: 'Communication Processor (CP)', type: 'option'},
        { value: 'Controller-redundant', type: 'option'},
        { value: 'Controller', type: 'option'},
        { value: 'Fieldbus components', type: 'option'},
        { value: 'Network components', type: 'option'},
        { value: 'Network components suppl.', type: 'option'},
        { value: 'IADC', type: 'category'}, 
        { value: 'IADC FW SC', type: 'option'},
        { value: 'IADC FW Invalid SC', type: 'option'},
        { value: 'IADC FW HW', type: 'option'},
        { value: 'IADC FW HW red', type: 'option'},
        { value: 'IADC VM', type: 'option'},
        { value: 'IADC Server HW', type: 'option'},
        { value: 'IADC IT/OT', type: 'option'},
        { value: 'IADC PH Server', type: 'option'},
        { value: 'IADC PH SC', type: 'option'},
        { value: 'IADC BR Server', type: 'option'},
        { value: 'IADC BR SC', type: 'option'},
        { value: 'IADC BR SL', type: 'option'},
        { value: 'Other', type: 'category'}, 
        { value: '3-Party SW Addons', type: 'option'},
        { value: '3-Party HW Addons', type: 'option'}
      ]
    },
    {
      element: 'newIndividualIuWTaskComponent',
      options: [
        { value: 'PC' },
        { value: 'Central Cabinet' },
        { value: 'Decentral Cabinet' },
        { value: 'ES' },
        { value: 'OS-Server' },
        { value: 'Switch' },
        { value: 'Plant' }
      ]
    },
    {
      element: 'remoteServicePeriod',
      options: [
        { value: 'one-time' },
        { value: 'per month' },
        { value: 'per year' }
      ]
    },
    {
      element: 'hmiAdaptionOptionSelection',
      options: [
        { value: 'based on [%] of ini. engineering' },
        { value: 'based on cost per picture' }
      ]
    },
    {
      element: 'replaceNetworkComponentsInYear',
      options: [
        { value: '2022' },
        { value: '2023' },
        { value: '2024' },
        { value: '2025' },
        { value: '2026' },
        { value: '2027' },
        { value: '2028' },
        { value: '2029' },
        { value: '2030' },
        { value: '2031' },
        { value: '2032' },
        { value: '2033' },
        { value: '2034' },
        { value: '2035' },
        { value: '2036' },
        { value: '2037' },
        { value: '2038' },
        { value: '2039' },
        { value: '2040' },
        { value: '2041' },
        { value: '2042' },
        { value: '2043' },
        { value: '2045' },
        { value: '2046' },
        { value: '2047' },
        { value: '2048' },
        { value: '2049' },
        { value: '2051' },
        { value: '2052' },
        { value: '2053' },
        { value: '2054' }

      ]
    }
  ];
}
