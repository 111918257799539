import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SiNewtonLoadingService } from '@simpl/newton-ng/loading-spinner';
import { SivaasComponent } from 'src/app/data-models/SivaasComponent';
import { DataTransferService } from 'src/app/services/data-transfer.service';
import { FormDataService } from 'src/app/services/form-data.service';
import { ProductPriceServiceService } from 'src/app/services/product-price-service.service';
import { UiElements } from 'src/app/const-data/ui-elements';

export interface DropDownOption {
  value: string;
}

interface KeyValuePair {
  [key: string]: string | number;
}

@Component({
  selector: 'app-sivaas-system',
  templateUrl: './sivaas-system.component.html',
  styleUrls: ['./sivaas-system.component.scss']
})
export class SivaasSystemComponent {

  @ViewChild('fileSelection', { static: false })
  fileSelection: ElementRef<HTMLInputElement> | undefined;

  isThisSectionVisible = false;
  bomFile: File | undefined;

  toggleVisibility(){
    console.log(this.isThisSectionVisible);
    this.isThisSectionVisible = !this.isThisSectionVisible;
  }

  @Input()
  parentForm!: FormGroup;

  @Output()
  readData: EventEmitter<any> = new EventEmitter();

  @Output()
  submitValues: EventEmitter<any> = new EventEmitter();

  allSivaasComponents: SivaasComponent[] = [];

  isSivaasConfigurationVisbible = false;
  areNullValuesInPricesPresent = false;


  public getDropdownOptions(formControlName: string){
    return this.formDataService.getDropDownOptions(formControlName);
  }

  public getDropdownValue(dropDownOption: DropDownOption): string {
    return dropDownOption.value;
  }

  isNewComponentValid(){

    try {
      if (!this.parentForm.get('sivaasSystem')?.get('SivaasConfCategory')?.value.value){
        return false;
      } else if (!this.parentForm.get('sivaasSystem')?.get('newComponentName')?.value){
        return false;
      } else if (!this.parentForm.get('sivaasSystem')?.get('newComponentMlfb')?.value){
        return false;
      } else if (!this.parentForm.get('sivaasSystem')?.get('newComponentQuantity')?.value){
        return false;
      } else if (this.parentForm.get('sivaasSystem')?.get('newComponentQuantity')?.value < 0){
        return false;
      } else if (!this.parentForm.get('sivaasSystem')?.get('newComponentQuantity')?.valid){
        return false;
      } else {
        return true;
      }
    } catch {
      return false;
    }
  }

  isSivaasComponentPresent(compNumber: number): boolean {

    try {
      if (this.parentForm.get('sivaasSystem')?.get('SivaasConfCategory' + compNumber)?.value){
        return true;
      } else {
        return false;
      }
    } catch {
      return false;
    }
  }

  toggleSivaasConfVisible(){
    this.isSivaasConfigurationVisbible = !this.isSivaasConfigurationVisbible;
  }

  constructor(private formDataService: FormDataService, private dataTransferService: DataTransferService,
    private productPriceService: ProductPriceServiceService,
    private loadingSpinner: SiNewtonLoadingService) { }

  async storeSivaasBom(){

    this.bomFile = this.fileSelection?.nativeElement.files?.item(0) || undefined;

  }

  async uploadBOM(pcsVersion: string){

    if (this.bomFile !== undefined){

      const sivaasBomValues = await this.dataTransferService.importSystem(this.bomFile, pcsVersion);

      await this.setNewSivaasSystem(sivaasBomValues);

      this.submitValues.emit();

    }
  }

  async setNewSivaasSystem(valuesAsJson: { [key: string]: string | number }){
    for (const key in valuesAsJson) {
      if (valuesAsJson.hasOwnProperty(key)) {
        const value = valuesAsJson[key];
        this.parentForm.get('sivaasSystem')!.get(key)!.setValue(value);
        this.parentForm.get('sivaasSystem')!.get(key)!.markAsDirty();
      }
    }
  }
  
  async fillTableWithSivaasComponents(){
    this.allSivaasComponents = [];

    this.areNullValuesInPricesPresent = false;

    for (let i = 1; i <= 150; i++){

      if (this.isSivaasComponentPresent(i)){

        this.allSivaasComponents.push({
          'componentNumber': i,
          'componentType': this.parentForm.get('sivaasSystem')?.get('SivaasConfCategory' + i)?.value,
          'name': this.parentForm.get('sivaasSystem')?.get('SivaasConfName' + i)?.value,
          'mlfb': this.parentForm.get('sivaasSystem')?.get('SivaasConfMLFB' + i)?.value,
          'quantity': this.parentForm.get('sivaasSystem')?.get('SivaasConfQuantity' + i)?.value,
          'listPrice': this.parentForm.get('sivaasSystem')?.get('SivaasConfListPrice' + i)?.value,
          'totalListPrice': this.parentForm.get('sivaasSystem')?.get('SivaasConfTotalListPrice' + i)?.value,
          'totalGaPrice': this.parentForm.get('sivaasSystem')?.get('SivaasConfTotalGAPrice' + i)?.value
        });

        if (this.parentForm.get('sivaasSystem')?.get('SivaasConfListPrice' + i)?.value === '0'){
          this.areNullValuesInPricesPresent = true;
        }
      }
    }

    console.log(this.allSivaasComponents);

  }

  async deleteComponent(componentNumber: number){

    const updatedSivaasComponents: KeyValuePair = {};


    for (let i = componentNumber; i <= this.allSivaasComponents.length; i++){

      if (i === this.allSivaasComponents.length){
        updatedSivaasComponents['SivaasConfCategory' + i] = '';
        updatedSivaasComponents['SivaasConfName' + i] = '';
        updatedSivaasComponents['SivaasConfMLFB' + i] = '';
        updatedSivaasComponents['SivaasConfQuantity' + i] = 0;
        updatedSivaasComponents['SivaasConfListPrice' + i] = 0;

      } else {
        updatedSivaasComponents['SivaasConfCategory' + i] =
          this.parentForm.get('sivaasSystem')?.get('SivaasConfCategory' + (i + 1))?.value;
        updatedSivaasComponents['SivaasConfName' + i] =
        this.parentForm.get('sivaasSystem')?.get('SivaasConfName' + (i + 1))?.value;
        updatedSivaasComponents['SivaasConfMLFB' + i] =
        this.parentForm.get('sivaasSystem')?.get('SivaasConfMLFB' + (i + 1))?.value;
        updatedSivaasComponents['SivaasConfQuantity' + i] =
        this.parentForm.get('sivaasSystem')?.get('SivaasConfQuantity' + (i + 1))?.value || 0;
        updatedSivaasComponents['SivaasConfListPrice' + i] =
        this.parentForm.get('sivaasSystem')?.get('SivaasConfListPrice' + (i + 1))?.value || 0;
      }

    }

    await this.dataTransferService.writeDataToExcel(JSON.stringify(updatedSivaasComponents));

    this.readData.emit();
  }

  async addNewComponent(updatedSivaasComponents: KeyValuePair, id: number){
    updatedSivaasComponents['SivaasConfCategory' + id] =
    this.parentForm.get('sivaasSystem')?.get('SivaasConfCategory')?.value.value;

    updatedSivaasComponents['SivaasConfName' + id] =
      this.parentForm.get('sivaasSystem')?.get('newComponentName')?.value;

    updatedSivaasComponents['SivaasConfMLFB' + id] =
    this.parentForm.get('sivaasSystem')?.get('newComponentMlfb')?.value;

    updatedSivaasComponents['SivaasConfQuantity' + id] =
      this.parentForm.get('sivaasSystem')?.get('newComponentQuantity')?.value;

    const priceMflbList =
    await this.productPriceService.getCurrentPricesByMlfbList(updatedSivaasComponents['SivaasConfMLFB' + id].toString()) as any;

    updatedSivaasComponents['SivaasConfListPrice' + id] = priceMflbList[updatedSivaasComponents['SivaasConfMLFB' + id]];

    this.parentForm.get('sivaasSystem')?.get('newComponentListPrice')?.
      setValue(priceMflbList[updatedSivaasComponents['SivaasConfMLFB' + id]]);

  }

  async updateSivaasComponents(){
    const updatedSivaasComponents: KeyValuePair = {};

    for (let i = 1; i <= this.allSivaasComponents.length; i++){

      updatedSivaasComponents['SivaasConfCategory' + i] = this.allSivaasComponents[i - 1].componentType;
      updatedSivaasComponents['SivaasConfName' + i] = this.allSivaasComponents[i - 1].name;
      updatedSivaasComponents['SivaasConfMLFB' + i] = this.allSivaasComponents[i - 1].mlfb;
      updatedSivaasComponents['SivaasConfQuantity' + i] = this.allSivaasComponents[i - 1].quantity;
      updatedSivaasComponents['SivaasConfListPrice' + i] = this.allSivaasComponents[i - 1].listPrice;

      if (i === this.allSivaasComponents.length){
        await this.addNewComponent(updatedSivaasComponents, (i + 1));
      }
    }

    if (this.allSivaasComponents.length === 0){
      await this.addNewComponent(updatedSivaasComponents, 1);
    }

    await this.dataTransferService.writeDataToExcel(JSON.stringify(updatedSivaasComponents));

    this.readData.emit();
  }

  
  public getNumOfUnclassifiedElements(pcsVersion: string): number{

    const componentTypeDropdownData = UiElements.dropDowns.find(dropdown => dropdown.element === 'sivaasComponentTypesDropdown');
    const possibleComponentTypes = componentTypeDropdownData ? componentTypeDropdownData.options.map((option: DropDownOption) => option.value) : [];

    let unclassifiedElements = [];

      unclassifiedElements = this.allSivaasComponents.filter(element =>
        !possibleComponentTypes.includes(element.componentType) || element.componentType === 'Unclassified');



    return unclassifiedElements.length;
  }

  // openSivaasImportModal(){
  //   this.modalRef = this.modalService.show(ImportSivaasConfigModalComponent, { });
  //   this.modalRef.content.closeBtnName = 'Close';

  //   this.modalRef.content.importedConfiguration = false;

  //   this.modalRef.onHidden.subscribe(async (result: any) => {
  //     if (this.modalRef.content.importedConfiguration){
  //       this.readData.emit();
  //     }
  //   });
  // }

  getSivaasComponentsWithoutIADC(){
    return this.allSivaasComponents.filter(element => !element.componentType.includes('IADC'));
  }

  getSivaasComponentsForIADC(){
    return this.allSivaasComponents.filter(element => element.componentType.includes('IADC'));
  }

  areSivaasComponentsWithNoPricePresent(){

    return this.areNullValuesInPricesPresent;

  }

  async getPricesForSivaasComponents(){

    this.loadingSpinner.startLoading();

    let sivaasComponentsCommaSeperated = '';
    for (const sivaasComponent of this.allSivaasComponents){
      sivaasComponentsCommaSeperated += sivaasComponent.mlfb + ',';
    }

    const priceMflbList =
      await this.productPriceService.getCurrentPricesByMlfbList(sivaasComponentsCommaSeperated.replace(/.$/, '')) as any;

    for (const sivaasComponent of this.allSivaasComponents){

      if (priceMflbList[sivaasComponent.mlfb] !== undefined && priceMflbList[sivaasComponent.mlfb] !== sivaasComponent.listPrice){
        // eslint-disable-next-line max-len
        this.parentForm.get('sivaasSystem')!.get('SivaasConfListPrice' + (sivaasComponent.componentNumber))!.setValue(priceMflbList[sivaasComponent.mlfb]);
        this.parentForm.get('sivaasSystem')!.get('SivaasConfListPrice' + (sivaasComponent.componentNumber))!.markAsDirty();
        this.parentForm.get('sivaasSystem')!.get('SivaasConfListPrice' + (sivaasComponent.componentNumber))!.markAsUntouched();
      }
    }

    this.loadingSpinner.stopLoading();

    this.submitValues.emit();
  }

  
  async downloadFile(fileType: string){
    this.loadingSpinner.startLoading();
    await this.dataTransferService.downloadFile(fileType);
    this.loadingSpinner.stopLoading();
  }
}
