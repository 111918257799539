import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DataTransferService } from 'src/app/services/data-transfer.service';
import { FormDataService } from 'src/app/services/form-data.service';
import { SwMaintenanceContractTimelineComponent } from './sub-sections/sw-maintenance-contract-timeline/sw-maintenance-contract-timeline.component';

@Component({
  selector: 'app-sw-maintenance',
  templateUrl: './sw-maintenance.component.html',
  styleUrls: ['./sw-maintenance.component.scss']
})
export class SwMaintenanceComponent {

  swMaintenanceForm: FormGroup;
  @ViewChild(SwMaintenanceContractTimelineComponent) swMaintContractTimeline!: SwMaintenanceContractTimelineComponent;



  constructor(private formBuilder: FormBuilder, private formDataService: FormDataService,
      private dataTransferService: DataTransferService) { 
      
      this.swMaintenanceForm = this.formBuilder.group({
        swMaintContractTimeline: this.formBuilder.group({
          startWithPcs7OrPcsNeo: [null, Validators.required],
          lcsContractDuration: [null, Validators.required],
          susContractDuration: [null, Validators.required],
          pcs7OutOfWarranty: [null, Validators.required],
          yearOfStartFix: [null],
          firstUpgradeOverall: [null],
          secondUpgradeOverall: [null],
          thirdUpgradeOverall: [null],
          pcsNeoEvolution: [0],
          pcs7UpgradeYear1: [0], pcs7UpgradeYear2: [0], pcs7UpgradeYear3: [0],
          pcsNeoHwUpgradeYear1: [0], pcsNeoHwUpgradeYear2: [0], pcsNeoHwUpgradeYear3: [0],
          pcsNeoSwUpgradeYear1: [0], pcsNeoSwUpgradeYear2: [0], pcsNeoSwUpgradeYear3: [0],
        }),
        swMaintCostOverview: this.formBuilder.group({
          yearOfStartFix: [null],
          lcsContractDuration: [null, Validators.required]
        }),
        legacySystemServiceForm: this.formBuilder.group({
          lssPackageSelection: [null, Validators.required],
          lssPackageExtension: [null, Validators.required],
          lssPackageV7Mlfb: [null, Validators.required],
          lssPackageV7ExtensionMlfb: [null, Validators.required],
          lssPackageV8Mlfb: [null, Validators.required],
          lssPackageV8ExtensionMlfb: [null, Validators.required],
          lssPackageV9Mlfb: [null, Validators.required],
          lssPackageV9ExtensionMlfb: [null, Validators.required],
          lssPackageV7Description: [null, Validators.required],
          lssPackageV7ExtensionDescription: [null, Validators.required],
          lssPackageV8Description: [null, Validators.required],
          lssPackageV8ExtensionDescription: [null, Validators.required],
          lssPackageV9Description: [null, Validators.required],
          lssPackageV9ExtensionDescription: [null, Validators.required],
          lssPackageV7LPrice: [null, Validators.required],
          lssPackageV7ExtensionLPrice: [null, Validators.required],
          lssPackageV8LPrice: [null, Validators.required],
          lssPackageV8ExtensionLPrice: [null, Validators.required],
          lssPackageV9LPrice: [null, Validators.required],
          lssPackageV9ExtensionLPrice: [null, Validators.required],
          lssPackageV7GAPrice: [null, Validators.required],
          lssPackageV7ExtensionGAPrice: [null, Validators.required],
          lssPackageV8GAPrice: [null, Validators.required],
          lssPackageV8ExtensionGAPrice: [null, Validators.required],
          lssPackageV9GAPrice: [null, Validators.required],
          lssPackageV9ExtensionGAPrice: [null, Validators.required],


        }),
        neoSwProcMaintForm: this.formBuilder.group({
          pcsNeoLicenseProcurementwithoutPO: [null, Validators.required],
          pcsNeoLicenseProcurementwithoutPOCostIncreaseRate: [null, Validators.required],
          pcsNeoSWPInfinity: [null, Validators.required],
          pcsNeoLicenseProcurementIncPO: [null, Validators.required],
          pcsNeoLicenseProcurementIncPOShareOfTotal: [null, Validators.required],
          pcsNeoiLicenseProcurementCostIncreaseRate: [null, Validators.required],
        }),
        pcs7MaintenanceContract: this.formBuilder.group({
          relevantPcs7LicenseVolume: [null],
          shareOfPcs7LicenseVolumeWithoutRTperYear: [null],
        })
      });

      const swMaintContractTimelineAsFormGroup = this.swMaintenanceForm?.get('swMaintContractTimeline') as FormGroup;

      for(let i = 1; i <= 25; i++){
        swMaintContractTimelineAsFormGroup.addControl('pcs7SusYear' + i, new FormControl(0));
        swMaintContractTimelineAsFormGroup.addControl('pcsLssYear' + i, new FormControl(0));
        swMaintContractTimelineAsFormGroup.addControl('pcs7SwMCYear' + i, new FormControl(0));
        swMaintContractTimelineAsFormGroup.addControl('pcs7SwUpdateYear' + i, new FormControl(0));
        swMaintContractTimelineAsFormGroup.addControl('pcsNeoSwUpdateYear' + i, new FormControl(0));
      }

      const swMaintCostOverviewAsFormGroup = this.swMaintenanceForm?.get('swMaintCostOverview') as FormGroup;

      for(let i = 1; i <= 15; i++){
        swMaintCostOverviewAsFormGroup.addControl('pcs7SusCostYear' + i, new FormControl(0));
        swMaintCostOverviewAsFormGroup.addControl('pcs7LssCostYear' + i, new FormControl(0));
        swMaintCostOverviewAsFormGroup.addControl('pcs7SwMaintConCostYear' + i, new FormControl(0));
        swMaintCostOverviewAsFormGroup.addControl('pcsNeoLicenseProcCostYear' + i, new FormControl(0));
        swMaintCostOverviewAsFormGroup.addControl('pcsNeoSwInfinityCostYear' + i, new FormControl(0));
      }
  }
  ngOnInit() {
    this.readData();
    
  }

  async submit(){
    await this.writeData();
  }

  logInvalidControls(formGroup: FormGroup) {

    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.get(key);

      if (control instanceof FormGroup) {
        this.logInvalidControls(control);
      } else {
        if (control?.invalid) {

          console.log(`Control '${key}' is invalid`);
        }
      }
    });
  }

  async readData() {

    const keysFromDialog = await this.formDataService.getAllControlsFromDialogAsArray(this.swMaintenanceForm);

    const formData = await this.dataTransferService.getValuesFromExcel(keysFromDialog) as any;

    this.formDataService.fillDataIntoDialog(this.swMaintenanceForm, formData);

    await this.swMaintContractTimeline.updateContractTime();
    this.swMaintContractTimeline.fillSelectedYears('pcs7Upgrade', 3);
    this.swMaintContractTimeline.fillSelectedYears('pcsNeoHwUpgrade', 3);
    this.swMaintContractTimeline.fillSelectedYears('pcsNeoSwUpgrade', 3);
    this.swMaintContractTimeline.fillSelectedYears('pcs7SwUpdate', 25);
    this.swMaintContractTimeline.fillSelectedYears('pcsNeoSwUpdate', 25);
    this.swMaintContractTimeline.fillSelectedYears('pcs7Sus', 25);
    this.swMaintContractTimeline.fillSelectedYears('pcsLss', 25);
    this.swMaintContractTimeline.fillSelectedYears('pcs7SwMC', 25);
    

  }

  async writeData() {
    const jsonData = await this.formDataService.getDataFromDialog(this.swMaintenanceForm);

    await this.dataTransferService.writeDataToExcel(JSON.stringify(jsonData));

    await this.readData();
  }

  scrollToTop(){
    const element = document.getElementById('first-element');
    element?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }



}
