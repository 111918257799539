import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UserManagementService } from 'src/app/services/user-management.service';

@Component({
  selector: 'app-corrective-maintenance',
  templateUrl: './corrective-maintenance.component.html',
  styleUrls: ['./corrective-maintenance.component.scss']
})
export class CorrectiveMaintenanceComponent {
  isThisSectionVisible = false;

  toggleVisibility(){
    console.log(this.isThisSectionVisible);
    this.isThisSectionVisible = !this.isThisSectionVisible;
  }

  @Input()
  parentForm!: FormGroup;
  correctiveMainServiceTimesRb!: string;

  constructor(private userManagementService: UserManagementService) {  }

  getUserRole(){
    return this.userManagementService.getUserRole();
  }

}
