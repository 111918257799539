import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SiNewtonLoadingService } from '@simpl/newton-ng/loading-spinner';
import { saveAs } from 'file-saver';
import { environment } from 'src/environments/environment';
import { CustomToastService } from './custom-toast.service';


@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private httpService: HttpClient, private loadingSpinner: SiNewtonLoadingService,
    private toastService: CustomToastService) { }

  downloadCalculationFile(projectId: string, projectName: string) {

    return new Promise((resolve, reject) => {
      const endpoint = environment.baseUrl + environment.apiUrl + '/projects/download/lcs/' + projectId;
      const options = {
        headers: new HttpHeaders({
          'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }),
        responseType: 'blob' as const,
        observe: 'body' as const
      };

      this.httpService.get(endpoint, options).subscribe(blob => {

        if (projectId === 'Masterfile') {
          saveAs(blob, 'LCS_Master.xlsx');
        } else {
          saveAs(blob, projectId + '_' + projectName + '-in-work.xlsx');
        }
        return resolve(true);
      }, (errorResponse: HttpErrorResponse) => {

        this.toastService.showErrorToast('Could not download the project file');

        return resolve(true);
      });
    });
  }

  uploadCalculationFile(projectId: string, projectFile: File) {

    return new Promise((resolve, reject) => {
      const endpoint = environment.baseUrl + environment.apiUrl + '/projects/upload/lcs/' + projectId;
      const formData: FormData = new FormData();
      formData.append('file', projectFile);

      const options = {
        observe: 'response' as const
      };

      this.loadingSpinner.startLoading();

      this.httpService.post(endpoint, formData, options).subscribe(response => {

        this.toastService.showSuccessToast((response as any).body.message);
        this.loadingSpinner.stopLoading();
        return resolve(true);
      }, (errorResponse: HttpErrorResponse) => {
        console.log(errorResponse);
        this.toastService.showErrorToast(errorResponse.error.message);
        this.loadingSpinner.stopLoading();
        return resolve(true);
      });
    });
  }

  async uploadConfigFile(file: File, fileType: string) {

    const masterFileType = 'Masterfile';
    const mlfbReference = 'Referencefile';

    if (fileType != masterFileType && fileType != mlfbReference) {
      this.toastService.showErrorToast(`Only uploads of ${masterFileType} or ${mlfbReference} are possible`);
      return;
    }

    let endpoint = '';

    if (fileType == masterFileType) {
      endpoint = environment.baseUrl + environment.apiUrl + '/masterfile/upload/lcs';
    } else if (fileType == mlfbReference) {
      endpoint = environment.baseUrl + '/file-transfer/upload-mlfb-reference';
    }

    const formData: FormData = new FormData();
    formData.append('file', file);

    const options = {
      observe: 'response' as const
    };

    const resultOfRequest = await this.httpService.post(endpoint, formData, options).toPromise();

    if (resultOfRequest.status == 200) {
      await this.updateFileInfo(fileType, file.name);
      this.toastService.showSuccessToast((resultOfRequest as any).body.message);
    } else {
      this.toastService.showErrorToast(`Something went wrong. ${fileType} was not updated.`);
    }
  }

  async updateFileInfo(fileType: string, fileName: string){
    const endpoint = environment.baseUrl + '/file-info/update-file-info';

    const body = {
      fileType,
      fileName
    }

    const options = {
      observe: 'response' as const
    };

    const resultOfRequest = await this.httpService.post(endpoint, body, options).toPromise();

    console.log(resultOfRequest);

  }

  async getFileInfo(fileType: string): Promise<{ file_name: string; file_upload: string; }>{
    const endpoint = environment.baseUrl + '/file-info/get-file-info';

    const body = {
      fileType
    }
    const options = {
      observe: 'response' as const
    };

    try {
      const resultOfRequest = await this.httpService.post(endpoint, body, options).toPromise();

      return (resultOfRequest.body as {file_name: string, file_upload: string});

    } catch {
      return {
        file_name: '',
        file_upload: ''
      }
    }


  }

}
