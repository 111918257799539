<form [formGroup]="parentForm" style="margin: auto;">
  <div formGroupName="plantTopology">
    <h5 class="subSectionHeader">Plant Topology
        <i style="float: right; cursor: pointer"
      [ngClass]="{'bi-arrow-up-circle-fill': isThisSectionVisible, 'bi-arrow-down-circle-fill': !isThisSectionVisible}"
      (click)="toggleVisibility()"></i>
    </h5>
    <div *ngIf="isThisSectionVisible">
      <div class="row">
        <div class="col-xs-12 col-sm-6 col-md-3 col-lg-2">
          <b class="labelAccent">Central Control Rooms</b>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-3 col-lg-2">
          <si-newton-form-group label="Quantity" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers allowed here'}">
            <input formControlName="centralControlRooms" type="number" [min]="0" siNewton>
          </si-newton-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <b class="subSubSectionHeader">Cabinets</b>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 col-sm-6 col-md-3 col-lg-2">
          <b class="labelAccent">Central Cabinets</b>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-3 col-lg-2">
          <si-newton-form-group label="Quantity" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers allowed here'}">
            <input formControlName="centralCabinets" type="number" [min]="0" siNewton>
          </si-newton-form-group>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-3 col-lg-2">
          <b class="labelAccent">Distributed Cabinets</b>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-3 col-lg-2">
          <si-newton-form-group label="Quantity" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers allowed here'}">
            <input formControlName="distributedCabinets" type="number" [min]="0" siNewton>
          </si-newton-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <b class="subSubSectionHeader">Transfer Time on site </b>
          <label title="Transfer Time from Central Control room to location">
            <i class="bi bi-question-circle-fill" style="color: #eb780a"></i>
          </label>
        </div>
      </div>

      <div class="row">

        <div class="col-xs-12 col-sm-6 col-md-3 col-lg-2">
          <b class="labelAccent">Central Cabinet </b>
          <label title="per Control room">
            <i class="bi bi-question-circle-fill" style="color: #eb780a"></i>
          </label>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-3 col-lg-2">
          <si-newton-form-group label="Transfer Time" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers allowed here'}">
            <input formControlName="transferTimeToCentralCabinet" type="number" [min]="0" siNewton>
            <div class="input-group-prepend">
              <span class="input-group-text">min</span>
            </div>
          </si-newton-form-group>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-3 col-lg-2">
          <b class="labelAccent">Distributed Cabinet </b>
          <label title="per Cabinet">
            <i class="bi bi-question-circle-fill" style="color: #eb780a"></i>
          </label>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-3 col-lg-2">
          <si-newton-form-group label="Transfer Time" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers allowed here'}">
            <input formControlName="transferTimeToDistributedCabinet" type="number" [min]="0" siNewton>
            <div class="input-group-prepend">
              <span class="input-group-text">min</span>
            </div>
          </si-newton-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <b class="subSubSectionHeader">Difficult access to physical components</b>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-1">
          <b class="labelAccent">ASes / PLCs</b>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-5 col-lg-2">
          <si-newton-form-group label="No. of rel. components" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers allowed here'}">
            <input formControlName="ASesNoOfComponents" type="number" [min]="0" siNewton>
          </si-newton-form-group>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-5 col-lg-2">
          <si-newton-form-group label="Add. effort per piece" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers allowed here'}">
            <input formControlName="ASesAddEffort" type="number" [min]="0" siNewton>
            <div class="input-group-prepend">
              <span class="input-group-text">min</span>
            </div>
          </si-newton-form-group>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-1">
          <b class="labelAccent">PCs</b>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-5 col-lg-2">
          <si-newton-form-group label="No. of rel. components" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers allowed here'}">
            <input formControlName="PcsNoOfComponents" type="number" [min]="0" siNewton>
          </si-newton-form-group>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-5 col-lg-2">
          <si-newton-form-group label="Add. effort per piece" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers allowed here'}">
            <input formControlName="PcsAddEffort" type="number" [min]="0" siNewton>
            <div class="input-group-prepend">
              <span class="input-group-text">min</span>
            </div>
          </si-newton-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-1">
          <b class="labelAccent">Cabinets</b>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-5 col-lg-2">
          <si-newton-form-group label="No. of rel. components" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers allowed here'}">
            <input formControlName="CabinetsNoOfComponents" type="number" [min]="0" siNewton>
          </si-newton-form-group>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-5 col-lg-2">
          <si-newton-form-group label="Add. effort per piece" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers allowed here'}">
            <input formControlName="CabinetsAddEffort" type="number" [min]="0" siNewton>
            <div class="input-group-prepend">
              <span class="input-group-text">min</span>
            </div>
          </si-newton-form-group>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-1">
          <b class="labelAccent">Switches</b>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-5 col-lg-2">
          <si-newton-form-group label="No. of rel. components" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers allowed here'}">
            <input formControlName="SwitchesNoOfComponents" type="number" [min]="0" siNewton>
          </si-newton-form-group>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-5 col-lg-2">
          <si-newton-form-group label="Add. effort per piece" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers allowed here'}">
            <input formControlName="SwitchesAddEffort" type="number" [min]="0" siNewton>
            <div class="input-group-prepend">
              <span class="input-group-text">min</span>
            </div>
          </si-newton-form-group>
        </div>
      </div>
    </div>


  </div>
</form>
