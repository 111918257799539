<form [formGroup]="parentForm">
  <div formGroupName="onCallServiceControlCenter">

    <h5 class="subSectionHeader">On Call Service
      <i style="float: right; cursor: pointer"
      [ngClass]="{'bi-arrow-up-circle-fill': isThisSectionVisible, 'bi-arrow-down-circle-fill': !isThisSectionVisible}"
      (click)="toggleVisibility()"></i>
    </h5>

    <div  [ngStyle]="{'display': isThisSectionVisible ? 'block' : 'none'}">

      <div class="row">

        <div class="col">
          <div class="form-check">
            <input id="cb-enabled" class="form-check-input" formControlName="checkBoxOnCallService" type="checkbox">
            <label for="cb-enabled" class="form-check-label">
              OnCall Service
            </label>
          </div>
        </div>

          <div class="col" [hidden]="+(parentForm.get('onCallServiceControlCenter')?.get('checkBoxOnCallService')?.value) !== 1">
            <si-newton-form-group label="Cost per year" [errorResolverMap]="{required: 'Value is required'}">
              <div class="input-group-prepend">
                <span class="input-group-text">€</span>
              </div>
              <input formControlName="costPerYearOnCallService" type="number" step="any" [min]="0" siNewton>
            </si-newton-form-group>
          </div>

          <div class="col" [hidden]="+(parentForm.get('onCallServiceControlCenter')?.get('checkBoxOnCallService')?.value) !== 1">
            <si-newton-form-group label="Implementation (one time)" [errorResolverMap]="{required: 'Value is required'}">
              <div class="input-group-prepend">
                <span class="input-group-text">€</span>
              </div>
              <input formControlName="implementationCostOnCallService" type="number" step="any" [min]="0" siNewton>
            </si-newton-form-group>
          </div>

          <div class="col">

          </div>

      </div>
    </div>


  </div>
</form>
