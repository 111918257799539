<div class="modal-header">
  <h4 class="modal-title">Delete calculation {{projectName}}</h4>
</div>
<div class="modal-body">

  <div class="col-md-12">

    <div class="row">

      <div class="col-md-6">
        <b>Do you really want to delete the Contract {{projectName}}?</b>
      </div>

      <div class="col-md-3">
        <button type="button" class="btn btn-success"  style="float: left;" (click)="acceptDelete()">
          Delete contract
        </button>
      </div>

      <div class="col-md-3">
        <button type="button" class="btn btn-primary"  style="float: left;" (click)="modalRef.hide()">
          Cancel
        </button>
      </div>
    </div>
  </div>

</div>

<div class="modal-footer">

</div>
