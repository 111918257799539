<form [formGroup]="parentForm">
  <div formGroupName="sivaasSystemServices">

    <h5 class="subSectionHeader">SIVaaS System - Services
      <i style="float: right; cursor: pointer"
      [ngClass]="{'bi-arrow-up-circle-fill': isThisSectionVisible, 'bi-arrow-down-circle-fill': !isThisSectionVisible}"
      (click)="toggleVisibility()"></i>
    </h5>

    <div  [ngStyle]="{'display': isThisSectionVisible ? 'block' : 'none'}">

      <div class="row">
        <div class="col">
          <div class="form-check">
            <input id="cb-enabled" class="form-check-input" formControlName="checkBoxRegionalParmaterUpgrade" type="checkbox">
            <label for="cb-enabled" class="form-check-label">
              Use regional Parameters for virtual system upgrades
            </label>
          </div>
        </div>
      </div>
      <tabset>
        <tab heading="Off Site Services">
          <div class="row">
            <div class="col">
              <b class="subSubSectionHeader">Configuration based on SIVaaS BOM</b>

                <div class="row">
                  <div class="col-md-7">

                  </div>
                  <div class="col-md-1">
                    <b><u>Quantity</u></b>
                  </div>
                  <div class="col-md-1">
                    <b><u>Effective</u></b>
                  </div>
                  <div class="col-md-1">
                    <b><u>HQ</u></b>
                  </div>
                  <div class="col-md-2">
                    <b><u>Regional Estimation</u></b>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-3">
                    <b>Additional VMs</b>
                  </div>
                  <div class="col-md-2">

                  </div>
                  <div class="col-md-2">
                    <b>VMs PH / IS</b>
                  </div>

                  <div class="col-md-1">
                    <si-newton-form-group [errorResolverMap]="{required: 'Value is required'}">
                      <input formControlName="quantityOffSite12" type="number" step="any" [min]="0" siNewton readonly>
                    </si-newton-form-group>
                  </div>
                  <div class="col-md-1">
                    <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                      <input formControlName="effectiveTimeOffSite12" type="number" step="any" [min]="0" siNewton readonly>
                    </si-newton-form-group>
                  </div>
                  <div class="col-md-1">
                    <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                      <input formControlName="hQTimeOffSite12" type="number" step="any" [min]="0" siNewton readonly>
                    </si-newton-form-group>
                  </div>
                  <div class="col-md-2">
                    <si-newton-form-group label="Estimation" [errorResolverMap]="{required: 'Value is required'}">
                      <div class="input-group-prepend">
                        <span class="input-group-text">hours</span>
                      </div>
                      <input formControlName="regEstimationOffSite12" type="number" step="any" [min]="0" siNewton>
                    </si-newton-form-group>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-3">
                    <b>FAT VM vCenter Server</b>
                  </div>
                  <div class="col-md-2">

                  </div>
                  <div class="col-md-2">
                    <b>VM vCenter Server </b>
                  </div>

                  <div class="col-md-1">
                    <si-newton-form-group [errorResolverMap]="{required: 'Value is required'}">
                      <input formControlName="quantityOffSite18" type="number" step="any" [min]="0" siNewton readonly>
                    </si-newton-form-group>
                  </div>
                  <div class="col-md-1">
                    <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                      <input formControlName="effectiveTimeOffSite18" type="number" step="any" [min]="0" siNewton readonly>
                    </si-newton-form-group>
                  </div>
                  <div class="col-md-1">
                    <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                      <input formControlName="hQTimeOffSite18" type="number" step="any" [min]="0" siNewton readonly>
                    </si-newton-form-group>
                  </div>
                  <div class="col-md-2">
                    <si-newton-form-group label="Estimation" [errorResolverMap]="{required: 'Value is required'}">
                      <div class="input-group-prepend">
                        <span class="input-group-text">hours</span>
                      </div>
                      <input formControlName="regEstimationOffSite18" type="number" step="any" [min]="0" siNewton>
                    </si-newton-form-group>
                  </div>
                </div>


                <div class="row">
                  <div class="col-md-3">
                    <b>Create Final report / Acceptance protocoll</b>
                  </div>
                  <div class="col-md-2">

                  </div>
                  <div class="col-md-2">

                  </div>

                  <div class="col-md-1">
                    <si-newton-form-group [errorResolverMap]="{required: 'Value is required'}">
                      <input formControlName="quantityOffSite21" type="number" step="any" [min]="0" siNewton readonly>
                    </si-newton-form-group>
                  </div>
                  <div class="col-md-1">
                    <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                      <input formControlName="effectiveTimeOffSite21" type="number" step="any" [min]="0" siNewton readonly>
                    </si-newton-form-group>
                  </div>
                  <div class="col-md-1">
                    <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                      <input formControlName="hQTimeOffSite21" type="number" step="any" [min]="0" siNewton readonly>
                    </si-newton-form-group>
                  </div>
                  <div class="col-md-2">
                    <si-newton-form-group label="Estimation" [errorResolverMap]="{required: 'Value is required'}">
                      <div class="input-group-prepend">
                        <span class="input-group-text">hours</span>
                      </div>
                      <input formControlName="regEstimationOffSite21" type="number" step="any" [min]="0" siNewton>
                    </si-newton-form-group>
                  </div>
                </div>
            </div>
          </div>
        </tab>
        <tab heading="On-site Services">
          <div class="row">
            <div class="col">
              <b class="subSubSectionHeader">Installation of Host Systems</b>
            </div>
          </div>


          <div class="row">
            <div class="col-md-5">

            </div>
            <div class="col-md-2">
              <b><u>Component</u></b>
            </div>
            <div class="col-md-1">
              <b><u>Quantity</u></b>
            </div>
            <div class="col-md-1">
              <b><u>Effective</u></b>
            </div>
            <div class="col-md-1">
              <b><u>HQ</u></b>
            </div>
            <div class="col-md-2">
              <b><u>Regional Estimation</u></b>
            </div>
          </div>

          <div class="row">
            <div class="col-md-5">
              <b> Mounting in a 19' - rack / cabinet --Assembly (Keyboard / Mouse / Monitor)' - rack / cab </b>
              <label title="Control Room / Schaltraum">
                <i class="bi bi-question-circle-fill" style="color: #eb780a"></i>
              </label>
            </div>
            <div class="col-md-2">
              <b>Host System</b>
            </div>

            <div class="col-md-1">
              <si-newton-form-group [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="quantityOnSite1" type="number" step="any" [min]="0" siNewton readonly>
              </si-newton-form-group>
            </div>
            <div class="col-md-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="effectiveOnSite1" type="number" step="any" [min]="0" siNewton readonly>
              </si-newton-form-group>
            </div>
            <div class="col-md-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="hqTimeOnSite1" type="number" step="any" [min]="0" siNewton readonly>
              </si-newton-form-group>
            </div>
            <div class="col-md-2">
              <si-newton-form-group label="Estimation" [errorResolverMap]="{required: 'Value is required'}">
                <div class="input-group-prepend">
                  <span class="input-group-text">hours</span>
                </div>
                <input formControlName="regEstimationOnSite1" type="number" step="any" [min]="0" siNewton>
              </si-newton-form-group>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <b class="subSubSectionHeader">Installation of Thin Clients</b>
            </div>
          </div>


          <div class="row">
            <div class="col-md-5">

            </div>
            <div class="col-md-2">
              <b><u>Component</u></b>
            </div>
            <div class="col-md-1">
              <b><u>Quantity</u></b>
            </div>
            <div class="col-md-1">
              <b><u>Effective</u></b>
            </div>
            <div class="col-md-1">
              <b><u>HQ</u></b>
            </div>
            <div class="col-md-2">
              <b><u>Regional Estimation</u></b>
            </div>
          </div>

          <div class="row">
            <div class="col-md-5">
              <b>Assembly (Keyboard / Mouse / Monitor) </b>
              <label title="Observation room / Bedienzentrale">
                <i class="bi bi-question-circle-fill" style="color: #eb780a"></i>
              </label>
            </div>
            <div class="col-md-2">
              <b>Thin Client</b>
            </div>

            <div class="col-md-1">
              <si-newton-form-group [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="quantityOnSite2" type="number" step="any" [min]="0" siNewton readonly>
              </si-newton-form-group>
            </div>
            <div class="col-md-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="effectiveOnSite2" type="number" step="any" [min]="0" siNewton readonly>
              </si-newton-form-group>
            </div>
            <div class="col-md-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="hqTimeOnSite2" type="number" step="any" [min]="0" siNewton readonly>
              </si-newton-form-group>
            </div>
            <div class="col-md-2">
              <si-newton-form-group label="Estimation" [errorResolverMap]="{required: 'Value is required'}">
                <div class="input-group-prepend">
                  <span class="input-group-text">hours</span>
                </div>
                <input formControlName="regEstimationOnSite2" type="number" step="any" [min]="0" siNewton>
              </si-newton-form-group>
            </div>
          </div>


          <div class="row">
            <div class="col">
              <b class="subSubSectionHeader">Installation of HW Addons</b>
            </div>
          </div>


          <div class="row">
            <div class="col-md-5">

            </div>
            <div class="col-md-2">
              <b><u>Component</u></b>
            </div>
            <div class="col-md-1">
              <b><u>Quantity</u></b>
            </div>
            <div class="col-md-1">
              <b><u>Effective</u></b>
            </div>
            <div class="col-md-1">
              <b><u>HQ</u></b>
            </div>
            <div class="col-md-2">
              <b><u>Regional Estimation</u></b>
            </div>
          </div>

          <div class="row">
            <div class="col-md-5">
              <b>Installation / Configuration of HW Addons</b>
            </div>
            <div class="col-md-2">
              <b>HW Addons</b>
            </div>

            <div class="col-md-1">
              <si-newton-form-group [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="quantityOnSiteHWAddons" type="number" step="any" [min]="0" siNewton readonly>
              </si-newton-form-group>
            </div>
            <div class="col-md-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="effectiveOnSiteHWAddons" type="number" step="any" [min]="0" siNewton readonly>
              </si-newton-form-group>
            </div>
            <div class="col-md-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="hqTimeOnSiteHWAddons" type="number" step="any" [min]="0" siNewton readonly>
              </si-newton-form-group>
            </div>
            <div class="col-md-2">
              <si-newton-form-group label="Estimation" [errorResolverMap]="{required: 'Value is required'}">
                <div class="input-group-prepend">
                  <span class="input-group-text">hours</span>
                </div>
                <input formControlName="regEstimationOnSiteHWAddons" type="number" step="any" [min]="0" siNewton>
              </si-newton-form-group>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <b class="subSubSectionHeader">PCS 7 Project data management</b>
            </div>
          </div>


          <div class="row">
            <div class="col-md-5">

            </div>
            <div class="col-md-2">
              <b><u>Component</u></b>
            </div>
            <div class="col-md-1">
              <b><u>Quantity</u></b>
            </div>
            <div class="col-md-1">
              <b><u>Effective</u></b>
            </div>
            <div class="col-md-1">
              <b><u>HQ</u></b>
            </div>
            <div class="col-md-2">
              <b><u>Regional Estimation</u></b>
            </div>
          </div>

      
          <div class="row">
            <div class="col-md-5">
              <b>Runtime Data Migration OS-Server</b>
            </div>
            <div class="col-md-2">
              <b>OS Server pair</b>
            </div>

            <div class="col-md-1">
              <si-newton-form-group [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="quantityOnSite4" type="number" step="any" [min]="0" siNewton readonly>
              </si-newton-form-group>
            </div>
            <div class="col-md-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="effectiveOnSite4" type="number" step="any" [min]="0" siNewton readonly>
              </si-newton-form-group>
            </div>
            <div class="col-md-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="hqTimeOnSite4" type="number" step="any" [min]="0" siNewton readonly>
              </si-newton-form-group>
            </div>
            <div class="col-md-2">
              <si-newton-form-group label="Estimation" [errorResolverMap]="{required: 'Value is required'}">
                <div class="input-group-prepend">
                  <span class="input-group-text">hours</span>
                </div>
                <input formControlName="regEstimationOnSite4" type="number" step="any" [min]="0" siNewton>
              </si-newton-form-group>
            </div>
          </div>

          <div class="row">
            <div class="col-md-5">
              <b>Create PCS 7 project backup</b>
            </div>
            <div class="col-md-2">
              <b>PCS 7 Multi Project</b>
            </div>

            <div class="col-md-1">
              <si-newton-form-group [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="quantityOnSite5" type="number" step="any" [min]="0" siNewton readonly>
              </si-newton-form-group>
            </div>
            <div class="col-md-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="effectiveOnSite5" type="number" step="any" [min]="0" siNewton readonly>
              </si-newton-form-group>
            </div>
            <div class="col-md-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="hqTimeOnSite5" type="number" step="any" [min]="0" siNewton readonly>
              </si-newton-form-group>
            </div>
            <div class="col-md-2">
              <si-newton-form-group label="Estimation" [errorResolverMap]="{required: 'Value is required'}">
                <div class="input-group-prepend">
                  <span class="input-group-text">hours</span>
                </div>
                <input formControlName="regEstimationOnSite5" type="number" step="any" [min]="0" siNewton>
              </si-newton-form-group>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <b class="subSubSectionHeader">Microsoft Windows Domain</b>
            </div>
          </div>


          <div class="row">
            <div class="col-md-5">

            </div>
            <div class="col-md-2">
              <b><u>Component</u></b>
            </div>
            <div class="col-md-1">
              <b><u>Quantity</u></b>
            </div>
            <div class="col-md-1">
              <b><u>Effective</u></b>
            </div>
            <div class="col-md-1">
              <b><u>HQ</u></b>
            </div>
            <div class="col-md-2">
              <b><u>Regional Estimation</u></b>
            </div>
          </div>

          <div class="row">
            <div class="col-md-5">
              <b>Integration of VMs in Microsoft Windows Domain</b>
            </div>
            <div class="col-md-2">
              <b>VM instance</b>
            </div>

            <div class="col-md-1">
              <si-newton-form-group [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="quantityOnSite6" type="number" step="any" [min]="0" siNewton readonly>
              </si-newton-form-group>
            </div>
            <div class="col-md-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="effectiveOnSite6" type="number" step="any" [min]="0" siNewton readonly>
              </si-newton-form-group>
            </div>
            <div class="col-md-1">
              <si-newton-form-group label="hours" [errorResolverMap]="{required: 'Value is required'}">
                <input formControlName="hqTimeOnSite6" type="number" step="any" [min]="0" siNewton readonly>
              </si-newton-form-group>
            </div>
            <div class="col-md-2">
              <si-newton-form-group label="Estimation" [errorResolverMap]="{required: 'Value is required'}">
                <div class="input-group-prepend">
                  <span class="input-group-text">hours</span>
                </div>
                <input formControlName="regEstimationOnSite6" type="number" step="any" [min]="0" siNewton>
              </si-newton-form-group>
            </div>
          </div>


        </tab>
      </tabset>
    </div>
    </div>

</form>

