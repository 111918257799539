import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Licences } from 'src/app/data-models/Licences';

@Component({
  selector: 'app-selected-licenses',
  templateUrl: './selected-licenses.component.html',
  styleUrls: ['./selected-licenses.component.scss']
})
export class SelectedLicensesComponent {
  @Input()
  public parentForm!: FormGroup;

  @Input()
  allSusPackages!: Licences[];

  isThisSectionVisible = false;

  toggleVisibility(){
    this.isThisSectionVisible = !this.isThisSectionVisible;
  }

  constructor() { }
}
