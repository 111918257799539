import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormDataService } from 'src/app/services/form-data.service';

export interface DropDownOption {
  value: string;
}


@Component({
  selector: 'app-product-service-and-support',
  templateUrl: './product-service-and-support.component.html',
  styleUrls: ['./product-service-and-support.component.scss']
})
export class ProductServiceAndSupportComponent {
  isThisSectionVisible = false;

  toggleVisibility(){
    console.log(this.isThisSectionVisible);
    this.isThisSectionVisible = !this.isThisSectionVisible;
  }

  @Input()
  parentForm!: FormGroup;

  rB36prioPrem!: string;
  rBperYearPrioPrem!: string;
  rBSISCyearsWithUpgrades!: string;

  formName = 'productServiceAndSupport';

  public getDropdownOptions(formControlName: string){
    return this.formDataService.getDropDownOptions(formControlName);
  }

  public getDropdownValue(dropDownOption: DropDownOption): string {
    return dropDownOption.value;
  }

  constructor(private formDataService: FormDataService) {}

}
