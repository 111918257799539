/* eslint-disable max-len */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DataTransferService } from 'src/app/services/data-transfer.service';
import { FormDataService } from 'src/app/services/form-data.service';

@Component({
  selector: 'app-result-overview',
  templateUrl: './result-overview.component.html',
  styleUrls: ['./result-overview.component.scss']
})

export class ResultOverviewComponent implements OnInit {

  resultOverviewForm!: FormGroup;

  costOverviewData: {name: string; series: {name: string; value: number}[]}[] = [];

  // view: [number, number] = [800, 400];

  // // options
  // showXAxis = true;
  // showYAxis = true;
  // gradient = true;
  // showLegend = false;
  // showXAxisLabel = true;
  // xAxisLabel = 'Year of Contract';
  // showYAxisLabel = true;
  // yAxisLabel = 'Internal Costs in €';

  // colorScheme = {
  //   domain: ['#00646e', '#41aaaa', '#5AA454']
  // };


  constructor(private formBuilder: FormBuilder, private formDataService: FormDataService,
    private dataTransferService: DataTransferService) {
    this.resultOverviewForm = this.formBuilder.group({
      costOverview: this.formBuilder.group({
        resultNonRecuringServices1: [null], resultNonRecuringServices2: [null], resultNonRecuringServices3: [null],
        resultNonRecuringServices4: [null], resultNonRecuringServices5: [null], resultNonRecuringServices6: [null],
        resultNonRecuringServices7: [null], resultNonRecuringServices8: [null], resultNonRecuringServices9: [null],
        resultNonRecuringServices10: [null], resultNonRecuringServices11: [null], resultNonRecuringServices12: [null],
        resultNonRecuringServices13: [null], resultNonRecuringServices14: [null], resultNonRecuringServices15: [null],
        resultNonRecuringServices16: [null], resultNonRecuringServices17: [null], resultNonRecuringServices18: [null],
        resultNonRecuringServices19: [null], resultNonRecuringServices20: [null], resultNonRecuringServices21: [null],
        resultNonRecuringServices22: [null], resultNonRecuringServices23: [null], resultNonRecuringServices24: [null],
        resultNonRecuringServices25: [null], resultlcsStandardServices1: [null], resultlcsStandardServices2: [null],
        resultlcsStandardServices3: [null], resultlcsStandardServices4: [null], resultlcsStandardServices5: [null],
        resultlcsStandardServices6: [null], resultlcsStandardServices7: [null], resultlcsStandardServices8: [null],
        resultlcsStandardServices9: [null], resultlcsStandardServices10: [null], resultlcsStandardServices11: [null],
        resultlcsStandardServices12: [null], resultlcsStandardServices13: [null], resultlcsStandardServices14: [null],
        resultlcsStandardServices15: [null], resultlcsStandardServices16: [null], resultlcsStandardServices17: [null],
        resultlcsStandardServices18: [null], resultlcsStandardServices19: [null], resultlcsStandardServices20: [null],
        resultlcsStandardServices21: [null], resultlcsStandardServices22: [null], resultlcsStandardServices23: [null],
        resultlcsStandardServices24: [null], resultlcsStandardServices25: [null], resultAdditionalServices1: [null],
        resultAdditionalServices2: [null], resultAdditionalServices3: [null], resultAdditionalServices4: [null],
        resultAdditionalServices5: [null], resultAdditionalServices6: [null], resultAdditionalServices7: [null],
        resultAdditionalServices8: [null], resultAdditionalServices9: [null], resultAdditionalServices10: [null],
        resultAdditionalServices11: [null], resultAdditionalServices12: [null], resultAdditionalServices13: [null],
        resultAdditionalServices14: [null], resultAdditionalServices15: [null], resultAdditionalServices16: [null],
        resultAdditionalServices17: [null], resultAdditionalServices18: [null], resultAdditionalServices19: [null],
        resultAdditionalServices20: [null], resultAdditionalServices21: [null], resultAdditionalServices22: [null],
        resultAdditionalServices23: [null], resultAdditionalServices24: [null], resultAdditionalServices25: [null],
        resultLcsSUS1: [null], resultLcsSUS2: [null], resultLcsSUS3: [null], resultLcsSUS4: [null], resultLcsSUS5: [null],
        resultLcsSUS6: [null], resultLcsSUS7: [null], resultLcsSUS8: [null], resultLcsSUS9: [null], resultLcsSUS10: [null],
        resultLcsSUS11: [null], resultLcsSUS12: [null], resultLcsSUS13: [null], resultLcsSUS14: [null], resultLcsSUS15: [null],
        resultLcsSUS16: [null], resultLcsSUS17: [null], resultLcsSUS18: [null], resultLcsSUS19: [null], resultLcsSUS20: [null],
        resultLcsSUS21: [null], resultLcsSUS22: [null], resultLcsSUS23: [null], resultLcsSUS24: [null], resultLcsSUS25: [null],
        resultPcs7HwSwUpgrade1: [null], resultPcs7HwSwUpgrade2: [null], resultPcs7HwSwUpgrade3: [null],
        resultPcs7HwSwUpgrade4: [null], resultPcs7HwSwUpgrade5: [null], resultPcs7HwSwUpgrade6: [null],
        resultPcs7HwSwUpgrade7: [null], resultPcs7HwSwUpgrade8: [null], resultPcs7HwSwUpgrade9: [null],
        resultPcs7HwSwUpgrade10: [null], resultPcs7HwSwUpgrade11: [null], resultPcs7HwSwUpgrade12: [null],
        resultPcs7HwSwUpgrade13: [null], resultPcs7HwSwUpgrade14: [null], resultPcs7HwSwUpgrade15: [null],
        resultPcs7HwSwUpgrade16: [null],
        resultPcs7HwSwUpgrade17: [null],
        resultPcs7HwSwUpgrade18: [null],
        resultPcs7HwSwUpgrade19: [null],
        resultPcs7HwSwUpgrade20: [null],
        resultPcs7HwSwUpgrade21: [null],
        resultPcs7HwSwUpgrade22: [null],
        resultPcs7HwSwUpgrade23: [null],
        resultPcs7HwSwUpgrade24: [null],
        resultPcs7HwSwUpgrade25: [null],
        resultPcsNeoLicenseProcurement1: [null],
        resultPcsNeoLicenseProcurement2: [null],
        resultPcsNeoLicenseProcurement3: [null],
        resultPcsNeoLicenseProcurement4: [null],
        resultPcsNeoLicenseProcurement5: [null],
        resultPcsNeoLicenseProcurement6: [null],
        resultPcsNeoLicenseProcurement7: [null],
        resultPcsNeoLicenseProcurement8: [null],
        resultPcsNeoLicenseProcurement9: [null],
        resultPcsNeoLicenseProcurement10: [null],
        resultPcsNeoLicenseProcurement11: [null],
        resultPcsNeoLicenseProcurement12: [null],
        resultPcsNeoLicenseProcurement13: [null],
        resultPcsNeoLicenseProcurement14: [null],
        resultPcsNeoLicenseProcurement15: [null],
        resultPcsNeoLicenseProcurement16: [null],
        resultPcsNeoLicenseProcurement17: [null],
        resultPcsNeoLicenseProcurement18: [null],
        resultPcsNeoLicenseProcurement19: [null],
        resultPcsNeoLicenseProcurement20: [null],
        resultPcsNeoLicenseProcurement21: [null],
        resultPcsNeoLicenseProcurement22: [null],
        resultPcsNeoLicenseProcurement23: [null],
        resultPcsNeoLicenseProcurement24: [null],
        resultPcsNeoLicenseProcurement25: [null],
        resultPcsNeoSwpInfinity1: [null],
        resultPcsNeoSwpInfinity2: [null],
        resultPcsNeoSwpInfinity3: [null],
        resultPcsNeoSwpInfinity4: [null],
        resultPcsNeoSwpInfinity5: [null],
        resultPcsNeoSwpInfinity6: [null],
        resultPcsNeoSwpInfinity7: [null],
        resultPcsNeoSwpInfinity8: [null],
        resultPcsNeoSwpInfinity9: [null],
        resultPcsNeoSwpInfinity10: [null],
        resultPcsNeoSwpInfinity11: [null],
        resultPcsNeoSwpInfinity12: [null],
        resultPcsNeoSwpInfinity13: [null],
        resultPcsNeoSwpInfinity14: [null],
        resultPcsNeoSwpInfinity15: [null],
        resultPcsNeoSwpInfinity16: [null],
        resultPcsNeoSwpInfinity17: [null],
        resultPcsNeoSwpInfinity18: [null],
        resultPcsNeoSwpInfinity19: [null],
        resultPcsNeoSwpInfinity20: [null],
        resultPcsNeoSwpInfinity21: [null],
        resultPcsNeoSwpInfinity22: [null],
        resultPcsNeoSwpInfinity23: [null],
        resultPcsNeoSwpInfinity24: [null],
        resultPcsNeoSwpInfinity25: [null],
        resultHmiPictureAdaption1: [null],
        resultHmiPictureAdaption2: [null],
        resultHmiPictureAdaption3: [null],
        resultHmiPictureAdaption4: [null],
        resultHmiPictureAdaption5: [null],
        resultHmiPictureAdaption6: [null],
        resultHmiPictureAdaption7: [null],
        resultHmiPictureAdaption8: [null],
        resultHmiPictureAdaption9: [null],
        resultHmiPictureAdaption10: [null],
        resultHmiPictureAdaption11: [null],
        resultHmiPictureAdaption12: [null],
        resultHmiPictureAdaption13: [null],
        resultHmiPictureAdaption14: [null],
        resultHmiPictureAdaption15: [null],
        resultHmiPictureAdaption16: [null],
        resultHmiPictureAdaption17: [null],
        resultHmiPictureAdaption18: [null],
        resultHmiPictureAdaption19: [null],
        resultHmiPictureAdaption20: [null],
        resultHmiPictureAdaption21: [null],
        resultHmiPictureAdaption22: [null],
        resultHmiPictureAdaption23: [null],
        resultHmiPictureAdaption24: [null],
        resultHmiPictureAdaption25: [null],
        resultPcsNeoEvolution1: [null],
        resultPcsNeoEvolution2: [null],
        resultPcsNeoEvolution3: [null],
        resultPcsNeoEvolution4: [null],
        resultPcsNeoEvolution5: [null],
        resultPcsNeoEvolution6: [null],
        resultPcsNeoEvolution7: [null],
        resultPcsNeoEvolution8: [null],
        resultPcsNeoEvolution9: [null],
        resultPcsNeoEvolution10: [null],
        resultPcsNeoEvolution11: [null],
        resultPcsNeoEvolution12: [null],
        resultPcsNeoEvolution13: [null],
        resultPcsNeoEvolution14: [null],
        resultPcsNeoEvolution15: [null],
        resultPcsNeoEvolution16: [null],
        resultPcsNeoEvolution17: [null],
        resultPcsNeoEvolution18: [null],
        resultPcsNeoEvolution19: [null],
        resultPcsNeoEvolution20: [null],
        resultPcsNeoEvolution21: [null],
        resultPcsNeoEvolution22: [null],
        resultPcsNeoEvolution23: [null],
        resultPcsNeoEvolution24: [null],
        resultPcsNeoEvolution25: [null],
        resultPcsNeoHwUpgrade1: [null],
        resultPcsNeoHwUpgrade2: [null],
        resultPcsNeoHwUpgrade3: [null],
        resultPcsNeoHwUpgrade4: [null],
        resultPcsNeoHwUpgrade5: [null],
        resultPcsNeoHwUpgrade6: [null],
        resultPcsNeoHwUpgrade7: [null],
        resultPcsNeoHwUpgrade8: [null],
        resultPcsNeoHwUpgrade9: [null],
        resultPcsNeoHwUpgrade10: [null],
        resultPcsNeoHwUpgrade11: [null],
        resultPcsNeoHwUpgrade12: [null],
        resultPcsNeoHwUpgrade13: [null],
        resultPcsNeoHwUpgrade14: [null],
        resultPcsNeoHwUpgrade15: [null],
        resultPcsNeoHwUpgrade16: [null],
        resultPcsNeoHwUpgrade17: [null],
        resultPcsNeoHwUpgrade18: [null],
        resultPcsNeoHwUpgrade19: [null],
        resultPcsNeoHwUpgrade20: [null],
        resultPcsNeoHwUpgrade21: [null],
        resultPcsNeoHwUpgrade22: [null],
        resultPcsNeoHwUpgrade23: [null],
        resultPcsNeoHwUpgrade24: [null],
        resultPcsNeoHwUpgrade25: [null],
        resultPcsNeoSwUpgrade1: [null],
        resultPcsNeoSwUpgrade2: [null],
        resultPcsNeoSwUpgrade3: [null],
        resultPcsNeoSwUpgrade4: [null],
        resultPcsNeoSwUpgrade5: [null],
        resultPcsNeoSwUpgrade6: [null],
        resultPcsNeoSwUpgrade7: [null],
        resultPcsNeoSwUpgrade8: [null],
        resultPcsNeoSwUpgrade9: [null],
        resultPcsNeoSwUpgrade10: [null],
        resultPcsNeoSwUpgrade11: [null],
        resultPcsNeoSwUpgrade12: [null],
        resultPcsNeoSwUpgrade13: [null],
        resultPcsNeoSwUpgrade14: [null],
        resultPcsNeoSwUpgrade15: [null],
        resultPcsNeoSwUpgrade16: [null],
        resultPcsNeoSwUpgrade17: [null],
        resultPcsNeoSwUpgrade18: [null],
        resultPcsNeoSwUpgrade19: [null],
        resultPcsNeoSwUpgrade20: [null],
        resultPcsNeoSwUpgrade21: [null],
        resultPcsNeoSwUpgrade22: [null],
        resultPcsNeoSwUpgrade23: [null],
        resultPcsNeoSwUpgrade24: [null],
        resultPcsNeoSwUpgrade25: [null],
        resultNetworkCompReplacement1: [null],
        resultNetworkCompReplacement2: [null],
        resultNetworkCompReplacement3: [null],
        resultNetworkCompReplacement4: [null],
        resultNetworkCompReplacement5: [null],
        resultNetworkCompReplacement6: [null],
        resultNetworkCompReplacement7: [null],
        resultNetworkCompReplacement8: [null],
        resultNetworkCompReplacement9: [null],
        resultNetworkCompReplacement10: [null],
        resultNetworkCompReplacement11: [null],
        resultNetworkCompReplacement12: [null],
        resultNetworkCompReplacement13: [null],
        resultNetworkCompReplacement14: [null],
        resultNetworkCompReplacement15: [null],
        resultNetworkCompReplacement16: [null],
        resultNetworkCompReplacement17: [null],
        resultNetworkCompReplacement18: [null],
        resultNetworkCompReplacement19: [null],
        resultNetworkCompReplacement20: [null],
        resultNetworkCompReplacement21: [null],
        resultNetworkCompReplacement22: [null],
        resultNetworkCompReplacement23: [null],
        resultNetworkCompReplacement24: [null],
        resultNetworkCompReplacement25: [null],
        resultIndividualServices1: [null],
        resultIndividualServices2: [null],
        resultIndividualServices3: [null],
        resultIndividualServices4: [null],
        resultIndividualServices5: [null],
        resultIndividualServices6: [null],
        resultIndividualServices7: [null],
        resultIndividualServices8: [null],
        resultIndividualServices9: [null],
        resultIndividualServices10: [null],
        resultIndividualServices11: [null],
        resultIndividualServices12: [null],
        resultIndividualServices13: [null],
        resultIndividualServices14: [null],
        resultIndividualServices15: [null],
        resultIndividualServices16: [null],
        resultIndividualServices17: [null],
        resultIndividualServices18: [null],
        resultIndividualServices19: [null],
        resultIndividualServices20: [null],
        resultIndividualServices21: [null],
        resultIndividualServices22: [null],
        resultIndividualServices23: [null],
        resultIndividualServices24: [null],
        resultIndividualServices25: [null],
        resultSwUpdate1: [null],
        resultSwUpdate2: [null],
        resultSwUpdate3: [null],
        resultSwUpdate4: [null],
        resultSwUpdate5: [null],
        resultSwUpdate6: [null],
        resultSwUpdate7: [null],
        resultSwUpdate8: [null],
        resultSwUpdate9: [null],
        resultSwUpdate10: [null],
        resultSwUpdate11: [null],
        resultSwUpdate12: [null],
        resultSwUpdate13: [null],
        resultSwUpdate14: [null],
        resultSwUpdate15: [null],
        resultSwUpdate16: [null],
        resultSwUpdate17: [null],
        resultSwUpdate18: [null],
        resultSwUpdate19: [null],
        resultSwUpdate20: [null],
        resultSwUpdate21: [null],
        resultSwUpdate22: [null],
        resultSwUpdate23: [null],
        resultSwUpdate24: [null],
        resultSwUpdate25: [null]
      })
    });
  }

  ngOnInit() {
    this.readData();
  }

  async readData() {

    const keysFromDialog = await this.formDataService.getAllControlsFromDialogAsArray(this.resultOverviewForm);

    const formData = await this.dataTransferService.getValuesFromExcel(keysFromDialog) as any;

    console.log(formData);

    this.formDataService.fillDataIntoDialog(this.resultOverviewForm, formData);

    // for (let i = 1; i <= 15; i++) {
    //   this.costOverviewData.push({
    //     'name': 'Year ' + i,
    //     'value': Number(this.resultOverviewForm.get('costOverview')?.get('jahresWert' + i)?.value) || 0
    //   });
    // }

    for (let i = 1; i <= 15; i++) {
      this.costOverviewData.push({
        'name': 'Year ' + i,
        'series': [
          {
            'name': 'Non recuring services',
            'value': parseInt(this.resultOverviewForm.get('costOverview')?.get('resultNonRecuringServices' + i)?.value, 10) || 0
          },
          {
            'name': 'LCS Standard Services',
            'value': parseInt(this.resultOverviewForm.get('costOverview')?.get('resultlcsStandardServices' + i)?.value, 10) || 0
          },
          {
            'name': 'Additional Services',
            'value': parseInt(this.resultOverviewForm.get('costOverview')?.get('resultAdditionalServices' + i)?.value, 10) || 0
          },
          {
            'name': 'LCS SUS',
            'value': parseInt(this.resultOverviewForm.get('costOverview')?.get('resultLcsSUS' + i)?.value, 10) || 0
          },
          {
            'name': 'PCS 7 HW & SW Upgrade',
            // eslint-disable-next-line max-len
            'value': parseInt(this.resultOverviewForm.get('costOverview')?.get('resultPcs7HwSwUpgrade' + i)?.value, 10) || 0
          },
          {
            'name': 'PCS Neo License Procurement',
            // eslint-disable-next-line max-len
            'value': parseInt(this.resultOverviewForm.get('costOverview')?.get('resultPcsNeoLicenseProcurement' + i)?.value, 10) || 0
          },
          {
            'name': 'PCS Neo SWP Infinity',
            // eslint-disable-next-line max-len
            'value': parseInt(this.resultOverviewForm.get('costOverview')?.get('resultPcsNeoSwpInfinity' + i)?.value, 10) || 0
          },
          {
            'name': 'HMI Picture Adaption',
            // eslint-disable-next-line max-len
            'value': parseInt(this.resultOverviewForm.get('costOverview')?.get('resultHmiPictureAdaption' + i)?.value, 10) || 0
          },
          {
            'name': 'PCS Neo Evolution',
            // eslint-disable-next-line max-len
            'value': parseInt(this.resultOverviewForm.get('costOverview')?.get('resultPcsNeoEvolution' + i)?.value, 10) || 0
          },
          {
            'name': 'SW Update',
            // eslint-disable-next-line max-len
            'value': parseInt(this.resultOverviewForm.get('costOverview')?.get('resultSwUpdate' + i)?.value, 10) || 0
          },
          {
            'name': 'PCS Neo HW Upgrade',
            // eslint-disable-next-line max-len
            'value': parseInt(this.resultOverviewForm.get('costOverview')?.get('resultPcsNeoHwUpgrade' + i)?.value, 10) || 0
          },
          {
            'name': 'PCS Neo SW Upgrade',
            // eslint-disable-next-line max-len
            'value': parseInt(this.resultOverviewForm.get('costOverview')?.get('resultPcsNeoSwUpgrade' + i)?.value, 10) || 0
          },
          {
            'name': 'Network Component Replacement',
            // eslint-disable-next-line max-len
            'value': parseInt(this.resultOverviewForm.get('costOverview')?.get('resultNetworkCompReplacement' + i)?.value, 10) || 0
          },
          {
            'name': 'Individual Services',
            // eslint-disable-next-line max-len
            'value': parseInt(this.resultOverviewForm.get('costOverview')?.get('resultIndividualServices' + i)?.value, 10) || 0
          }
        ]
      });
    }

    this.costOverviewData = [...this.costOverviewData];

  }

  async writeData() {
    const jsonData = await this.formDataService.getDataFromDialog(this.resultOverviewForm);

    await this.dataTransferService.writeDataToExcel(JSON.stringify(jsonData));

    await this.readData();
  }

}
