import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-cost-increase-rates',
  templateUrl: './cost-increase-rates.component.html',
  styleUrls: ['./cost-increase-rates.component.scss']
})
export class CostIncreaseRatesComponent {

  @Input()
  public parentForm!: FormGroup;

  constructor() { }

}
