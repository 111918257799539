import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PhysicalSystemComponentModel } from 'src/app/data-models/PhysicalSystemComponent';

@Component({
  selector: 'app-hw-addons',
  templateUrl: './hw-addons.component.html',
  styleUrls: ['./hw-addons.component.scss']
})
export class HwAddonsComponent {
  isThisSectionVisible = false;

  thirdPartyHwAddonsFromPCS7Bom: PhysicalSystemComponentModel[] = [];
  thirdPartyHwAddonsFromPcsNeoBom: PhysicalSystemComponentModel[] = [];

  async toggleVisibilityForIdentifiedHWAddons(){

    await this.fillThirdPartyHwComponentsList();
    this.isThisSectionVisible = !this.isThisSectionVisible;
  }

  async fillThirdPartyHwComponentsList(){
    this.thirdPartyHwAddonsFromPCS7Bom = [];
    this.thirdPartyHwAddonsFromPcsNeoBom = [];

    const relevantThirdPartyHWComponentTypes: string[] = [
      '3-Party HW Addons'
    ];

    for (let i = 1; i <= 200; i++){


      if (this.isPhysicalSystemComponentPresent(i, 'PCS 7')){
        console.log(i);
        const componentType = this.parentForm.get('hwAddons')?.get('physicalSystemComponentType' + i)?.value;


        if (relevantThirdPartyHWComponentTypes.includes(componentType)){
          this.thirdPartyHwAddonsFromPCS7Bom.push({
            'componentNumber': i,
            'componentType': this.parentForm.get('hwAddons')?.get('physicalSystemComponentType' + i)?.value,
            'mlfb': this.parentForm.get('hwAddons')?.get('physicalSystemMlfb' + i)?.value,
            'quantity': this.parentForm.get('hwAddons')?.get('physicalSystemQuantity' + i)?.value,
            'listPrice': this.parentForm.get('hwAddons')?.get('physicalSystemPrice' + i)?.value,
            'description': this.parentForm.get('hwAddons')?.get('physicalSystemDesc' + i)?.value
          });
        }
      }

      if (this.isPhysicalSystemComponentPresent(i, 'PCS neo')){

        const componentType = this.parentForm.get('hwAddons')?.get('physicalSystemNeoComponentType' + i)?.value;

        if (relevantThirdPartyHWComponentTypes.includes(componentType)){
          this.thirdPartyHwAddonsFromPcsNeoBom.push({
            'componentNumber': i,
            'componentType': this.parentForm.get('hwAddons')?.get('physicalSystemNeoComponentType' + i)?.value,
            'mlfb': this.parentForm.get('hwAddons')?.get('physicalSystemNeoMlfb' + i)?.value,
            'quantity': this.parentForm.get('hwAddons')?.get('physicalSystemNeoQuantity' + i)?.value,
            'listPrice': this.parentForm.get('hwAddons')?.get('physicalSystemNeoPrice' + i)?.value,
            'description': this.parentForm.get('hwAddons')?.get('physicalSystemNeoDesc' + i)?.value
          });
        }
      }
    }

  }

  isPhysicalSystemComponentPresent(compNumber: number, pcsVersion: string): boolean {

    let componentTypeVarName = '';
    if (pcsVersion === 'PCS neo'){
      componentTypeVarName = 'physicalSystemNeoComponentType';
    } else {
      componentTypeVarName = 'physicalSystemComponentType';
    }

    try {
      if (this.parentForm.get('hwAddons')?.get(componentTypeVarName + compNumber)?.value){
        return true;
      } else {
        return false;
      }
    } catch {
      return false;
    }
  }

  @Input()
  parentForm!: FormGroup;

  constructor() { }

}
