<form [formGroup]="parentForm">
    <div formGroupName="swMaintContractTimeline">
            <div class="evolutionOverview">
        
                <!-- Checkbox Sections -->
                <div class="checkbox-sections">
        
                  <!---->
                  <div class="checkbox-row">
                    <div class="checkbox-label"></div>
                    <ul style="margin-bottom: 0px;">
        
                      <li class="years" *ngFor="let year of contractYearsArray; let i = index"
                      [ngClass]=
                      "pcsNeoEvolution && year === pcsNeoEvolution ? 'pcsNeoEvolution' :
                        year >= pcsNeoEvolution && pcsNeoEvolution !== 0 ? 'pcsNeo' : 'pcs7'">
                        {{ i+1 }}
                      </li>
                    </ul>
                  </div>
                
                  <!---->
                  <div class="checkbox-row">
                    <div class="checkbox-label"></div>
                    <ul style="margin-bottom: 0px;">
                      <li class="year" *ngFor="let year of contractYearsArray">
                        {{ year }}
                      </li>
                    </ul>
                  </div>
        
                  <!-- PCS 7 Upgrades -->
                  <div class="checkbox-row" style="background-color: rgba(80, 190, 215, 0.7); color: white"
                    [style.display]="startWithPcs7OrPcsneo === 'PCS neo' ? 'none' : 'flex'">
                      <div class="checkbox-label">PCS 7 Upgrades</div>
                      <ul>
                          <li *ngFor="let year of contractYearsArray; let i = index">
                              <input class="form-check form-check-inline custom-disabled" type="checkbox" style="margin-top: 5px"
                                      *ngIf="pcsNeoEvolution === 0 || year < pcsNeoEvolution"
                                    [checked]="selectedPcs7Upgrades[i]" disabled>
                          </li>
                      </ul>
                  </div>
        
                  <!-- PCS 7 SW Updates -->
                  <div class="checkbox-row" style="background-color: rgba(80, 190, 215, 0.7); color: white"
                    [style.display]="startWithPcs7OrPcsneo === 'PCS neo' ? 'none' : 'flex'">
                      <div class="checkbox-label">PCS 7 SW Updates</div>
                      <ul >
                          <li *ngFor="let year of contractYearsArray; let i = index">
                              <input class="form-check form-check-inline custom-disabled" type="checkbox"
                                      *ngIf="pcsNeoEvolution === 0 || year < pcsNeoEvolution"
                                    [checked]="selectedPCS7SwUpdates[i]" disabled> 
                          </li>
                      </ul>
                  </div>

                  <!-- PCS 7 SUS Updates -->
                  <div class="checkbox-row" style="background-color: rgba(80, 190, 215, 0.7); color: white"
                  [style.display]="startWithPcs7OrPcsneo === 'PCS neo' ? 'none' : 'flex'">
                    <div class="checkbox-label">SUS</div>
                    <ul >
                        <li *ngFor="let year of contractYearsArray; let i = index">
                            <input class="form-check form-check-inline custom-disabled" type="checkbox"
                                    *ngIf="pcsNeoEvolution === 0 || year < pcsNeoEvolution" (change)="onCheckboxChange($event, 'pcs7Sus', i)"
                                    [checked]="selectedSUSYears[i]" >
                        </li>
                    </ul>
                </div>

                 <!-- LSS Updates -->
                 <div class="checkbox-row" style="background-color: rgba(80, 190, 215, 0.7); color: white"
                  [style.display]="startWithPcs7OrPcsneo === 'PCS neo' ? 'none' : 'flex'">
                    <div class="checkbox-label">LSS</div>
                    <ul >
                        <li *ngFor="let year of contractYearsArray; let i = index">
                            <input class="form-check form-check-inline custom-disabled" type="checkbox"
                                    *ngIf="pcsNeoEvolution === 0 || year < pcsNeoEvolution"  (change)="onCheckboxChange($event, 'pcsLss', i)"
                                  [checked]="selectedLSSYears[i]" >
                        </li>
                    </ul>
                  </div>

                  <!-- PCS 7 SW Maint. Contract -->
                  <div class="checkbox-row" style="background-color: rgba(80, 190, 215, 0.7); color: white"
                  [style.display]="startWithPcs7OrPcsneo === 'PCS neo' ? 'none' : 'flex'">
                    <div class="checkbox-label">PCS 7 SW Maint. Contract</div>
                    <ul >
                        <li *ngFor="let year of contractYearsArray; let i = index">
                            <input class="form-check form-check-inline custom-disabled" type="checkbox"
                                    *ngIf="pcsNeoEvolution === 0 || year < pcsNeoEvolution"  (change)="onCheckboxChange($event, 'pcs7SwMC', i)"
                                  [checked]="selectedPcs7SwMCYears[i]" >
                        </li>
                    </ul>
                  </div>
             
        
                  <!-- PCS neo Evolution -->
                  <div class="checkbox-row" style="background-color: rgba(235, 120, 10, 0.7); color: white"
                    [style.display]="startWithPcs7OrPcsneo === 'PCS neo' ? 'none' : 'flex'">
                    <div class="checkbox-label">PCS neo Evolution</div>
                    <ul>
                        <li *ngFor="let year of contractYearsArray; let i = index">
                            <input class="form-check form-check-inline custom-disabled" type="checkbox"
                              *ngIf="pcsNeoEvolution !== 0 && year === pcsNeoEvolution"
                              [checked]="true" disabled>
                        </li>
                    </ul>
                  </div>
        
                  <!-- PCS neo HW Upgrade -->
                  <div class="checkbox-row" style="background-color: rgba(0, 95, 135, 0.7); color: white">
                    <div class="checkbox-label">PCS Neo HW Upgrades</div>
                    <ul>
                        <li *ngFor="let year of contractYearsArray; let i = index">
                            <input class="form-check form-check-inline custom-disabled" type="checkbox"
                                  *ngIf="pcsNeoEvolution === 0 || year > pcsNeoEvolution"
                                  [checked]="selectedNeoHWUpgrades[i]" disabled>
                        </li>
                    </ul>
                  </div>
        
                  <!-- PCS neo SW Upgrade -->
                  <div class="checkbox-row" style="background-color: rgba(0, 95, 135, 0.7); color: white">
                    <div class="checkbox-label">PCS Neo SW Upgrades</div>
                    <ul>
                        <li *ngFor="let year of contractYearsArray; let i = index">
                            <input class="form-check form-check-inline custom-disabled" type="checkbox"
                                  *ngIf="pcsNeoEvolution === 0 || year > pcsNeoEvolution"
                                  [checked]="selectedNeoSWUpgrades[i]" disabled>
                        </li>
                    </ul>
                  </div>
        
                  <!-- PCS neo SW Update -->
                  <div class="checkbox-row" style="background-color: rgba(0, 95, 135, 0.7); color: white">
                    <div class="checkbox-label">PCS Neo SW Update</div>
                    <ul>
                        <li *ngFor="let year of contractYearsArray; let i = index">
                            <input class="form-check form-check-inline custom-disabled" type="checkbox"
                                  *ngIf="pcsNeoEvolution === 0 || year > pcsNeoEvolution"
                                  [checked]="selectedNeoSwUpdates[i]" disabled>
                        </li>
                    </ul>
                  </div>
        
                </div>
            </div>
        </div>
</form>

