<form [formGroup]="parentForm">
  <div formGroupName="exchangeFactors">

    <h5 class="subSectionHeader">Target currency - Exchange factors</h5>
    <div class="row" style="margin-bottom: 5px;">
      <div class="col">
        <div class="form-check">
          <input id="cb-enabled" class="form-check-input" formControlName="cBCurrencyConversion" type="checkbox">
          <label for="cb-enabled" class="form-check-label">
            Currency conversion required
          </label>
        </div>
      </div>

    </div>

    <div class="row"
      *ngIf="+parentForm.get('exchangeFactors')!.get('cBCurrencyConversion')!.value === 1" >
      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <si-newton-form-group label="Target currency" [errorResolverMap]="{required: 'Value is required'}">
          <div class="input-group-prepend">
            <span class="input-group-text">1</span>
          </div>
          <input formControlName="targetCurrency" siNewton>
        </si-newton-form-group>
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <si-newton-form-group label="Exchange rate" [errorResolverMap]="{required: 'Value is required'}">
          <div class="input-group-prepend">
            <span class="input-group-text">€</span>
          </div>
          <input formControlName="currencyExchangeFactor" type="number" step="any" [min]="0" siNewton>
        </si-newton-form-group>
      </div>

    </div>

  </div>
</form>
