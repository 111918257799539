<div class="modal-header">
  <h4 class="modal-title pull-left">Individual Service: {{currentIndividualService.disService}}</h4>

</div>
<div #modalBody class="modal-body">

  <form [formGroup]="individualServiceFormMain" class="col-md-12" style="align-items: center;">

    <div class="row">
      <div class="col-md-3">
        <si-newton-form-group label="Service Name" [errorResolverMap]="{required: 'Value is required'}">
          <input formControlName="disService{{serviceNumberAsString}}" siNewton>
        </si-newton-form-group>
      </div>
      <div class="col-md-3">
        <si-newton-form-group label="Material Name" [errorResolverMap]="{required: 'Value is required'}">
          <input formControlName="disMaterial{{serviceNumberAsString}}" siNewton>
        </si-newton-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <si-newton-form-group label="Cost per instance" [errorResolverMap]="{required: 'Value is required'}">
          <input formControlName="disCostPerInstance{{serviceNumberAsString}}" type="number" step="any" [min]="0" siNewton>
          <div class="input-group-prepend">
            <span class="input-group-text">€</span>
          </div>
        </si-newton-form-group>
      </div>

      <div class="col">
        <si-newton-form-group label="Cost increase per year" [errorResolverMap]="{required: 'Value is required'}">
          <input formControlName="disCostIncreasePerYear{{serviceNumberAsString}}" type="number" step="any" [min]="0" siNewton>
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
        </si-newton-form-group>
      </div>

      <div class="col">
        <si-newton-form-group label="Margin" [errorResolverMap]="{required: 'Value is required'}">
          <input formControlName="disMargin{{serviceNumberAsString}}" type="number" step="any" [min]="0" siNewton>
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
        </si-newton-form-group>
      </div>

      <div class="col">
        <si-newton-form-group label="Service Cost per instance" [errorResolverMap]="{required: 'Value is required'}">
          <input formControlName="disServiceCostPerInstance{{serviceNumberAsString}}" type="number" step="any" [min]="0" siNewton>
          <div class="input-group-prepend">
            <span class="input-group-text">€</span>
          </div>
        </si-newton-form-group>
      </div>

   

      <div class="col">
        <si-newton-form-group label="Risk addition" [errorResolverMap]="{required: 'Value is required'}">
          <input formControlName="disRiskAddition{{serviceNumberAsString}}" type="number" step="any" [min]="0" siNewton>
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
        </si-newton-form-group>
      </div>

      <div class="col">
        <div class="form-check">
          <input id="cb-enabled" class="form-check-input" formControlName="disRiskAdditionIncl{{serviceNumberAsString}}" type="checkbox">
          <label for="cb-enabled" class="form-check-label">
            Risk included?
          </label>
        </div>
      </div>
    </div>
    <div *ngFor="let dummy of ' '.repeat(15).split(''), let x = index">
      <div class="row"></div>
      <div class="col">
        <b><u>Year {{stringToNumber(currentIndividualService.yearOfStartFix!) + (x)}}</u></b>
      </div>
      <div class="row">

        <div class="col-lg-2 col-md-4 col-sm-6">
          <si-newton-form-group label="No. of Instances" [errorResolverMap]="{required: 'Value is required', pattern: 'Only inetegers are allowed here'}">
            <input formControlName="amountApplicationYear{{x+1}}{{serviceNumberAsString}}" type="number" [min]="0" siNewton>
          </si-newton-form-group>
        </div>

        <div class="col-lg-2 col-md-4 col-sm-6">
          <si-newton-form-group label="Materials" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="materialsYear{{x+1}}{{serviceNumberAsString}}" type="number" step="any" [min]="0" siNewton readonly highlight>
            <div class="input-group-prepend">
              <span class="input-group-text">€</span>
            </div>
          </si-newton-form-group>
        </div>

        <div class="col-lg-2 col-md-4 col-sm-6">
          <si-newton-form-group label="Services" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="servicesYear{{x+1}}{{serviceNumberAsString}}" type="number" step="any" [min]="0" siNewton readonly highlight>
            <div class="input-group-prepend">
              <span class="input-group-text">€</span>
            </div>
          </si-newton-form-group>
        </div>



        <div class="col-lg-2 col-md-4 col-sm-6">
          <si-newton-form-group label="Total" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="totalCostYear{{x+1}}{{serviceNumberAsString}}" type="number" step="any" [min]="0" siNewton readonly highlight>
            <div class="input-group-prepend">
              <span class="input-group-text">€</span>
            </div>
          </si-newton-form-group>
        </div>

        <div class="col-lg-2 col-md-4 col-sm-6">
          <si-newton-form-group label="Included Risk Add." [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="includedRiskAdditionYear{{x+1}}{{serviceNumberAsString}}" type="number" step="any" [min]="0" siNewton readonly highlight>
            <div class="input-group-prepend">
              <span class="input-group-text">€</span>
            </div>
          </si-newton-form-group>
        </div>
      </div>
    </div>


  </form>


</div>
<div class="modal-footer">
  <button type="button" class="btn btn-warning" (click)="modalRef.hide()">Close</button>
  <button type="button" class="btn btn-secondary" (click)="update()" [disabled]="!individualServiceFormMain.valid">Update</button>
  <button type="button" class="btn btn-success" (click)="submitAndClose()" [disabled]="!individualServiceFormMain.valid">Save & Close</button>
</div>
