<div class="modal-header">
  <h4 class="modal-title">Disclaimer</h4>
</div>
<div class="modal-body">

  <div class="col-md-12">

    <tabset>
      <tab heading="EN">
        <div class="row">
            <div class="col">
              <div class="info">
                <h3>Attention</h3>
                
                <p>
                  User is responsible for the whole system configuration of PCS 7, PCS neo and alternative virtualized system platform and he has to care about adaption 
                  of the “Regional Factors” to the needs of the respective regional organization (RC-CS) 
                </p>

                <p>
                  For components with a valid Siemens MLFB its possible to obtain L1-Prices in EURO based on an integral update function which is to be triggered manually.  
                  For components without such a valid MLFB, prices must be adapted manually in EURO. 
                </p>
                  
                <p>
                  In order to get results also in foreign currency sheets, a currency-exchange factor can be parameterized, within the Regional Factors. 
                </p>
                
              </div>
            </div>
        </div>
      </tab>
      <tab heading="DE">
        <div class="row">
          <div class="col">
            <div class="info">
              <h3>Achtung</h3>
              
              <p>
                 Der Anwender ist für die gesamte Systemkonfiguration von PCS 7, PCS neo und alternativen virtualisierten Systemplattformen verantwortlich und 
                 muss sich um die Anpassung der "Regional Factors" an die Bedürfnisse der jeweiligen Regionalorganisation (RC-CS) kümmern.
              </p>

              <p>
                Für Komponenten mit einer gültigen Siemens MLFB ist es möglich, L1-Preise in EURO zu erhalten, basierend auf einer integrierten Update-Funktion, 
                die manuell ausgelöst werden muss. Für Komponenten ohne eine solche gültige MLFB müssen die Preise manuell in EURO angepasst werden. 
              </p>
                
              <p>
                Um Ergebnisse auch in Fremdwährungen zu erhalten, kann im Rahmen der Regionalfaktoren ein Umrechnungsfaktor parametriert werden.  
              </p>
              
            </div>
          </div>
      </div>

      </tab>
    </tabset>
    <div class="row">

      <div class="col">
        <button type="button" class="btn btn-success"  style="float: right;" (click)="acceptDelete()">
          Confirm
        </button>
      </div>

  
    </div>
  </div>

</div>

<div class="modal-footer">

</div>
