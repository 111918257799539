<form [formGroup]="parentForm">
  <div formGroupName="individualServices">

    <h5 class="subSectionHeader">Individual Services
      <i style="float: right; cursor: pointer"
      [ngClass]="{'bi-arrow-up-circle-fill': isThisSectionVisible, 'bi-arrow-down-circle-fill': !isThisSectionVisible}"
      (click)="toggleVisibility()"></i>
    </h5>

    <div  [ngStyle]="{'display': isThisSectionVisible ? 'block' : 'none'}">

      <div class="row">
        <div class="col">
          <b class="subSubSectionHeader">Total Cost Overview of Individual Services</b>
        </div>
      </div>

      <div class="row" *ngFor="let _ of [].constructor(20), let x = index">
        <div class="col-md-2">
          <si-newton-form-group label="Service Name" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="disServiceService{{('00'+(x+1)).slice(-2)}}" siNewton readonly>
          </si-newton-form-group>
        </div>

        <div class="col-md-2">
          <si-newton-form-group label="Material Cost" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="materialsYearTotalService{{('00'+(x+1)).slice(-2)}}" type="number" step="any" [min]="0" siNewton readonly highlight>
            <div class="input-group-prepend">
              <span class="input-group-text">€</span>
            </div>
          </si-newton-form-group>

        </div>

        <div class="col-md-2">
          <si-newton-form-group label="Service Cost" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="servicesYearTotalService{{('00'+(x+1)).slice(-2)}}" type="number" step="any" [min]="0" siNewton readonly highlight>
            <div class="input-group-prepend">
              <span class="input-group-text">€</span>
            </div>
          </si-newton-form-group>

        </div>

       

        <div class="col-md-2">
          <si-newton-form-group label="Total Cost" [errorResolverMap]="{required: 'Value is required'}">
            <input formControlName="totalCostYearTotalService{{('00'+(x+1)).slice(-2)}}" type="number" step="any" [min]="0" siNewton readonly highlight>
            <div class="input-group-prepend">
              <span class="input-group-text">€</span>
            </div>
          </si-newton-form-group>
        </div>

        <div class="col-md-2">
          <button
          title="Edit Individual Service"
          class="btn btn-success"
          style="float: left; margin: 1px; border: 0px;"
          (click)="editSelectedIndividualService(('00'+(x+1)).slice(-2))"
          >
          <i class="bi bi-calculator-fill"></i>
        </button>
        </div>
      </div>
    </div>

  </div>
</form>
