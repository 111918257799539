import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LeaveWithoutSaveModalComponent } from '../leave-without-save-modal/leave-without-save-modal.component';
import { DataTransferService } from '../services/data-transfer.service';
import { FormDataService } from '../services/form-data.service';
import { NavigationService } from '../services/navigation.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent {

  @Input()
  currentSectionForm!: FormGroup;

  modalRef!: BsModalRef;

  constructor(public navigationService: NavigationService, private formDataService: FormDataService,
    private dataTransferService: DataTransferService, private modalService: BsModalService) { }

  navigateToSection(section: string){


    if (this.currentSectionForm.dirty){
      this.modalRef = this.modalService.show(LeaveWithoutSaveModalComponent, { });
      this.modalRef.content.leaveDialog = false;
      this.modalRef.content.saveChanges = false;

      this.modalRef.onHidden.subscribe(async (result: any) => {

        if (this.modalRef.content.saveChanges){
          await this.writeData();
        }

        if (this.modalRef.content.leaveDialog){
          this.navigationService.naviClick(section);
        }

      });
    } else {
      this.navigationService.naviClick(section);

    }
  }

  async writeData() {
    const jsonData = await this.formDataService.getDataFromDialog(this.currentSectionForm);

    await this.dataTransferService.writeDataToExcel(JSON.stringify(jsonData));

  }

}
