import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private httpClient: HttpClient) { }

  ngOnInit(): void {
  }

  createProject(){
    const options = {
      headers: {
        'Authorization': 'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjYzalZZWW1wNG9VaEsyN3FfX3NnUSJ9.eyJodHRwczovL3NpZW1lbnMuY29tL2VtYWlsIjoiYmVuamFtaW4ua3JlaXNjaGVyQHNpZW1lbnMuY29tIiwiaHR0cHM6Ly9zaWVtZW5zLmNvbS9yb2xlcyI6WyJBZG1pbiIsIkxDUyBDYWxjdWxhdGlvbiBBZG1pbiIsIkxXU19BZG1pbiIsIlBDU25lbyBDb250cmFjdCBEYXNoYm9hcmQgVXNlciJdLCJpc3MiOiJodHRwczovL3NpZW1lbnMtcWEtMDAxNjkuZXUuYXV0aDAuY29tLyIsInN1YiI6Im9hdXRoMnxtYWluLXRlbmFudC1vaWRjfHNhbWxwfFNpZW1lbnN8WjAwM1gyVFIiLCJhdWQiOlsiZXhjZWwtY2FsY3VsYXRvci1kZXYiLCJodHRwczovL3NpZW1lbnMtcWEtMDAxNjkuZXUuYXV0aDAuY29tL3VzZXJpbmZvIl0sImlhdCI6MTcxMzg5OTMzNywiZXhwIjoxNzEzOTg1NzM3LCJzY29wZSI6Im9wZW5pZCBwcm9maWxlIGVtYWlsIiwiYXpwIjoiZkprcFJpejdpcDNEZ2o2VlRtbm14MkZJTkpqM25YdTIifQ.WWz-yLOvn_xBKEQeERMqrqWVb2BNweH8qoXPTx2ngUcwTtSYYeAtWCn7C6tsaJlwA828R4wsyKq_ehtTojTN1xTkDhbUSxLmRSt-klb7kUh8CNondvOG3dzfkRTJZOEgWpT5O_ql6QSYKnNnhOANjdn5cQ9JHu0_GU9ss22rCxq1wCQaY7UaI1_jG4NLaW2D1DYrgb9nU8VoY2W23H3EQgJixCzGmjM4wLU-pGRbO27tQLfOhxbQInGhRerHJ2179_WId6DCkNN5yc4ffuWzgu0tyJqJi6CnnAj04Od0jABM5DqomJ4BLdXPs5esFTGsgeS9xXO1vpMcU3_RjW8Sgg'
      }
    }
    this.httpClient.post('https://excel-calculator-dev.westeurope.azurecontainer.io/projects/create/riops/ben123', {}, options).subscribe(res => {
      console.log(res);
    })
  }

}
