<si-newton-section>

  <app-navigation [currentSectionForm]=iadcForm></app-navigation>
  <form [formGroup]="iadcForm" class="col-md-12" style="align-items: center;">
  <tabset id="first-element">
    <tab heading="Firewalls">
      <app-iadc-firewalls [parentForm]="iadcForm"></app-iadc-firewalls>
    </tab>
    <tab heading="UPS">
      <app-iadc-usv [parentForm]="iadcForm"></app-iadc-usv>
    </tab>
    <tab heading="OT Switch">
      <app-ot-switch [parentForm]="iadcForm"></app-ot-switch>
    </tab>
    <tab heading="IT Switch">
      <app-it-switch [parentForm]="iadcForm"></app-it-switch>
    </tab>
    <tab heading="PH Server">
      <app-ph-server [parentForm]="iadcForm"></app-ph-server>
    </tab>
    <tab heading="Backup & Restore">
      <app-backup-restore [parentForm]="iadcForm"></app-backup-restore>
    </tab>
  </tabset>

    <button type="button" class="btn btn-success" style="position: fixed; float:right; bottom: 50px; right: 120px;" (click)="submit()"
    [disabled]="!(iadcForm.valid)">Submit</button>
     <button *ngIf="!(iadcForm.valid)" type="button" class="btn btn-primary" style="position: fixed; float:right; bottom: 0px; right: 20px;"
    (click)="logInvalidControls(iadcForm)">
      Log Invalid Values
    </button>
  </form>
</si-newton-section>
