<form [formGroup]="parentForm">
  <div formGroupName="costOfOneTimeServices">

    <h5 class="subSectionHeader">Costs of One Time Services
      <i style="float: right; cursor: pointer"
      [ngClass]="{'bi-arrow-up-circle-fill': isThisSectionVisible, 'bi-arrow-down-circle-fill': !isThisSectionVisible}"
      (click)="toggleVisibility()"></i>
    </h5>


    <div  [ngStyle]="{'display': isThisSectionVisible ? 'block' : 'none'}">
      <div class="row">
        <div class="col-sm-1 col-md">
          <si-newton-form-group label="Set-up and Init. Mobilization" [errorResolverMap]="{required: 'Value is required'}">
            <div class="input-group-prepend">
              <span class="input-group-text">€</span>
            </div>
            <input formControlName="setUpInitMobilization" type="number" step="any" [min]="0" siNewton>
          </si-newton-form-group>
        </div>
        <div class="col-sm-1 col-md">
          <si-newton-form-group label="Inventory Baseline Service" [errorResolverMap]="{required: 'Value is required'}">
            <div class="input-group-prepend">
              <span class="input-group-text">€</span>
            </div>
            <input formControlName="inventoryBaselineService" type="number" step="any" [min]="0" siNewton>
          </si-newton-form-group>
        </div>
        <div class="col-sm-1 col-md">
          <si-newton-form-group label="Life Cycle Information Service" [errorResolverMap]="{required: 'Value is required'}">
            <div class="input-group-prepend">
              <span class="input-group-text">€</span>
            </div>
            <input formControlName="lifeCycleInformationService" type="number" step="any" [min]="0" siNewton>
          </si-newton-form-group>
        </div>
        <div class="col-sm-1 col-md">
          <si-newton-form-group label="Asset Optimization Service Analysis" [errorResolverMap]="{required: 'Value is required'}">
            <div class="input-group-prepend">
              <span class="input-group-text">€</span>
            </div>
            <input formControlName="assetOptServiceAnalysis" type="number" step="any" [min]="0" siNewton>
          </si-newton-form-group>
        </div>
        <div class="col-sm-1 col-md">
          <si-newton-form-group label="System Audit LCS" [errorResolverMap]="{required: 'Value is required'}">
            <div class="input-group-prepend">
              <span class="input-group-text">€</span>
            </div>
            <input formControlName="systemAuditLCS" type="number" step="any" [min]="0" siNewton>
          </si-newton-form-group>
        </div>
      </div>
    </div>

  </div>
</form>
