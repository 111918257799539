import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ProjectManagementService } from '../services/project-management.service';

@Component({
  selector: 'app-reject-project-assignment',
  templateUrl: './reject-project-assignment.component.html',
  styleUrls: ['./reject-project-assignment.component.scss']
})
export class RejectProjectAssignmentComponent {

  projectId!: string;
  projectName!: string;

  constructor(public modalRef: BsModalRef, private projectManagementService: ProjectManagementService) { }

  async unassignProject(){
    await this.projectManagementService.unassignProject(this.projectId);
    this.modalRef.hide();
  }

}
