<form [formGroup]="parentForm" style="margin: auto;">
  <div formGroupName="systemOverview">
    <h5 class="subSectionHeader">System Overview
      <i style="float: right; cursor: pointer"
      [ngClass]="{'bi-arrow-up-circle-fill': isThisSectionVisible, 'bi-arrow-down-circle-fill': !isThisSectionVisible}"
      (click)="toggleVisibility()"></i>
    </h5>
    <div *ngIf="isThisSectionVisible">
      <div class="row">
        <p class="info">This is an overiew of your system. The data is from Physical System BOM section. Please add new elements there to extend your system.</p>
      </div>

      <tabset>
        <tab heading="DCS System">
          <div class="row">
            <div class="col">
              <b class="subSubSectionHeader"><u>PCS 7</u></b>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 col-sm-4">
              <si-newton-form-group label="OS Client HW">
                <input formControlName="quantityPCS7OSClientHW" siNewton readonly>
              </si-newton-form-group>
            </div>
            <div class="col-md-3 col-sm-4">
              <si-newton-form-group label="OS Server HW">
                <input formControlName="quantityPCS7OSServerHW" siNewton readonly>
              </si-newton-form-group>
            </div>
            <div class="col-md-3 col-sm-4">
              <si-newton-form-group label="ES Server HW">
                <input formControlName="quantityPCS7ESHW" siNewton readonly>
              </si-newton-form-group>
            </div>

            <div class="col-md-3 col-sm-4">
              <si-newton-form-group label="Batch Server HW">
                <input formControlName="quantityPCS7BatchServerHW" siNewton readonly>
              </si-newton-form-group>
            </div>

          </div>

          <div class="row">
            <div class="col-md-3 col-sm-4">
              <si-newton-form-group label="Web Server HW">
                <input formControlName="quantityPCS7WebServerHW" siNewton readonly>
              </si-newton-form-group>
            </div>
            <div class="col-md-3 col-sm-4">
              <si-newton-form-group label="PH Server HW">
                <input formControlName="quantityPCS7PHServerHW" siNewton readonly>
              </si-newton-form-group>
            </div>
            <div class="col-md-3 col-sm-4">
              <si-newton-form-group label="RC Server HW">
                <input formControlName="quantityPCS7RCServerHW" siNewton readonly>
              </si-newton-form-group>
            </div>

          </div>

          <div class="row">
            <div class="col-md-3 col-sm-4">
              <si-newton-form-group label="OPC Server HW">
                <input formControlName="quantityPCS7OPCServerHW" siNewton readonly>
              </si-newton-form-group>
            </div>

            <div class="col-md-3 col-sm-4">
              <si-newton-form-group label="PDM Server HW">
                <input formControlName="quantityPCS7PdmServerHW" siNewton readonly>
              </si-newton-form-group>
            </div>

          </div>

          <div class="row">
            <div class="col">
              <b class="subSubSectionHeader"><u>PCS Neo</u></b>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3 col-sm-4">
              <si-newton-form-group label="M&C Server HW">
                <input formControlName="quantityPcsNeoMCServerHW" siNewton readonly>
              </si-newton-form-group>
            </div>
            <div class="col-md-3 col-sm-4">
              <si-newton-form-group label="Plant Clients HW">
                <input formControlName="quantityPcsNeoPlantClientsHW" siNewton readonly>
              </si-newton-form-group>
            </div>

            <div class="col-md-3 col-sm-4">
              <si-newton-form-group label="Engineering Station HW">
                <input formControlName="quantityPcsNeoESHW" siNewton readonly>
              </si-newton-form-group>
            </div>

            <div class="col-md-3 col-sm-4">
              <si-newton-form-group label="PH Server HW">
                <input formControlName="quantityPcsNeoPHServerHW" siNewton readonly>
              </si-newton-form-group>
            </div>

          </div>

          <div class="row">
            <div class="col-md-3 col-sm-4">
              <si-newton-form-group label="Batch Server HW">
                <input formControlName="quantityPcsNeoBatchServerHW" siNewton readonly>
              </si-newton-form-group>
            </div>
            <div class="col-md-3 col-sm-4">
              <si-newton-form-group label="RC Server HW">
                <input formControlName="quantityPcsNeoRCServerHW" siNewton readonly>
              </si-newton-form-group>
            </div>

            <div class="col-md-3 col-sm-4">
              <si-newton-form-group label="D&M Server HW">
                <input formControlName="quantityPcsNeoDMServerHW" siNewton readonly>
              </si-newton-form-group>
            </div>


        

          </div>

          <div class="row">
            <div class="col-md-3 col-sm-4">
              <si-newton-form-group label="OPC Server HW">
                <input formControlName="quantityPcsNeoOPCHW" siNewton readonly>
              </si-newton-form-group>
            </div>

          </div>


        </tab>

        <tab heading="PCS System Addon">
          <div class="row">
            <div class="col">
              <b class="subSubSectionHeader"><u>PCS 7</u></b>
            </div>
          </div>



          <div class="row">
            <div class="col-md-3 col-sm-4">
              <si-newton-form-group label="Asset Management included">
                <input formControlName="pcs7assetManagementIncluded" siNewton readonly>
              </si-newton-form-group>
            </div>
            <div class="col-md-3 col-sm-4">
              <si-newton-form-group label="Maintenance Station SW">
                <input formControlName="quantityPCS7MaintenanceStationSW" siNewton readonly>
              </si-newton-form-group>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3 col-sm-4">
              <si-newton-form-group label="PDM Server SW">
                <input formControlName="quantityPCS7PdmServerSW" siNewton readonly>
              </si-newton-form-group>
            </div>
            <div class="col-md-3 col-sm-4">
              <si-newton-form-group label="Web Server SW">
                <input formControlName="quantityPCS7WebServerSW" siNewton readonly>
              </si-newton-form-group>
            </div>

            <div class="col-md-3 col-sm-4">
              <si-newton-form-group label="PH Server SW">
                <input formControlName="quantityPCS7PhServerSW" siNewton readonly>
              </si-newton-form-group>
            </div>

            <div class="col-md-3 col-sm-4">
              <si-newton-form-group label="OPC Server SW">
                <input formControlName="quantityPCS7OPCServerSW" siNewton readonly>
              </si-newton-form-group>
            </div>
          </div>
            <div class="row">
              <div class="col">
                <label><b><u>Batch</u></b></label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3 col-sm-4">
                <si-newton-form-group label="Batch Server SW">
                  <input formControlName="quantityPCS7BatchServerSW" siNewton readonly>
                </si-newton-form-group>
              </div>
              <div class="col-md-3 col-sm-4">
                <si-newton-form-group label="Batch Clients SW">
                  <input formControlName="quantityPCS7BatchClientsSW" siNewton readonly>
                </si-newton-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <label><b><u>Route Control</u></b></label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3 col-sm-4">
                <si-newton-form-group label="RC Server SW">
                  <input formControlName="quantityPCS7RCServerSW" siNewton readonly>
                </si-newton-form-group>
              </div>
              <div class="col-md-3 col-sm-4">
                <si-newton-form-group label="RC Clients SW">
                  <input formControlName="quantityPCS7RcClientsSW" siNewton readonly>
                </si-newton-form-group>
              </div>
              <div class="col-md-3 col-sm-4">
                <si-newton-form-group label="RC Engineering Station SW">
                  <input formControlName="quantityPCS7RcEsSW" siNewton readonly>
                </si-newton-form-group>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <b class="subSubSectionHeader"><u>PCS Neo</u></b>
              </div>
            </div>

            <div class="row">
              <div class="col-md-3 col-sm-4">
                <si-newton-form-group label="Asset Management included" [errorResolverMap]="{required: 'Please select an option'}">
                  <si-newton-dropdown
                    [dropdownOptions]="getDropdownOptions('pcsNeoAssetManagementIncluded')"
                    [valueProvider]="getDropdownValue"
                    formControlName="pcsNeoAssetManagementIncluded"></si-newton-dropdown>
                </si-newton-form-group>
              </div>
              <div class="col-md-3 col-sm-4">
                <si-newton-form-group label="M&C OPC UA Server SW">
                  <input formControlName="quantityPcsNeoMCOpcUAServerSW" siNewton readonly>
                </si-newton-form-group>
              </div>

              <div class="col-md-3 col-sm-4">
                <si-newton-form-group label="Historian SW">
                  <input formControlName="quantityPcsNeoHistorianSW" siNewton readonly>
                </si-newton-form-group>
              </div>

              <div class="col-md-3 col-sm-4">
                <si-newton-form-group label="PDM SW">
                  <input formControlName="quantityPcsNeoPDMSW" siNewton readonly>
                </si-newton-form-group>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <label><b><u>Batch</u></b></label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3 col-sm-4">
                <si-newton-form-group label="Batch SW">
                  <input formControlName="quantityPcsNeoBatchSW" siNewton readonly>
                </si-newton-form-group>
              </div>

              <div class="col-md-3 col-sm-4">
                <si-newton-form-group label="Batch Client SW">
                  <input formControlName="quantityPcsNeoBatchClientSW" siNewton readonly>
                </si-newton-form-group>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <label><b><u>Route Control</u></b></label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3 col-sm-4">
                <si-newton-form-group label="Route Control SW">
                  <input formControlName="quantityPcsNeoRouteControlSW" siNewton readonly>
                </si-newton-form-group>
              </div>
            </div>
        </tab>
        <tab heading="Field Level Components">

          <div class="row">
            <div class="col">
              <b class="subSubSectionHeader"><u>PCS 7</u></b>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3 col-sm-4">
              <si-newton-form-group label="Communication Processor">
                <input formControlName="quantityPCS7CPs" siNewton readonly>
              </si-newton-form-group>
            </div>

            <div class="col-md-3 col-sm-4">
              <si-newton-form-group label="Fieldbus components">
                <input formControlName="quantityPCS7FieldbusComponents" siNewton readonly>
              </si-newton-form-group>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <b class="subSubSectionHeader"><u>PCS Neo</u></b>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3 col-sm-4">
              <si-newton-form-group label="Communication Processor">
                <input formControlName="quantityPcsNeoCPs" siNewton readonly>
              </si-newton-form-group>
            </div>
            <div class="col-md-3 col-sm-4">
              <si-newton-form-group label="Fieldbus components">
                <input formControlName="quantityPcsNeoFieldbusComponents" siNewton readonly>
              </si-newton-form-group>
            </div>
          </div>

        </tab>
        <tab heading="AS & F-AS connected">

         

          <div class="row">
            <div class="col">
              <b class="subSubSectionHeader"><u>PCS 7</u></b>
            </div>
          </div>

          <div class="row">
            <p class="info">F-Engineering (No. of ESes) can't be read automatically. Please adapt the quantity by yourself.</p>
          </div>

            <div class="row">
              <div class="col">
                <label><u><b>ASes connected to physical system</b></u></label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3 col-sm-4">
                <si-newton-form-group label="AS single (1 CPU)">
                  <input formControlName="quantityPCS7ASSingle" readOnly siNewton>
                </si-newton-form-group>
              </div>

              <div class="col-md-3 col-sm-4">
                <si-newton-form-group label="AS redundand (2 CPUs)" >
                  <input formControlName="quantityPCS7ASRedundand" readOnly siNewton>
                </si-newton-form-group>
              </div>

              <div class="col-md-3 col-sm-4">
                <si-newton-form-group label="F-SYS SW" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers allowed here'}">
                  <input formControlName="quantityPCS7FSYS" type="number" [min]="0" readOnly siNewton>
                </si-newton-form-group>
              </div>

            </div>


            <div class="row">
              <div class="col">
                <b class="subSubSectionHeader"><u>PCS Neo</u></b>
              </div>
            </div>


            <div class="row">
              <div class="col">
                <label><u><b>ASes connected to physical system</b></u></label>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3 col-sm-4">
                <si-newton-form-group label="AS single (1 CPU)">
                  <input formControlName="quantityPcsNeoPlcAsSingle" readOnly siNewton>
                </si-newton-form-group>
              </div>

              <div class="col-md-3 col-sm-4">
                <si-newton-form-group label="AS redundand (2 CPUs)" >
                  <input formControlName="quantityPcsNeoPlcAsRedundant" readOnly siNewton>
                </si-newton-form-group>
              </div>

              <div class="col-md-3 col-sm-4">
                <si-newton-form-group label="F-SYS SW" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers allowed here'}">
                  <input formControlName="quantityPcsNeoFSYS" type="number" [min]="0" readOnly siNewton>
                </si-newton-form-group>
              </div>

            </div>


        </tab>
        <tab heading="Network Components">
          <div class="row">
            <p class="info">Please adapt the quantity of Net segments by yourself.</p>
          </div>

          <div class="row">
            <div class="col">
              <b class="subSubSectionHeader"><u>PCS 7</u></b>
            </div>
          </div>


            <div class="row">
              <div class="col-md-3 col-sm-4">
                <si-newton-form-group label="Net segments" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers allowed here'}">
                  <input formControlName="quantityPCS7NetSegments" type="number" [min]="0" siNewton>
                </si-newton-form-group>
              </div>

              <div class="col-md-3 col-sm-4">
                <si-newton-form-group label="Network Components">
                  <input formControlName="quantityPCS7NetworkComponents" type="number" [min]="0" readOnly siNewton>
                </si-newton-form-group>
              </div>

              <div class="col-md-3 col-sm-4">
                <si-newton-form-group label="Unique Types of Switches" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers allowed here'}">
                  <input formControlName="typesOfPCS7Switches" readOnly siNewton>
                </si-newton-form-group>
              </div>

              <div class="col-md-3 col-sm-4">
                <si-newton-form-group label="Total L Price for Switches">
                  <div class="input-group-prepend">
                    <span class="input-group-text">€</span>
                  </div>
                  <input formControlName="totalLPriceOfPCS7Switches" readOnly siNewton>
                </si-newton-form-group>
              </div>
            </div>

            <div class="row">
              <div class="col-md-3 col-sm-4">
                <si-newton-form-group label="Suppl. Components">
                  <input formControlName="quantityPCS7NetworkCompSuppl" type="number" [min]="0" readOnly siNewton>
                </si-newton-form-group>
              </div>

              <div class="col-md-3 col-sm-4">
                <si-newton-form-group label="Unique Types of Suppl. Comp" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers allowed here'}">
                  <input formControlName="typesOfPCS7NetworkComponentsSuppl" readOnly siNewton>
                </si-newton-form-group>
              </div>

              <div class="col-md-3 col-sm-4">
                <si-newton-form-group label="Total L Price for Suppl. Components">
                  <div class="input-group-prepend">
                    <span class="input-group-text">€</span>
                  </div>
                  <input formControlName="pcs7SupplComponentTotalCost" readOnly siNewton>
                </si-newton-form-group>
              </div>
            </div>

            

            <div class="row">
              <div class="col">
                <b class="subSubSectionHeader"><u>PCS Neo</u></b>
              </div>
            </div>

            <div class="row">
              <div class="col-md-3 col-sm-4">
                <si-newton-form-group label="Net segments" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers allowed here'}">
                  <input formControlName="quantityPcsNeoNetSegments" type="number" [min]="0" siNewton>
                </si-newton-form-group>
              </div>

              <div class="col-md-3 col-sm-4">
                <si-newton-form-group label="Network Components">
                  <input formControlName="quantityPcsNeoSwitches" type="number" [min]="0" readOnly siNewton>
                </si-newton-form-group>
              </div>

              <div class="col-md-3 col-sm-4">
                <si-newton-form-group label="Unique Types of Switches" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers allowed here'}">
                  <input formControlName="typesOfPcsNeoSwitches" readOnly siNewton>
                </si-newton-form-group>
              </div>

              <div class="col-md-3 col-sm-4">
                <si-newton-form-group label="Total L Price for Switches">
                  <div class="input-group-prepend">
                    <span class="input-group-text">€</span>
                  </div>
                  <input formControlName="totalLPriceOfPcsNeoSwitches" readOnly siNewton>
                </si-newton-form-group>
              </div>
            </div>

            <div class="row">
              <div class="col-md-3 col-sm-4">
                <si-newton-form-group label="Suppl. Components">
                  <input formControlName="pcsNeoSupplComponentsQuantity" type="number" [min]="0" readOnly siNewton>
                </si-newton-form-group>
              </div>

              <div class="col-md-3 col-sm-4">
                <si-newton-form-group label="Unique Types of Suppl. Comp" [errorResolverMap]="{required: 'Value is required', pattern: 'Only integers allowed here'}">
                  <input formControlName="typesOfPcsNeoNetworkComponentsSuppl" readOnly siNewton>
                </si-newton-form-group>
              </div>

              <div class="col-md-3 col-sm-4">
                <si-newton-form-group label="Total L Price for Suppl. Components">
                  <div class="input-group-prepend">
                    <span class="input-group-text">€</span>
                  </div>
                  <input formControlName="pcsNeoSupplComponentTotalCost" readOnly siNewton>
                </si-newton-form-group>
              </div>
            </div>



        </tab>

        <tab heading="IT Components">

          <div class="row">
            <div class="col">
              <b class="subSubSectionHeader"><u>PCS 7</u></b>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3 col-sm-4">
              <si-newton-form-group label="Windows Domain Server HW">
                <input formControlName="quantityPCS7WinDomainServerHW" siNewton readonly>
              </si-newton-form-group>
            </div>

            <div class="col-md-3 col-sm-4">
              <si-newton-form-group label="WSUS Server HW">
                <input formControlName="quantityPCS7WSUSServerHW" siNewton readonly>
              </si-newton-form-group>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <b class="subSubSectionHeader"><u>PCS Neo</u></b>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3 col-sm-4">
              <si-newton-form-group label="Windows Domain Server HW">
                <input formControlName="quantityPcsNeoWinDomainServerHW" siNewton readonly>
              </si-newton-form-group>
            </div>

            <div class="col-md-3 col-sm-4">
              <si-newton-form-group label="WSUS Server HW">
                <input formControlName="quantityPcsNeoWSUSServerHW" siNewton readonly>
              </si-newton-form-group>
            </div>
          </div>

        </tab>
      </tabset>
    </div>
  </div>
</form>
