import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormDataService } from 'src/app/services/form-data.service';

export interface DropDownOption {
  value: string;
}

@Component({
  selector: 'app-iadc-usv',
  templateUrl: './iadc-usv.component.html',
  styleUrls: ['./iadc-usv.component.scss']
})
export class IadcUsvComponent {

  formName = 'iadcUSV';

  @Input()
  public parentForm!: FormGroup;

  contractDuration = 0;
  iadcUSVSizeRb!: string;

  stringToNumber(num: string){
    return parseInt(num, 10);
  }

  constructor(private formDataService: FormDataService) {}


  public getDropdownOptions(formControlName: string){
    return this.formDataService.getDropDownOptions(formControlName);
  }

  public getDropdownValue(dropDownOption: DropDownOption): string {
    return dropDownOption.value;
  }

}
