<form [formGroup]="parentForm">
  <div formGroupName="costOverview">

    <h5 class="subSectionHeader">Cost Overview</h5>

    <div class="row">
      <div #ContainerRef class="col">
        <ngx-charts-bar-vertical-stacked
        [view]="[ContainerRef.offsetWidth, 400]"
        [scheme]="colorScheme"
        [results]="costOverviewData"
        [xAxis]="showXAxis"
        [yAxis]="showYAxis"
        [legend]="showLegend"
        [showDataLabel]="true"
        [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel"
        [xAxisLabel]="xAxisLabel"
        [yAxisLabel]="yAxisLabel"
        (select)="onSelect($event)">
      </ngx-charts-bar-vertical-stacked>

      </div>

    </div>
  </div>
</form>

