/* eslint-disable max-len */
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ConstRestData } from 'src/app/const-data/rest';
import { DataTransferService } from 'src/app/services/data-transfer.service';
import { FormDataService } from 'src/app/services/form-data.service';

@Component({
  selector: 'app-iadc',
  templateUrl: './iadc.component.html',
  styleUrls: ['./iadc.component.scss']
})
export class IadcComponent implements OnInit {

  REST_URLS = ConstRestData.restUrls;
  iadcForm: FormGroup;

  @ViewChild('first-element', { static: false }) firstElement!: ElementRef;

  constructor(private formBuilder: FormBuilder, private dataTransferService: DataTransferService,
    private formDataService: FormDataService) {
    this.iadcForm = this.formBuilder.group({
      iadcFirewalls: this.formBuilder.group({
        firstUpgradeOverall: [null],
        secondUpgradeOverall: [null],
        thirdUpgradeOverall: [null],
        lcsContractLastYear: [null],
        yearOfStartFix: [null],
        lcsContractDuration: [null],
        iadcFirewallsServiceCostIncreaseRate: [null, Validators.required],
        iadcFirewallsProductCostIncreaseRate: [null, Validators.required],
        iadcFirewallsMargin: [null, Validators.required],
        iadcFirewallsRiskAddition: [null, Validators.required],
        iadcFirewallsRegionalDiscount: [null, Validators.required],
        iadcFirewallsHwSwUpgrServiceTask1Name: [null, Validators.required],
        iadcFirewallsHwSwUpgrServiceTask1Description: [null],
        iadcFirewallsHwSwUpgrServiceTask1EffortOnSite: [null, Validators.required],
        iadcFirewallsHwSwUpgrServiceTask1EffortOffSite: [null, Validators.required],
        iadcFirewallsHwSwUpgrServiceTask1ServiceCost: [null, Validators.required],
        iadcFirewallsIuWTimesPerYear: [null, Validators.required],
        iadcFirewallsIuWTask1Name: [null, Validators.required],
        iadcFirewallsIuWTask1Description: [null, Validators.required],
        iadcFirewallsIuWTask1EffortOnSite: [null, Validators.required],
        iadcFirewallsIuWTask1MaterialCost: [null, Validators.required],
        iadcFirewallsIuWTask1ServiceCost: [null, Validators.required],
        iadcFirewallsIuWTask1TotalCost: [null, Validators.required],
        iadcFirewallsIuWTask2Name: [null, Validators.required],
        iadcFirewallsIuWTask2Description: [null, Validators.required],
        iadcFirewallsIuWTask2EffortOnSite: [null, Validators.required],
        iadcFirewallsIuWTask2MaterialCost: [null, Validators.required],
        iadcFirewallsIuWTask2ServiceCost: [null, Validators.required],
        iadcFirewallsIuWTask2TotalCost: [null, Validators.required],
        iadcFirewallsServiceContractCostIncreaseRate: [null, Validators.required]

      }),
      iadcUSV: this.formBuilder.group({
        yearOfStartFix: [null],
        lcsContractDuration: [null],
        iadcUSVSelectedCb: [null, Validators.required],
        iadcUSVQuantityBattery: [null, Validators.required],
        iadcUSVSizeRb: [null, Validators.required],
        iadcUSVProductCostIncreaseRate: [null, Validators.compose([Validators.required, Validators.min(0)])],
        iadcUSVServiceCostIncreaseRate: [null, Validators.compose([Validators.required, Validators.min(0)])],
        iadcUSVRiskAddition: [null, Validators.compose([Validators.required, Validators.min(0)])],
        iadcUSVMargin: [null, Validators.compose([Validators.required, Validators.min(0)])],
        iadcUSVRegionalDiscount: [null, Validators.compose([Validators.required, Validators.min(0)])],
        iadcUSVHwSwUpgrServiceTask1Name: [null, Validators.required],
        iadcUSVHwSwUpgrServiceTask1Description: [null, Validators.required],
        iadcUSVHwSwUpgrServiceTask1EffortOnSite: [null, Validators.compose([Validators.required, Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        iadcUSVHwSwUpgrServiceTask1EffortOffSite: [null, Validators.compose([Validators.required, Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        iadcUSVHwSwUpgrServiceTask1ServiceCost: [null, Validators.required],
        iadcUSVHwSwUpgrServiceTask2Name: [null, Validators.required],
        iadcUSVHwSwUpgrServiceTask2Description: [null, Validators.required],
        iadcUSVHwSwUpgrServiceTask2EffortOnSite: [null, Validators.compose([Validators.required, Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        iadcUSVHwSwUpgrServiceTask2EffortOffSite: [null, Validators.compose([Validators.required, Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        iadcUSVHwSwUpgrServiceTask2ServiceCost: [null, Validators.required],
        iadcUSVHwSwUpgrProduct1Name: [null, Validators.required],
        iadcUSVHwSwUpgrProduct1Quantity: [null, Validators.required],
        iadcUSVHwSwUpgrProduct1CostIncreaseRate: [null, Validators.compose([Validators.required, Validators.min(0)])],
        iadcUSVHwSwUpgrProduct1DescriptionMlfb: [null, Validators.required],
        iadcUSVHwSwUpgrProduct1Cost: [null, Validators.required],
        iadcUSVHwSwUpgrProduct2Name: [null, Validators.required],
        iadcUSVHwSwUpgrProduct2Quantity: [null, Validators.required],
        iadcUSVHwSwUpgrProduct2CostIncreaseRate: [null, Validators.compose([Validators.required, Validators.min(0)])],
        iadcUSVHwSwUpgrProduct2DescriptionMlfb: [null, Validators.required],
        iadcUSVHwSwUpgrProduct2Cost: [null, Validators.required],
        iadcUSVHwSwUpgrProduct3Name: [null, Validators.required],
        iadcUSVHwSwUpgrProduct3Quantity: [null, Validators.required],
        iadcUSVHwSwUpgrProduct3CostIncreaseRate: [null, Validators.compose([Validators.required, Validators.min(0)])],
        iadcUSVHwSwUpgrProduct3DescriptionMlfb: [null, Validators.required],
        iadcUSVHwSwUpgrProduct3Cost: [null, Validators.required],
        iadcUSVIuWTimesPerYear: [null, Validators.compose([Validators.required, Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        iadcUSVIuWTask1Name: [null, Validators.required],
        iadcUSVIuWTask1Description: [null],
        iadcUSVIuWTask1EffortOnSite: [null, Validators.compose([Validators.required, Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        iadcUSVIuWTask1MaterialCost: [null, Validators.compose([Validators.required, Validators.min(0)])],
        iadcUSVIuWTask1ServiceCost: [null, Validators.required],
        iadcUSVIuWTask1TotalCost: [null, Validators.required],
        iadcUSVIuWTask2Name: [null, Validators.required],
        iadcUSVIuWTask2Description: [null],
        iadcUSVIuWTask2EffortOnSite: [null, Validators.compose([Validators.required, Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        iadcUSVIuWTask2MaterialCost: [null, Validators.compose([Validators.required, Validators.min(0)])],
        iadcUSVIuWTask2ServiceCost: [null, Validators.required],
        iadcUSVIuWTask2TotalCost: [null, Validators.required],
        iadcUSVIuWTask3Name: [null, Validators.required],
        iadcUSVIuWTask3Description: [null],
        iadcUSVIuWTask3EffortOnSite: [null, Validators.compose([Validators.required, Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        iadcUSVIuWTask3MaterialCost: [null, Validators.compose([Validators.required, Validators.min(0)])],
        iadcUSVIuWTask3ServiceCost: [null, Validators.required],
        iadcUSVIuWTask3TotalCost: [null, Validators.required],
        iadcUSVIuWSelectedYear1: [null, Validators.required],
        iadcUSVIuWSelectedYear2: [null, Validators.required],
        iadcUSVIuWSelectedYear3: [null, Validators.required],
        iadcUSVIuWSelectedYear4: [null, Validators.required],
        iadcUSVIuWSelectedYear5: [null, Validators.required],
        iadcUSVIuWSelectedYear6: [null, Validators.required],
        iadcUSVIuWSelectedYear7: [null, Validators.required],
        iadcUSVIuWSelectedYear8: [null, Validators.required],
        iadcUSVIuWSelectedYear9: [null, Validators.required],
        iadcUSVIuWSelectedYear10: [null, Validators.required],
        iadcUSVIuWSelectedYear11: [null, Validators.required],
        iadcUSVIuWSelectedYear12: [null, Validators.required],
        iadcUSVIuWSelectedYear13: [null, Validators.required],
        iadcUSVIuWSelectedYear14: [null, Validators.required],
        iadcUSVIuWSelectedYear15: [null, Validators.required],
        iadcUSVCostOverviewHwSwUpgServicesYear1: [null, Validators.required],
        iadcUSVCostOverviewHwSwUpgServicesYear2: [null, Validators.required],
        iadcUSVCostOverviewHwSwUpgServicesYear3: [null, Validators.required],
        iadcUSVCostOverviewHwSwUpgServicesYear4: [null, Validators.required],
        iadcUSVCostOverviewHwSwUpgServicesYear5: [null, Validators.required],
        iadcUSVCostOverviewHwSwUpgServicesYear6: [null, Validators.required],
        iadcUSVCostOverviewHwSwUpgServicesYear7: [null, Validators.required],
        iadcUSVCostOverviewHwSwUpgServicesYear8: [null, Validators.required],
        iadcUSVCostOverviewHwSwUpgServicesYear9: [null, Validators.required],
        iadcUSVCostOverviewHwSwUpgServicesYear10: [null, Validators.required],
        iadcUSVCostOverviewHwSwUpgServicesYear11: [null, Validators.required],
        iadcUSVCostOverviewHwSwUpgServicesYear12: [null, Validators.required],
        iadcUSVCostOverviewHwSwUpgServicesYear13: [null, Validators.required],
        iadcUSVCostOverviewHwSwUpgServicesYear14: [null, Validators.required],
        iadcUSVCostOverviewHwSwUpgServicesYear15: [null, Validators.required],
        iadcUSVCostOverviewHwSwUpgProductsYear1: [null, Validators.required],
        iadcUSVCostOverviewHwSwUpgProductsYear2: [null, Validators.required],
        iadcUSVCostOverviewHwSwUpgProductsYear3: [null, Validators.required],
        iadcUSVCostOverviewHwSwUpgProductsYear4: [null, Validators.required],
        iadcUSVCostOverviewHwSwUpgProductsYear5: [null, Validators.required],
        iadcUSVCostOverviewHwSwUpgProductsYear6: [null, Validators.required],
        iadcUSVCostOverviewHwSwUpgProductsYear7: [null, Validators.required],
        iadcUSVCostOverviewHwSwUpgProductsYear8: [null, Validators.required],
        iadcUSVCostOverviewHwSwUpgProductsYear9: [null, Validators.required],
        iadcUSVCostOverviewHwSwUpgProductsYear10: [null, Validators.required],
        iadcUSVCostOverviewHwSwUpgProductsYear11: [null, Validators.required],
        iadcUSVCostOverviewHwSwUpgProductsYear12: [null, Validators.required],
        iadcUSVCostOverviewHwSwUpgProductsYear13: [null, Validators.required],
        iadcUSVCostOverviewHwSwUpgProductsYear14: [null, Validators.required],
        iadcUSVCostOverviewHwSwUpgProductsYear15: [null, Validators.required],
        iadcUSVCostOverviewIuWYear1: [null, Validators.required],
        iadcUSVCostOverviewIuWYear2: [null, Validators.required],
        iadcUSVCostOverviewIuWYear3: [null, Validators.required],
        iadcUSVCostOverviewIuWYear4: [null, Validators.required],
        iadcUSVCostOverviewIuWYear5: [null, Validators.required],
        iadcUSVCostOverviewIuWYear6: [null, Validators.required],
        iadcUSVCostOverviewIuWYear7: [null, Validators.required],
        iadcUSVCostOverviewIuWYear8: [null, Validators.required],
        iadcUSVCostOverviewIuWYear9: [null, Validators.required],
        iadcUSVCostOverviewIuWYear10: [null, Validators.required],
        iadcUSVCostOverviewIuWYear11: [null, Validators.required],
        iadcUSVCostOverviewIuWYear12: [null, Validators.required],
        iadcUSVCostOverviewIuWYear13: [null, Validators.required],
        iadcUSVCostOverviewIuWYear14: [null, Validators.required],
        iadcUSVCostOverviewIuWYear15: [null, Validators.required],
        iadcUSVCostOverviewTotalYear1: [null, Validators.required],
        iadcUSVCostOverviewTotalYear2: [null, Validators.required],
        iadcUSVCostOverviewTotalYear3: [null, Validators.required],
        iadcUSVCostOverviewTotalYear4: [null, Validators.required],
        iadcUSVCostOverviewTotalYear5: [null, Validators.required],
        iadcUSVCostOverviewTotalYear6: [null, Validators.required],
        iadcUSVCostOverviewTotalYear7: [null, Validators.required],
        iadcUSVCostOverviewTotalYear8: [null, Validators.required],
        iadcUSVCostOverviewTotalYear9: [null, Validators.required],
        iadcUSVCostOverviewTotalYear10: [null, Validators.required],
        iadcUSVCostOverviewTotalYear11: [null, Validators.required],
        iadcUSVCostOverviewTotalYear12: [null, Validators.required],
        iadcUSVCostOverviewTotalYear13: [null, Validators.required],
        iadcUSVCostOverviewTotalYear14: [null, Validators.required],
        iadcUSVCostOverviewTotalYear15: [null, Validators.required],
        iadcUSVCostOverviewIncludedRiskYear1: [null, Validators.required],
        iadcUSVCostOverviewIncludedRiskYear2: [null, Validators.required],
        iadcUSVCostOverviewIncludedRiskYear3: [null, Validators.required],
        iadcUSVCostOverviewIncludedRiskYear4: [null, Validators.required],
        iadcUSVCostOverviewIncludedRiskYear5: [null, Validators.required],
        iadcUSVCostOverviewIncludedRiskYear6: [null, Validators.required],
        iadcUSVCostOverviewIncludedRiskYear7: [null, Validators.required],
        iadcUSVCostOverviewIncludedRiskYear8: [null, Validators.required],
        iadcUSVCostOverviewIncludedRiskYear9: [null, Validators.required],
        iadcUSVCostOverviewIncludedRiskYear10: [null, Validators.required],
        iadcUSVCostOverviewIncludedRiskYear11: [null, Validators.required],
        iadcUSVCostOverviewIncludedRiskYear12: [null, Validators.required],
        iadcUSVCostOverviewIncludedRiskYear13: [null, Validators.required],
        iadcUSVCostOverviewIncludedRiskYear14: [null, Validators.required],
        iadcUSVCostOverviewIncludedRiskYear15: [null, Validators.required],
        firstUpgradeOverall: [null],
        secondUpgradeOverall: [null],
        thirdUpgradeOverall: [null],
        lcsContractLastYear: [null]
      }),
      iadcOtSwitch: this.formBuilder.group({
        yearOfStartFix: [null],
        lcsContractDuration: [null],
        iadcOtSwitchProductType: [null, Validators.required],
        iadcOtSwitchSelectedCb: [null, Validators.required],
        iadcOtSwitchQuantity: [null, Validators.required],
        iadcOtSwitchProductCostIncreaseRate: [null, Validators.compose([Validators.required, Validators.min(0)])],
        iadcOtSwitchServiceCostIncreaseRate: [null, Validators.compose([Validators.required, Validators.min(0)])],
        iadcOtSwitchRiskAddition: [null, Validators.compose([Validators.required, Validators.min(0)])],
        iadcOtSwitchMargin: [null, Validators.compose([Validators.required, Validators.min(0)])],
        iadcOtSwitchRegionalDiscount: [null, Validators.compose([Validators.required, Validators.min(0)])],
        iadcOtSwitchHwSwUpgrServiceTask1Name: [null, Validators.required],
        iadcOtSwitchHwSwUpgrServiceTask1Description: [null, Validators.required],
        iadcOtSwitchHwSwUpgrServiceTask1EffortOnSite: [null, Validators.compose([Validators.required, Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        iadcOtSwitchHwSwUpgrServiceTask1EffortOffSite: [null, Validators.compose([Validators.required, Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        iadcOtSwitchHwSwUpgrServiceTask1ServiceCost: [null, Validators.required],
        iadcOtSwitchHwSwUpgrServiceTask2Name: [null, Validators.required],
        iadcOtSwitchHwSwUpgrServiceTask2Description: [null, Validators.required],
        iadcOtSwitchHwSwUpgrServiceTask2EffortOnSite: [null, Validators.compose([Validators.required, Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        iadcOtSwitchHwSwUpgrServiceTask2EffortOffSite: [null, Validators.compose([Validators.required, Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        iadcOtSwitchHwSwUpgrServiceTask2ServiceCost: [null, Validators.required],
        iadcOtSwitchHwSwUpgrProduct1Name: [null, Validators.required],
        iadcOtSwitchHwSwUpgrProduct1CostIncreaseRate: [null, Validators.compose([Validators.required, Validators.min(0)])],
        iadcOtSwitchHwSwUpgrProduct1DescriptionMlfb: [null, Validators.required],
        iadcOtSwitchHwSwUpgrProduct1Cost: [null, Validators.required],
        iadcOtSwitchIuWTimesPerYear: [null, Validators.compose([Validators.required, Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        iadcOtSwitchIuWTask1Name: [null, Validators.required],
        iadcOtSwitchIuWTask1Description: [null],
        iadcOtSwitchIuWTask1EffortOnSite: [null, Validators.compose([Validators.required, Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        iadcOtSwitchIuWTask1MaterialCost: [null, Validators.compose([Validators.required, Validators.min(0)])],
        iadcOtSwitchIuWTask1ServiceCost: [null, Validators.required],
        iadcOtSwitchIuWTask1TotalCost: [null, Validators.required],
        iadcOtSwitchIuWTask2Name: [null, Validators.required],
        iadcOtSwitchIuWTask2Description: [null],
        iadcOtSwitchIuWTask2EffortOnSite: [null, Validators.compose([Validators.required, Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        iadcOtSwitchIuWTask2MaterialCost: [null, Validators.compose([Validators.required, Validators.min(0)])],
        iadcOtSwitchIuWTask2ServiceCost: [null, Validators.required],
        iadcOtSwitchIuWTask2TotalCost: [null, Validators.required],
        iadcOtSwitchIuWTask3Name: [null, Validators.required],
        iadcOtSwitchIuWTask3Description: [null],
        iadcOtSwitchIuWTask3EffortOnSite: [null, Validators.compose([Validators.required, Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        iadcOtSwitchIuWTask3MaterialCost: [null, Validators.compose([Validators.required, Validators.min(0)])],
        iadcOtSwitchIuWTask3ServiceCost: [null, Validators.required],
        iadcOtSwitchIuWTask3TotalCost: [null, Validators.required],
        iadcOtSwitchIuWSelectedYear1: [null, Validators.required],
        iadcOtSwitchIuWSelectedYear2: [null, Validators.required],
        iadcOtSwitchIuWSelectedYear3: [null, Validators.required],
        iadcOtSwitchIuWSelectedYear4: [null, Validators.required],
        iadcOtSwitchIuWSelectedYear5: [null, Validators.required],
        iadcOtSwitchIuWSelectedYear6: [null, Validators.required],
        iadcOtSwitchIuWSelectedYear7: [null, Validators.required],
        iadcOtSwitchIuWSelectedYear8: [null, Validators.required],
        iadcOtSwitchIuWSelectedYear9: [null, Validators.required],
        iadcOtSwitchIuWSelectedYear10: [null, Validators.required],
        iadcOtSwitchIuWSelectedYear11: [null, Validators.required],
        iadcOtSwitchIuWSelectedYear12: [null, Validators.required],
        iadcOtSwitchIuWSelectedYear13: [null, Validators.required],
        iadcOtSwitchIuWSelectedYear14: [null, Validators.required],
        iadcOtSwitchIuWSelectedYear15: [null, Validators.required],
        iadcOtSwitchCostOverviewHwSwUpgServicesYear1: [null, Validators.required],
        iadcOtSwitchCostOverviewHwSwUpgServicesYear2: [null, Validators.required],
        iadcOtSwitchCostOverviewHwSwUpgServicesYear3: [null, Validators.required],
        iadcOtSwitchCostOverviewHwSwUpgServicesYear4: [null, Validators.required],
        iadcOtSwitchCostOverviewHwSwUpgServicesYear5: [null, Validators.required],
        iadcOtSwitchCostOverviewHwSwUpgServicesYear6: [null, Validators.required],
        iadcOtSwitchCostOverviewHwSwUpgServicesYear7: [null, Validators.required],
        iadcOtSwitchCostOverviewHwSwUpgServicesYear8: [null, Validators.required],
        iadcOtSwitchCostOverviewHwSwUpgServicesYear9: [null, Validators.required],
        iadcOtSwitchCostOverviewHwSwUpgServicesYear10: [null, Validators.required],
        iadcOtSwitchCostOverviewHwSwUpgServicesYear11: [null, Validators.required],
        iadcOtSwitchCostOverviewHwSwUpgServicesYear12: [null, Validators.required],
        iadcOtSwitchCostOverviewHwSwUpgServicesYear13: [null, Validators.required],
        iadcOtSwitchCostOverviewHwSwUpgServicesYear14: [null, Validators.required],
        iadcOtSwitchCostOverviewHwSwUpgServicesYear15: [null, Validators.required],
        iadcOtSwitchCostOverviewHwSwUpgProductsYear1: [null, Validators.required],
        iadcOtSwitchCostOverviewHwSwUpgProductsYear2: [null, Validators.required],
        iadcOtSwitchCostOverviewHwSwUpgProductsYear3: [null, Validators.required],
        iadcOtSwitchCostOverviewHwSwUpgProductsYear4: [null, Validators.required],
        iadcOtSwitchCostOverviewHwSwUpgProductsYear5: [null, Validators.required],
        iadcOtSwitchCostOverviewHwSwUpgProductsYear6: [null, Validators.required],
        iadcOtSwitchCostOverviewHwSwUpgProductsYear7: [null, Validators.required],
        iadcOtSwitchCostOverviewHwSwUpgProductsYear8: [null, Validators.required],
        iadcOtSwitchCostOverviewHwSwUpgProductsYear9: [null, Validators.required],
        iadcOtSwitchCostOverviewHwSwUpgProductsYear10: [null, Validators.required],
        iadcOtSwitchCostOverviewHwSwUpgProductsYear11: [null, Validators.required],
        iadcOtSwitchCostOverviewHwSwUpgProductsYear12: [null, Validators.required],
        iadcOtSwitchCostOverviewHwSwUpgProductsYear13: [null, Validators.required],
        iadcOtSwitchCostOverviewHwSwUpgProductsYear14: [null, Validators.required],
        iadcOtSwitchCostOverviewHwSwUpgProductsYear15: [null, Validators.required],
        iadcOtSwitchCostOverviewIuWYear1: [null, Validators.required],
        iadcOtSwitchCostOverviewIuWYear2: [null, Validators.required],
        iadcOtSwitchCostOverviewIuWYear3: [null, Validators.required],
        iadcOtSwitchCostOverviewIuWYear4: [null, Validators.required],
        iadcOtSwitchCostOverviewIuWYear5: [null, Validators.required],
        iadcOtSwitchCostOverviewIuWYear6: [null, Validators.required],
        iadcOtSwitchCostOverviewIuWYear7: [null, Validators.required],
        iadcOtSwitchCostOverviewIuWYear8: [null, Validators.required],
        iadcOtSwitchCostOverviewIuWYear9: [null, Validators.required],
        iadcOtSwitchCostOverviewIuWYear10: [null, Validators.required],
        iadcOtSwitchCostOverviewIuWYear11: [null, Validators.required],
        iadcOtSwitchCostOverviewIuWYear12: [null, Validators.required],
        iadcOtSwitchCostOverviewIuWYear13: [null, Validators.required],
        iadcOtSwitchCostOverviewIuWYear14: [null, Validators.required],
        iadcOtSwitchCostOverviewIuWYear15: [null, Validators.required],
        iadcOtSwitchCostOverviewTotalYear1: [null, Validators.required],
        iadcOtSwitchCostOverviewTotalYear2: [null, Validators.required],
        iadcOtSwitchCostOverviewTotalYear3: [null, Validators.required],
        iadcOtSwitchCostOverviewTotalYear4: [null, Validators.required],
        iadcOtSwitchCostOverviewTotalYear5: [null, Validators.required],
        iadcOtSwitchCostOverviewTotalYear6: [null, Validators.required],
        iadcOtSwitchCostOverviewTotalYear7: [null, Validators.required],
        iadcOtSwitchCostOverviewTotalYear8: [null, Validators.required],
        iadcOtSwitchCostOverviewTotalYear9: [null, Validators.required],
        iadcOtSwitchCostOverviewTotalYear10: [null, Validators.required],
        iadcOtSwitchCostOverviewTotalYear11: [null, Validators.required],
        iadcOtSwitchCostOverviewTotalYear12: [null, Validators.required],
        iadcOtSwitchCostOverviewTotalYear13: [null, Validators.required],
        iadcOtSwitchCostOverviewTotalYear14: [null, Validators.required],
        iadcOtSwitchCostOverviewTotalYear15: [null, Validators.required],
        iadcOtSwitchCostOverviewIncludedRiskYear1: [null, Validators.required],
        iadcOtSwitchCostOverviewIncludedRiskYear2: [null, Validators.required],
        iadcOtSwitchCostOverviewIncludedRiskYear3: [null, Validators.required],
        iadcOtSwitchCostOverviewIncludedRiskYear4: [null, Validators.required],
        iadcOtSwitchCostOverviewIncludedRiskYear5: [null, Validators.required],
        iadcOtSwitchCostOverviewIncludedRiskYear6: [null, Validators.required],
        iadcOtSwitchCostOverviewIncludedRiskYear7: [null, Validators.required],
        iadcOtSwitchCostOverviewIncludedRiskYear8: [null, Validators.required],
        iadcOtSwitchCostOverviewIncludedRiskYear9: [null, Validators.required],
        iadcOtSwitchCostOverviewIncludedRiskYear10: [null, Validators.required],
        iadcOtSwitchCostOverviewIncludedRiskYear11: [null, Validators.required],
        iadcOtSwitchCostOverviewIncludedRiskYear12: [null, Validators.required],
        iadcOtSwitchCostOverviewIncludedRiskYear13: [null, Validators.required],
        iadcOtSwitchCostOverviewIncludedRiskYear14: [null, Validators.required],
        iadcOtSwitchCostOverviewIncludedRiskYear15: [null, Validators.required],
        firstUpgradeOverall: [null],
        secondUpgradeOverall: [null],
        thirdUpgradeOverall: [null],
        lcsContractLastYear: [null]
      }),
      iadcItSwitch: this.formBuilder.group({
        yearOfStartFix: [null],
        lcsContractDuration: [null],
        iadcItSwitchProductType: [null, Validators.required],
        iadcItSwitchSelectedCb: [null, Validators.required],
        iadcItSwitchQuantity: [null, Validators.required],
        iadcItSwitchProductCostIncreaseRate: [null, Validators.compose([Validators.required, Validators.min(0)])],
        iadcItSwitchServiceCostIncreaseRate: [null, Validators.compose([Validators.required, Validators.min(0)])],
        iadcItSwitchRiskAddition: [null, Validators.compose([Validators.required, Validators.min(0)])],
        iadcItSwitchMargin: [null, Validators.compose([Validators.required, Validators.min(0)])],
        iadcItSwitchRegionalDiscount: [null, Validators.compose([Validators.required, Validators.min(0)])],
        iadcItSwitchHwSwUpgrServiceTask1Name: [null, Validators.required],
        iadcItSwitchHwSwUpgrServiceTask1Description: [null, Validators.required],
        iadcItSwitchHwSwUpgrServiceTask1EffortOnSite: [null, Validators.compose([Validators.required, Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        iadcItSwitchHwSwUpgrServiceTask1EffortOffSite: [null, Validators.compose([Validators.required, Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        iadcItSwitchHwSwUpgrServiceTask1ServiceCost: [null, Validators.required],
        iadcItSwitchHwSwUpgrServiceTask2Name: [null, Validators.required],
        iadcItSwitchHwSwUpgrServiceTask2Description: [null, Validators.required],
        iadcItSwitchHwSwUpgrServiceTask2EffortOnSite: [null, Validators.compose([Validators.required, Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        iadcItSwitchHwSwUpgrServiceTask2EffortOffSite: [null, Validators.compose([Validators.required, Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        iadcItSwitchHwSwUpgrServiceTask2ServiceCost: [null, Validators.required],
        iadcItSwitchHwSwUpgrProduct1Name: [null, Validators.required],
        iadcItSwitchHwSwUpgrProduct1CostIncreaseRate: [null, Validators.compose([Validators.required, Validators.min(0)])],
        iadcItSwitchHwSwUpgrProduct1DescriptionMlfb: [null, Validators.required],
        iadcItSwitchHwSwUpgrProduct1Cost: [null, Validators.required],
        iadcItSwitchIuWTimesPerYear: [null, Validators.compose([Validators.required, Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        iadcItSwitchIuWTask1Name: [null, Validators.required],
        iadcItSwitchIuWTask1Description: [null],
        iadcItSwitchIuWTask1EffortOnSite: [null, Validators.compose([Validators.required, Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        iadcItSwitchIuWTask1MaterialCost: [null, Validators.compose([Validators.required, Validators.min(0)])],
        iadcItSwitchIuWTask1ServiceCost: [null, Validators.required],
        iadcItSwitchIuWTask1TotalCost: [null, Validators.required],
        iadcItSwitchIuWTask2Name: [null, Validators.required],
        iadcItSwitchIuWTask2Description: [null],
        iadcItSwitchIuWTask2EffortOnSite: [null, Validators.compose([Validators.required, Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        iadcItSwitchIuWTask2MaterialCost: [null, Validators.compose([Validators.required, Validators.min(0)])],
        iadcItSwitchIuWTask2ServiceCost: [null, Validators.required],
        iadcItSwitchIuWTask2TotalCost: [null, Validators.required],
        iadcItSwitchIuWTask3Name: [null, Validators.required],
        iadcItSwitchIuWTask3Description: [null],
        iadcItSwitchIuWTask3EffortOnSite: [null, Validators.compose([Validators.required, Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        iadcItSwitchIuWTask3MaterialCost: [null, Validators.compose([Validators.required, Validators.min(0)])],
        iadcItSwitchIuWTask3ServiceCost: [null, Validators.required],
        iadcItSwitchIuWTask3TotalCost: [null, Validators.required],
        iadcItSwitchIuWSelectedYear1: [null, Validators.required],
        iadcItSwitchIuWSelectedYear2: [null, Validators.required],
        iadcItSwitchIuWSelectedYear3: [null, Validators.required],
        iadcItSwitchIuWSelectedYear4: [null, Validators.required],
        iadcItSwitchIuWSelectedYear5: [null, Validators.required],
        iadcItSwitchIuWSelectedYear6: [null, Validators.required],
        iadcItSwitchIuWSelectedYear7: [null, Validators.required],
        iadcItSwitchIuWSelectedYear8: [null, Validators.required],
        iadcItSwitchIuWSelectedYear9: [null, Validators.required],
        iadcItSwitchIuWSelectedYear10: [null, Validators.required],
        iadcItSwitchIuWSelectedYear11: [null, Validators.required],
        iadcItSwitchIuWSelectedYear12: [null, Validators.required],
        iadcItSwitchIuWSelectedYear13: [null, Validators.required],
        iadcItSwitchIuWSelectedYear14: [null, Validators.required],
        iadcItSwitchIuWSelectedYear15: [null, Validators.required],
        iadcItSwitchServiceContractCostIncreaseRate: [null, Validators.compose([Validators.required, Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        iadcItSwitchServiceContract1Name: [null, Validators.required],
        iadcItSwitchServiceContract1MLFB: [null, Validators.required],
        iadcItSwitchServiceContract1Duration: [null, Validators.compose([Validators.required, Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        iadcItSwitchServiceContract1Selected: [null, Validators.required],
        iadcItSwitchServiceContract1InitialYear: [null, Validators.compose([Validators.required, Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        iadcItSwitchServiceContract1Cost: [null, Validators.compose([Validators.required, Validators.min(0)])],
        iadcItSwitchServiceContract1SelectedYear1: [null, Validators.required],
        iadcItSwitchServiceContract1SelectedYear2: [null, Validators.required],
        iadcItSwitchServiceContract1SelectedYear3: [null, Validators.required],
        iadcItSwitchServiceContract1SelectedYear4: [null, Validators.required],
        iadcItSwitchServiceContract1SelectedYear5: [null, Validators.required],
        iadcItSwitchServiceContract1SelectedYear6: [null, Validators.required],
        iadcItSwitchServiceContract1SelectedYear7: [null, Validators.required],
        iadcItSwitchServiceContract1SelectedYear8: [null, Validators.required],
        iadcItSwitchServiceContract1SelectedYear9: [null, Validators.required],
        iadcItSwitchServiceContract1SelectedYear10: [null, Validators.required],
        iadcItSwitchServiceContract1SelectedYear11: [null, Validators.required],
        iadcItSwitchServiceContract1SelectedYear12: [null, Validators.required],
        iadcItSwitchServiceContract1SelectedYear13: [null, Validators.required],
        iadcItSwitchServiceContract1SelectedYear14: [null, Validators.required],
        iadcItSwitchServiceContract1SelectedYear15: [null, Validators.required],
        iadcItSwitchCostOverviewHwSwUpgServicesYear1: [null, Validators.required],
        iadcItSwitchCostOverviewHwSwUpgServicesYear2: [null, Validators.required],
        iadcItSwitchCostOverviewHwSwUpgServicesYear3: [null, Validators.required],
        iadcItSwitchCostOverviewHwSwUpgServicesYear4: [null, Validators.required],
        iadcItSwitchCostOverviewHwSwUpgServicesYear5: [null, Validators.required],
        iadcItSwitchCostOverviewHwSwUpgServicesYear6: [null, Validators.required],
        iadcItSwitchCostOverviewHwSwUpgServicesYear7: [null, Validators.required],
        iadcItSwitchCostOverviewHwSwUpgServicesYear8: [null, Validators.required],
        iadcItSwitchCostOverviewHwSwUpgServicesYear9: [null, Validators.required],
        iadcItSwitchCostOverviewHwSwUpgServicesYear10: [null, Validators.required],
        iadcItSwitchCostOverviewHwSwUpgServicesYear11: [null, Validators.required],
        iadcItSwitchCostOverviewHwSwUpgServicesYear12: [null, Validators.required],
        iadcItSwitchCostOverviewHwSwUpgServicesYear13: [null, Validators.required],
        iadcItSwitchCostOverviewHwSwUpgServicesYear14: [null, Validators.required],
        iadcItSwitchCostOverviewHwSwUpgServicesYear15: [null, Validators.required],
        iadcItSwitchCostOverviewHwSwUpgProductsYear1: [null, Validators.required],
        iadcItSwitchCostOverviewHwSwUpgProductsYear2: [null, Validators.required],
        iadcItSwitchCostOverviewHwSwUpgProductsYear3: [null, Validators.required],
        iadcItSwitchCostOverviewHwSwUpgProductsYear4: [null, Validators.required],
        iadcItSwitchCostOverviewHwSwUpgProductsYear5: [null, Validators.required],
        iadcItSwitchCostOverviewHwSwUpgProductsYear6: [null, Validators.required],
        iadcItSwitchCostOverviewHwSwUpgProductsYear7: [null, Validators.required],
        iadcItSwitchCostOverviewHwSwUpgProductsYear8: [null, Validators.required],
        iadcItSwitchCostOverviewHwSwUpgProductsYear9: [null, Validators.required],
        iadcItSwitchCostOverviewHwSwUpgProductsYear10: [null, Validators.required],
        iadcItSwitchCostOverviewHwSwUpgProductsYear11: [null, Validators.required],
        iadcItSwitchCostOverviewHwSwUpgProductsYear12: [null, Validators.required],
        iadcItSwitchCostOverviewHwSwUpgProductsYear13: [null, Validators.required],
        iadcItSwitchCostOverviewHwSwUpgProductsYear14: [null, Validators.required],
        iadcItSwitchCostOverviewHwSwUpgProductsYear15: [null, Validators.required],
        iadcItSwitchCostOverviewIuWYear1: [null, Validators.required],
        iadcItSwitchCostOverviewIuWYear2: [null, Validators.required],
        iadcItSwitchCostOverviewIuWYear3: [null, Validators.required],
        iadcItSwitchCostOverviewIuWYear4: [null, Validators.required],
        iadcItSwitchCostOverviewIuWYear5: [null, Validators.required],
        iadcItSwitchCostOverviewIuWYear6: [null, Validators.required],
        iadcItSwitchCostOverviewIuWYear7: [null, Validators.required],
        iadcItSwitchCostOverviewIuWYear8: [null, Validators.required],
        iadcItSwitchCostOverviewIuWYear9: [null, Validators.required],
        iadcItSwitchCostOverviewIuWYear10: [null, Validators.required],
        iadcItSwitchCostOverviewIuWYear11: [null, Validators.required],
        iadcItSwitchCostOverviewIuWYear12: [null, Validators.required],
        iadcItSwitchCostOverviewIuWYear13: [null, Validators.required],
        iadcItSwitchCostOverviewIuWYear14: [null, Validators.required],
        iadcItSwitchCostOverviewIuWYear15: [null, Validators.required],
        iadcItSwitchCostOverviewServiceContractsYear1: [null, Validators.required],
        iadcItSwitchCostOverviewServiceContractsYear2: [null, Validators.required],
        iadcItSwitchCostOverviewServiceContractsYear3: [null, Validators.required],
        iadcItSwitchCostOverviewServiceContractsYear4: [null, Validators.required],
        iadcItSwitchCostOverviewServiceContractsYear5: [null, Validators.required],
        iadcItSwitchCostOverviewServiceContractsYear6: [null, Validators.required],
        iadcItSwitchCostOverviewServiceContractsYear7: [null, Validators.required],
        iadcItSwitchCostOverviewServiceContractsYear8: [null, Validators.required],
        iadcItSwitchCostOverviewServiceContractsYear9: [null, Validators.required],
        iadcItSwitchCostOverviewServiceContractsYear10: [null, Validators.required],
        iadcItSwitchCostOverviewServiceContractsYear11: [null, Validators.required],
        iadcItSwitchCostOverviewServiceContractsYear12: [null, Validators.required],
        iadcItSwitchCostOverviewServiceContractsYear13: [null, Validators.required],
        iadcItSwitchCostOverviewServiceContractsYear14: [null, Validators.required],
        iadcItSwitchCostOverviewServiceContractsYear15: [null, Validators.required],
        iadcItSwitchCostOverviewTotalYear1: [null, Validators.required],
        iadcItSwitchCostOverviewTotalYear2: [null, Validators.required],
        iadcItSwitchCostOverviewTotalYear3: [null, Validators.required],
        iadcItSwitchCostOverviewTotalYear4: [null, Validators.required],
        iadcItSwitchCostOverviewTotalYear5: [null, Validators.required],
        iadcItSwitchCostOverviewTotalYear6: [null, Validators.required],
        iadcItSwitchCostOverviewTotalYear7: [null, Validators.required],
        iadcItSwitchCostOverviewTotalYear8: [null, Validators.required],
        iadcItSwitchCostOverviewTotalYear9: [null, Validators.required],
        iadcItSwitchCostOverviewTotalYear10: [null, Validators.required],
        iadcItSwitchCostOverviewTotalYear11: [null, Validators.required],
        iadcItSwitchCostOverviewTotalYear12: [null, Validators.required],
        iadcItSwitchCostOverviewTotalYear13: [null, Validators.required],
        iadcItSwitchCostOverviewTotalYear14: [null, Validators.required],
        iadcItSwitchCostOverviewTotalYear15: [null, Validators.required],
        iadcItSwitchCostOverviewIncludedRiskYear1: [null, Validators.required],
        iadcItSwitchCostOverviewIncludedRiskYear2: [null, Validators.required],
        iadcItSwitchCostOverviewIncludedRiskYear3: [null, Validators.required],
        iadcItSwitchCostOverviewIncludedRiskYear4: [null, Validators.required],
        iadcItSwitchCostOverviewIncludedRiskYear5: [null, Validators.required],
        iadcItSwitchCostOverviewIncludedRiskYear6: [null, Validators.required],
        iadcItSwitchCostOverviewIncludedRiskYear7: [null, Validators.required],
        iadcItSwitchCostOverviewIncludedRiskYear8: [null, Validators.required],
        iadcItSwitchCostOverviewIncludedRiskYear9: [null, Validators.required],
        iadcItSwitchCostOverviewIncludedRiskYear10: [null, Validators.required],
        iadcItSwitchCostOverviewIncludedRiskYear11: [null, Validators.required],
        iadcItSwitchCostOverviewIncludedRiskYear12: [null, Validators.required],
        iadcItSwitchCostOverviewIncludedRiskYear13: [null, Validators.required],
        iadcItSwitchCostOverviewIncludedRiskYear14: [null, Validators.required],
        iadcItSwitchCostOverviewIncludedRiskYear15: [null, Validators.required],
        firstUpgradeOverall: [null],
        secondUpgradeOverall: [null],
        thirdUpgradeOverall: [null],
        lcsContractLastYear: [null]
      }),
      iadcPhServer: this.formBuilder.group({
        yearOfStartFix: [null],
        lcsContractDuration: [null],
        iadcPhSelectedCb: [null, Validators.required],
        iadcPhQuantity: [null, Validators.required],
        iadcPhServiceCostIncreaseRate: [null, Validators.compose([Validators.required, Validators.min(0)])],
        iadcPhProductCostIncreaseRate: [null, Validators.compose([Validators.required, Validators.min(0)])],
        iadcPhRiskAddition: [null, Validators.compose([Validators.required, Validators.min(0)])],
        iadcPhMargin: [null, Validators.compose([Validators.required, Validators.min(0)])],
        iadcPhRegionalDiscount: [null, Validators.compose([Validators.required, Validators.min(0)])],
        iadcPhHwSwUpgrServiceTask1Name: [null, Validators.required],
        iadcPhHwSwUpgrServiceTask1Description: [null, Validators.required],
        iadcPhHwSwUpgrServiceTask1EffortOnSite: [null, Validators.compose([Validators.required, Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        iadcPhHwSwUpgrServiceTask1EffortOffSite: [null, Validators.compose([Validators.required, Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        iadcPhHwSwUpgrServiceTask1ServiceCost: [null, Validators.required],
        iadcPhHwSwUpgrProduct1Name: [null],
        iadcPhHwSwUpgrProduct1CostIncreaseRate: [null, Validators.compose([Validators.required, Validators.min(0)])],
        iadcPhHwSwUpgrProduct1DescriptionMlfb: [null],
        iadcPhHwSwUpgrProduct1Cost: [null, Validators.required],
        iadcPhIuWTimesPerYear: [null, Validators.compose([Validators.required, Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        iadcPhIuWTask1Name: [null, Validators.required],
        iadcPhIuWTask1Description: [null],
        iadcPhIuWTask1EffortOnSite: [null, Validators.compose([Validators.required, Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        iadcPhIuWTask1MaterialCost: [null, Validators.compose([Validators.required, Validators.min(0)])],
        iadcPhIuWTask1ServiceCost: [null, Validators.required],
        iadcPhIuWTask1TotalCost: [null, Validators.required],
        iadcPhIuWTask2Name: [null, Validators.required],
        iadcPhIuWTask2Description: [null],
        iadcPhIuWTask2EffortOnSite: [null, Validators.compose([Validators.required, Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        iadcPhIuWTask2MaterialCost: [null, Validators.compose([Validators.required, Validators.min(0)])],
        iadcPhIuWTask2ServiceCost: [null, Validators.required],
        iadcPhIuWTask2TotalCost: [null, Validators.required],
        iadcPhIuWTask3Name: [null, Validators.required],
        iadcPhIuWTask3Description: [null],
        iadcPhIuWTask3EffortOnSite: [null, Validators.compose([Validators.required, Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        iadcPhIuWTask3MaterialCost: [null, Validators.compose([Validators.required, Validators.min(0)])],
        iadcPhIuWTask3ServiceCost: [null, Validators.required],
        iadcPhIuWTask3TotalCost: [null, Validators.required],
        iadcPhIuWTask4Name: [null, Validators.required],
        iadcPhIuWTask4Description: [null],
        iadcPhIuWTask4EffortOnSite: [null, Validators.compose([Validators.required, Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        iadcPhIuWTask4MaterialCost: [null, Validators.compose([Validators.required, Validators.min(0)])],
        iadcPhIuWTask4ServiceCost: [null, Validators.required],
        iadcPhIuWTask4TotalCost: [null, Validators.required],
        iadcPhIuWTask5Name: [null, Validators.required],
        iadcPhIuWTask5Description: [null],
        iadcPhIuWTask5EffortOnSite: [null, Validators.compose([Validators.required, Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        iadcPhIuWTask5MaterialCost: [null, Validators.compose([Validators.required, Validators.min(0)])],
        iadcPhIuWTask5ServiceCost: [null, Validators.required],
        iadcPhIuWTask5TotalCost: [null, Validators.required],
        iadcPhIuWTask6Name: [null, Validators.required],
        iadcPhIuWTask6Description: [null],
        iadcPhIuWTask6EffortOnSite: [null, Validators.compose([Validators.required, Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        iadcPhIuWTask6MaterialCost: [null, Validators.compose([Validators.required, Validators.min(0)])],
        iadcPhIuWTask6ServiceCost: [null, Validators.required],
        iadcPhIuWTask6TotalCost: [null, Validators.required],
        iadcPhIuWTask7Name: [null, Validators.required],
        iadcPhIuWTask7Description: [null],
        iadcPhIuWTask7EffortOnSite: [null, Validators.compose([Validators.required, Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        iadcPhIuWTask7MaterialCost: [null, Validators.compose([Validators.required, Validators.min(0)])],
        iadcPhIuWTask7ServiceCost: [null, Validators.required],
        iadcPhIuWTask7TotalCost: [null, Validators.required],
        iadcPhIuWTask8Name: [null, Validators.required],
        iadcPhIuWTask8Description: [null],
        iadcPhIuWTask8EffortOnSite: [null, Validators.compose([Validators.required, Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        iadcPhIuWTask8MaterialCost: [null, Validators.compose([Validators.required, Validators.min(0)])],
        iadcPhIuWTask8ServiceCost: [null, Validators.required],
        iadcPhIuWTask8TotalCost: [null, Validators.required],
        iadcPhIuWTask9Name: [null, Validators.required],
        iadcPhIuWTask9Description: [null],
        iadcPhIuWTask9EffortOnSite: [null, Validators.compose([Validators.required, Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        iadcPhIuWTask9MaterialCost: [null, Validators.compose([Validators.required, Validators.min(0)])],
        iadcPhIuWTask9ServiceCost: [null, Validators.required],
        iadcPhIuWTask9TotalCost: [null, Validators.required],
        iadcPhIuWTask10Name: [null, Validators.required],
        iadcPhIuWTask10Description: [null],
        iadcPhIuWTask10EffortOnSite: [null, Validators.compose([Validators.required, Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        iadcPhIuWTask10MaterialCost: [null, Validators.compose([Validators.required, Validators.min(0)])],
        iadcPhIuWTask10ServiceCost: [null, Validators.required],
        iadcPhIuWTask10TotalCost: [null, Validators.required],
        iadcPhIuWTask11Name: [null, Validators.required],
        iadcPhIuWTask11Description: [null],
        iadcPhIuWTask11EffortOnSite: [null, Validators.compose([Validators.required, Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        iadcPhIuWTask11MaterialCost: [null, Validators.compose([Validators.required, Validators.min(0)])],
        iadcPhIuWTask11ServiceCost: [null, Validators.required],
        iadcPhIuWTask11TotalCost: [null, Validators.required],
        iadcPhIuWTask12Name: [null, Validators.required],
        iadcPhIuWTask12Description: [null],
        iadcPhIuWTask12EffortOnSite: [null, Validators.compose([Validators.required, Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        iadcPhIuWTask12MaterialCost: [null, Validators.compose([Validators.required, Validators.min(0)])],
        iadcPhIuWTask12ServiceCost: [null, Validators.required],
        iadcPhIuWTask12TotalCost: [null, Validators.required],
        iadcPhIuWTask13Name: [null, Validators.required],
        iadcPhIuWTask13Description: [null],
        iadcPhIuWTask13EffortOnSite: [null, Validators.compose([Validators.required, Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        iadcPhIuWTask13MaterialCost: [null, Validators.compose([Validators.required, Validators.min(0)])],
        iadcPhIuWTask13ServiceCost: [null, Validators.required],
        iadcPhIuWTask13TotalCost: [null, Validators.required],
        iadcPhIuWTask14Name: [null, Validators.required],
        iadcPhIuWTask14Description: [null],
        iadcPhIuWTask14EffortOnSite: [null, Validators.compose([Validators.required, Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        iadcPhIuWTask14MaterialCost: [null, Validators.compose([Validators.required, Validators.min(0)])],
        iadcPhIuWTask14ServiceCost: [null, Validators.required],
        iadcPhIuWTask14TotalCost: [null, Validators.required],
        iadcPhIuWTask15Name: [null, Validators.required],
        iadcPhIuWTask15Description: [null],
        iadcPhIuWTask15EffortOnSite: [null, Validators.compose([Validators.required, Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        iadcPhIuWTask15MaterialCost: [null, Validators.compose([Validators.required, Validators.min(0)])],
        iadcPhIuWTask15ServiceCost: [null, Validators.required],
        iadcPhIuWTask15TotalCost: [null, Validators.required],
        iadcPhIuWTask16Name: [null, Validators.required],
        iadcPhIuWTask16Description: [null],
        iadcPhIuWTask16EffortOnSite: [null, Validators.compose([Validators.required, Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        iadcPhIuWTask16MaterialCost: [null, Validators.compose([Validators.required, Validators.min(0)])],
        iadcPhIuWTask16ServiceCost: [null, Validators.required],
        iadcPhIuWTask16TotalCost: [null, Validators.required],
        iadcPhIuWSelectedYear1: [null, Validators.required],
        iadcPhIuWSelectedYear2: [null, Validators.required],
        iadcPhIuWSelectedYear3: [null, Validators.required],
        iadcPhIuWSelectedYear4: [null, Validators.required],
        iadcPhIuWSelectedYear5: [null, Validators.required],
        iadcPhIuWSelectedYear6: [null, Validators.required],
        iadcPhIuWSelectedYear7: [null, Validators.required],
        iadcPhIuWSelectedYear8: [null, Validators.required],
        iadcPhIuWSelectedYear9: [null, Validators.required],
        iadcPhIuWSelectedYear10: [null, Validators.required],
        iadcPhIuWSelectedYear11: [null, Validators.required],
        iadcPhIuWSelectedYear12: [null, Validators.required],
        iadcPhIuWSelectedYear13: [null, Validators.required],
        iadcPhIuWSelectedYear14: [null, Validators.required],
        iadcPhIuWSelectedYear15: [null, Validators.required],
        iadcPhServiceContractCostIncreaseRate: [null, Validators.compose([Validators.required, Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        iadcPhServiceContract1Name: [null, Validators.required],
        iadcPhServiceContract1MLFB: [null, Validators.required],
        iadcPhServiceContract1Duration: [null, Validators.compose([Validators.required, Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        iadcPhServiceContract1Selected: [null, Validators.required],
        iadcPhServiceContract1InitialYear: [null, Validators.compose([Validators.required, Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        iadcPhServiceContract1Cost: [null, Validators.compose([Validators.required, Validators.min(0)])],
        iadcPhServiceContract1SelectedYear1: [null, Validators.required],
        iadcPhServiceContract1SelectedYear2: [null, Validators.required],
        iadcPhServiceContract1SelectedYear3: [null, Validators.required],
        iadcPhServiceContract1SelectedYear4: [null, Validators.required],
        iadcPhServiceContract1SelectedYear5: [null, Validators.required],
        iadcPhServiceContract1SelectedYear6: [null, Validators.required],
        iadcPhServiceContract1SelectedYear7: [null, Validators.required],
        iadcPhServiceContract1SelectedYear8: [null, Validators.required],
        iadcPhServiceContract1SelectedYear9: [null, Validators.required],
        iadcPhServiceContract1SelectedYear10: [null, Validators.required],
        iadcPhServiceContract1SelectedYear11: [null, Validators.required],
        iadcPhServiceContract1SelectedYear12: [null, Validators.required],
        iadcPhServiceContract1SelectedYear13: [null, Validators.required],
        iadcPhServiceContract1SelectedYear14: [null, Validators.required],
        iadcPhServiceContract1SelectedYear15: [null, Validators.required],
        iadcPhCostOverviewHwSwUpgServicesYear1: [null, Validators.required],
        iadcPhCostOverviewHwSwUpgServicesYear2: [null, Validators.required],
        iadcPhCostOverviewHwSwUpgServicesYear3: [null, Validators.required],
        iadcPhCostOverviewHwSwUpgServicesYear4: [null, Validators.required],
        iadcPhCostOverviewHwSwUpgServicesYear5: [null, Validators.required],
        iadcPhCostOverviewHwSwUpgServicesYear6: [null, Validators.required],
        iadcPhCostOverviewHwSwUpgServicesYear7: [null, Validators.required],
        iadcPhCostOverviewHwSwUpgServicesYear8: [null, Validators.required],
        iadcPhCostOverviewHwSwUpgServicesYear9: [null, Validators.required],
        iadcPhCostOverviewHwSwUpgServicesYear10: [null, Validators.required],
        iadcPhCostOverviewHwSwUpgServicesYear11: [null, Validators.required],
        iadcPhCostOverviewHwSwUpgServicesYear12: [null, Validators.required],
        iadcPhCostOverviewHwSwUpgServicesYear13: [null, Validators.required],
        iadcPhCostOverviewHwSwUpgServicesYear14: [null, Validators.required],
        iadcPhCostOverviewHwSwUpgServicesYear15: [null, Validators.required],
        iadcPhCostOverviewHwSwUpgProductsYear1: [null, Validators.required],
        iadcPhCostOverviewHwSwUpgProductsYear2: [null, Validators.required],
        iadcPhCostOverviewHwSwUpgProductsYear3: [null, Validators.required],
        iadcPhCostOverviewHwSwUpgProductsYear4: [null, Validators.required],
        iadcPhCostOverviewHwSwUpgProductsYear5: [null, Validators.required],
        iadcPhCostOverviewHwSwUpgProductsYear6: [null, Validators.required],
        iadcPhCostOverviewHwSwUpgProductsYear7: [null, Validators.required],
        iadcPhCostOverviewHwSwUpgProductsYear8: [null, Validators.required],
        iadcPhCostOverviewHwSwUpgProductsYear9: [null, Validators.required],
        iadcPhCostOverviewHwSwUpgProductsYear10: [null, Validators.required],
        iadcPhCostOverviewHwSwUpgProductsYear11: [null, Validators.required],
        iadcPhCostOverviewHwSwUpgProductsYear12: [null, Validators.required],
        iadcPhCostOverviewHwSwUpgProductsYear13: [null, Validators.required],
        iadcPhCostOverviewHwSwUpgProductsYear14: [null, Validators.required],
        iadcPhCostOverviewHwSwUpgProductsYear15: [null, Validators.required],
        iadcPhCostOverviewIuWYear1: [null, Validators.required],
        iadcPhCostOverviewIuWYear2: [null, Validators.required],
        iadcPhCostOverviewIuWYear3: [null, Validators.required],
        iadcPhCostOverviewIuWYear4: [null, Validators.required],
        iadcPhCostOverviewIuWYear5: [null, Validators.required],
        iadcPhCostOverviewIuWYear6: [null, Validators.required],
        iadcPhCostOverviewIuWYear7: [null, Validators.required],
        iadcPhCostOverviewIuWYear8: [null, Validators.required],
        iadcPhCostOverviewIuWYear9: [null, Validators.required],
        iadcPhCostOverviewIuWYear10: [null, Validators.required],
        iadcPhCostOverviewIuWYear11: [null, Validators.required],
        iadcPhCostOverviewIuWYear12: [null, Validators.required],
        iadcPhCostOverviewIuWYear13: [null, Validators.required],
        iadcPhCostOverviewIuWYear14: [null, Validators.required],
        iadcPhCostOverviewIuWYear15: [null, Validators.required],
        iadcPhCostOverviewServiceContractsYear1: [null, Validators.required],
        iadcPhCostOverviewServiceContractsYear2: [null, Validators.required],
        iadcPhCostOverviewServiceContractsYear3: [null, Validators.required],
        iadcPhCostOverviewServiceContractsYear4: [null, Validators.required],
        iadcPhCostOverviewServiceContractsYear5: [null, Validators.required],
        iadcPhCostOverviewServiceContractsYear6: [null, Validators.required],
        iadcPhCostOverviewServiceContractsYear7: [null, Validators.required],
        iadcPhCostOverviewServiceContractsYear8: [null, Validators.required],
        iadcPhCostOverviewServiceContractsYear9: [null, Validators.required],
        iadcPhCostOverviewServiceContractsYear10: [null, Validators.required],
        iadcPhCostOverviewServiceContractsYear11: [null, Validators.required],
        iadcPhCostOverviewServiceContractsYear12: [null, Validators.required],
        iadcPhCostOverviewServiceContractsYear13: [null, Validators.required],
        iadcPhCostOverviewServiceContractsYear14: [null, Validators.required],
        iadcPhCostOverviewServiceContractsYear15: [null, Validators.required],
        iadcPhCostOverviewTotalYear1: [null, Validators.required],
        iadcPhCostOverviewTotalYear2: [null, Validators.required],
        iadcPhCostOverviewTotalYear3: [null, Validators.required],
        iadcPhCostOverviewTotalYear4: [null, Validators.required],
        iadcPhCostOverviewTotalYear5: [null, Validators.required],
        iadcPhCostOverviewTotalYear6: [null, Validators.required],
        iadcPhCostOverviewTotalYear7: [null, Validators.required],
        iadcPhCostOverviewTotalYear8: [null, Validators.required],
        iadcPhCostOverviewTotalYear9: [null, Validators.required],
        iadcPhCostOverviewTotalYear10: [null, Validators.required],
        iadcPhCostOverviewTotalYear11: [null, Validators.required],
        iadcPhCostOverviewTotalYear12: [null, Validators.required],
        iadcPhCostOverviewTotalYear13: [null, Validators.required],
        iadcPhCostOverviewTotalYear14: [null, Validators.required],
        iadcPhCostOverviewTotalYear15: [null, Validators.required],
        iadcPhCostOverviewIncludedRiskYear1: [null, Validators.required],
        iadcPhCostOverviewIncludedRiskYear2: [null, Validators.required],
        iadcPhCostOverviewIncludedRiskYear3: [null, Validators.required],
        iadcPhCostOverviewIncludedRiskYear4: [null, Validators.required],
        iadcPhCostOverviewIncludedRiskYear5: [null, Validators.required],
        iadcPhCostOverviewIncludedRiskYear6: [null, Validators.required],
        iadcPhCostOverviewIncludedRiskYear7: [null, Validators.required],
        iadcPhCostOverviewIncludedRiskYear8: [null, Validators.required],
        iadcPhCostOverviewIncludedRiskYear9: [null, Validators.required],
        iadcPhCostOverviewIncludedRiskYear10: [null, Validators.required],
        iadcPhCostOverviewIncludedRiskYear11: [null, Validators.required],
        iadcPhCostOverviewIncludedRiskYear12: [null, Validators.required],
        iadcPhCostOverviewIncludedRiskYear13: [null, Validators.required],
        iadcPhCostOverviewIncludedRiskYear14: [null, Validators.required],
        iadcPhCostOverviewIncludedRiskYear15: [null, Validators.required],
        firstUpgradeOverall: [null],
        secondUpgradeOverall: [null],
        thirdUpgradeOverall: [null],
        lcsContractLastYear: [null]
      }),
      iadcBackupRestore: this.formBuilder.group({
        yearOfStartFix: [null],
        lcsContractDuration: [null],
        iadcBackupRestoreProductType: [null, Validators.required],
        iadcBackupRestoreSelectedCb: [null, Validators.required],
        iadcBackupRestoreQuantity: [null, Validators.required],
        iadcBackupRestoreServiceCostIncreaseRate: [null, Validators.compose([Validators.required, Validators.min(0)])],
        iadcBackupRestoreProductCostIncreaseRate: [null, Validators.compose([Validators.required, Validators.min(0)])],
        iadcBackupRestoreRiskAddition: [null, Validators.compose([Validators.required, Validators.min(0)])],
        iadcBackupRestoreMargin: [null, Validators.compose([Validators.required, Validators.min(0)])],
        iadcBackupRestoreRegionalDiscount: [null, Validators.compose([Validators.required, Validators.min(0)])],
        iadcBackupRestoreHwSwUpgrServiceTask1Name: [null, Validators.required],
        iadcBackupRestoreHwSwUpgrServiceTask1Description: [null, Validators.required],
        iadcBackupRestoreHwSwUpgrServiceTask1EffortOnSite: [null, Validators.compose([Validators.required, Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        iadcBackupRestoreHwSwUpgrServiceTask1EffortOffSite: [null, Validators.compose([Validators.required, Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        iadcBackupRestoreHwSwUpgrServiceTask1ServiceCost: [null, Validators.required],
        iadcBackupRestoreHwSwUpgrServiceTask2Name: [null, Validators.required],
        iadcBackupRestoreHwSwUpgrServiceTask2Description: [null, Validators.required],
        iadcBackupRestoreHwSwUpgrServiceTask2EffortOnSite: [null, Validators.compose([Validators.required, Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        iadcBackupRestoreHwSwUpgrServiceTask2EffortOffSite: [null, Validators.compose([Validators.required, Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        iadcBackupRestoreHwSwUpgrServiceTask2ServiceCost: [null, Validators.required],
        iadcBackupRestoreHwSwUpgrProduct1Name: [null, Validators.required],
        iadcBackupRestoreHwSwUpgrProduct1CostIncreaseRate: [null, Validators.compose([Validators.required, Validators.min(0)])],
        iadcBackupRestoreHwSwUpgrProduct1Mlfb: [null, Validators.required],
        iadcBackupRestoreHwSwUpgrProduct1Cost: [null, Validators.required],
        iadcBackupRestoreServiceContractCostIncreaseRate: [null, Validators.compose([Validators.required, Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        iadcBackupRestoreServiceContract1Name: [null, Validators.required],
        iadcBackupRestoreServiceContract1MLFB: [null, Validators.required],
        iadcBackupRestoreServiceContract1Duration: [null, Validators.compose([Validators.required, Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        iadcBackupRestoreServiceContract1Selected: [null, Validators.required],
        iadcBackupRestoreServiceContract1InitialYear: [null, Validators.compose([Validators.required, Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)?$/)])],
        iadcBackupRestoreServiceContract1Cost: [null, Validators.compose([Validators.required, Validators.min(0)])],
        iadcBackupRestoreServiceContract1SelectedYear1: [null, Validators.required],
        iadcBackupRestoreServiceContract1SelectedYear2: [null, Validators.required],
        iadcBackupRestoreServiceContract1SelectedYear3: [null, Validators.required],
        iadcBackupRestoreServiceContract1SelectedYear4: [null, Validators.required],
        iadcBackupRestoreServiceContract1SelectedYear5: [null, Validators.required],
        iadcBackupRestoreServiceContract1SelectedYear6: [null, Validators.required],
        iadcBackupRestoreServiceContract1SelectedYear7: [null, Validators.required],
        iadcBackupRestoreServiceContract1SelectedYear8: [null, Validators.required],
        iadcBackupRestoreServiceContract1SelectedYear9: [null, Validators.required],
        iadcBackupRestoreServiceContract1SelectedYear10: [null, Validators.required],
        iadcBackupRestoreServiceContract1SelectedYear11: [null, Validators.required],
        iadcBackupRestoreServiceContract1SelectedYear12: [null, Validators.required],
        iadcBackupRestoreServiceContract1SelectedYear13: [null, Validators.required],
        iadcBackupRestoreServiceContract1SelectedYear14: [null, Validators.required],
        iadcBackupRestoreServiceContract1SelectedYear15: [null, Validators.required],
        iadcBackupRestoreCostOverviewHwSwUpgServicesYear1: [null, Validators.required],
        iadcBackupRestoreCostOverviewHwSwUpgServicesYear2: [null, Validators.required],
        iadcBackupRestoreCostOverviewHwSwUpgServicesYear3: [null, Validators.required],
        iadcBackupRestoreCostOverviewHwSwUpgServicesYear4: [null, Validators.required],
        iadcBackupRestoreCostOverviewHwSwUpgServicesYear5: [null, Validators.required],
        iadcBackupRestoreCostOverviewHwSwUpgServicesYear6: [null, Validators.required],
        iadcBackupRestoreCostOverviewHwSwUpgServicesYear7: [null, Validators.required],
        iadcBackupRestoreCostOverviewHwSwUpgServicesYear8: [null, Validators.required],
        iadcBackupRestoreCostOverviewHwSwUpgServicesYear9: [null, Validators.required],
        iadcBackupRestoreCostOverviewHwSwUpgServicesYear10: [null, Validators.required],
        iadcBackupRestoreCostOverviewHwSwUpgServicesYear11: [null, Validators.required],
        iadcBackupRestoreCostOverviewHwSwUpgServicesYear12: [null, Validators.required],
        iadcBackupRestoreCostOverviewHwSwUpgServicesYear13: [null, Validators.required],
        iadcBackupRestoreCostOverviewHwSwUpgServicesYear14: [null, Validators.required],
        iadcBackupRestoreCostOverviewHwSwUpgServicesYear15: [null, Validators.required],
        iadcBackupRestoreCostOverviewHwSwUpgProductsYear1: [null, Validators.required],
        iadcBackupRestoreCostOverviewHwSwUpgProductsYear2: [null, Validators.required],
        iadcBackupRestoreCostOverviewHwSwUpgProductsYear3: [null, Validators.required],
        iadcBackupRestoreCostOverviewHwSwUpgProductsYear4: [null, Validators.required],
        iadcBackupRestoreCostOverviewHwSwUpgProductsYear5: [null, Validators.required],
        iadcBackupRestoreCostOverviewHwSwUpgProductsYear6: [null, Validators.required],
        iadcBackupRestoreCostOverviewHwSwUpgProductsYear7: [null, Validators.required],
        iadcBackupRestoreCostOverviewHwSwUpgProductsYear8: [null, Validators.required],
        iadcBackupRestoreCostOverviewHwSwUpgProductsYear9: [null, Validators.required],
        iadcBackupRestoreCostOverviewHwSwUpgProductsYear10: [null, Validators.required],
        iadcBackupRestoreCostOverviewHwSwUpgProductsYear11: [null, Validators.required],
        iadcBackupRestoreCostOverviewHwSwUpgProductsYear12: [null, Validators.required],
        iadcBackupRestoreCostOverviewHwSwUpgProductsYear13: [null, Validators.required],
        iadcBackupRestoreCostOverviewHwSwUpgProductsYear14: [null, Validators.required],
        iadcBackupRestoreCostOverviewHwSwUpgProductsYear15: [null, Validators.required],
        iadcBackupRestoreCostOverviewTotalYear1: [null, Validators.required],
        iadcBackupRestoreCostOverviewTotalYear2: [null, Validators.required],
        iadcBackupRestoreCostOverviewTotalYear3: [null, Validators.required],
        iadcBackupRestoreCostOverviewTotalYear4: [null, Validators.required],
        iadcBackupRestoreCostOverviewTotalYear5: [null, Validators.required],
        iadcBackupRestoreCostOverviewTotalYear6: [null, Validators.required],
        iadcBackupRestoreCostOverviewTotalYear7: [null, Validators.required],
        iadcBackupRestoreCostOverviewTotalYear8: [null, Validators.required],
        iadcBackupRestoreCostOverviewTotalYear9: [null, Validators.required],
        iadcBackupRestoreCostOverviewTotalYear10: [null, Validators.required],
        iadcBackupRestoreCostOverviewTotalYear11: [null, Validators.required],
        iadcBackupRestoreCostOverviewTotalYear12: [null, Validators.required],
        iadcBackupRestoreCostOverviewTotalYear13: [null, Validators.required],
        iadcBackupRestoreCostOverviewTotalYear14: [null, Validators.required],
        iadcBackupRestoreCostOverviewTotalYear15: [null, Validators.required],
        iadcBackupRestoreCostOverviewServiceContractsYear1: [null, Validators.required],
        iadcBackupRestoreCostOverviewServiceContractsYear2: [null, Validators.required],
        iadcBackupRestoreCostOverviewServiceContractsYear3: [null, Validators.required],
        iadcBackupRestoreCostOverviewServiceContractsYear4: [null, Validators.required],
        iadcBackupRestoreCostOverviewServiceContractsYear5: [null, Validators.required],
        iadcBackupRestoreCostOverviewServiceContractsYear6: [null, Validators.required],
        iadcBackupRestoreCostOverviewServiceContractsYear7: [null, Validators.required],
        iadcBackupRestoreCostOverviewServiceContractsYear8: [null, Validators.required],
        iadcBackupRestoreCostOverviewServiceContractsYear9: [null, Validators.required],
        iadcBackupRestoreCostOverviewServiceContractsYear10: [null, Validators.required],
        iadcBackupRestoreCostOverviewServiceContractsYear11: [null, Validators.required],
        iadcBackupRestoreCostOverviewServiceContractsYear12: [null, Validators.required],
        iadcBackupRestoreCostOverviewServiceContractsYear13: [null, Validators.required],
        iadcBackupRestoreCostOverviewServiceContractsYear14: [null, Validators.required],
        iadcBackupRestoreCostOverviewServiceContractsYear15: [null, Validators.required],
        iadcBackupRestoreCostOverviewIncludedRiskYear1: [null, Validators.required],
        iadcBackupRestoreCostOverviewIncludedRiskYear2: [null, Validators.required],
        iadcBackupRestoreCostOverviewIncludedRiskYear3: [null, Validators.required],
        iadcBackupRestoreCostOverviewIncludedRiskYear4: [null, Validators.required],
        iadcBackupRestoreCostOverviewIncludedRiskYear5: [null, Validators.required],
        iadcBackupRestoreCostOverviewIncludedRiskYear6: [null, Validators.required],
        iadcBackupRestoreCostOverviewIncludedRiskYear7: [null, Validators.required],
        iadcBackupRestoreCostOverviewIncludedRiskYear8: [null, Validators.required],
        iadcBackupRestoreCostOverviewIncludedRiskYear9: [null, Validators.required],
        iadcBackupRestoreCostOverviewIncludedRiskYear10: [null, Validators.required],
        iadcBackupRestoreCostOverviewIncludedRiskYear11: [null, Validators.required],
        iadcBackupRestoreCostOverviewIncludedRiskYear12: [null, Validators.required],
        iadcBackupRestoreCostOverviewIncludedRiskYear13: [null, Validators.required],
        iadcBackupRestoreCostOverviewIncludedRiskYear14: [null, Validators.required],
        iadcBackupRestoreCostOverviewIncludedRiskYear15: [null, Validators.required],
        firstUpgradeOverall: [null],
        secondUpgradeOverall: [null],
        thirdUpgradeOverall: [null],
        lcsContractLastYear: [null]
      })

    });

    const iadcFirewalls = this.iadcForm.get('iadcFirewalls') as FormGroup;

    for (let i = 1; i <= 15; i++){
      iadcFirewalls.addControl('iadcFirewallsProductName' + i, new FormControl(null));
      iadcFirewalls.addControl('iadcFirewallsProductDescription' + i, new FormControl(null));
      iadcFirewalls.addControl('iadcFirewallsProductMlfb' + i, new FormControl(null));
      iadcFirewalls.addControl('iadcFirewallsProductListPrice' + i, new FormControl(null));
      iadcFirewalls.addControl('iadcFirewallsProductQuantity' + i, new FormControl(null));
      iadcFirewalls.addControl('iadcFirewallsProductRelevantForIADC' + i, new FormControl(null));

      iadcFirewalls.addControl('iadcFirewallsServiceContractName' + i, new FormControl(null));
      iadcFirewalls.addControl('iadcFirewallsServiceContractMLFB' + i, new FormControl(null));
      iadcFirewalls.addControl('iadcFirewallsServiceContractInitialYear' + i, new FormControl(null));
      iadcFirewalls.addControl('iadcFirewallsProductMlfb' + i, new FormControl(null));
      iadcFirewalls.addControl('iadcFirewallsServiceContractListPrice' + i, new FormControl(null));
      iadcFirewalls.addControl('iadcFirewallsServiceContractQuantity' + i, new FormControl(null));
      iadcFirewalls.addControl('iadcFirewallsServiceContractRelevantForIADC' + i, new FormControl(null));

      iadcFirewalls.addControl('iadcFirewallsIuWSelectedYear' + i, new FormControl(null));

      iadcFirewalls.addControl('iadcFirewallsCostOverviewHwSwUpgServicesYear' + i, new FormControl(null));
      iadcFirewalls.addControl('iadcFirewallsCostOverviewHwSwUpgProductsYear' + i, new FormControl(null));
      iadcFirewalls.addControl('iadcFirewallsCostOverviewIuWYear' + i, new FormControl(null));
      iadcFirewalls.addControl('iadcFirewallsCostOverviewServiceContractsYear' + i, new FormControl(null));
      iadcFirewalls.addControl('iadcFirewallsCostOverviewTotalYear' + i, new FormControl(null));
      iadcFirewalls.addControl('iadcFirewallsCostOverviewIncludedRiskYear' + i, new FormControl(null));

      for (let j = 1; j <= 15; j++){
        iadcFirewalls.addControl('iadcFirewallsServiceContract' + i + 'SelectedYear' + j, new FormControl(null));
      }
      
      
    }

  }

  ngOnInit() {

    this.readData();
  }

  async submit(){
    await this.writeData();
  }

  async logInvalidControls(formGroup: FormGroup) {

    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.get(key);

      if (control instanceof FormGroup) {
        this.logInvalidControls(control);
      } else {
        if (control?.invalid) {

          console.log(`Control '${key}' is invalid`);
        }
      }
    });
  }

  async readData() {

    const keysFromDialog = await this.formDataService.getAllControlsFromDialogAsArray(this.iadcForm);

    const formData = await this.dataTransferService.getValuesFromExcel(keysFromDialog) as any;

    this.formDataService.fillDataIntoDialog(this.iadcForm, formData);
  }

  async writeData() {
    const jsonData = await this.formDataService.getDataFromDialog(this.iadcForm);

    await this.dataTransferService.writeDataToExcel(JSON.stringify(jsonData));

    await this.readData();
  }

  public findInvalidControls() {
    const controls = (this.iadcForm.get('iadcUSV') as FormGroup).controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        console.log(name);
      }
    }
  }

}
