<si-newton-section>

  <div class="row">
    <div class="col">
      <b class="subSubSectionHeader">Masterfile</b>
    </div>
  </div>

  <div class="row" style="margin-bottom: 10px;">

    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
      <div class="customLink" (click)="downloadFile('LCS_Master', masterFileData?.file_name || 'LCS_Master')">
        <b>{{masterFileData?.file_name || 'Download Masterfile'}}</b>
      </div>
      <div>{{masterFileData?.file_upload}}</div>
    </div>

    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
      <label class="btn btn-primary" style="margin: auto;">
        Select new Master File
        <span class="newton-search"></span>
        <input #fileSelection type="file" accept="application/msexcel" (change)="storeMasterFile()">
      </label>
    </div>

    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
      <button type="button" class="btn btn-success" (click)="uploadMasterfile()"
        [disabled]="masterFile === undefined">
        Upload new Masterfile
      </button>
    </div>
 
  </div>

  <div class="row" style="margin-top: 10px;">
    <div class="col">
      <b class="subSubSectionHeader">MLFB Reference</b>
    </div>
  </div>

  <div class="row" style="margin-bottom: 10px;">

    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
      <div class="customLink" (click)="downloadFile('mlfbReference', referenceFileData?.file_name || 'MLFB_Reference')">
        <b>{{referenceFileData?.file_name || 'Download Referencefile'}}</b>
      </div>
      <div>{{referenceFileData?.file_upload}}</div>
    </div>

    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
      <label class="btn btn-primary" style="margin: auto;">
        Select new Reference File
        <span class="newton-search"></span>
        <input #fileSelectionReference type="file" accept="application/msexcel" (change)="storeReferenceFile()">
      </label>
    </div>

    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
      <button type="button" class="btn btn-success" (click)="uploadReferencefile()"
        [disabled]="referenceFile === undefined">
        Upload new MLFB Reference
      </button>
    </div>
   
  </div>


</si-newton-section>
