import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-leave-without-save-modal',
  templateUrl: './leave-without-save-modal.component.html',
  styleUrls: ['./leave-without-save-modal.component.scss']
})
export class LeaveWithoutSaveModalComponent {


  constructor(public modalRef: BsModalRef) {}

  saveAndLeave(){

    this.modalRef.content.saveChanges = true;
    this.modalRef.content.leaveDialog = true;
    this.modalRef.hide();
  }

  discardAndLeave(){

    this.modalRef.content.saveChanges = false;
    this.modalRef.content.leaveDialog = true;
    this.modalRef.hide();
  }

  goBackToDialog(){
    this.modalRef.content.saveChanges = false;
    this.modalRef.content.leaveDialog = false;
    this.modalRef.hide();
  }

}
