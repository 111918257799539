import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PhysicalSystemComponentModel } from 'src/app/data-models/PhysicalSystemComponent';

@Component({
  selector: 'app-sw-addons',
  templateUrl: './sw-addons.component.html',
  styleUrls: ['./sw-addons.component.scss']
})
export class SwAddonsComponent {
  isThisSectionVisible = false;

  thirdPartySwAddonsFromPCS7Bom: PhysicalSystemComponentModel[] = [];
  thirdPartySwAddonsFromPcsNeoBom: PhysicalSystemComponentModel[] = [];

  async toggleVisibility(){

    await this.fillThirdPartySwComponentsList();
    this.isThisSectionVisible = !this.isThisSectionVisible;
  }

  async fillThirdPartySwComponentsList(){
    this.thirdPartySwAddonsFromPCS7Bom = [];
    this.thirdPartySwAddonsFromPcsNeoBom = [];

    const relevantThirdPartySWComponentTypes: string[] = [
      '3-Party SW Addons'
    ];

    for (let i = 1; i <= 200; i++){
      console.log(i);

      if (this.isPhysicalSystemComponentPresent(i, 'PCS 7')){
        console.log(i);
        const componentType = this.parentForm.get('swAddons')?.get('physicalSystemComponentType' + i)?.value;


        if (relevantThirdPartySWComponentTypes.includes(componentType)){
          this.thirdPartySwAddonsFromPCS7Bom.push({
            'componentNumber': i,
            'componentType': this.parentForm.get('swAddons')?.get('physicalSystemComponentType' + i)?.value,
            'mlfb': this.parentForm.get('swAddons')?.get('physicalSystemMlfb' + i)?.value,
            'quantity': this.parentForm.get('swAddons')?.get('physicalSystemQuantity' + i)?.value,
            'listPrice': this.parentForm.get('swAddons')?.get('physicalSystemPrice' + i)?.value,
            'description': this.parentForm.get('swAddons')?.get('physicalSystemDesc' + i)?.value
          });
        }
      }

      if (this.isPhysicalSystemComponentPresent(i, 'PCS neo')){

        const componentType = this.parentForm.get('swAddons')?.get('physicalSystemNeoComponentType' + i)?.value;

        if (relevantThirdPartySWComponentTypes.includes(componentType)){
          this.thirdPartySwAddonsFromPcsNeoBom.push({
            'componentNumber': i,
            'componentType': this.parentForm.get('swAddons')?.get('physicalSystemNeoComponentType' + i)?.value,
            'mlfb': this.parentForm.get('swAddons')?.get('physicalSystemNeoMlfb' + i)?.value,
            'quantity': this.parentForm.get('swAddons')?.get('physicalSystemNeoQuantity' + i)?.value,
            'listPrice': this.parentForm.get('swAddons')?.get('physicalSystemNeoPrice' + i)?.value,
            'description': this.parentForm.get('swAddons')?.get('physicalSystemNeoDesc' + i)?.value
          });
        }
      }
    }

  }

  isPhysicalSystemComponentPresent(compNumber: number, pcsVersion: string): boolean {

    let componentTypeVarName = '';
    if (pcsVersion === 'PCS neo'){
      componentTypeVarName = 'physicalSystemNeoComponentType';
    } else {
      componentTypeVarName = 'physicalSystemComponentType';
    }

    try {
      if (this.parentForm.get('swAddons')?.get(componentTypeVarName + compNumber)?.value){
        return true;
      } else {
        return false;
      }
    } catch {
      return false;
    }
  }

  @Input()
  parentForm!: FormGroup;

  constructor() { }

}
