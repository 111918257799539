<form [formGroup]="parentForm">
  <div formGroupName="riskFactors">

    <h5 class="subSectionHeader">Risk Factors</h5>

    <div class="row">
      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <si-newton-form-group label="Service Risk" [errorResolverMap]="{required: 'Value is required'}">
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
          <input formControlName="serviceRisk" type="number" step="any" [min]="0" siNewton>
        </si-newton-form-group>
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <si-newton-form-group label="PCS 7 / neo Upgrade Risk" [errorResolverMap]="{required: 'Value is required'}">
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
          <input formControlName="upgradeRisk" type="number" step="any" [min]="0" siNewton>
        </si-newton-form-group>
      </div>

      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <si-newton-form-group label="PCS neo Evolution Risk" [errorResolverMap]="{required: 'Value is required'}">
          <div class="input-group-prepend">
            <span class="input-group-text">%</span>
          </div>
          <input formControlName="pcsNeoEvolutionRisk" type="number" step="any" [min]="0" siNewton>
        </si-newton-form-group>
      </div>


    </div>
  </div>
</form>
