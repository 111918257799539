import { Component, ElementRef, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AdminService } from 'src/app/services/admin.service';
import { CustomToastService } from 'src/app/services/custom-toast.service';
import { ProjectManagementService } from 'src/app/services/project-management.service';

@Component({
  selector: 'app-upload-project-modal',
  templateUrl: './upload-project-modal.component.html',
  styleUrls: ['./upload-project-modal.component.scss']
})
export class UploadProjectModalComponent {

  projectId!: string;

  @ViewChild('fileSelection', { static: true })
  fileSelection: ElementRef<HTMLInputElement> | undefined;

  projectFile: File | undefined;

  constructor(private projectManagementService: ProjectManagementService, public modalRef: BsModalRef,
    private toastService: CustomToastService, private adminService: AdminService) { }

  async storeBomFile(){

    this.projectFile = this.fileSelection?.nativeElement.files?.item(0) || undefined;

  }

  async uploadProject(){

    try {

      if (this.projectFile !== undefined){
        await this.projectManagementService.closeCalculationFileForAllUsers(this.projectId, false);
        await this.adminService.uploadCalculationFile(this.projectId, this.projectFile);
        this.modalRef.hide();
      } else {
        this.toastService.showErrorToast('No project file selected');
        this.modalRef.hide();
      }
    } catch (err){
      await this.projectManagementService.closeCalculationFileForAllUsers(this.projectId, false);

      this.toastService.showErrorToast('Upload of new Calculation File failed');
      this.modalRef.hide();
    }
  }

}
