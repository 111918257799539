import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-pricing-factors',
  templateUrl: './pricing-factors.component.html',
  styleUrls: ['./pricing-factors.component.scss']
})
export class PricingFactorsComponent {

  @Input()
  public parentForm!: FormGroup;

  constructor() { }

  stringToNumber(value: string){
    return Number(value);
  }

}
