import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-iadc-firewalls',
  templateUrl: './iadc-firewalls.component.html',
  styleUrls: ['./iadc-firewalls.component.scss']
})

export class IadcFirewallsComponent implements OnInit {
  contractDuration = 0;

  formName = 'iadcFirewalls';

  stringToNumber(num: string){
    return parseInt(num, 10);
  }

  @Input()
  public parentForm!: FormGroup;

  isThisSectionVisible = false;
  
  toggleVisibility(){
    this.isThisSectionVisible = !this.isThisSectionVisible;
  }

  constructor() { }

  ngOnInit(): void {
  }

}
